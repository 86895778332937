import { ReactElement, useEffect, useState } from "react";
import { Persona } from "../../../models/users/Users";
import { deleteParticipanteAdicional, getParticipantesAdicionales, postParticipantesAdicionales } from "../../../api/UsersApi";
import cogoToast from "cogo-toast";
import EditPersonasComponent from "../../users/EditPersonasComponent";
import { SelectOption } from "../../../models/optionsFilters/Select";

interface Props {
    title: string,
    funcion: string,
    tipoComponente?: string,
    modeloComponente?: string,
    isNotDisabled: boolean,
    userPermission: boolean,
    isBusy: boolean,
    rolUsuarioOptions: SelectOption<string>[],
}

const ParticipantesAdicionalesItem = ({ title, funcion, userPermission, tipoComponente, modeloComponente, isNotDisabled, isBusy, rolUsuarioOptions }: Props): ReactElement => {

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ loadFailed, setLoadFailed ] = useState<boolean>(false);
    const [ participantes, setParticipantes ] = useState<Persona[]>([]);

    useEffect(() => {
        if (isNotDisabled) {
            setIsLoading(true);
            getParticipantesAdicionales({
                tipoComponente: tipoComponente || '',
                modeloComponente: modeloComponente || '',
                funcion: funcion
            }).then((response) => {
                setParticipantes(response.data);
                setIsLoading(false);
            }).catch(() => {
                cogoToast.error(`No fue posible obtener las opciones de ${title}`);
                setIsLoading(false);
                setLoadFailed(true);
            })
        }
    }, [tipoComponente, modeloComponente]);

    return (
        <EditPersonasComponent
            title = {title}
            subjectsName = "Participantes adicionales"
            userPermission = {userPermission}
            isUpdatingTarea = {isBusy}
            isLoadingAddresses = {isLoading}
            addressLoadFailed = {loadFailed}
            existingPersonas = {participantes}
            isWaiting = {!isNotDisabled}
            rolUsuarioOptions = {rolUsuarioOptions}
            addPersonas = {(personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => {
                if (isNotDisabled) {
                    postParticipantesAdicionales({
                        tipoComponente: tipoComponente || '',
                        modeloComponente: modeloComponente || '',
                        participantesCorreoID: personas.map((persona) => persona.email),
                        funcion: funcion
                    }).then((response) => {
                        if (response.data) {
                            setParticipantes(response.data);
                            cogoToast.success('Opciones de participantes adicionales actualizados');
                            hideModal();
                        } else {
                            cogoToast.error('No fue posible actualizar las opciones de participantes adicionales');
                        }
                        setStateToFalse();
                    }).catch(() => {
                        cogoToast.error('No fue posible actualizar las opciones de participantes adicionales');
                        setStateToFalse();
                    })
                }
            }}
            deletePersona = {(persona: Persona, hideModal: () => void, setStateToFalse: () => void) => {
                if (isNotDisabled) {
                    deleteParticipanteAdicional({
                        tipoComponente: tipoComponente || '',
                        modeloComponente: modeloComponente || '',
                        participantesCorreoID: [persona.email],
                        funcion: funcion
                    }).then((response) => {
                        if (response.data) {
                            setParticipantes(response.data);
                            cogoToast.success('Opciones de participantes adicionales actualizados');
                            hideModal();
                        } else {
                            cogoToast.error('No fue posible actualizar las opciones de participantes adicionales');
                        }
                        setStateToFalse();
                    }).catch(() => {
                        cogoToast.error('No fue posible actualizar las opciones de participantes adicionales');
                        setStateToFalse();
                    })
                }
            }}
        />
    );
}

export default ParticipantesAdicionalesItem;