import getMenuBarItems from "./menubar";

export const isUrlCliente = (url: string): boolean => ((/\/componentesQR\/.+/).test(url) || url === '/');

const urlChecker = (mainUrl: string, incomingUrl: string): boolean => ((new RegExp("\\" + mainUrl + "(\\/.+)*")).test(incomingUrl) || (/\/users\/currentUser\/.+/).test(incomingUrl));

export const checkUrlByRoles = (roles: string[], url: string): boolean => {
    const menuBarItems = getMenuBarItems(roles);
    if (menuBarItems.length === 0) {
        if (roles.includes("CLIENTE")) {
            return isUrlCliente(url);
        } else {
            return false;
        }
    } else {
        return menuBarItems.some((item) => urlChecker(item.href, url));
    }
}

export const fixUrlSpecialCharacters = (item: string): string => item.replace(/[/\\]/, '%2f');

// export const revertUrlSpecialCharacters = (item: string): string => item.replace('%2f')