import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { isUserEmailValid } from "../../utils/users";
import cogoToast from "cogo-toast";
import { getDestinatariosReporteOPNS } from "../../api/EvaluacionesApi";
import { getReporteOPNSEmail, generalSendEmailInModal } from "../../utils/emails";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import { Persona } from "../../models/users/Users";
import { getCorreoEmisorOperario } from "../../api/UsersApi";
import { EmailReport, EvaluacionOrReparacionComponenteEtapa } from "../../models/EvaluacionesReparaciones";

interface Props {
    emailReport: EmailReport,
    userData: { userName: string, userEmail: string, userRoles: string[] },
    codigoOP: string,
    isNotEditable: boolean
    evaluacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa
}

const EvaluacionComponenteDetallesEmails = ({ emailReport, userData, codigoOP, evaluacionComponenteEtapa, isNotEditable }: Props): ReactElement => {

    const [ show, setShow ] = useState<boolean>(false);
    const [ addresses, setAddresses ] = useState<Persona[]>();
    const [ copiedAddresses, setCopiedAddresses ] = useState<Persona[]>();
    const [ userEmail, setUserEmail ] = useState<string>(userData.userEmail);
    const [ isSending, setIsSending ] = useState<boolean>(false);
    const [ validUserEmail, setValidUserEmail ] = useState<boolean>(false);
    const [ isGettingAddresses, setIsGettingAddresses ] = useState<boolean>(false);
    const [ events, setEvents ] = useState<string[]>([]);
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();

    const hideModal = () => setShow(false);

    useEffect(() => {
        (async () => {
            if (show) {
                setIsGettingAddresses(true);
                const defaultUserRol = userData.userRoles.find((rol) => rol.includes('OPERARIO'));
                await getOrdenProceso(codigoOP)
                    .then(async (response) => {
                        if (response.data) {
                            setOrdenProceso(response.data);
                            const events: string[] = [];
                            let breakCounter = 0;
                            for (let i = 0; i < evaluacionComponenteEtapa.tareas.length; i++) {
                                if (evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar N/S' || evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar OP') {
                                    const inputs = evaluacionComponenteEtapa.tareas[i].inputs;
                                    if (inputs) {
                                        for (let e = 0; e < inputs.length; e++) {
                                            if (evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar N/S' && inputs[e].name === 'Número de serie') {
                                                if (inputs[e].value && inputs[e].value !== response.data.numeroSerieComponente) {
                                                    events.push('No corresponde el número de serie');
                                                }
                                                break;
                                            } else if (evaluacionComponenteEtapa.tareas[i].nombre === 'Confirmar OP' && inputs[e].name === 'Orden de proceso') {
                                                if (inputs[e].value && inputs[e].value !== response.data.numeroSerieComponente) {
                                                    events.push('No corresponde el código de OP');
                                                }
                                                break;
                                            }
                                        }
                                    }
                                    breakCounter++;
                                }
                                if (breakCounter === 2) {
                                    break;
                                }
                            }
                            setEvents(events);
                            await getDestinatariosReporteOPNS()
                                .then((response) => {
                                    setCopiedAddresses(response.data.cc);
                                    setAddresses(response.data.to);
                                }).catch(() => {
                                    cogoToast.error('No fue posible adquirir los destinatarios');
                                });
                            if (defaultUserRol) {
                                await getCorreoEmisorOperario(defaultUserRol)
                                    .then((response) => {
                                        if (response.data) {
                                            setUserEmail(response.data.email);
                                        } else {
                                            cogoToast.error('No fue posible adquirir el correo del técnico');
                                        }
                                    }).catch(() => cogoToast.error('No fue posible adquirir el correo del técnico'));
                            }
                            if (isUserEmailValid(userEmail)) {
                                setValidUserEmail(true);
                            }
                        } else {
                            cogoToast.error(`No fue posible obtener la orden de proceso para generar el correo`);
                        }
                        setIsGettingAddresses(false);
                    })
                    .catch(() => cogoToast.error(`No fue posible obtener la orden de proceso para generar el correo`));
            }
        })().catch(() => cogoToast.error('No fue posible preparar el correo'));
    }, [show]);

    return (
        <>
            <div className = "cards-header">
                <h5>{emailReport.description}</h5>
                <Button onClick = {() => setShow(true)} className = "buttons-group" disabled = {isNotEditable}>
                    Enviar correo
                </Button>
            </div>
            {show && (
                <Modal show = {show} onHide = {hideModal}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Reporte de OP y N/S</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h5>Eventos por informar:</h5>
                            <ul>
                                {events.length > 0 ?
                                    events.map((action) => {
                                        return (
                                            <li>
                                                <div className = "email-list-object">
                                                    {action}
                                                </div>
                                            </li>
                                        );
                                    }) :
                                    <small>No hay eventos para informar</small>
                                }
                            </ul>
                        </div>
                        <div>
                            <h5>Destinatarios:</h5>
                            <ul>
                                {isGettingAddresses ? 
                                    <>
                                        <small>Adquiriendo destinatarios ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </> :
                                    addresses && addresses.length > 0 ?
                                        addresses.map((address) => {
                                            return (
                                                <li>
                                                    <div className = "email-list-object">
                                                        {`${address.name} - ${address.email}`}
                                                    </div>
                                                </li>
                                            );
                                        }) :
                                    <small>No existen destinatarios, para agregar destinatarios debe solicitarlo a un administrador</small>
                                }
                            </ul>
                        </div>
                        {!validUserEmail ?
                            <div className = "text-center">
                                <small>El usuario actual no tiene una dirección de correo válida</small>
                            </div> :
                            isSending &&
                                <>
                                    <small>Enviando correo ...</small>
                                    <Spinner animation="border" size="sm" />
                                </>
                        }
                        <div className = "modal-buttons">
                            <Button
                                disabled = {(validUserEmail && addresses && addresses.length > 0 && events.length > 0) ? isSending : true}
                                onClick = {() => {
                                    if (addresses && ordenProceso) {
                                        generalSendEmailInModal(
                                            getReporteOPNSEmail(ordenProceso, evaluacionComponenteEtapa, addresses, copiedAddresses),
                                            userData.userName,
                                            userEmail,
                                            (state: boolean) => setIsSending(state),
                                            hideModal
                                        );
                                    }
                                }}
                            >
                                {isSending ? <Spinner animation="border" size="sm" /> : 'Enviar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isSending}>
                                {isSending ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default EvaluacionComponenteDetallesEmails;