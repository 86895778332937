import { createContext } from "react";
import { AuthContextValue } from "../models/users/AuthContext";

const AuthContext = createContext<AuthContextValue>({
    userName: '',
    userEmail: '',
    userRoles: [],
    setJWT: () => {},
    logout: () => {},
    previuosLocation: '',
    setPreviuosLocation: () => {}
});
  
export default AuthContext;