import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Modal, Spinner, Table } from "react-bootstrap";
import { EvaluacionOrReparacionComponenteEtapa, FinalCheckList, FinalCheck } from "../../models/EvaluacionesReparaciones";

interface Props {
    finalCheckList: FinalCheckList,
    isBusy: boolean,
    updateEvaluacionReparacionComponenteEtapaTarea: (hideModal: () => void) => void,
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa
}

const EvaluacionReparacionComponenteEditCheckList = ({
    finalCheckList,
    isBusy,
    updateEvaluacionReparacionComponenteEtapaTarea,
    isNotEditable,
    changeEvaluacionReparacionComponenteEtapa,
    evaluacionReparacionComponenteEtapa
}: Props): ReactElement => {

    const [ show, setShow ] = useState<boolean>(false);
    const hideModal = () => setShow(false);

    const getStates = (toDefault?: boolean): FinalCheck[] => finalCheckList.checkList.map((check) => ({
        ...check,
        check: !toDefault ? check.check : check.defaultState,
    }));

    const [ states, setStates ] = useState<FinalCheck[]>(getStates());

    useEffect(() => {
        setStates(getStates());
    }, [show]);

    const getTable = (checks: (FinalCheck | undefined)[], title: string): ReactElement => (
        checks.every((check) => !check) ? <></> :
        <>
            <h5>{title}</h5>
            <Table bordered size = 'sm'>
                <tr>
                    <th>{finalCheckList.name.substring(0, finalCheckList.name.indexOf(' '))}</th>
                    <th><div className = "text-center">¿Aplica?</div></th>
                </tr>
                {checks.map((check, index) => (
                    !check ? <></> :
                        <tr>
                            <td>{check.name}</td>
                            <td>
                                <div className = "text-center">
                                    <input
                                        disabled = {isBusy}
                                        className = "largeCheckBox"
                                        type = "checkbox"
                                        checked = {check.check}
                                        onChange = {() => {
                                            const newStates = states;
                                            newStates[index].check = !newStates[index].check;
                                            setStates([...newStates]);
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                ))}
            </Table>
        </>
    )

    return (
        <>
            <Button onClick = {() => setShow(true)} disabled = {isNotEditable}>
                {!isBusy ? 'Editar' : <Spinner animation = "border" size = "sm"/>}
            </Button>
            {show &&
                <Modal show = {show} onHide = {hideModal}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>{finalCheckList.name}</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {getTable(states.map((check) => check.manual ? check : undefined), 'Selección manual')}
                        {getTable(states.map((check) => !check.manual ? check : undefined), 'Selección automática')}
                        <div className = "modal-buttons">
                            <Button onClick = {() => setStates(getStates(true))} disabled = {isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Reiniciar'}
                            </Button>
                            <Button
                                variant = 'success'
                                disabled = {isBusy}
                                onClick = {() => {
                                    changeEvaluacionReparacionComponenteEtapa({ ...evaluacionReparacionComponenteEtapa, tareas: evaluacionReparacionComponenteEtapa.tareas.map((tarea) => {
                                        let response = tarea;
                                        if (response.nombre === finalCheckList.name && response.finalCheckList) {
                                            response.finalCheckList.checkList = states;
                                        }
                                        return response;
                                    })});
                                    updateEvaluacionReparacionComponenteEtapaTarea(hideModal);
                                }}
                            >
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default EvaluacionReparacionComponenteEditCheckList;