import { ReactElement, useState } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";

interface ConfirmDeletionModalProps {
    text: string,
    onDelete: (setIsDeleting: () => void) => void
}

const ConfirmDeletionModal = ({ text, onDelete }: ConfirmDeletionModalProps): ReactElement => {

    const [ show, setShow ] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const changeDeletionState = () => {
        setIsDeleting(!isDeleting);
        handleClose();
    }

    const onDeleting = () => {
        setIsDeleting(true);
        onDelete(changeDeletionState);
    }

    return (
        <>
            <Button onClick = {handleShow} variant = "danger">
                Eliminar
            </Button>
            {show &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>¿Confirma la eliminación?</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <p>{text}</p>
                        </Form>
                        <div className = "modal-buttons">
                            <Button onClick = {onDeleting} variant = "danger" className = "body-buttons" disabled = {isDeleting}>
                                {isDeleting ? <Spinner animation = "border" size="sm"/> : 'Eliminar'}
                            </Button>
                            <Button onClick = {handleClose} variant = "secondary" className = "body-buttons" disabled = {isDeleting}>
                                {isDeleting ? <Spinner animation = "border" size="sm"/> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default ConfirmDeletionModal;