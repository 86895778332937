import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { postOrdenProceso } from "../../api/OrdenesProcesoApi";
import useUENElectromecanico from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENElectromecanico";
import useUENLaboratorio from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENLaboratorio";
import useUENMecanico from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENMecanico";
import useUENPotencia from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENPotencia";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";
import useFormState from "../../hooks/useFormState";
import { OrdenProcesoForm } from "../../models/estatusGeneral/OrdenProceso";
import TipoOrdenProceso from "../../models/estatusGeneral/TipoOrdenProceso";
import TipoProceso from "../../models/estatusGeneral/TipoProceso";
import { UnidadEstrategicaNegocio } from "../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../models/optionsFilters/Select";
import { formatOrdenProcesoRequestBody, getEmptyOrdenProcesoForm } from "../../utils/estatusGeneral";
import OrdenProcesoFormComponent from "./OrdenProcesoFormComponent";

interface NuevaOrdenProcesoProps {
    tipoOrdenProcesoOptions: SelectOption<TipoOrdenProceso>[],
    tipoProcesoOptions: SelectOption<TipoProceso>[],
    userPermission: boolean
}

const NuevaOrdenProceso = ({ userPermission, tipoOrdenProcesoOptions, tipoProcesoOptions }: NuevaOrdenProcesoProps): ReactElement => {
    
    const formState = useFormState(getEmptyOrdenProcesoForm());
    const centroCostoProcesoOptions = useCentroCostoProceso();
    const uenElectromecanicoOptions = useUENElectromecanico();
    const uenPotenciaOptions = useUENPotencia();
    const uenLaboratorioOptions = useUENLaboratorio();
    const uenMecanicoOptions = useUENMecanico();
    const [uenOptions, setUenOptions] = useState<SelectOption<UnidadEstrategicaNegocio>[]>();
    const [centroCostoAnterior, setCentroCostoAnterior] = useState<string>('');

    const onUenOptionsChange = (uens?: SelectOption<UnidadEstrategicaNegocio>[]): void => {
        setUenOptions(uens);
    }

    const onCentroCostoAnteriorChange = (cc: string): void => {
        setCentroCostoAnterior(cc);
    }

    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const submitForm = (ordenProcesoForm: OrdenProcesoForm) => {
        postOrdenProceso(formatOrdenProcesoRequestBody(ordenProcesoForm))
            .then((response) => {
                if (response.data.codigoOP) {
                    history.push(`/ordenesProceso/${response.data.codigoOP}`);
                    cogoToast.success('Orden de proceso creada');
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible obtener la orden de proceso');
                }
                formState.setSubmitting(false);
            })
            .catch(() => {
                // cogoToast.error(error.message);
                formState.setSubmitting(false);
                cogoToast.error('No fue posible obtener la orden de proceso');
            })
    };

    return (
        <>
            {userPermission && 
                <Button onClick = {handleShow}>
                    Nueva OP
                </Button>
            }
            {show &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Nueva Orden de Proceso</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <OrdenProcesoFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                            tipoProcesoOptions = {tipoProcesoOptions}
                            centroCostoProcesoOptions = {centroCostoProcesoOptions}
                            uenOptions = {uenOptions}
                            onUenOptionsChange = {onUenOptionsChange}
                            centroCostoAnterior = {centroCostoAnterior}
                            onCentroCostoAnteriorChange = {onCentroCostoAnteriorChange}
                            uenElectromecanicoOptions = {uenElectromecanicoOptions}
                            uenPotenciaOptions = {uenPotenciaOptions}
                            uenLaboratorioOptions = {uenLaboratorioOptions}
                            uenMecanicoOptions = {uenMecanicoOptions}
                            isCreating
                        />
                    </Modal.Body>
                </Modal>)
            }
        </>
    );
}

export default NuevaOrdenProceso;