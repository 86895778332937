import { CalculationsTableDetails } from "../../../models/EvaluacionesReparaciones";
import { roundNumber } from "../../inputs";
import TablesGeneralCalculations from "../evaluacionesReparacionesGeneralTableCalculations";

export const blower5GY19_U_LTableCalculations = (tableDetails: CalculationsTableDetails) => {
    const generalsCalculations = new TablesGeneralCalculations(tableDetails);
    if (tableDetails.stage === 'Test eléctricos pre-lavado' || tableDetails.stage === 'Test eléctricos post-lavado') {
        if (tableDetails.taskName === 'Resistencia eléctrica') {
            let valorNumber = generalsCalculations.cellStringToNumber(1);
            let resultado = 'Fuera de rango';
            if (!isNaN(valorNumber)) {
                if (tableDetails.table.rows[tableDetails.tableRowIndex][2].content[0] === 'Ω') {
                    valorNumber = valorNumber * 1000;
                }
                if (generalsCalculations.cellStringToNumber(4) <= valorNumber && valorNumber <= generalsCalculations.cellStringToNumber(5)) {
                    resultado = 'Dentro de rango';
                }
            }
            tableDetails.updateTable(6, resultado);
        } else if (tableDetails.taskName === 'Aislación a tierra (Megger)') {
            let valorNumber = generalsCalculations.cellStringToNumber(1);
            let resultado = 'Fuera de rango';
            if (!isNaN(valorNumber)) {
                if (tableDetails.table.rows[tableDetails.tableRowIndex][2].content[0] === 'KΩ') {
                    valorNumber = valorNumber / 1000;
                } else if (tableDetails.table.rows[tableDetails.tableRowIndex][2].content[0] === 'GΩ') {
                    valorNumber = valorNumber * 1000;
                }
                if (generalsCalculations.cellStringToNumber(4) <= valorNumber) {
                    resultado = 'Dentro de rango';
                }
            }
            tableDetails.updateTable(6, resultado);
        } else if (tableDetails.stage === 'Test eléctricos post-lavado' && tableDetails.taskName === 'Surge test') {
            if (tableDetails.variableName === 'Armadura') {
                const minimo = generalsCalculations.cellStringToNumber(1);
                const maximo = generalsCalculations.cellStringToNumber(2);
                let promedioText = '';
                let resultadoText = 'Fuera de rango';
                if (!isNaN(minimo) && !isNaN(maximo)) {
                    const promedio = roundNumber((minimo + maximo) / 2);
                    const area = generalsCalculations.cellStringToNumber(4);
                    // const correlacion = generalsCalculations.cellStringToNumber(5);
                    promedioText = `${promedio}`;
                    if (!isNaN(area)) {
                        const limiteInferior = generalsCalculations.cellStringToNumber(5);
                        const limiteSuperior = generalsCalculations.cellStringToNumber(6);
                        if (limiteInferior <= promedio && promedio <= limiteSuperior && limiteInferior <= area && area <= limiteSuperior) {
                            resultadoText = 'Dentro de rango';
                        }
                    }
                }
                tableDetails.updateTable(3, promedioText);
                tableDetails.updateTable(8, resultadoText);
            } else {
                generalsCalculations.standartMeasurements([1, 2]);
            }
        }
    } else if (tableDetails.stage === 'Metrología de carcasas') {
        if ((tableDetails.taskName !== 'Guardar y embalar carcasas a reparar' && tableDetails.taskName !== 'Condición de componentes')) {
            if (tableDetails.taskName === 'Concentricidad') {
                const inputsCellIndex = [1, 2, 3, 4];
                generalsCalculations.promedioCalc(inputsCellIndex)
                generalsCalculations.standartMeasurements(inputsCellIndex);
            } else {
                if ((tableDetails.variableName === '1' || tableDetails.variableName === 'Rango de pistas')) {
                    generalsCalculations.standartMeasurements([1]);
                }
            }
        }
    } else if (tableDetails.stage === 'Inspección de ventiladores y otros') {
        if (tableDetails.taskName !== 'Inspeccionar cables terminales') {
            if (tableDetails.variableName === 'Tensión de cajetines') {
                generalsCalculations.standartMeasurements([2]);
            } else if (tableDetails.variableName === 'Medición de carbones') {
                generalsCalculations.standartMeasurements([1, 2]);
            } else if (tableDetails.variableName === 'Aislación de porta carbones') {
                let valorNumber = generalsCalculations.cellStringToNumber(1);
                let resultado = 'Fuera de rango';
                if (!isNaN(valorNumber)) {
                    if (tableDetails.table.rows[tableDetails.tableRowIndex][2].content[0] === 'KΩ') {
                        valorNumber = valorNumber / 1000000;
                    } else if (tableDetails.table.rows[tableDetails.tableRowIndex][2].content[0] === 'MΩ') {
                        valorNumber = valorNumber / 1000;
                    } else if (tableDetails.table.rows[tableDetails.tableRowIndex][2].content[0] === 'TΩ') {
                        valorNumber = valorNumber * 1000;
                    }
                    if (generalsCalculations.cellStringToNumber(3) <= valorNumber) {
                        resultado = 'Dentro de rango';
                    }
                }
                tableDetails.updateTable(4, resultado);
            } else {
                generalsCalculations.standartMeasurements([1]);
            }
        }
    }
}