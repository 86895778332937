// import { useState, useEffect } from "react";
import { SelectOption } from "../../models/optionsFilters/Select"
import TipoComponente from "../../models/componentes/TipoComponente"
// import { getTiposComponentes } from "../../api/ComponentesApi";

const useTiposComponentesOptions = (): SelectOption<TipoComponente>[] => {

    return [
        { label: 'ALTERNADOR PRINCIPAL', value: { tipo: 'ALTERNADOR PRINCIPAL' } },
        { label: 'ARNÉS', value: { tipo: 'ARNES' } },
        { label: 'BANCO DE PARRILLAS', value: { tipo: 'BANCO DE PARRILLAS' } },
        { label: 'BLOWER', value: { tipo: 'BLOWER' } },
        { label: 'CONJUNTO DE FRENOS', value: { tipo: 'CONJUNTO DE FRENOS' } },
        { label: 'CONTACTOR', value: { tipo: 'CONTACTOR' } },
        { label: 'EXCITADOR', value: { tipo: 'EXCITADOR' } },
        { label: 'GABINETE DE CONTROL', value: { tipo: 'GABINETE DE CONTROL' } },
        { label: 'MÓDULO', value: { tipo: 'MODULO' } },
        { label: 'MOTOR DE TRACCIÓN', value: { tipo: 'MOTOR DE TRACCION' } },
        { label: 'MOTOR DIÉSEL', value: { tipo: 'MOTOR DIESEL' } },
        { label: 'MOTOR ELÉCTRICO', value: { tipo: 'MOTOR ELECTRICO' } },
        { label: 'OTRO', value: { tipo: 'OTRO' } },
        { label: 'PARRILLA', value: { tipo: 'PARRILLA' } },
        { label: 'PROYECTO SISTEMA', value: { tipo: 'PROYECTO SISTEMA' } },
        { label: 'RECTIFICADOR', value: { tipo: 'RECTIFICADOR' } },
        { label: 'SENSOR', value: { tipo: 'SENSOR' } },
        { label: 'TARJETA', value: { tipo: 'TARJETA' } },
    ];

    /*
    const [tiposComponentesOptions, setTiposComponentesOptions] = useState<
    SelectOption<TipoComponente>[]>([]);

    useEffect((): void => {
        getTiposComponentes()
            .then((response): void => {
                setTiposComponentesOptions(
                    response.data.map((tipo) => {
                        return { label: tipo.tipo, value: tipo };
                    }),
                );
            })
            .catch(() => {});
    }, [setTiposComponentesOptions]);

    return tiposComponentesOptions;
    */
};

export default useTiposComponentesOptions;