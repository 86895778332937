import cogoToast from "cogo-toast";
import { ReactElement } from "react";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";
import { Persona } from "../../models/users/Users";
import EditPersonasComponent from "../users/EditPersonasComponent";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";

interface Props {
    evaulacionReparacionComponenteResumenEtapa: EvaluacionOrReparacionComponenteResumenEtapa,
    userPermission: boolean,
    changeEvaulacionReparacionComponenteResumenEtapa: (evaulacionReparacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void
    isUpdating: boolean,
    isButtonDisabled?: boolean,
    detallesApi: DetallesEvaluacionReparacionApi
}

const EvaluacionReparacionComponenteEtapaParticipantes = ({ evaulacionReparacionComponenteResumenEtapa, changeEvaulacionReparacionComponenteResumenEtapa, userPermission, isUpdating, isButtonDisabled, detallesApi }: Props): ReactElement => (
    <EditPersonasComponent
        title = "Participantes"
        subjectsName = "Participantes"
        userPermission = {userPermission}
        isUpdatingTarea = {isUpdating}
        existingPersonas = {evaulacionReparacionComponenteResumenEtapa.participantes || []}
        rolUsuarioOptions = {useRolUsuarioOptions('evaluacion')}
        isButtonDisabled = {isButtonDisabled}
        addPersonas = {(personas: Persona[], hideModal: () => void, setStateToFalse: () => void) => 
            detallesApi.putEvaluacionOrReparacionComponenteResumen({
                ...evaulacionReparacionComponenteResumenEtapa,
                participantes: personas.concat(evaulacionReparacionComponenteResumenEtapa.participantes || [])
            }).then((response) => {
                if (response.data) {
                    changeEvaulacionReparacionComponenteResumenEtapa(response.data);
                    cogoToast.success('Participantes actualizados');
                    hideModal();
                } else {
                    cogoToast.success('No fue posible actualizar a los participantes');
                }
                setStateToFalse();
            }).catch(() => {
                cogoToast.success('No fue posible actualizar a los participantes');
                setStateToFalse();
            })
        }
        deletePersona = {(persona: Persona, hideModal: () => void, setStateToFalse: () => void) => {
            const newParticipantes: Persona[] = [];
            evaulacionReparacionComponenteResumenEtapa.participantes?.forEach((participante) => {
                if (participante.email !== persona.email) {
                    newParticipantes.push(participante);
                }
            });
            detallesApi.putEvaluacionOrReparacionComponenteResumen({ ...evaulacionReparacionComponenteResumenEtapa, participantes: newParticipantes })
                .then((response) => {
                    if (response.data) {
                        changeEvaulacionReparacionComponenteResumenEtapa(response.data);
                        cogoToast.success('Participantes actualizados');
                        hideModal();
                    } else {
                        cogoToast.success('No fue posible actualizar a los participantes');
                    }
                    setStateToFalse();
                }).catch(() => {
                    cogoToast.success('No fue posible actualizar a los participantes');
                    setStateToFalse();
                });
        }}
    />
);

export default EvaluacionReparacionComponenteEtapaParticipantes;