import { Dispatch, ReactElement, ReactText, SetStateAction, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { CentroCostoProceso } from "../../models/estatusGeneral/CentroCostoProceso";
import EstadoOrdenProceso from "../../models/estatusGeneral/EstadoOrdenProceso";
import { OrdenesProcesoFilters } from "../../models/estatusGeneral/OrdenProceso";
import TipoOrdenProceso from "../../models/estatusGeneral/TipoOrdenProceso";
import TipoProceso from "../../models/estatusGeneral/TipoOrdenProceso";
import { SelectOption } from "../../models/optionsFilters/Select";
import DateSelect from "../form/DateSelect";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";

interface OrdenesProcesosFiltersProps {
    ordenesProcesoFilters: OrdenesProcesoFilters,
    estadosOrdenProcesoOptions: SelectOption<EstadoOrdenProceso>[],
    centroCostoOptions: SelectOption<CentroCostoProceso>[],
    tipoOrdenProcesoOptions: SelectOption<TipoOrdenProceso>[],
    tipoProcesoOptions: SelectOption<TipoProceso>[],
    onFiltersChange: Dispatch<SetStateAction<OrdenesProcesoFilters>>,
    aperturaOPs?: boolean,
    componenteQR?: boolean
}

const OrdenesProcesosFiltersComponent = ({
    ordenesProcesoFilters,
    estadosOrdenProcesoOptions,
    centroCostoOptions,
    tipoOrdenProcesoOptions,
    tipoProcesoOptions,
    onFiltersChange,
    aperturaOPs,
    componenteQR
}: OrdenesProcesosFiltersProps): ReactElement => {

    const handleFilterChange = (name: string, value?: ReactText): void => {
        onFiltersChange((prevFilters) => ({
            ...prevFilters,
            page: 1,
            [name]: value,
        }));
    }

    const getEstadosSelection = (selected?: SelectOption<EstadoOrdenProceso>[]): SelectOption<EstadoOrdenProceso>[] => {
        if (selected) {
            let optionsHidingSelected: SelectOption<EstadoOrdenProceso>[] = [];
            estadosOrdenProcesoOptions.forEach((option) => {
                let isSelected: boolean = false;
                selected.forEach((optionSelected) => {
                    if (optionSelected.value.estado === option.value.estado) {
                        isSelected = true;
                    }
                });
                if (!isSelected) {
                    optionsHidingSelected.push(option);
                }
            });
            return optionsHidingSelected;
        } else {
            return estadosOrdenProcesoOptions;
        }
    }

    const getCentroCostoSelection = (selected?: SelectOption<CentroCostoProceso>[]): SelectOption<CentroCostoProceso>[] => {
        if (selected) {
            let optionsHidingSelected: SelectOption<CentroCostoProceso>[] = [];
            centroCostoOptions.forEach((option) => {
                let isSelected: boolean = false;
                selected.forEach((optionSelected) => {
                    if (optionSelected.value.centroCosto === option.value.centroCosto) {
                        isSelected = true;
                    }
                });
                if (!isSelected) {
                    optionsHidingSelected.push(option);
                }
            });
            return optionsHidingSelected;
        } else {
            return centroCostoOptions;
        }
    }
    
    const [ estadosSelection, setEstadosSelection ] = useState<SelectOption<EstadoOrdenProceso>[]>(getEstadosSelection());
    const [ centroCostoSelection, setCentroCostoSelection ] = useState<SelectOption<CentroCostoProceso>[]>(getCentroCostoSelection());
    const [ estadosSelected, setEstadosSelected ] = useState<SelectOption<EstadoOrdenProceso>[] | undefined>(
        ((): SelectOption<EstadoOrdenProceso>[] | undefined => {
            if (aperturaOPs) {
                return [{ label: 'NUEVA', value: { estado: 'NUEVA' } }];
            }
            /*
            else if (componenteQR) {
                return [
                    { label: 'REPARADA', value: { estado: 'REPARADA' } },
                    { label: 'DESPACHADA', value: { estado: 'DESPACHADA' } },
                    { label: 'CERRADA', value: { estado: 'CERRADA' } }
                ];
            }
            */
        })()
    );
    const [ centroCostoSelected, setCentroCostoSelected ] = useState<SelectOption<CentroCostoProceso>[]>();

    useEffect(() => {
        onFiltersChange((prevFilters) => ({
            ...prevFilters,
            estados: ((): string[] => {
                let estadosString: string[] = [];
                if (estadosSelected) {
                    estadosSelected.forEach((estado) => estadosString.push(estado.value.estado));
                }
                return estadosString;
            })()
        }));
        setEstadosSelection(getEstadosSelection(estadosSelected))
    }, [estadosSelected]);

    useEffect(() => {
        onFiltersChange((prevFilters) => ({
            ...prevFilters,
            centroCosto: ((): string[] => {
                let centroCostoString: string[] = [];
                if (centroCostoSelected) {
                    centroCostoSelected.forEach((centro) => centroCostoString.push(centro.value.centroCosto));
                }
                return centroCostoString;
            })()
        }));
        setCentroCostoSelection(getCentroCostoSelection(centroCostoSelected))
    }, [centroCostoSelected]);

    return (
        <div className = "body-filters">
            <Row>
                <Col lg = {3}>
                    <Input
                        value = {ordenesProcesoFilters.codigoOP}
                        name = "codigoOP"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Código OP"
                    />
                </Col>
                 <Col lg = {3}>
                    <Input
                        value = {ordenesProcesoFilters.cliente}
                        name = "cliente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Cliente"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {ordenesProcesoFilters.descripcionComponente}
                        name = "descripcionComponente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Descripción Componente"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {ordenesProcesoFilters.numeroSerieComponente}
                        name = "numeroSerieComponente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "N° Serie Componente"
                    />
                </Col>
            </Row>
            <Row>
                <Col lg = {componenteQR ? 4 : 2}>
                    <div className = "form-group">
                        Estado
                    </div>
                    <ReactSelect 
                        defaultValue = {estadosSelected}
                        options = {estadosSelection}
                        onChange = {setEstadosSelected}
                        isDisabled = {aperturaOPs}
                        isMulti
                        // hideSelectedOptions
                    />
                    {/* <Select
                        title = "Estado"
                        name = "estado"
                        value = {aperturaOPs ? { label: 'NUEVA', value: { estado: 'NUEVA' } } : componenteQR ? { label: 'CERRADA', value: { estado: 'CERRADA' } } : undefined}
                        options = {estadosOrdenProcesoOptions}
                        onSelectWithName = {(name, estado): void => {
                            handleFilterChange(name, estado.value.estado);
                        }}
                        isMulti = {componenteQR? true : false}
                        disabled = {aperturaOPs || componenteQR}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    /> */}
                </Col>
                <Col lg = {componenteQR ? 4 : 2}>
                    <div className = "form-group">
                        Centro Costo
                    </div>
                    <ReactSelect 
                        defaultValue = {centroCostoSelected}
                        options = {centroCostoSelection}
                        onChange = {setCentroCostoSelected}
                        isMulti
                        // hideSelectedOptions
                    />
                    {/* <Select
                        title = "Estado"
                        name = "estado"
                        value = {aperturaOPs ? { label: 'NUEVA', value: { estado: 'NUEVA' } } : componenteQR ? { label: 'CERRADA', value: { estado: 'CERRADA' } } : undefined}
                        options = {estadosOrdenProcesoOptions}
                        onSelectWithName = {(name, estado): void => {
                            handleFilterChange(name, estado.value.estado);
                        }}
                        isMulti = {componenteQR? true : false}
                        disabled = {aperturaOPs || componenteQR}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    /> */}
                </Col>
                <Col lg = {componenteQR ? 4 : 2}>
                    <Select
                        title = "Tipo OP"
                        name = "tipoOP"
                        options = {tipoOrdenProcesoOptions}
                        onSelectWithName = {(name, tipo): void => {
                            handleFilterChange(name, tipo.value.tipo);
                        }}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    />
                </Col>
                <Col lg = {componenteQR ? 4 : 2}>
                    <Select
                        title = "Proceso"
                        name = "proceso"
                        options = {tipoProcesoOptions}
                        onSelectWithName = {(name, tipo): void => {
                            handleFilterChange(name, tipo.value.tipo);
                        }}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    />
                </Col>
                <Col lg = {componenteQR ? 5 : 2}>
                    <DateSelect
                        title = "Fecha Recepción"
                        name = "fechaRecepcionInicio"
                        name2 = "fechaRecepcionFinal"
                        selected = {ordenesProcesoFilters.fechaRecepcionInicio}
                        selected2 = {ordenesProcesoFilters.fechaRecepcionFinal}
                        onChangeField = {handleFilterChange}
                        startDate = {ordenesProcesoFilters.fechaRecepcionInicio}
                        endDate = {ordenesProcesoFilters.fechaRecepcionFinal}
                        isClearable
                        isInterval
                    />
                    {/* <div className = "form-group">Fecha Recepción</div>
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            ordenesProcesoFilters.fechaRecepcionInicio ?
                            parse(ordenesProcesoFilters.fechaRecepcionInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFilterChange("fechaRecepcionInicio",  changedFilter);
                        }}
                        selectsStart
                        startDate = {
                            ordenesProcesoFilters.fechaRecepcionInicio ?
                            parse(ordenesProcesoFilters.fechaRecepcionInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        endDate = {
                            ordenesProcesoFilters.fechaRecepcionFinal ?
                            parse(ordenesProcesoFilters.fechaRecepcionFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        isClearable
                        placeholderText = {'Desde'}
                    />
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            ordenesProcesoFilters.fechaRecepcionFinal ?
                            parse(ordenesProcesoFilters.fechaRecepcionFinal, 'dd/MM/yyyy', new Date()) :
                            null
                         }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFilterChange("fechaRecepcionFinal",  changedFilter);
                        }}
                        selectsEnd
                        startDate = {
                            ordenesProcesoFilters.fechaRecepcionInicio ?
                            parse(ordenesProcesoFilters.fechaRecepcionInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        endDate = {
                            ordenesProcesoFilters.fechaRecepcionFinal ?
                            parse(ordenesProcesoFilters.fechaRecepcionFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        isClearable
                        placeholderText = {'Hasta'}
                    /> */}
                </Col>
                <Col lg = {componenteQR ? 5 : 2}>
                    <DateSelect
                        title = "Fecha Entrega"
                        name = "fechaEntregaInicio"
                        name2 = "fechaEntregaFinal"
                        selected = {ordenesProcesoFilters.fechaEntregaInicio}
                        selected2 = {ordenesProcesoFilters.fechaEntregaFinal}
                        onChangeField = {handleFilterChange}
                        startDate = {ordenesProcesoFilters.fechaEntregaInicio}
                        endDate = {ordenesProcesoFilters.fechaEntregaFinal}
                        isClearable
                        isInterval
                    />
                    {/* <div className = "form-group">Fecha Entrega</div>
                    <DatePicker
                        className = "form-control"
                        disabled = {aperturaOPs}
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            ordenesProcesoFilters.fechaEntregaInicio ?
                            parse(ordenesProcesoFilters.fechaEntregaInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFilterChange("fechaEntregaInicio",  changedFilter);
                        }}
                        selectsStart
                        startDate = {
                            ordenesProcesoFilters.fechaEntregaInicio ?
                            parse(ordenesProcesoFilters.fechaEntregaInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        endDate = {
                            ordenesProcesoFilters.fechaEntregaFinal ?
                            parse(ordenesProcesoFilters.fechaEntregaFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        isClearable
                        placeholderText = {'Desde'}
                    />
                    <DatePicker
                        className = "form-control"
                        disabled = {aperturaOPs}
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            ordenesProcesoFilters.fechaEntregaFinal ?
                            parse(ordenesProcesoFilters.fechaEntregaFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFilterChange("fechaEntregaFinal",  changedFilter);
                        }}
                        selectsEnd
                        startDate = {
                            ordenesProcesoFilters.fechaEntregaInicio ?
                            parse(ordenesProcesoFilters.fechaEntregaInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        endDate = {
                            ordenesProcesoFilters.fechaEntregaFinal ?
                            parse(ordenesProcesoFilters.fechaEntregaFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        isClearable
                        placeholderText = {'Hasta'}
                    /> */}
                </Col>
            </Row>
        </div>
    )
}

export default OrdenesProcesosFiltersComponent;