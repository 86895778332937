import { SelectOption } from "../../models/optionsFilters/Select";


const useEstadoUsuarioOptions = (): SelectOption<string>[] => {

    return [
        { label: 'HABILITADO', value: 'HABILITADO' },
        { label: 'DESHABILITADO', value: 'DESHABILITADO' }
    ];
}

export default useEstadoUsuarioOptions;