import { ModeloComponenteEvaluacionOrReparacionElements } from "../../../models/EvaluacionesReparaciones";
import informeEvaluacion5GEB25 from "../../evaluaciones/motoresTraccion/informe5GEB25";
import { motorTraccion5GEB25DiferenciaBacklashCalculations, motorTraccion5GEB25InterferenciaCalculations, motorTraccion5GEB25TableCalculations } from "../tablesCalculations/motoresTraccion";

const motoresTraccionElementsList: ModeloComponenteEvaluacionOrReparacionElements[] = [
    {
        modeloComponente: '5GEB25',
        getInformeEvaluacion: informeEvaluacion5GEB25,
        getCalculations: motorTraccion5GEB25TableCalculations,
        tablesThatNeedSharedValues: [
            { stageName: 'Metrología', taskName: 'Metrología tubo de torque y anillo flinger', tableName: 'Interferencia', calculation: motorTraccion5GEB25InterferenciaCalculations },
            { stageName: 'Desarme inicial', taskName: 'Medir juego backlash planetarios', tableName: 'Diferencias de backlash', calculation: motorTraccion5GEB25DiferenciaBacklashCalculations }
        ]
    },
];

export default motoresTraccionElementsList;