import { Button, Col, Modal } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { postComponenteReparado } from "../../api/ComponentesApi";
import useFormState from "../../hooks/useFormState";
import { ComponenteReparadoForm } from "../../models/componentes/Componente";
import { formatComponenteReparadoRequestBody, getEmptyComponenteReparadoForm } from "../../utils/componentes";
import ComponenteReparadoFormComponent from "./ComponenteReparadoFormComponent";
import { OrdenesProcesoFilters, OrdenProcesoTabla } from "../../models/estatusGeneral/OrdenProceso";
import OrdenesProcesosFiltersComponent from "../estatusGeneral/OrdenesProcesoFiltersComponent";
import { getEmptyOrdenesProcesoFilters } from "../../utils/estatusGeneral";
import useEstadosOrdenProceso from "../../hooks/estatusGeneral/useEstadosOrdenProceso";
import useTipoOrdenProceso from "../../hooks/estatusGeneral/useTipoOrdenProceso";
import useTipoProceso from "../../hooks/estatusGeneral/useTipoProceso";
import OrdenesProcesoList from "../estatusGeneral/OrdenesProcesoList";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";

interface NuevoComponenteReparadoProps {
    userPermission: boolean
}

const NuevoComponenteReparado = ({ userPermission }: NuevoComponenteReparadoProps): ReactElement => {

    const [ op, setOP ] = useState<OrdenProcesoTabla>();
    const[ ordenesProcesoFilters, setOrdenesProcesoFilters ] = useState<OrdenesProcesoFilters>(getEmptyOrdenesProcesoFilters('qr'));

    const estadosOrdenProcesoOptions = useEstadosOrdenProceso('qr');
    const centroCostoOptions = useCentroCostoProceso();
    const tipoOrdenProcesoOptions = useTipoOrdenProceso();
    const tipoProcesoOptions = useTipoProceso(true);
    
    const formState = useFormState(getEmptyComponenteReparadoForm());
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showForm, setShowForm] = useState(false);
    const handleCloseForm = () => {
        setShowForm(false);
        formState.reset();
    };
    const handleShowForm = () => setShowForm(true);

    const changeOrdenesProcesoFilters = (ordenesProcesoFilters: OrdenesProcesoFilters): void => {
        setOrdenesProcesoFilters(ordenesProcesoFilters);
    };

    const submitForm = (componenteReparadoForm: ComponenteReparadoForm) => {
        if (op) {
            postComponenteReparado(formatComponenteReparadoRequestBody(op.codigoOP, componenteReparadoForm))
            .then((response) => {
                if (response.data._id) {
                    cogoToast.success('Componente Reparado añadido');
                    //formState.reset();
                    history.push(`/componentesQR/${response.data._id}`);
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('Ya existe el componente reparado');
                }
                formState.setSubmitting(false);
            })
            .catch(() => {
                // cogoToast.error(error.message);
                cogoToast.error('No fue posible agregar el componente reparado');
                formState.setSubmitting(false);
            });
        }
    }

    const addOrdenProcesoToQR = (ordenProceso: OrdenProcesoTabla) => {
        setOP(ordenProceso);
        handleClose();
        handleShowForm();
    }

    return (
        <>
            {userPermission &&
                <Button onClick = {handleShow}>
                    Nuevo Componente Reparado
                </Button>
            }
            {show && estadosOrdenProcesoOptions && (
                <Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className="text-center">
                            <Modal.Title>Nuevo Componente Reparado</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <div >
                            <OrdenesProcesosFiltersComponent
                                ordenesProcesoFilters = {ordenesProcesoFilters}
                                estadosOrdenProcesoOptions = {estadosOrdenProcesoOptions}
                                centroCostoOptions = {centroCostoOptions}
                                tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                                tipoProcesoOptions = {tipoProcesoOptions}
                                onFiltersChange = {setOrdenesProcesoFilters}
                                componenteQR
                            />
                        </div>
                        <OrdenesProcesoList
                            ordenesProcesoFilters = {ordenesProcesoFilters}
                            setOrdenesProcesoFilters = {changeOrdenesProcesoFilters}
                            addOrdenProcesoToQR = {addOrdenProcesoToQR}
                        />
                    </Modal.Body>
                </Modal>
            )}
            {showForm && (
                <Modal show = {showForm} onHide = {handleCloseForm}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Agregar componente desde OP</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {op && (<h5>{`OP seleccionada: ${op?.codigoOP}`}</h5>)}
                        <h6>Es necesario completar los siguientes campos</h6>
                        <ComponenteReparadoFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleCloseForm}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default NuevoComponenteReparado;