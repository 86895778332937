import { ReactElement } from "react"
import DocumentsManager from "./DocumentsManager";
import { deleteDocumentoEvaluacion, getDocumentoEvaluacion, postDocumentoEvaluacion } from "../../api/DocumentosApi";
import { Archivo, ArchivoNombre } from "../../models/Archivos";

interface Props {
    codigoOP: string,
    userPermission: boolean,
    documentosListado?: ArchivoNombre[],
    isComponenteQR?: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    addMessage?: boolean
}

const DocumentosEvaluacion = ({ codigoOP, documentosListado, userPermission, isComponenteQR, isBusy, changeBusyState, addMessage }: Props): ReactElement => {
    const documentsListName = 'Documentos de evaluación';
    return (
        <DocumentsManager
            isMultiple
            documentosListado = {documentosListado}
            isNotEditable = {isComponenteQR}
            userPermission = {userPermission}
            title = {!isComponenteQR ? documentsListName : undefined}
            subtitle = {isComponenteQR ? documentsListName : undefined}
            externalIsBusy = {isBusy}
            changeExternalBusyState = {changeBusyState}
            message = {addMessage ? 'Para agregar el anexo al informe se debe subir aquí, éste debe tener la palabra "resumen" en su nombre y estar en formato PDF.' : undefined}
            getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getDocumentoEvaluacion({ _id: _id }, onDownloadProgressFunction)}
            postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postDocumentoEvaluacion({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
            deleteDocumento = {(_id: string) => deleteDocumentoEvaluacion({ _id: _id })}
            // getDocumentosListado = {() => getDocumentosEvaluacionListado(codigoOP)}
        />
    )
}

export default DocumentosEvaluacion;