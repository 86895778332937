import { Dispatch, ReactElement, ReactText, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentesFilters } from "../../models/componentes/Componente";
import NumeroGalpon from "../../models/componentes/NumeroGalpon";
import TipoComponente from "../../models/componentes/TipoComponente";
import { SelectOption } from "../../models/optionsFilters/Select";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";

interface ComponentesFiltersProps {
    tiposComponentesOptions: SelectOption<TipoComponente>[];
    numeroGalponOptions: SelectOption<NumeroGalpon>[];
    planificacionComponenteOptions?: SelectOption<boolean>[];
    componentesFilters: ComponentesFilters;
    onFiltersChange: Dispatch<SetStateAction<ComponentesFilters>>;
}

const ComponentesFiltersComponent = ({
    tiposComponentesOptions,
    numeroGalponOptions,
    planificacionComponenteOptions,
    componentesFilters,
    onFiltersChange,
}: ComponentesFiltersProps): ReactElement => {
    
    const handleFilterChange = (name: string, value?: ReactText): void =>
        onFiltersChange((prevFilters) => ({
            ...prevFilters,
            page: 1,
            [name]: value,
        }));

    return (
        <div className = "body-filters">
            <Row>
                <Col lg = {3}>
                    <Input 
                        value = {componentesFilters.descripcion}
                        name = "descripcion"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Descripción"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {componentesFilters.modelo}
                        name = "modelo"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Modelo"
                    />
                </Col>
                {/* <Col lg = {2}>
                    <Input
                        value = {componentesFilters.numeroParte}
                        name = "numeroParte"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "N° Parte"
                    />
                </Col> */}
                <Col lg = {3}>
                    <Select
                        title = "Galpón"
                        name = "galpon"
                        options = {numeroGalponOptions}
                        onSelectWithName = {(name, numeroGalpon): void => {
                            handleFilterChange(name, numeroGalpon.value.numero.toString());
                        }}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    />
                </Col>
                <Col lg = {3}> 
                    <Select
                        title = "Tipo"
                        name = "tipoComponente"
                        options = {tiposComponentesOptions}
                        onSelectWithName = {(name, tipoComponente): void => {
                            handleFilterChange(name, tipoComponente.value.tipo.toString());
                        }}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    />
                </Col>
                {/* {planificacionComponenteOptions &&
                    <Col lg = {3}> 
                        <Select
                            title = "¿Posee planificación?"
                            name = "planificacion"
                            options = {planificacionComponenteOptions}
                            onSelectWithName = {(name, tipoComponente): void => {
                                handleFilterChange(name, tipoComponente.value.toString());
                            }}
                            onClearWithName = {(name): void => handleFilterChange(name)}
                        />
                    </Col>
                } */}
            </Row>
        </div>
    )
};

export default ComponentesFiltersComponent;