import { ReactElement, useEffect } from "react";
import useBreadcrumb from "../hooks/useBreadcrumb";

const HomePage = (): ReactElement => {

    const breadcrumb = useBreadcrumb();

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Inicio',
                inactive: true,
            },
        ]);
    }, [breadcrumb]);

    return (
        <>
        </>
    )
};

export default HomePage