import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getOrdenesProceso } from "../../api/OrdenesProcesoApi";
import { OrdenesProcesoFilters, OrdenProcesoTabla } from "../../models/estatusGeneral/OrdenProceso";
import Table, { TableColumn } from "../form/table/Table";

interface OrdenesProcesoListProps {
    ordenesProcesoFilters: OrdenesProcesoFilters,
    setOrdenesProcesoFilters: (ordenesProcesoFilters: OrdenesProcesoFilters) => void,
    addOrdenProcesoToQR?: (ordenProceso: OrdenProcesoTabla) => void
}

const OrdenesProcesoList = ({
    ordenesProcesoFilters,
    setOrdenesProcesoFilters,
    addOrdenProcesoToQR
}: OrdenesProcesoListProps): ReactElement => {

    const [ ordenesProceso, setOrdenesProceso ] = useState<OrdenProcesoTabla[]>();
    const [ totalPages, setTotalPages ] = useState<number>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const history = useHistory();

    useEffect(() => {
        getOrdenesProceso(ordenesProcesoFilters)
            .then((response) => {
                if (response.data.ordenesProceso) {
                    setOrdenesProceso(response.data.ordenesProceso);
                    setTotalPages(response.data.totalPages);
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible obtener las órdenes de proceso');
                }
                setIsLoading(false);
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener las órdenes de proceso');
                setIsLoading(false);
            });
    }, [ordenesProcesoFilters]);

    const handleOrdenProcesoClick = (ordenProceso: OrdenProcesoTabla): void => {
        if (addOrdenProcesoToQR) {
            addOrdenProcesoToQR(ordenProceso);
        } else {
            history.push(`/ordenesProceso/${ordenProceso.codigoOP}`);
        }
    };

    const tableColumns: TableColumn<OrdenProcesoTabla>[] = [
        {
            title: 'Item',
            accessor: 'item'
        },
        {
            title: 'Fecha recepción',
            accessor: 'fechaRecepcion'
        },
        {
            title: 'Centro Costo',
            accessor: 'centroCosto'
        },
        {
            title: 'Descripción Componente',
            accessor: 'descripcionComponente'
        },
        {
            title: 'Modelo Componente',
            accessor: 'modeloComponente'
        },
        {
            title: 'N° Parte Cliente',
            accessor: 'numeroParteCliente'
        },
        {
            title: 'N/S',
            accessor: 'numeroSerieComponente'
        },
        {
            title: 'Cliente',
            accessor: 'cliente'
        },
        {
            title: 'Orden cliente',
            accessor: 'ordenTrabajoCliente'
        },
        {
            title: 'Orden Proceso',
            accessor: 'codigoOP'
        },
        {
            title: 'Proceso',
            accessor: 'proceso'
        },
        {
            title: 'ORACLE ZAL',
            accessor: 'codigoSolped'
        },
        {
            title: 'Tipo OP',
            accessor: 'tipoOP'
        },
        {
            title: 'G/D Cliente',
            accessor: 'numeroGuiaDespachoRecepcion'
        },
        {
            title: 'Etapa Proceso',
            accessor: 'etapaProceso'
        },
        {
            title: 'N° Cotización',
            accessor: 'numeroCotizacion'
        },
        {
            title: 'Fecha envío PPTO',
            accessor: 'fechaEnvioPPTO'
        },
        {
            title: 'Orden Compra',
            accessor: 'ordenCompra'
        },
        {
            title: 'Fecha recepción OC',
            accessor: 'fechaRecepcionOC'
        },
        {
            title: 'Fecha entrega OC',
            accessor: 'fechaEntregaOC'
        },
        {
            title: 'Fecha entrega',
            accessor: 'fechaEntrega'
        },
        {
            title: 'Guía',
            accessor: 'numeroGuiaDespachoEntrega'
        },
        {
            title: 'N° factura Electrans',
            accessor: 'numeroFacturaElectrans'
        },
        {
            title: 'Fecha Factura',
            accessor: 'fechaFactura'
        },
        {
            title: 'Fecha recepción factura',
            accessor: 'fechaRecepcionFactura'
        },
        {
            title: 'Estado',
            accessor: 'estado'
        }
    ];

    if (!ordenesProceso) {
        if (isLoading) {
            return (
                <div className = "text-center">
                    <Spinner animation = "border"/>
                </div>
            );
        } else {
            return <p className = "text-center">No fue posible cargar las órdenes de procesos</p>;
        }
    } else {
        return (
            <Table
                hover
                items = {ordenesProceso}
                columns = {tableColumns}
                onItemClick = {handleOrdenProcesoClick}
                pages = {totalPages}
                activePage = {ordenesProcesoFilters.page}
                onPageSelect = {(page) => setOrdenesProcesoFilters({ ...ordenesProcesoFilters, page: page})}
            />
        );
    }
}

export default OrdenesProcesoList;