import { ReactElement } from "react";
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import DocumentsManager from "./DocumentsManager";
import { deleteGuiaDespachoEntrega, getGuiaDespachoEntrega, postGuiaDespachoEntrega } from "../../api/DocumentosApi";

interface Props {
    paramToCheck?: any
    codigoOP: string,
    userPermission: boolean,
    isComponenteQR?: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    guiaDespachoDoc?: ArchivoNombre
}

const GuiaDespachoEntrega = ({ paramToCheck, codigoOP, userPermission, isComponenteQR, isBusy, changeBusyState, guiaDespachoDoc }: Props): ReactElement => {
    const documentsListName = 'Guía de Despacho';
    return (
        <DocumentsManager
            paramToCheck = {paramToCheck}
            documentosListado = {guiaDespachoDoc ? [guiaDespachoDoc] : undefined}
            isNotEditable = {isComponenteQR}
            userPermission = {userPermission}
            title = {!isComponenteQR ? documentsListName : undefined}
            subtitle = {isComponenteQR ? documentsListName : undefined}
            externalIsBusy = {isBusy}
            changeExternalBusyState = {changeBusyState}
            getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getGuiaDespachoEntrega({ _id: _id }, onDownloadProgressFunction)}
            postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postGuiaDespachoEntrega({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
            deleteDocumento = {(_id: string) => deleteGuiaDespachoEntrega({ _id: _id })}
            // getDocumentosListado = {() => getGuiaDespachoEntregaListado(codigoOP)}
        />
    )
}

export default GuiaDespachoEntrega;