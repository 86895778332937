import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import OrdenProcesoDetails from "../../components/estatusGeneral/OrdenProcesoDetails";
import AuthContext from "../../contexts/AuthContext";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { getUserPermissionFunction } from "../../utils/users";

const OrdenProcesoPage = (): ReactElement => {

    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    // const { codigoOP, _id } = useParams<{ codigoOP: string, _id: string }>();
    const { codigoOP } = useParams<{ codigoOP: string }>();
    const breadcrumb = useBreadcrumb();
    const { userRoles, userName, userEmail } = useContext(AuthContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ currentOPIndex, setCurrentOPIndex ] = useState<number>(0)

    useEffect(() => {
        if (!ordenProceso) {
            let codigoOPToGet = codigoOP;
            const indexOfLastDash = codigoOPToGet.lastIndexOf('-');
            if (indexOfLastDash !== codigoOPToGet.indexOf('-')) {
                setCurrentOPIndex(parseInt(codigoOPToGet.substring(indexOfLastDash + 1)));
                codigoOPToGet = codigoOPToGet.substring(0, indexOfLastDash)
            }
            getOrdenProceso(codigoOPToGet)
                .then((response) => {
                    if (response.data.codigoOP) {
                        setOrdenProceso(response.data);
                    } else {
                        cogoToast.error('No fue posible obtener la OP');
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    cogoToast.error('No fue posible obtener la OP');
                    setIsLoading(false);
                });
        }
    }, [codigoOP]);

    useEffect(() => {
        if (ordenProceso) {
            breadcrumb.setItems([
                    {
                        label: 'Estatus General',
                        href: '/ordenesProceso',
                    },
                    {
                        label: ordenProceso.codigoOP,
                        inactive: true,
                    }
                ]);
        }
    }, [codigoOP, ordenProceso]);

    const handleOrdenProcesoChange = (ordenProceso: OrdenProceso): void => {
        setOrdenProceso(ordenProceso);
    }

    if (!ordenProceso) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border" />
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">No fue posible cargar la orden de proceso</p>;
        }
    } else {
        return (
            <>
                <OrdenProcesoDetails
                    ordenProceso = {ordenProceso}
                    onOrdenProcesoChange = {handleOrdenProcesoChange}
                    userPermissionFunction = {getUserPermissionFunction(userRoles)}
                    userRoles = {userRoles}
                    currentOPIndex = {currentOPIndex}
                    userName = {userName}
                    userEmail = {userEmail}
                />
            </>
        );
    }
}

export default OrdenProcesoPage;