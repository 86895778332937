import { ReactElement, ReactNode } from 'react';
import MenuBar from './menuBar/MenuBar';
import Header from './header/Header';
import LayoutStore from '../../store/LayoutStore';
import ContentContainer from './ContentContainer';

interface LayoutProps {
    children?: ReactNode;
}

const Layout = ({ children }: LayoutProps): ReactElement => {
    return(
        <>
            <LayoutStore>
                <Header />
                <MenuBar />
                <ContentContainer>{children}</ContentContainer>
            </LayoutStore>
        </>
    );
};

export default Layout;