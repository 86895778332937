import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { putOrdenProceso } from "../../api/OrdenesProcesoApi";
import useFormState from "../../hooks/useFormState";
import { DetallesOPForm } from "../../models/estatusGeneral/DetallesOP";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { bodyCardsFields } from "../../utils/bodyCards";
import { detallesOPArray, formatDetallesOPForm, formatOrdenProceso } from "../../utils/estatusGeneral";
import DetallesOPFormComponent from "./DetallesOPFormComponent";

interface DetallesOPProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    // _id?: string,
    userPermission: boolean
}

const DetallesOP = ({ ordenProceso, onOrdenProcesoChange, userPermission }: DetallesOPProps): ReactElement => {

    const formState = useFormState(formatDetallesOPForm(ordenProceso));
    const detallesOPHTML = bodyCardsFields(detallesOPArray(ordenProceso));

    const submitForm = (clienteForm: DetallesOPForm) => {
        putOrdenProceso(formatOrdenProceso(ordenProceso, undefined, clienteForm))
            .then((response) => {
                if (response.data.codigoOP) {
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    // formState.setForm(formatDetallesOPForm(response.data));
                    cogoToast.success('Orden de proceso editada');
                } else {
                    cogoToast.error('No fue posible editar los detalles de la OP');
                    // cogoToast.error(response.data.message);
                }
                formState.setSubmitting(false);
            })
            .catch(() => {
                // cogoToast.error(error.message);
                cogoToast.error('No fue posible editar los detalles de la OP');
                formState.setSubmitting(false);
                formState.reset();
            })
    }

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };
    
    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        Detalles
                    </div>
                    {userPermission &&
                        <div className = "buttons-group">
                            <Button onClick = {handleShow}>Editar</Button>
                        </div>
                    }
                    {/*!_id &&
                        (<div className = "body-buttons">
                            <Button onClick = {handleShow} disabled = {!rolPermission}>Editar</Button>
                        </div>)
                    */}
                </Card.Header>
                <Card.Body className = "cards-body-groups">
                    {detallesOPHTML}
                </Card.Body>
            </Card>
            {show &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar detalles</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <DetallesOPFormComponent
                            submitForm = {submitForm}
                            formState = {formState}
                            onCancel = {handleClose}
                        />
                    </Modal.Body>
                </Modal>)
            }
        </>
    );
}

export default DetallesOP;