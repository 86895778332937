import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Attachment } from "../../models/Email";
import { OrdenProceso, OrdenProcesoTabla } from "../../models/estatusGeneral/OrdenProceso";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import { getGuiaDespachoRecepcion, getInformeFallasRecepcion, getNoConformidad, getRegistroRecepcion } from "../../api/DocumentosApi";
import { getListadoRepuesto } from "../../api/ComponentesApi";
import AuthContext from "../../contexts/AuthContext";
import { isUserEmailValid } from "../../utils/users";
import { areFilesOver10MB } from "../../utils/archivos";

interface CorreoAperturaProps {
    ordenesProceso: OrdenProcesoTabla[],
    formatEmailContent: (ordenesProcesoDetails: OrdenProceso[], attachments: Attachment[]) => boolean,
    sendEmail: (ordenesProcesoDetails: OrdenProceso[], closeModal: () => void) => Promise<void>,
    isSending: boolean,
    changeToSendingState: () => void,
    changeToNotSendingState: () => void,
    areThereAddresses: () => boolean,
    isGettingAddresses: boolean,
    getDestinatarios: (changeIsEmailReadyState: (state: boolean) => void) => void,
    //removeOrdenesProcesoSelected: (op: OrdenProcesoTabla) => void
    userPermission: boolean
}

const CorreoApertura = ({
    ordenesProceso,
    formatEmailContent,
    sendEmail,
    isSending,
    changeToSendingState,
    changeToNotSendingState,
    areThereAddresses,
    isGettingAddresses,
    getDestinatarios,
    userPermission
    //removeOrdenesProcesoSelected
}: CorreoAperturaProps): ReactElement => {

    const { userEmail } = useContext(AuthContext);
    const [ validUserEmail, setValidUserEmail ] = useState<boolean>(false);
    const [ ordenesProcesoDetails, setOrdenesProcesoDetails ] = useState<OrdenProceso[]>([]);
    const [ attachments, setAttachments ] = useState<Attachment[]>([]);
    const [ isDownloadingFiles, setIsDownloadingFiles ] = useState<boolean>(false);
    const [ isCalculatingAttachmentsSize, setIsCalculatingAttachmentsSize ] = useState<boolean>(false);
    const [ isEmailOverloaded, setIsEmailOverloaded ] = useState<boolean>(false);
    const [ currentAttachmentsSize, setCurrentAttachmentsSize ] = useState<number>(0);
    const [ isEmailReady, setIsEmailReady ] = useState<boolean>(false);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setIsEmailReady(false);
        setIsDownloadingFiles(false);
        setIsCalculatingAttachmentsSize(false);
        setIsEmailOverloaded(false);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const isDisabled = (): boolean => {
        if (ordenesProceso && ordenesProceso.length > 0 && isEmailReady && !isGettingAddresses && validUserEmail && !isEmailOverloaded && areThereAddresses()) {
            return isSending;
        } else {
            return true;
        }
    }

    const sendEmailEvent = async () => {
        changeToSendingState();
        if (await formatEmailContent(ordenesProcesoDetails, attachments)) {
            await sendEmail(ordenesProcesoDetails, handleClose);
        } else {
            changeToNotSendingState();
            cogoToast.error('No fue posible enviar el correo porque no hay destinatarios');
        }
    }

    useEffect(() => {
        if (isUserEmailValid(userEmail)) {
            setValidUserEmail(true);
        }
    }, [userEmail]);

    useEffect(() => {
        (async () => {
            if (validUserEmail) {
                if (show) {
                    setIsDownloadingFiles(true);
                    const ops: OrdenProceso[] = [];
                    const guias: Attachment[] = [];
                    const imgs: Attachment[] = [];
                    const kits: Attachment[] = [];
                    const fallas: Attachment[] = [];
                    const inconformidades: Attachment[] = [];
                    const guiasIDs: string[] = [];
                    const imgsIDs: string[] = [];
                    const repuestosIDs: string[] = [];
                    const getOP = async (codigoOP: string) => {
                        await getOrdenProceso(codigoOP)
                            .then(async (response) => {
                                if (response.data.codigoOP) {
                                    ops.push(response.data);
                                    if (response.data.subOPs && response.data.subOPs.length > 0) {
                                        for (let i = 0; i < response.data.subOPs.length; i++) {
                                            await getOP(response.data.subOPs[i]);
                                        }
                                    }
                                    if (response.data.recepcion?.guiaDespachoDoc) {
                                        let doesDocExists = false;
                                        for (let i = 0; i < guiasIDs.length; i++) {
                                            if (response.data.recepcion.guiaDespachoDoc === guiasIDs[i]) {
                                                doesDocExists = true;
                                                break;
                                            }
                                        }
                                        if (!doesDocExists) {
                                            guiasIDs.push(response.data.recepcion?.guiaDespachoDoc);
                                        }
                                    }
                                    if (response.data.recepcion?.registroDoc) {
                                        let doesDocExists = false;
                                        for (let i = 0; i < imgsIDs.length; i++) {
                                            if (response.data.recepcion.registroDoc === imgsIDs[i]) {
                                                doesDocExists = true;
                                                break;
                                            }
                                        }
                                        if (!doesDocExists) {
                                            imgsIDs.push(response.data.recepcion?.registroDoc);
                                        }
                                    }
                                    if (response.data.componente?.documentosListado) {
                                        for (let i = 0; i < response.data.componente.documentosListado.length; i++) {
                                            let doesDocExists = false;
                                            for (let e = 0; e < repuestosIDs.length; e++) {
                                                if (repuestosIDs[e] === response.data.componente.documentosListado[i]) {
                                                    doesDocExists = true;
                                                    break;
                                                }
                                            }
                                            if (!doesDocExists) {
                                                repuestosIDs.push(response.data.componente.documentosListado[i]);
                                            }
                                        }
                                    }
                                    if (response.data.informeFallasRecepcionDoc?._id) {
                                        await getInformeFallasRecepcion({ _id: response.data.informeFallasRecepcionDoc?._id })
                                            .then((informeFallaResponse) => {
                                                if (informeFallaResponse.data?.base64) {
                                                    fallas.push({
                                                        name: informeFallaResponse.data.nombre,
                                                        content: informeFallaResponse.data.base64
                                                    });
                                                }
                                            })
                                    }
                                    if (response.data.noConformidadRecepcionDoc?._id) {
                                        await getNoConformidad({ _id: response.data.noConformidadRecepcionDoc?._id })
                                            .then((noConformidadResponse) => {
                                                if (noConformidadResponse.data?.base64) {
                                                    inconformidades.push({
                                                        name: noConformidadResponse.data.nombre,
                                                        content: noConformidadResponse.data.base64
                                                    })
                                                }
                                            })
                                    }
                                }
                            }).catch(() => {
                                cogoToast.error('No fue posible obtener la orden de proceso');
                            });
                    };
                    for (let i = 0; i < ordenesProceso.length; i++) {
                        await getOP(ordenesProceso[i].codigoOP);
                    }
                    for (let i = 0; i < guiasIDs.length; i++) {
                        await getGuiaDespachoRecepcion({ _id: guiasIDs[i] })
                            .then((guiaResponse) => {
                                if (guiaResponse.data?.base64) {
                                    guias.push({
                                        name: guiaResponse.data.nombre,
                                        content: guiaResponse.data.base64
                                    });
                                }
                            });
                    }
                    for (let i = 0; i < imgsIDs.length; i++) {
                        await getRegistroRecepcion({ _id: imgsIDs[i] })
                            .then((imagenesResponse) => {
                                if (imagenesResponse.data?.base64) {
                                    imgs.push({
                                        name: imagenesResponse.data.nombre,
                                        content: imagenesResponse.data.base64
                                    });
                                }
                            });
                    }
                    for (let i = 0; i < repuestosIDs.length; i++) {
                        await getListadoRepuesto({ _id: repuestosIDs[i] })
                            .then((listadoResponse) => {
                                if (listadoResponse.data?.base64) {
                                    kits.push({
                                        name: listadoResponse.data.nombre,
                                        content: listadoResponse.data.base64
                                    })
                                }
                            })
                    }
                    setOrdenesProcesoDetails(ops);
                    const allAttachments = [...guias, ...imgs, ...kits, ...fallas, ...inconformidades];
                    setAttachments(allAttachments);
                    setIsDownloadingFiles(false);
                    if (!(await areFilesOver10MB(
                            allAttachments,
                            (state: boolean) => setIsEmailOverloaded(state),
                            (state: boolean) => setIsCalculatingAttachmentsSize(state),
                            (size: number) => setCurrentAttachmentsSize(size)
                        ))) {
                        getDestinatarios((state: boolean) => setIsEmailReady(state));
                    }
                } else {
                    setAttachments([]);
                }
            }
        })().catch(() => cogoToast.error('No fue posible preparar el correo'));
    }, [show]);

    return (
        <>
            {userPermission && 
                <Button onClick = {handleShow} disabled = {ordenesProceso ? false : true}>
                    Envío de Correos
                </Button>
            }
            {show &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Correo de apertura</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Órdenes de proceso por abrir:</h5>
                        <ul>
                            {ordenesProceso?.map((op) => {
                                return (
                                    <li>
                                        <div className = "email-list-object">
                                            {`${op.codigoOP}, ${op.descripcionComponente}`}
                                            {/*
                                            <Button variant = "danger" size = "sm" onClick = {() => {removeOrdenesProcesoSelected(op)}}>
                                                X
                                            </Button>
                                            */}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        {!validUserEmail ?
                            <div>
                                <small>El usuario actual no tiene una dirección de correo válida</small>
                            </div> :
                            <div>
                                {!isEmailReady && isDownloadingFiles && !isCalculatingAttachmentsSize && !isGettingAddresses && (
                                    <>
                                        <small>Descargando archivos para adjuntar ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </>
                                )}
                                {!isEmailReady && !isDownloadingFiles && isCalculatingAttachmentsSize && !isGettingAddresses && (
                                    <>
                                        <small>Calculando tamaño de los archivos adjuntos ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </>
                                )}
                                {!isEmailReady && !isDownloadingFiles && !isCalculatingAttachmentsSize && !isGettingAddresses && isEmailOverloaded && (
                                    <>
                                        <Row>
                                            <small>El tamaño de los archivos adjuntos es muy grande, debe ser menor a <b>9,75MB</b>.</small>
                                        </Row>
                                        <Row>
                                            <small>Seleccione menos OPs para abrir o reemplace los archivos de las recepciones.</small>
                                        </Row>
                                        <Row>
                                            <small>Tamaño total de los archivos adjuntos: <b>{`${((currentAttachmentsSize/1024)/1024).toFixed(2)}MB`}</b></small>
                                        </Row>
                                        {/* <Spinner animation="border" size="sm" /> */}
                                    </>
                                )}
                                {!isEmailReady && !isDownloadingFiles && !isCalculatingAttachmentsSize && isGettingAddresses && (
                                    <>
                                        <small>Adquiriendo destinatarios ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </>
                                )}
                                {!isEmailReady && !isDownloadingFiles && !isCalculatingAttachmentsSize && !isGettingAddresses && (
                                    <>
                                        <small>Adquiriendo destinatarios ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </>
                                )}
                                {isEmailReady && !isSending && (
                                    <>
                                        <small>Tamaño total de los archivos adjuntos: <b>{`${((currentAttachmentsSize/1024)/1024).toFixed(2)}MB`}</b> de 9,75MB</small>
                                    </>
                                )}
                                {isEmailReady && !areThereAddresses() && (
                                    <small>No hay destinatarios</small>
                                )}
                                {isEmailReady && isSending && (
                                    <>
                                        <small>Enviando correo ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </>
                                )}
                            </div>
                        }
                        <div className = "modal-buttons">
                            <Button onClick = {sendEmailEvent} disabled = {isDisabled()}>
                                {isSending ? <Spinner animation="border" size="sm" /> : 'Enviar'}
                            </Button>
                            <Button onClick = {handleClose} variant = "secondary" disabled = {isSending}>
                                {isSending ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>)
            }
        </>
    );
}

export default CorreoApertura;