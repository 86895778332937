import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Card } from "react-bootstrap";
import { deleteDestinatarioReporteOPNS, getDestinatariosReporteOPNS, postDestinatarioReporteOPNS } from "../../api/EvaluacionesApi";
import { Destinatario } from "../../models/Email";
import { Persona } from "../../models/users/Users";
import DestinatariosCorreo from "../users/DestinatariosCorreo";

interface Props {
    userPermission: boolean
}

const ReporteOPNS = ({ userPermission }: Props): ReactElement => {

    const [ addresses, setAddresses ] = useState<Persona[]>();
    const [ copiedAddresses, setCopiedAddresses ] = useState<Persona[]>();

    return (
        <Card className = "cards-container">
            <Card.Header className = "cards-header">
                <div className = "body-subtitle">
                    Reporte OP y N/S en evaluaciones
                </div>
            </Card.Header>
            <Card.Body className = "cards-body-groups">
                <DestinatariosCorreo
                    isNotCard
                    addresses = {addresses}
                    copiedAddresses = {copiedAddresses}
                    userPermission = {userPermission}
                    postDestinatarios = {(personas: Destinatario[], hideModal: () => void, setStateToFalse: () => void) =>
                        postDestinatarioReporteOPNS(personas)
                            .then((response) => {
                                setAddresses(response.data.to);
                                setCopiedAddresses(response.data.cc);
                                cogoToast.success('Destinatarios añadidos');
                                setStateToFalse();
                                hideModal();
                            })
                            .catch(() => {
                                cogoToast.error('No fue posible añadir la dirección de correo');
                                setStateToFalse();
                            })
                    }
                    deleteDestinatario = {(personaEmail: string, hideModal: () => void, setStateToFalse: () => void) =>
                        deleteDestinatarioReporteOPNS(personaEmail)
                            .then((response) => {
                                setAddresses(response.data.to);
                                setCopiedAddresses(response.data.cc);
                                cogoToast.success('Persona removida');
                                setStateToFalse();
                                hideModal();
                            }).catch(() => {
                                cogoToast.error('No fue posible remover a la persona');
                                setStateToFalse();
                            })
                    }
                    getDestinatarios = {(changeIsLoadingToFalse: () => void, changeLoadFailedToTrue: () => void) =>
                        getDestinatariosReporteOPNS()
                            .then((response) => {
                                setCopiedAddresses(response.data.cc);
                                setAddresses(response.data.to);
                                changeIsLoadingToFalse();
                            })
                            .catch(() => {
                                cogoToast.error('No fue posible obtener las direcciones de correo');
                                changeIsLoadingToFalse();
                                changeLoadFailedToTrue();
                            })
                    }
                />
            </Card.Body>
        </Card>
    );
}

export default ReporteOPNS;