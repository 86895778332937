import { UnidadEstrategicaNegocio } from "../../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../../models/optionsFilters/Select";


const useUENMecanico = (): SelectOption<UnidadEstrategicaNegocio>[] => {

    return [
        { label: 'MOTORES DIESEL', value: { uen: 'MOTORES DIESEL' } },
        { label: 'MOTOR TRACCIÓN', value: { uen: 'MOTOR TRACCION' } },
        { label: 'FRENOS', value: { uen: 'FRENOS' } },
        { label: 'DINAMÓMETRO', value: { uen: 'DINAMOMETRO' } },
        { label: 'SOLDADURAS', value: { uen: 'SOLDADURAS' } },
        { label: 'PINTURAS', value: { uen: 'PINTURAS' } },
    ];
    
}

export default useUENMecanico;