import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Spinner, Table } from "react-bootstrap";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { useHistory } from "react-router-dom";
import EditEvaluacionReparacionComponenteResumen from "./EditEvaluacionReparacionComponenteResumen";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";

interface Props {
    evaulacionReparacionComponenteResumenEtapas: EvaluacionOrReparacionComponenteResumenEtapa[],
    codigoOP: string,
    changeEvaulacionReparacionComponenteResumenEtapa?: (evaulacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void,
    isBusy?: boolean,
    defaultDisabled?: boolean,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean,
    userRoles: string[],
    evaluacionReparacionComponenteEtapaTareasApplyConst?: { nombreTarea: string, isConst: boolean }[],
    isTareaComplete?: (nombreTarea: string) => boolean,
    isEvaluacionNotComplete?: boolean,
    detallesApi: DetallesEvaluacionReparacionApi
}

const EvaluacionReparacionComponenteResumen = ({
    codigoOP, evaulacionReparacionComponenteResumenEtapas, changeEvaulacionReparacionComponenteResumenEtapa, isBusy, userPermissionFunction, userRoles, evaluacionReparacionComponenteEtapaTareasApplyConst, isTareaComplete, defaultDisabled, isEvaluacionNotComplete, detallesApi
}: Props): ReactElement => {

    const [ showInicio, setShowInicio ] = useState<boolean>(false);
    const [ showAplica, setShowAplica ] = useState<boolean>(false);
    const [ showTermino, setShowTermino ] = useState<boolean>(false);
    const history = useHistory();

    const generalOnHide = () => {
        setShowInicio(false);
        setShowTermino(false);
        setShowAplica(false);
    }

    return (
        <>
            {evaulacionReparacionComponenteResumenEtapas.map((etapa) => {
                const isDisabled = defaultDisabled || !userPermissionFunction(
                    { roles: ['ADMINISTRADOR', 'JEFETALLER', 'CALIDAD'] },
                    etapa.participantes && { acceptedUsers: etapa.participantes.map((persona) => persona.email), acceptedRolesElements: { roles: ['OPERARIO'], isExcludingRoles: false, notExactRol: true } }
                ) || isBusy;
                const onClickDetails = () => history.push(`/ordenesProceso/${codigoOP}/evaluacion/${etapa.nombreEtapa}`);
                const columnas: string[] = [];
                etapa.tareas.forEach((tarea) => {
                    tarea.valores.forEach((valor) => {
                        if (!columnas.includes(valor.columna)) {
                            columnas.push(valor.columna);
                        }
                    })
                });
                return (
                    <>
                        <Card className = "cards-container">
                            <Card.Header className = "cards-header">
                                {changeEvaulacionReparacionComponenteResumenEtapa ?
                                    <>
                                        <div className = "body-subtitle">
                                            Registro de actividades
                                        </div>
                                            {!etapa.isEndDate && etapa.isTimeStamp ?
                                                <div className = "buttons-group">
                                                    <Button variant="success" disabled = {isDisabled} onClick = {() => setShowInicio(true)}>
                                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Inicio'}
                                                    </Button>
                                                    <Button variant="danger" disabled = {isDisabled} onClick = {() => setShowTermino(true)}>
                                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Término'}
                                                    </Button>
                                                </div>
                                            :
                                                ((): boolean => {
                                                    let response = false;
                                                    if (evaluacionReparacionComponenteEtapaTareasApplyConst) {
                                                        for (let i = 0; i < evaluacionReparacionComponenteEtapaTareasApplyConst.length; i++) {
                                                            if (!evaluacionReparacionComponenteEtapaTareasApplyConst[i].isConst) {
                                                                response = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    return response;
                                                })() &&
                                                <div className = "buttons-group">
                                                    <Button  disabled = {isDisabled} onClick = {() => setShowAplica(true)}>
                                                        {isBusy ? <Spinner animation="border" size="sm" /> : 'Aplica'}
                                                    </Button>
                                                </div>
                                            }
                                    </>
                                :
                                    <>
                                        <div className = "body-subtitle">
                                            {etapa.nombreEtapa}
                                        </div>
                                        {(
                                            userRoles.includes('ADMINISTRADOR') ||
                                            userRoles.includes('JEFETALLER') ||
                                            userRoles.includes('CALIDAD') ||
                                            userRoles.some((rol) => rol.includes('OPERARIO'))
                                        ) &&
                                            <div className = "buttons-group">
                                                <Button onClick = {onClickDetails} disabled = {isDisabled}>
                                                    {isBusy ? <Spinner animation="border" size="sm" /> : 'Detalles'}
                                                </Button>
                                            </div>
                                        }
                                    </> 
                                }
                            </Card.Header>
                            <Card.Body className = "cards-body-groups">
                                <Table bordered size = 'sm'>
                                    <thead>
                                        <tr>
                                            <th>Actividad</th>
                                            {columnas.map((columna) => {
                                                return (
                                                    <th><div className = "centerTextCell">{columna}</div></th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {etapa.tareas.map((tarea) => {
                                            return (
                                                <tr>
                                                    <td>{tarea.nombreTarea}</td>
                                                    {columnas.map((columna) => {
                                                        let valor: string | undefined;
                                                        for (let i = 0; i < tarea.valores.length; i++) {
                                                            if (tarea.valores[i].columna === columna) {
                                                                valor = tarea.valores[i].valor;
                                                                break;
                                                            }
                                                        }
                                                        return (
                                                            <td><div className = "centerTextCell">{valor ? valor : ''}</div></td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        {changeEvaulacionReparacionComponenteResumenEtapa && isTareaComplete && (showInicio || showTermino || showAplica) &&
                            <Modal show = {showInicio || showTermino || showAplica} onHide = {generalOnHide}>
                                <Modal.Header>
                                    <Col className = "text-center">
                                        <Modal.Title>{showAplica ? 'Realización' : showInicio ? 'Inicio' : showTermino && 'Término'} de actividades</Modal.Title>
                                    </Col>
                                </Modal.Header>
                                <Modal.Body>
                                    <EditEvaluacionReparacionComponenteResumen
                                        evaluacionReparacionComponenteResumenEtapa = {evaulacionReparacionComponenteResumenEtapas[0]}
                                        changeEvaulacionReparacionComponenteResumenEtapa = {changeEvaulacionReparacionComponenteResumenEtapa}
                                        showInicio = {showInicio}
                                        showAplica = {showAplica}
                                        showTermino = {showTermino}
                                        generalOnHide = {generalOnHide}
                                        evaluacionReparacionComponenteEtapaTareasApplyConst = {evaluacionReparacionComponenteEtapaTareasApplyConst}
                                        isTareaComplete = {isTareaComplete}
                                        isEvaluacionOrReparacionNotComplete = {isEvaluacionNotComplete}
                                        detallesApi = {detallesApi}
                                    />
                                </Modal.Body>
                            </Modal>
                        }
                    </>
                );
            })}
        </>
    );
}

export default EvaluacionReparacionComponenteResumen;