import { ReactElement, useState } from "react";
import { Archivo } from "../../models/Archivos";
import { useDropzone } from "react-dropzone";
import { Button, Modal, Spinner } from "react-bootstrap";
import classNames from "classnames";

interface Props {
    title: string,
    image?: Archivo,
    onDocumentDelete: () => void,
    onDocumentUpload: (file: Blob, name: string) => void
    isLoading: boolean,
    notEditable: boolean
}

const UploadImageProceso = ({ title, image, onDocumentDelete, onDocumentUpload, isLoading, notEditable }: Props): ReactElement  => {

    const [modalImage, setModalImage] = useState<string>();
    const [showImage, setShowImage] = useState(false);
    const handleShowImage = () => setShowImage(true);
    const handleCloseImage = () => {
        setShowImage(false);
        setModalImage(undefined);
    }

    const clickImage = () => {
        if (image) {
            setModalImage(image.base64);
            handleShowImage();
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        disabled: notEditable,
        onDrop: (async (onDropAcceptedFiles) => {
            await Promise.all(onDropAcceptedFiles.map(async (acceptedfile) => {
                onDocumentUpload(acceptedfile, acceptedfile.name);
                // onDocumentUpload({ nombre: acceptedfile.name, base64: await getBase64(acceptedfile) });
                /*
                await getAllDocumentsSize()
                    .then(async (response) => {
                        if (!response.data.message) {
                            if (isDBFull(response.data.size, acceptedfile.size)) {
                                cogoToast.error('No hay espacio para guardar más documentos');
                            } else {
                                onDocumentUpload({ nombre: acceptedfile.name, base64: await getBase64(acceptedfile) });
                            }
                        } else {
                            cogoToast.error('No fue posible calcular el tamaño futuro de la base de datos');
                            cogoToast.error('No fue posible subir del documento');
                        }
                    })
                    .catch((error) => {
                        cogoToast.error('No fue posible calcular el tamaño futuro de la base de datos');
                        cogoToast.error('No fue posible subir del documento');
                    })
                */
            }));
        })
    });

    return (
        <>
            <div className = "body-registro-image-container">
                {isLoading ? 
                    <div className = "text-center"><Spinner animation = "border"/></div>
                :
                    <>
                        <div className = "body-subtitle">{title}</div>
                        {image ? 
                            <>
                                <img className = "body-registros-images" src = {image.base64} onClick = {clickImage} alt = "componenteImg"/>
                                <div className = "image-button">
                                    <Button onClick = {onDocumentDelete} variant = "danger" disabled = {notEditable}>
                                        Eliminar
                                        {/* {notEditable ? <Spinner animation = "border" size = "sm"/> : 'Eliminar'} */}
                                    </Button>
                                </div>
                            </>
                        :
                            <div { ...getRootProps() } className = {classNames("dropzone", { disabled: notEditable })}>
                                <input { ...getInputProps() }/>
                                <p className = "text-center">Click o arrastre una imagen para agregar ...</p>
                            </div>
                        }
                    </>
                }
            </div>
            {showImage && (
                <Modal show = {showImage} onHide = {handleCloseImage}>
                    <img src = {modalImage} alt = "currentComponenteImg"/>
                </Modal>
            )}
        </>
    );
}

export default UploadImageProceso;