import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getUsers } from "../../api/UsersApi";
import { UsersFilters, UserTabla } from "../../models/users/Users";
import Table, { TableColumn } from "../form/table/Table";

interface UsersListProps {
    usersFilters: UsersFilters,
    setUsersFilters: (filters: UsersFilters) => void,
    addUserFunction?: (user: UserTabla) => void
    // addEmailUserToRecipients?: (user: UserTabla) => void,
    // existingPersonas?: Persona[]
}

const UsersList = ({ usersFilters, setUsersFilters, addUserFunction }: UsersListProps): ReactElement => {

    const [ users, setUsers ] = useState<UserTabla[]>();
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ totalPages, setTotalPages ] = useState<number>();

    useEffect(() => {
        getUsers(usersFilters)
            .then((response) => {
                if (response.data.users) {
                    setUsers(response.data.users);
                    setTotalPages(response.data.totalPages);
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible obtener los usuarios');
                }
                setIsLoading(false);
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener los usuarios');
                setIsLoading(false);
            })
    }, [usersFilters]);

    const handleUsuarioClick = (user: UserTabla): void => {
        if (addUserFunction) {
            addUserFunction(user);
        } else {
            history.push(`/users/${user.email}`);
        }
    };

    const getTableColumns = (): TableColumn<UserTabla>[] => {
        let response: TableColumn<UserTabla>[] = [
            {
                title: 'Nombre',
                accessor: 'nombre'
            },
            {
                title: 'Apellidos',
                accessor: 'apellidos'
            },
            {
                title: 'Email / ID',
                accessor: 'email',
                hideUntil: 'sm'
            },
            {
                title: 'Rol',
                accessor: 'roles',
                hideUntil: 'sm'
            }
        ];
        if (!addUserFunction) {
            response.push({    
                title: 'Estado',
                accessor: 'estado'
            })
        }
        return response;
    }

    if (!users) {
        if (isLoading) {
            return (
                <Card className = "cards-container">
                    <Card.Body>
                        <div className = "text-center">
                            <Spinner animation = "border"/>
                        </div>
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">No fue posible cargar los usuarios</p>;
        }
    } else {
        return (
            <>
                <Table
                    hover
                    items = {users}
                    columns = {getTableColumns()}
                    onItemClick = {handleUsuarioClick}
                    pages = {totalPages}
                    activePage = {usersFilters.page}
                    onPageSelect = {(page) => setUsersFilters({ ...usersFilters, page: page})}
                />
            </>
        );
    }
}

export default UsersList;