import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import CorreoApertura from "../components/aperturaOP/CorreoApertura";
import OrdenesProcesosFiltersComponent from "../components/estatusGeneral/OrdenesProcesoFiltersComponent";
import AuthContext from "../contexts/AuthContext";
import useEstadosOrdenProceso from "../hooks/estatusGeneral/useEstadosOrdenProceso";
import useTipoProceso from "../hooks/estatusGeneral/useTipoProceso";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { OrdenesProcesoFilters, OrdenProceso, OrdenProcesoTabla } from "../models/estatusGeneral/OrdenProceso";
import { formatUpdateOrdenesProcesoEstado, getEmptyOrdenesProcesoFilters } from "../utils/estatusGeneral";
import { TransactionalEmailsApi, SendSmtpEmail } from "@sendinblue/client";
import * as SibApiV3Sdk from "@sendinblue/client";
import { Attachment } from "../models/Email";
import { getAperturaOPEmail } from "../utils/emails";
import OPList from "../components/aperturaOP/OPList";
import { cambioEstadoOPs } from "../api/OrdenesProcesoApi";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getDestinatariosAperturaOP } from "../api/UsersApi";
import useTipoOrdenProceso from "../hooks/estatusGeneral/useTipoOrdenProceso";
import useCentroCostoProceso from "../hooks/estatusGeneral/useCentroCostoProceso";
import { Persona } from "../models/users/Users";
import DestinatariosCorreoAperturaOP from "../components/aperturaOP/DestinatariosCorreoAperturaOP";
import { getUserPermissionFunction } from "../utils/users";

const AperturaOPPage = (): ReactElement => {

    const { userName, userEmail, userRoles } = useContext(AuthContext);
    const userPermission = getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'PLANIFICACION'] });
    const [ apiInstance ] = useState<TransactionalEmailsApi>(new SibApiV3Sdk.TransactionalEmailsApi());
    const [ sendSmtpEmail ] = useState<SendSmtpEmail>(new SibApiV3Sdk.SendSmtpEmail());
    const [ addresses, setAddresses ] = useState<Persona[]>();
    const [ copiedAddresses, setCopiedAddresses ] = useState<Persona[]>();
    const [ isSending, setIsSending ] = useState<boolean>(false);
    const [ isGettingAddresses, setIsGettingAddresses ] = useState<boolean>(false);
    const [ ordenesProcesoSelected, setOrdenesProcesoSelected ] = useState<OrdenProcesoTabla[]>([]);
    const [ ordenesProcesoFilters, setOrdenesProcesoFilters ] = useState<OrdenesProcesoFilters>(
        getEmptyOrdenesProcesoFilters('apertura')
    );

    const tipoProcesoOptions = useTipoProceso(true);
    const tipoOrdenProcesoOptions = useTipoOrdenProceso();
    const estadosOrdenProcesoOptions = useEstadosOrdenProceso('filtros');
    const centroCostoOptions = useCentroCostoProceso();
    const breadcrumb = useBreadcrumb();

    const changeAddresses = (addresses: Persona[]) => {
        setAddresses(addresses);
    }

    const changeCopiedAddresses = (addresses: Persona[]) => {
        setCopiedAddresses(addresses);
    }

    const ordenesProcesoFiltersChange = (ordenesProcesoFilters: OrdenesProcesoFilters) => {
        setOrdenesProcesoFilters(ordenesProcesoFilters);
    }

    const areThereAddresses = (): boolean => {
        if ((addresses && addresses.length > 0) || (copiedAddresses && copiedAddresses.length > 0)) {
            return true;
        } else {
            return false;
        }
    }

    const changeToSendingState = () => setIsSending(true);
    const changeToNotSendingState = () => setIsSending(false);
    const addOrdenesProcesoSelected = (op: OrdenProcesoTabla) => {
        setOrdenesProcesoSelected([...ordenesProcesoSelected, op]);
    }
    const removeOrdenesProcesoSelected = (op: OrdenProcesoTabla) => {
        let opstemp: OrdenProcesoTabla[] = [];
        ordenesProcesoSelected.forEach((opSelected) => {
            if (opSelected.codigoOP !== op.codigoOP) {
                opstemp.push(opSelected);
            }
        });
        setOrdenesProcesoSelected(opstemp);
    }

    /*
    const rolPermission = (): boolean => {
        if (userRoles.includes('ADMINISTRADOR') ||
            userRoles.includes('PLANIFICACION')) {
            return true;
        } else {
            return false;
        }
    }
    */

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Apertura de OP',
                inactive: true,
            },
        ]);
    }, [breadcrumb]);

    const getDestinatarios = (changeIsEmailReadyState: (state: boolean) => void) => {
        setIsGettingAddresses(true);
        if (!areThereAddresses()) {
            getDestinatariosAperturaOP()
                .then((response) => {
                    changeCopiedAddresses(response.data.cc);
                    changeAddresses(response.data.to);
                    setIsGettingAddresses(false);
                    changeIsEmailReadyState(true);
                }).catch(() => {
                    // cogoToast.error(error);
                    cogoToast.error('No fue posible adquirir los destinatarios');
                    setIsGettingAddresses(false);
                    changeIsEmailReadyState(false);
                })
        } else {
            setIsGettingAddresses(false);
            changeIsEmailReadyState(true);
        }
    };

    const formatEmailContent = (ordenesProcesoDetails: OrdenProceso[], attachments: Attachment[]): boolean => {
        // console.log(ordenesProcesoDetails.length);
        // console.log(guiasDespacho?.length);
        // console.log(imagenesDespachos?.length);
        // console.log(kitsBasicos?.length);
        apiInstance.setApiKey(
            SibApiV3Sdk.TransactionalEmailsApiApiKeys.apiKey,
            process.env.EMAIL_API_KEY ||
                "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"
        );
        if (addresses || copiedAddresses) {
            const email = getAperturaOPEmail(ordenesProcesoDetails, attachments, addresses, copiedAddresses);
            sendSmtpEmail.sender = {
                "name": userName,
                "email": userEmail
            };
            sendSmtpEmail.subject = email?.subject;
            sendSmtpEmail.htmlContent = email?.htmlContent;
            sendSmtpEmail.to = email?.to;
            sendSmtpEmail.cc = email?.cc;
            if (email.attachment) {
                sendSmtpEmail.attachment = email?.attachment;
            } else {
                sendSmtpEmail.attachment = undefined;
            }
            return true;
        } else {
            return false;
        }
    }

    const sendEmail =  async (ordenesProcesoDetails: OrdenProceso[], closeModal: () => void): Promise<void> => {
        apiInstance.sendTransacEmail(sendSmtpEmail)
            .then(async () => {
                cogoToast.success('Email enviado');
                await cambioEstadoOPs(formatUpdateOrdenesProcesoEstado(ordenesProcesoDetails, 'RECEPCIONADA'))
                    .then(() => {
                        cogoToast.success('Órdenes de proceso abiertas');
                        setIsSending(false);
                    }).catch(() => {
                        cogoToast.error('No fue posible cambiar el estado de las órdenes de proceso');
                        setIsSending(false);
                    })
                ordenesProcesoFiltersChange(getEmptyOrdenesProcesoFilters('apertura'));
                closeModal();
                window.location.reload();
            })
            .catch(() => {
                // console.log(error.message);
                cogoToast.error('No fue posible enviar el correo');
                setIsSending(false);
                closeModal();
                window.location.reload();
            });
    }

    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>
                        <h5>Abrir OPs</h5>
                    </Tab>
                    <Tab>
                        <h5>Destinatarios</h5>
                    </Tab>
                </TabList>
                <TabPanel>
                    <Card className = "cards-container">
                        <Card.Body className = "cards-body-groups">
                            <div className = "cards-header">
                                {estadosOrdenProcesoOptions && (
                                    <OrdenesProcesosFiltersComponent
                                        ordenesProcesoFilters = {ordenesProcesoFilters}
                                        estadosOrdenProcesoOptions = {estadosOrdenProcesoOptions}
                                        centroCostoOptions = {centroCostoOptions}
                                        tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                                        tipoProcesoOptions = {tipoProcesoOptions}
                                        onFiltersChange = {setOrdenesProcesoFilters}
                                        aperturaOPs
                                    />
                                )}
                                <div className = "filters-buttons">
                                    <CorreoApertura
                                        ordenesProceso = {ordenesProcesoSelected}
                                        formatEmailContent = {formatEmailContent}
                                        sendEmail = {sendEmail}
                                        isSending = {isSending}
                                        changeToSendingState = {changeToSendingState}
                                        changeToNotSendingState = {changeToNotSendingState}
                                        areThereAddresses = {areThereAddresses}
                                        isGettingAddresses = {isGettingAddresses}
                                        //removeOrdenesProcesoSelected = {removeOrdenesProcesoSelected}
                                        getDestinatarios = {getDestinatarios}
                                        userPermission = {userPermission}
                                    />
                                </div>
                            </div>
                            <OPList
                                ordenesProcesoSelected = {ordenesProcesoSelected}
                                ordenesProcesoFilters = {ordenesProcesoFilters}
                                addOrdenesProcesoSelected = {addOrdenesProcesoSelected}
                                removeOrdenesProcesoSelected = {removeOrdenesProcesoSelected}
                                setOrdenesProcesoFilters = {ordenesProcesoFiltersChange}
                            />
                        </Card.Body>
                    </Card>
                </TabPanel>
                <TabPanel>
                    <DestinatariosCorreoAperturaOP
                        userPermission = {userPermission}
                        changeAddresses = {changeAddresses}
                        changeCopiedAddresses = {changeCopiedAddresses}
                        addresses = {addresses}
                        copiedAddresses = {copiedAddresses}
                    />
                </TabPanel>
            </Tabs>
        </>
    );
}

export default AperturaOPPage;