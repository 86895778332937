import { ReactElement } from "react"
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import DocumentsManager from "./DocumentsManager";
import { deleteNoConformidad, getNoConformidad, postNoConformidad } from "../../api/DocumentosApi";

interface Props {
    codigoOP: string,
    userPermission: boolean,
    // isComponenteQR?: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    noConformidadRecepcionDoc?: ArchivoNombre
}

const NoConformidad = ({ codigoOP, userPermission, isBusy, changeBusyState, noConformidadRecepcionDoc }: Props): ReactElement => (
    <DocumentsManager
        documentosListado = {noConformidadRecepcionDoc ? [noConformidadRecepcionDoc] : undefined}
        userPermission = {userPermission}
        title = {'No conformidad APP/AG'}
        externalIsBusy = {isBusy}
        changeExternalBusyState = {changeBusyState}
        getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getNoConformidad({ _id: _id }, onDownloadProgressFunction)}
        postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postNoConformidad({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
        deleteDocumento = {(_id: string) => deleteNoConformidad({ _id: _id })}
        // getDocumentosListado = {() => getNoConformidadListado(codigoOP)}
    />
)

export default NoConformidad;