import { createContext } from 'react';
import { LayoutContextValue } from '../models/layout/LayoutContext';

const LayoutContext = createContext<LayoutContextValue>({
    headerTitle: '',
    breadcrumbItems: [],
    isMenuBarActive: false,
    setHeaderTitle: () => {},
    setBreadcrumbItems: () => {},
    toggleMenuBar: () => {},
});

export default LayoutContext;