import axios, { AxiosPromise } from "axios";
import { EvaluacionOrReparacionComponenteEtapaInforme, EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteResumenEtapa, EvaluacionOrReparacionComponenteTarea } from "../models/EvaluacionesReparaciones";

export default class DetallesEvaluacionReparacionApi {

    codigoOP: string;
    etapaProceso: string;

    constructor(codigoOP: string, isReparacion?: boolean) {
        this.codigoOP = codigoOP;
        this.etapaProceso = isReparacion ? 'reparacion' : 'evaluacion';
    }

    getEvaluacionOrReparacionComponenteResumen(): AxiosPromise<EvaluacionOrReparacionComponenteResumenEtapa[] | void> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/resumen/${this.codigoOP}`);
    }
    
    getEvaluacionOrReparacionComponenteEtapa(etapa: string): AxiosPromise<EvaluacionOrReparacionComponenteEtapa> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/etapa/${this.codigoOP}`, { params: { nombreEtapa: etapa } });
    }
    
    putEvaluacionOrReparacionComponenteEtapaTarea(tarea: EvaluacionOrReparacionComponenteTarea): AxiosPromise<EvaluacionOrReparacionComponenteTarea> {
        return axios.put(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/etapa/${this.codigoOP}`, tarea);
    }
    
    putEvaluacionOrReparacionComponenteResumen(resumen: EvaluacionOrReparacionComponenteResumenEtapa): AxiosPromise<EvaluacionOrReparacionComponenteResumenEtapa | void> {
        return axios.put(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/resumen/${this.codigoOP}`, resumen)
    }
    
    getEvaluacionOrReparacionComponenteEtapas(): AxiosPromise<EvaluacionOrReparacionComponenteEtapaInforme[]> {
        return axios.get(`api/evaluacionesReparaciones/ordenProceso/${this.etapaProceso}/etapas/${this.codigoOP}`);
    }

}