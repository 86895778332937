import axios, { AxiosPromise } from "axios";
import { Entrega, EntregaRequestBody, GetEntregaRequest } from "../models/Entregas";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";

export function getEntrega(getEntregaRequest: GetEntregaRequest): AxiosPromise<Entrega> {
    return axios.get('api/entregas', { params: getEntregaRequest });
}

export function postEntrega(requestBody: EntregaRequestBody): AxiosPromise<OrdenProceso> {
    return axios.post('api/entregas', requestBody)
}

export function deleteEntrega(requestBody: GetEntregaRequest): AxiosPromise<OrdenProceso> {
    return axios.delete('api/entregas', { params: requestBody });
}

export function putEntrega(requestBody: EntregaRequestBody): AxiosPromise<Entrega> {
    return axios.put('api/entregas', requestBody);
}