import React, { ReactElement, ReactNode } from 'react';

interface ContentContainerProps {
    children?: ReactNode;
}

const ContentContainer = ({ children }: ContentContainerProps): ReactElement => {
    return <div className = "content-container">{children}</div>;
};

export default ContentContainer;