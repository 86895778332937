import { ReactElement } from "react";
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import DocumentsManager from "./DocumentsManager";
import { deleteInformeFallasRecepcion, getInformeFallasRecepcion, postInformeFallasRecepcion } from "../../api/DocumentosApi";

interface Props {
    codigoOP: string,
    userPermission: boolean,
    isComponenteQR?: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    informeFallasRecepcionDoc?: ArchivoNombre
}

const InformeFallasRecepcion = ({ codigoOP, userPermission, isComponenteQR, isBusy, changeBusyState, informeFallasRecepcionDoc }: Props): ReactElement => {
    const documentsListName = 'Informe de fallas';
    return (
        <DocumentsManager
            documentosListado = {informeFallasRecepcionDoc ? [informeFallasRecepcionDoc] : undefined}
            isNotEditable = {isComponenteQR}
            userPermission = {userPermission}
            title = {!isComponenteQR ? documentsListName : undefined}
            subtitle = {isComponenteQR ? documentsListName : undefined}
            externalIsBusy = {isBusy}
            changeExternalBusyState = {changeBusyState}
            getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getInformeFallasRecepcion({ _id: _id }, onDownloadProgressFunction)}
            postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postInformeFallasRecepcion({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
            deleteDocumento = {(_id: string) => deleteInformeFallasRecepcion({ _id: _id })}
            // getDocumentosListado = {() => getInformeFallasRecepcionListado(codigoOP)}
        />
    )
}

export default InformeFallasRecepcion;