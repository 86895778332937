import { SelectOption } from "../models/optionsFilters/Select";
import TOT from "../models/TOT";

const useTOTOptions = (): SelectOption<TOT>[] => {
    return [
        { label: 'SÍ', value: { tot: 'SI' } },
        { label: 'NO', value: { tot: 'NO' } }
    ];
}

export default useTOTOptions;