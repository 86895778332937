import { ReactElement, useContext, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import LayoutContext from '../../contexts/LayoutContext';


interface AppRouteProps extends RouteProps {
    title: string;
}

const AppRoute = ({ title, ...props}: AppRouteProps): ReactElement => {
    const { setHeaderTitle } = useContext(LayoutContext);

    useEffect(() => {
        setHeaderTitle(title);
    }, [title, setHeaderTitle]);

    return <Route {...props} />;
};

export default AppRoute;