import { ReactElement, useContext } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const HeaderBreadcrumb = (): ReactElement => {
    const { breadcrumbItems } = useContext(LayoutContext);

    const renderBreadcrumbItems = (): Array<ReactElement> => 
        breadcrumbItems.map((breadcrumbItem) => {
            const {label, href, inactive} = breadcrumbItem;
            return (
                <BreadcrumbItem active = {inactive}>
                    {href ? <Link to={href}>{label}</Link>: label}
                </BreadcrumbItem>
            );
        });
    
    return <Breadcrumb>{renderBreadcrumbItems()}</Breadcrumb>;
};

export default HeaderBreadcrumb;