import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { ComponentesFilters } from "../../models/componentes/Componente";
import useTiposComponentesOptions from "../../hooks/componentes/useTiposComponentes";
import useNumeroGalponOptions from "../../hooks/componentes/useNumeroGalpon";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import ComponentesFiltersComponent from "../../components/componentes/ComponentesFiltersComponent";
import ComponentesList from "../../components/componentes/ComponentesList";
import NuevoComponente from "../../components/componentes/NuevoComponente";
import AuthContext from "../../contexts/AuthContext";
import { getEmptyComponentesFilters } from "../../utils/componentes";
import { getUserPermissionFunction } from "../../utils/users";
import usePlanificacionComponenteOptions from "../../hooks/componentes/usePlanificacionComponente";

const ComponentesPage = (): ReactElement => {

    const [componentesFilters, setComponentesFilters] = useState<ComponentesFilters>(
        getEmptyComponentesFilters()
    );

    const tiposComponentesOptions = useTiposComponentesOptions();
    const numeroGalponOptions = useNumeroGalponOptions();
    const planificacionComponenteOptions = usePlanificacionComponenteOptions();
    const breadcrumb = useBreadcrumb();
    const { userRoles } = useContext(AuthContext);

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Componentes',
                inactive: true,
            },
        ]);
    }, [breadcrumb]);

    return (
        <Card className = "cards-container">
            <Card.Body className = "cards-body-groups">
                <div className = "cards-header">
                    <ComponentesFiltersComponent 
                        tiposComponentesOptions = {tiposComponentesOptions}
                        numeroGalponOptions = {numeroGalponOptions}
                        componentesFilters = {componentesFilters}
                        planificacionComponenteOptions = {planificacionComponenteOptions}
                        onFiltersChange = {setComponentesFilters}
                    />
                    <NuevoComponente
                        tiposComponentesOptions = {tiposComponentesOptions}
                        numeroGalponOptions = {numeroGalponOptions}
                        userPermission = {getUserPermissionFunction(userRoles)({ roles: [
                            'ADMINISTRADOR',
                            // 'JEFETALLER', 'PLANIFICACION'
                        ] })}
                    />
                </div>
                <ComponentesList
                    componentesFilters = {componentesFilters}
                    setComponentesFilters = {setComponentesFilters}
                />
            </Card.Body>
        </Card>
    )
    
};

export default ComponentesPage;