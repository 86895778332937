import { ReactElement, useState } from "react";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { Button, Card, Row, Spinner } from "react-bootstrap";
import { ProcesoPhotoRequest } from "../../models/Archivos";
import { getImagenProceso } from "../../api/DocumentosApi";
import cogoToast from "cogo-toast";
import { generarInformeEvaluacionComponente } from "../../utils/evaluaciones/evaluaciones";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";

interface Props {
    ordenProceso: OrdenProceso
}

const InformeEvaluacionQR = ({ ordenProceso }: Props): ReactElement => {

    const [ isDownloadingImages, setIsDownloadingImages ] = useState<boolean>(false);
    const detallesEvaluacionReparacionApi = new DetallesEvaluacionReparacionApi(ordenProceso.codigoOP);
    
    return (
        <Card>
            <Card.Body>
                <Row>
                    <u><h6>Documento de evaluación</h6></u>
                </Row>
                <Row>
                    <div className = "cards-header">
                        <div className = "body-subtitle"><h4>INFORME DE EVALUACION {ordenProceso.componente?.tipoComponente} {ordenProceso.codigoOP} DE {ordenProceso.cliente}.pdf</h4></div>
                        <div className = "buttons-group">
                            {isDownloadingImages ?
                                <div>
                                    ... Descargando <Spinner animation="border" size="sm" />
                                </div>
                                :
                                <Button variant = "success" onClick = {() => {
                                    detallesEvaluacionReparacionApi.getEvaluacionOrReparacionComponenteEtapa('Informe de evaluación')
                                    .then((etapaResponse) => {
                                        if (etapaResponse.data) {
                                            detallesEvaluacionReparacionApi.getEvaluacionOrReparacionComponenteEtapas()
                                                .then(async (etapasResponse) => {
                                                    if (etapasResponse.data && etapasResponse.data.length > 0) {
                                                        const photos: ProcesoPhotoRequest[] = [];
                                                        etapasResponse.data.forEach((etapa) => etapa.tareas.forEach((tarea) => tarea.photos?.forEach((photo) => photos.push({
                                                            nombreVariable: photo.title,
                                                            codigoOP: ordenProceso.codigoOP,
                                                            nombreProceso: 'evaluacion',
                                                            nombreEtapa: etapa.nombreEtapa,
                                                            nombreTarea: tarea.nombre
                                                        }))));
                                                        for (let i = 0; i < photos.length; i++) {
                                                            setIsDownloadingImages(true);
                                                            await getImagenProceso(photos[i])
                                                                .then((photoResponse) => {
                                                                    if (photoResponse.data) {
                                                                        photos[i].imagen = photoResponse.data;
                                                                    }
                                                                })
                                                                .catch(() => {
                                                                    cogoToast.error(`No fue posible obtener la imagen ${photos[i].nombreVariable}`);
                                                                })
                                                        }
                                                        setIsDownloadingImages(false);
                                                        generarInformeEvaluacionComponente(ordenProceso, etapaResponse.data, etapasResponse.data, photos);
                                                        cogoToast.success('Informe generado');
                                                        // setIsBusy(false);
                                                    } else {
                                                        // setIsBusy(false);
                                                        cogoToast.error('No fue posible generar el informe');
                                                    }
                                                })
                                                .catch(() => {
                                                    // setIsBusy(false);
                                                    cogoToast.error('No fue posible generar el informe');
                                                })
                                        } else {
                                            cogoToast.error('No fue posible obtener el detalle de la evaluación');
                                        }
                                        // setIsBusy(false);
                                    })
                                    .catch(() => {
                                        cogoToast.error('No fue posible obtener el detalle de la evaluación');
                                        // setIsBusy(false);
                                    })
                                }}>
                                    Descargar
                                    {/* {isButtonDisabled ? <Spinner animation = "border" size = "sm"/> : 'Descargar'} */}
                                </Button>
                            }
                        </div>
                    </div>
                </Row>
            </Card.Body>
        </Card>
        // <div className = "cards-header">
        //     <div className = "body-subtitle"><h4>INFORME DE EVALUACION {ordenProceso.componente?.tipoComponente} {ordenProceso.codigoOP} DE {ordenProceso.cliente}.pdf</h4></div>
        //     {isDownloadingImages ?
        //         <div>
        //             ... Descargando <Spinner animation="border" size="sm" />
        //         </div>
        //         :
        //         <Button variant = "success" onClick = {() => {
        //             getEvaluacionComponenteEtapa(ordenProceso.codigoOP, 'Informe de evaluación')
        //             .then((etapaResponse) => {
        //                 if (etapaResponse.data) {
        //                     getEvaluacionComponenteEtapas(ordenProceso.codigoOP)
        //                         .then(async (etapasResponse) => {
        //                             if (etapasResponse.data && etapasResponse.data.length > 0) {
        //                                 const photos: ProcesoPhotoRequest[] = [];
        //                                 etapasResponse.data.forEach((etapa) => etapa.tareas.forEach((tarea) => tarea.photos?.forEach((photo) => photos.push({
        //                                     nombreVariable: photo.title,
        //                                     codigoOP: ordenProceso.codigoOP,
        //                                     nombreProceso: 'evaluacion',
        //                                     nombreEtapa: etapa.nombreEtapa,
        //                                     nombreTarea: tarea.nombre
        //                                 }))));
        //                                 for (let i = 0; i < photos.length; i++) {
        //                                     setIsDownloadingImages(true);
        //                                     await getImagenProceso(photos[i])
        //                                         .then((photoResponse) => {
        //                                             if (photoResponse.data) {
        //                                                 photos[i].imagen = photoResponse.data;
        //                                             }
        //                                         })
        //                                         .catch(() => {
        //                                             cogoToast.error(`No fue posible obtener la imagen ${photos[i].nombreVariable}`);
        //                                         })
        //                                 }
        //                                 setIsDownloadingImages(false);
        //                                 generarInformeComponente(ordenProceso, etapaResponse.data, etapasResponse.data, photos);
        //                                 cogoToast.success('Informe generado');
        //                                 // setIsBusy(false);
        //                             } else {
        //                                 // setIsBusy(false);
        //                                 cogoToast.error('No fue posible generar el informe');
        //                             }
        //                         })
        //                         .catch(() => {
        //                             // setIsBusy(false);
        //                             cogoToast.error('No fue posible generar el informe');
        //                         })
        //                 } else {
        //                     cogoToast.error('No fue posible obtener el detalle de la evaluación');
        //                 }
        //                 // setIsBusy(false);
        //             })
        //             .catch(() => {
        //                 cogoToast.error('No fue posible obtener el detalle de la evaluación');
        //                 // setIsBusy(false);
        //             })
        //         }}>
        //             Descargar
        //             {/* {isButtonDisabled ? <Spinner animation = "border" size = "sm"/> : 'Descargar'} */}
        //         </Button>
        //     }
        // </div>
    );
}

export default InformeEvaluacionQR;