import { ReactElement } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { RecepcionForm } from "../../models/recepciones/Recepcion";
import { Input } from "../form/input/Input";
import FormButtons from "../form/buttons/FormButtons";
import DateSelect from "../form/DateSelect";

interface RecepcionFormComponentProps {
    formState: FormState<RecepcionForm>,
    submitForm: (recepcionForm: RecepcionForm) => void,
    onCancel: () => void,
    modeloComponente: string
    // isCreating?: boolean,
}

const RecepcionFormComponent = ({
    formState,
    submitForm,
    onCancel,
    modeloComponente
    // isCreating
}: RecepcionFormComponentProps): ReactElement => {

    const {
        value: recepcionForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    const isFormInvalid = (): boolean => {
        return !recepcionForm.fechaRecepcion || !recepcionForm.numeroSerieComponente;
    }

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid()) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(recepcionForm);
    }

    const cancelDelete = () => setConfirmingDelete(false);

    /*
    const isEditting = (): boolean => {
        if (onDelete === undefined) {
            return isSubmitting;
        } else {
            return true;
        }
    }
    */

    return (
        <>
            <Form>
                <Row>
                    <Input
                        title = "Número guía de despacho"
                        name = "numeroGuiaDespacho"
                        value = {recepcionForm.numeroGuiaDespacho}
                        onChangeWithName = {handleFieldChange}
                        // disabled = {!isCreating}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Input
                        title = "N/S Componente"
                        name = "numeroSerieComponente"
                        value = {recepcionForm.numeroSerieComponente}
                        onChangeWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && !recepcionForm.numeroSerieComponente}
                        disabled = {isSubmitting}
                        placeholder = 'Obligatorio'
                    />
                </Row>
                <Row>
                    <Col lg = {4}>
                        <div className = 'form-group'>Modelo</div>
                        <input
                            value = {modeloComponente}
                            className = "form-control"
                            disabled
                        />
                    </Col>
                    <Col>
                        <Input
                            title = "Diferencia modelo"
                            name = "subModeloComponente"
                            value = {recepcionForm.subModeloComponente}
                            onChangeWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                            // placeholder = 'Obligatorio'
                        />
                    </Col>
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha de Recepción'
                        name = 'fechaRecepcion'
                        onChangeField = {handleFieldChange}
                        invalid = {areValidationsEnabled && !recepcionForm.fechaRecepcion}
                        selected = {recepcionForm.fechaRecepcion}
                        disabled = {isSubmitting}
                        maxDate = {new Date()}
                        placeholder = 'Obligatorio'
                    />
                    {/* <div className = "form-group">
                        
                    </div>
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            recepcionForm.fechaRecepcion ?
                            parse(recepcionForm.fechaRecepcion, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFieldChange("fechaRecepcion", changedFilter);
                        }}
                        disabled = {isSubmitting}
                        maxDate = {new Date()}
                        placeholderText = 'Obligatorio'
                    /> */}
                </Row>
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    // onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    // onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    isFormInvalid={isFormInvalid()}
                    areValidationsEnabled={areValidationsEnabled}
                />
            </Form>
        </>
    );
}

export default RecepcionFormComponent;