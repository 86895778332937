import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getComponente } from "../../api/ComponentesApi";
import ComponenteDetails from "../../components/componentes/ComponenteDetails";
import AuthContext from "../../contexts/AuthContext";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { Componente } from "../../models/componentes/Componente";
import { getUserPermissionFunction } from "../../utils/users";
import { PlanificacionComponente } from "../../models/Planificaciones";
import { getPlanificacionComponente } from "../../api/PlanificacionesApi";

const ComponentePage = (): ReactElement => {

    const [ componente, setComponente ] = useState<Componente>();
    const [ planificacionesComponente, setPlanificacionComponente ] = useState<PlanificacionComponente[]>();
    const { modeloComponente, codigoOP, descripcionComponente } = useParams<{ modeloComponente: string, codigoOP: string, descripcionComponente: string }>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const breadcrumb = useBreadcrumb();
    const { userRoles } = useContext(AuthContext);

    const changePlanificacionesComponente = (newPlanificacionesComponente: PlanificacionComponente[]) => setPlanificacionComponente(newPlanificacionesComponente);
    
    useEffect(() => {
        if (!componente) {
            getComponente(modeloComponente, descripcionComponente)
                .then((response) => {
                    if (response.data.modelo) {
                        setComponente(response.data);
                        // getPlanificacionComponente(response.data.modelo, response.data.descripcion)
                        //     .then((response) => {
                        //         if (response.data) {
                        //             setPlanificacionComponente(response.data);
                        //         }
                        //     })
                        //     .catch(() => cogoToast.error('No fue posible obtener la planificacion del componente'))
                    } else {
                        // cogoToast.error(response.data.message);
                        cogoToast.error('No fue posible obtener el componente');
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    // cogoToast.error(error);
                    cogoToast.error('No fue posible obtener el componente');
                    setIsLoading(false);
                });
        }
    }, [modeloComponente, descripcionComponente]);

    useEffect(() => {
        if (componente) {
            if (codigoOP) {
                breadcrumb.setItems([
                    {
                        label: 'Estatus General',
                        href: '/ordenesProceso',
                    },
                    {
                        label: codigoOP,
                        href: `/ordenesProceso/${codigoOP}`,
                    },
                    {
                        label: modeloComponente,
                        inactive: true,
                    }
                ]);
            } else {
                breadcrumb.setItems([
                    {
                        label: 'Componentes',
                        href: '/componentes',
                    },
                    {
                        label: componente.modelo,
                        inactive: true,
                    }
                ]);
            }
        }
    }, [componente, codigoOP, modeloComponente]);

    const handleComponenteChange = (componente: Componente): void => {
        setComponente(componente);
    }

    if (!componente) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border" />
                    </Card.Body>
                </Card>
            )
        } else {
            return <p className = "text-center">No fue posible obtener el componente</p>;
        }
    } else {
        return (
            <ComponenteDetails
                componente = {componente}
                onComponenteChange = {handleComponenteChange}
                codigoOP = {codigoOP}
                userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'JEFETALLER', 'PLANIFICACION', 'CALIDAD']})}
                planificacionesComponente = {planificacionesComponente}
                changePlanificacionesComponente = {changePlanificacionesComponente}
            />
        );
    }
}

export default ComponentePage;