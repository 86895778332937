import { ReactElement, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { RolOperarioEmail } from "../../../models/users/Users";

interface Props {
    emails: RolOperarioEmail[],
    submitForm: (changeIsBusy: (state: boolean) => void) => void,
    onCancel: () => void,
    changeEmails: (index: number, value: string) => void
}

const EmailsOperadoresFormComponent = ({ emails, submitForm, onCancel, changeEmails }: Props): ReactElement => {

    const [ isBusy, setIsBusy ] = useState<boolean>(false);

    return (
        <Form>
            {emails.map((email, index) => {
                return (
                    <Row>
                        <div className = "form-group">
                            {email.rol}
                            <input
                                className = "form-control"
                                defaultValue = {email.email}
                                onChange = {(value) => changeEmails(index, value.target.value)}
                                disabled = {isBusy}
                                placeholder = "ejemplo@electrans.cl"
                            />
                        </div>
                    </Row>
                );
            })}
            <div className = "modal-buttons">
                <Button onClick = {() => submitForm((state: boolean) => setIsBusy(state))} variant = "success" disabled = {isBusy}>
                    {!isBusy ? 'Guardar' : <Spinner animation = "border" size = "sm"/>}
                </Button>
                <Button onClick = {onCancel} variant = "secondary" disabled = {isBusy}>
                    {!isBusy ? 'Cancelar' : <Spinner animation = "border" size = "sm"/>}
                </Button>
            </div>
        </Form>
    );
};

export default EmailsOperadoresFormComponent;