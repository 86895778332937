import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Spinner, Table } from "react-bootstrap";
import { getCorreosEmisorOperarios, putCorreosEmisorOperarios } from "../../../api/UsersApi";
import { RolOperarioEmail } from "../../../models/users/Users";
import { operariosRoles } from "../../../utils/users";
import EmailsOperadoresFormComponent from "./EmailsOperadoresFormComponent";

const EmailsOperadores = (): ReactElement => {

    const [ emails, setEmails ] = useState<RolOperarioEmail[]>(operariosRoles.map((rol) => ({ rol: rol, email: '' })));
    const [ showEdit, setShowEdit ] = useState(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const handleCloseEdit = () => setShowEdit(false);

    const changeEmails = (index: number, value: string) => {
        const newValue = emails;
        newValue[index].email = value;
        setEmails(newValue);
    }

    const updateEmails = (update: RolOperarioEmail[]) => {
        setEmails(emails.map((email) => ({
            rol: email.rol,
            email: update.find((responseEmail) => responseEmail.rol === email.rol)?.email || email.email
        })));
    }

    const submitForm = (changeIsBusy: (state: boolean) => void) => {
        changeIsBusy(true);
        putCorreosEmisorOperarios(emails)
            .then((response) => {
                if (response.data) {
                    updateEmails(response.data);
                    handleCloseEdit();
                } else {
                    cogoToast.error('No fue posible actualizar los correos de operadores');
                }
                changeIsBusy(false);
            }).catch(() => {
                cogoToast.error('No fue posible actualizar los correos de operadores');
                changeIsBusy(false);
            });
    }

    useEffect(() => {
        getCorreosEmisorOperarios()
            .then((response) => {
                if (response.data) {
                    updateEmails(response.data);
                } else {
                    cogoToast.error('No fue posible obtener los correos de operadores');
                }
                setIsLoading(false);
            }).catch(() => {
                cogoToast.error('No fue posible obtener los correos de operadores');
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        Correos generales para operarios
                    </div>
                    <div className = "buttons-group">
                        <Button onClick = {() => setShowEdit(true)} disabled = {isLoading}>
                            {!isLoading ? 'Editar' : <Spinner animation = "border" size = "sm"/>}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className = "cards-body-groups">
                    {isLoading ? <div className = "text-center">Cargando correos ...<Spinner animation = "border" size = "sm"/></div> :
                        <Table bordered size = 'sm'>
                            <thead>
                                <tr>
                                    <th>Rol</th>
                                    <th><div className = "centerTextCell">Email</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {emails.map((email) => {
                                    return (
                                        <tr>
                                            <td>{email.rol}</td>
                                            <td><div className = "centerTextCell">{email.email}</div></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    }
                </Card.Body>
            </Card>
            {showEdit && emails &&
                <Modal show = {showEdit} onHide = {handleCloseEdit}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar correos</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <EmailsOperadoresFormComponent
                            emails = {emails}
                            changeEmails = {changeEmails}
                            submitForm = {submitForm}
                            onCancel = {handleCloseEdit}
                        />
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default EmailsOperadores;