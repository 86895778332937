import { UnidadEstrategicaNegocio } from "../../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../../models/optionsFilters/Select";


const useUENLaboratorio = (): SelectOption<UnidadEstrategicaNegocio>[] => {

    return [
        { label: 'TARJETAS', value: { uen: 'TARJETAS' } },
        { label: '24V', value: { uen: '24V' } },
        { label: 'GABINETE CONTROL', value: { uen: 'GABINETE CONTROL' } }
    ];
    
}

export default useUENLaboratorio;