import { ReactElement } from "react";
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import DocumentsManager from "./DocumentsManager";
import { deleteDocumentoReparacion, getDocumentoReparacion, postDocumentoReparacion } from "../../api/DocumentosApi";

interface Props {
    codigoOP: string,
    userPermission: boolean,
    isComponenteQR?: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    documentosListado?: ArchivoNombre[]
}

const DocumentosReparacion = ({ codigoOP, userPermission, isComponenteQR, isBusy, changeBusyState, documentosListado }: Props): ReactElement => {
    const documentsListName = 'Documentos de reparación';
    return (
        <DocumentsManager
            isMultiple
            documentosListado = {documentosListado}
            isNotEditable = {isComponenteQR}
            userPermission = {userPermission}
            title = {!isComponenteQR ? documentsListName : undefined}
            subtitle = {isComponenteQR ? documentsListName : undefined}
            externalIsBusy = {isBusy}
            changeExternalBusyState = {changeBusyState}
            getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getDocumentoReparacion({ _id: _id }, onDownloadProgressFunction)}
            postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postDocumentoReparacion({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
            deleteDocumento = {(_id: string) => deleteDocumentoReparacion({ _id: _id })}
            // getDocumentosListado = {() => getDocumentosReparacionListado(codigoOP)}
        />
    )
}

export default DocumentosReparacion;