import cogoToast from "cogo-toast";
import { ReactElement } from "react";
import { deleteDestinatarioAperturaOP, getDestinatariosAperturaOP, postDestinatariosAperturaOP } from "../../api/UsersApi";
import { Destinatario } from "../../models/Email";
import { Persona } from "../../models/users/Users";
import DestinatariosCorreo from "../users/DestinatariosCorreo";

interface Props {
    changeAddresses: (addresses: Persona[]) => void,
    changeCopiedAddresses: (addresses: Persona[]) => void,
    addresses?: Persona[],
    copiedAddresses?: Persona[],
    userPermission: boolean
}

const DestinatariosCorreoAperturaOP = ({ userPermission, addresses, copiedAddresses, changeAddresses, changeCopiedAddresses }: Props): ReactElement => (
    <DestinatariosCorreo
        addresses = {addresses}
        userPermission = {userPermission}
        copiedAddresses = {copiedAddresses}
        postDestinatarios = {(personas: Destinatario[], hideModal: () => void, setStateToFalse: () => void) =>
            postDestinatariosAperturaOP(personas)
                .then((response) => {
                    changeAddresses(response.data.to);
                    changeCopiedAddresses(response.data.cc);
                    cogoToast.success('Destinatarios añadidos');
                    setStateToFalse();
                    hideModal();
                })
                .catch(() => {
                    cogoToast.error('No fue posible añadir la dirección de correo');
                    setStateToFalse();
                })
        }
        deleteDestinatario = {(personaEmail: string, hideModal: () => void, setStateToFalse: () => void) =>
            deleteDestinatarioAperturaOP(personaEmail)
                .then((response) => {
                    changeAddresses(response.data.to);
                    changeCopiedAddresses(response.data.cc);
                    cogoToast.success('Persona removida');
                    setStateToFalse();
                    hideModal();
                }).catch(() => {
                    cogoToast.error('No fue posible remover a la persona');
                    setStateToFalse();
                })
        }
        getDestinatarios = {(changeIsLoadingToFalse: () => void, changeLoadFailedToTrue: () => void) =>
            getDestinatariosAperturaOP()
                .then((response) => {
                    changeCopiedAddresses(response.data.cc);
                    changeAddresses(response.data.to);
                    changeIsLoadingToFalse();
                })
                .catch(() => {
                    cogoToast.error('No fue posible obtener las direcciones de correo');
                    changeIsLoadingToFalse();
                    changeLoadFailedToTrue();
                })
        }
    />
);

export default DestinatariosCorreoAperturaOP;