import cogoToast from "cogo-toast";
import { ReactElement } from "react";
import { Card } from "react-bootstrap";
import { getOrdenesProcesoExcel } from "../../api/OrdenesProcesoApi";
// import { OrdenProcesoTabla } from "../../models/estatusGeneral/OrdenProceso";
import { jsonToExcel } from "../../utils/archivos";
import DownloadProgress from "./DownloadProgress";

interface EstatusGeneralRespaldoProps {
    userPermission: boolean,
    isSomethingDownloading: boolean,
    changeIsSomethingDownloading: (state: boolean) => void
}

const EstatusGeneralRespaldo = ({ userPermission, isSomethingDownloading, changeIsSomethingDownloading }: EstatusGeneralRespaldoProps): ReactElement => {
    
    // const [ downloadedObject, setDownloadedObject ] = useState<OrdenProcesoTabla[]>();
    // const [ isAlreadyDownloaded, setIsAlreadyDownloaded ] = useState<boolean>(false);

    const onDownloadEstatusExcel = (
        // changeDownloadProgress: (progress: number) => void,
        stopDownloadingStatus: () => void) => {
        changeIsSomethingDownloading(true);
        getOrdenesProcesoExcel(
            // getDownloadProgress(changeDownloadProgress)
            ).then(
            (response) => {
                if (response.data.ordenesProceso) {
                    jsonToExcel(response.data.ordenesProceso);
                    stopDownloadingStatus();
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible obtener las órdenes de proceso');
                    stopDownloadingStatus();
                }
                changeIsSomethingDownloading(false);
                // isNotDownloading();
            }
        ).catch(
            () => {
                // cogoToast.error(error.message);
                cogoToast.error('No fue posible obtener las órdenes de proceso');
                stopDownloadingStatus();
                changeIsSomethingDownloading(false);
                // isNotDownloading();
            }
        )
    }

    // const getDownload = () => {
    //     if (!isAlreadyDownloaded && downloadedObject) {
    //         setIsAlreadyDownloaded(true);
    //         console.log('call download')
    //         jsonToExcel(downloadedObject);
    //     }
    // }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        Estatus General
                    </div>
                </Card.Header>
                <DownloadProgress
                    text = 'Formato Excel'
                    userPermission = {userPermission}
                    onDownload = {onDownloadEstatusExcel}
                    // getDownload = {getDownload}
                    isSomethingDownloading = {isSomethingDownloading}
                />
            </Card>
        </>
    );
}

export default EstatusGeneralRespaldo;