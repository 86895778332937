import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { postRecepcion, deleteRecepcion } from "../../api/RecepcionesApi";
import useFormState from "../../hooks/useFormState";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { RecepcionForm } from "../../models/recepciones/Recepcion";
import { getEmptyRecepcionForm } from "../../utils/recepciones";
import RecepcionFormComponent from "./RecepcionFormComponent";
import { useHistory } from "react-router-dom";
import ConfirmDeletionModal from "../form/ConfirmDeletionModal";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";

interface RecepcionOPProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    // _id?: string,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const RecepcionOP = ({ ordenProceso, onOrdenProcesoChange, userPermissionFunction }: RecepcionOPProps): ReactElement => {

    const editPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR'] });
    const viewPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER', 'CALIDAD'] });

    const formState = useFormState(getEmptyRecepcionForm());
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleShowAddRecepcion = () => setShow(true);
    const handleCloseAddRecepcion = () => {
        formState.reset();
        setShow(false);
    };

    const submitForm = (recepcion: RecepcionForm) => {
        postRecepcion(recepcion, ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.recepcion) {
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    formState.setSubmitting(false);
                    cogoToast.success('Recepción de componente añadida');
                    history.replace(`/ordenesProceso/${response.data.codigoOP}/recepcion`);
                } else {
                    formState.setSubmitting(false);
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible añadir la recepción');
                }
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible añadir la recepción');
            })
    };

    const showRecepcion = () => {
        history.replace(`/ordenesProceso/${ordenProceso.codigoOP}/recepcion`);
    }

    const deleteCurrentRecepcion = (setIsDeleting: () => void) => {
        deleteRecepcion(ordenProceso.codigoOP)
            .then((response) => {
                onOrdenProcesoChange(response.data);
                cogoToast.success('Recepción eliminada');
                setIsDeleting();
            })
            .catch(() => {
                // cogoToast.error(error.message);
                setIsDeleting();
            })
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        {ordenProceso.recepcion ? 'Recepción' : 'Sin recepción'}
                    </div>
                    {ordenProceso.recepcion ?
                        <div className = "buttons-group">
                            {editPermission && !ordenProceso.evaluacion && (ordenProceso.estado === 'RECEPCIONADA' || ordenProceso.estado === 'NUEVA') && (
                                <ConfirmDeletionModal
                                    text = 'Los documentos asociados a la recepción se eliminarán excepto si otra orden de proceso tiene el mismo número de guía de despacho'
                                    onDelete = {deleteCurrentRecepcion}
                                />
                            )}
                            {viewPermission &&
                                <Button onClick = {showRecepcion} variant = "success">
                                    Detalles
                                </Button>
                            }
                        </div>
                    :
                        editPermission &&
                            <div className = "buttons-group">
                                <Button onClick = {handleShowAddRecepcion} className = "body-buttons">
                                    Agregar Recepción
                                </Button>
                            </div>
                    }
                </Card.Header>
                {ordenProceso.recepcion &&
                    (<Card.Body>
                        <Row>
                            <Col>
                                <u>Número guía de despacho</u>
                                <h3>{ordenProceso.recepcion.numeroGuiaDespacho}</h3>
                            </Col>
                            <Col>
                                <u>Fecha de Recepción</u>
                                <h3>{ordenProceso.recepcion.fechaRecepcion}</h3>
                            </Col>
                        </Row>
                    </Card.Body>)
                }
            </Card>
            {show && ordenProceso.componente &&
                (<Modal show = {show} onHide = {handleCloseAddRecepcion}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Recepción de Componente</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {show &&
                            (<RecepcionFormComponent
                                formState = {formState}
                                submitForm = {submitForm}
                                onCancel = {handleCloseAddRecepcion}
                                modeloComponente = {ordenProceso.componente.modelo}
                            />)
                        }
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default RecepcionOP;