import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { putOrdenProceso } from "../../api/OrdenesProcesoApi";
import useFormState from "../../hooks/useFormState";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { formatOrdenProceso } from "../../utils/estatusGeneral";
import ObservacionesOPFormComponent from "./ObservacionesOPFormComponent";

interface ObservacionesOPComponentProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    userPermission: boolean
}

const ObservacionesOPComponent = ({ ordenProceso, onOrdenProcesoChange, userPermission }: ObservacionesOPComponentProps): ReactElement => {

    const formState = useFormState({ observaciones: ordenProceso.observaciones });

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };

    const submitForm = (obs: { observaciones: string }) => {
        putOrdenProceso(formatOrdenProceso(ordenProceso, undefined, undefined, obs.observaciones))
            .then((response) => {
                if (response.data.codigoOP) {
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    // formState.setForm({ observaciones: response.data.observaciones });
                    cogoToast.success('Orden de proceso editada');
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible editar la observación');
                }
                formState.setSubmitting(false);
            })
            .catch(() => {
                // cogoToast.error(error.message);
                cogoToast.error('No fue posible editar la observación');
                formState.setSubmitting(false);
                formState.reset();
            })
    }

    return (
        <>
            <Card className = 'cards-container'>
                <Card.Header className = 'cards-header'>
                    <div className = "body-subtitle">
                        Observaciones
                    </div>
                    {userPermission &&
                        <div className = "buttons-group">
                            <Button onClick = {handleShow}>Editar</Button>
                        </div>
                    }
                </Card.Header>
                <Card.Body>
                    <div className="body-obs"><h5>{ordenProceso.observaciones}</h5></div>
                </Card.Body>
            </Card>
            {show && (
                <Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Observaciones</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <ObservacionesOPFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default ObservacionesOPComponent;