import { ReactElement, useContext, useEffect, useState } from "react";
import useBreadcrumb from "../hooks/useBreadcrumb";
import AuthContext from "../contexts/AuthContext";
import EstatusGeneralRespaldo from "../components/respaldo/EstatusGeneralRespaldo";
import { getUserPermissionFunction } from "../utils/users";

const BackupPage = (): ReactElement => {

    const [ isSomethingDownloading, setIsSomethingDownloading ] = useState<boolean>(false);
    
    const breadcrumb = useBreadcrumb();
    const { userRoles } = useContext(AuthContext);

    const changeIsSomethingDownloading = (state: boolean) => {
        setIsSomethingDownloading(state);
    }
    
    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Respaldo',
                inactive: true
            }
        ]);
    }, [breadcrumb]);

    /*
    useEffect(() => {
        if (!imagenesSize) {
            getImagenesRegistrosRecepcionSize().then((response) => {
                setImagenesSize(response.data);
            }).catch(() => {
                cogoToast.error("No fue posible obtener el tamaño de almacenamiento de las imágenes de recepciones");
            })
        }
        if (!guiasSize) {
            getGuiasDespachoRecepcionSize().then((response) => {
                setGuiasSize(response.data);
            }).catch(() => {
                cogoToast.error("No fue posible obtener el tamaño de almacenamiento de las guías de recepción");
            })
        }
    }, [imagenesSize, guiasSize, logout]);

    const onDownloadAll = async (): Promise<void> => {
        getRecepciones().then(async (responseRecepciones) => {
            const zip = new jszip();
            const guiasDespacho = (await getGuiasDespachoRecepcion().then((response) => {
                return response.data
            }).catch(() => {
                cogoToast.error("No se pudo obtener la base de datos de recepciones");
            }));
            await Promise.all(responseRecepciones.data.map(async (recepcion) => {
                const clienteYear = recepcion.fechaRecepcion.substr(recepcion.fechaRecepcion.lastIndexOf("/")+1);
                await Promise.all(recepcion.ordenesProceso.map(async (ordenProceso) => {
                    if (guiasDespacho) {
                        guiasDespacho.map((guiaDespacho) => {
                            if (guiaDespacho.numeroGuiaDespacho === recepcion.numeroGuiaDespacho) {
                                if (guiaDespacho.archivo.base64) {
                                    zip.file(
                                        `status-gral/clientes ${clienteYear}/${recepcion.cliente}/${ordenProceso.ordenProceso}/${guiaDespacho.archivo.nombre}`,
                                        guiaDespacho.archivo.base64.substr(guiaDespacho.archivo.base64.indexOf(",")),
                                        {
                                            base64: true,
                                            createFolders: true
                                        }
                                    );
                                }
                            }
                            return null;
                        });
                    }
                    await (getImagenesRegistroRecepcion(ordenProceso.ordenProceso)
                        .then((responseImagenes) => {
                            if (responseImagenes.data.length > 0) {
                                responseImagenes.data.map((archivo) => {
                                    if (archivo.base64) {
                                        zip.file(
                                            `status-gral/clientes ${clienteYear}/${recepcion.cliente}/${ordenProceso.ordenProceso}/${archivo.nombre}`,
                                            archivo.base64.substr(archivo.base64.indexOf(",")),
                                            {
                                                base64: true,
                                                createFolders: true
                                            }
                                        );
                                    }
                                    return null;
                                });
                            } 
                        })
                        .catch(() => {
                            cogoToast.error("No fue posible obtener la base de datos de las imágenes de recepción");
                        }));
                }));
            }));
            if (Object.keys(zip.files).length > 0) {
                await (zip.generateAsync({ type: "blob" })
                .then(content => {
                    saveAs(content, "Electrans-ERP_backup_archivosRecepciones.zip");
                }));
            } else {
                cogoToast.error("No hay archivos para descargar");
            }
        }).catch(() => {
            cogoToast.error("No fue posible descargar la base de datos");
        });
    }

    const onDownloadGuias = async (): Promise<void> => {
        getRecepciones().then(async (responseRecepciones) => {
            const zip = new jszip();
            await Promise.all(responseRecepciones.data.map(async (recepcion) => {
                if (recepcion.numeroGuiaDespacho) {
                    const clienteYear = recepcion.fechaRecepcion.substr(recepcion.fechaRecepcion.lastIndexOf("/")+1);
                    await Promise.all(recepcion.ordenesProceso.map(async (ordenProceso) => {
                        await (getGuiaDespachoRecepcion(recepcion.numeroGuiaDespacho)
                            .then((responseGuia) => {
                                if (responseGuia.data && responseGuia.data.base64) {
                                    zip.file(
                                    `status-gral/clientes ${clienteYear}/${recepcion.cliente}/${ordenProceso.ordenProceso}/${responseGuia.data.nombre}`,
                                    responseGuia.data.base64.substr(responseGuia.data.base64.indexOf(",")),
                                    { base64: true, createFolders: true }
                                    );
                                }
                            }).catch(() => {
                                cogoToast.error("No fue posible obtener la guía de despacho");
                            }));
                    }));
                }
            }));
            if (Object.keys(zip.files).length > 0) {
                await (zip.generateAsync({ type: "blob" })
                .then(content => {
                    saveAs(content, "Electrans-ERP_backup_guiasDespacho.zip");
                }));
            } else {
                cogoToast.error("No hay guías de despacho para descargar");
            }
        }).catch(() => {
            cogoToast.error("No fue posible obtener las recepciones");
        });
    }

    const onDownloadImagenes = async (): Promise<void> => {
        getRecepciones().then(async (responseRecepciones) => {
            const zip = new jszip();
            await Promise.all(responseRecepciones.data.map(async (recepcion) => {
                const clienteYear = recepcion.fechaRecepcion.substr(recepcion.fechaRecepcion.lastIndexOf("/")+1);
                await Promise.all(recepcion.ordenesProceso.map(async (ordenProceso) => {
                    await (getImagenesRegistroRecepcion(ordenProceso.ordenProceso)
                        .then((responseImagenes) => {
                            if (typeof responseImagenes.data !== 'boolean') {
                                if (responseImagenes.data.length > 0) {
                                    responseImagenes.data.map((archivo) => {
                                        if (archivo.base64) {
                                            zip.file(
                                                `status-gral/clientes ${clienteYear}/${recepcion.cliente}/${ordenProceso.ordenProceso}/${archivo.nombre}`,
                                                archivo.base64.substr(archivo.base64.indexOf(",")),
                                                { base64: true, createFolders: true }
                                            );
                                        }
                                        return null;
                                    });
                                }
                            } else {
                                logout();
                            }
                        }))
                })); 
            }));
            if (Object.keys(zip.files).length > 0) {
                await (zip.generateAsync({ type: "blob" })
                .then(content => {
                    saveAs(content, "Electrans-ERP_backup_imagenesRecepcion.zip");
                }));
            } else {
                cogoToast.error("No hay archivos para descargar");
            }
        }).catch(() => {
            cogoToast.error("No fue posible obtener las recepciones");
        });
    }

    const onDeleteAll = async (): Promise<void> => {
        await (deleteImagenesRegistrosRecepcion().then(() => {
            cogoToast.success("Imágenes de recepciones eliminadas");
        }).catch(() => {
            cogoToast.error("No fue posible eliminar las imágenes de recepciones");
        }));
        await (deleteGuiasDespachoRecepcion().then(() => {
            cogoToast.success("Guías de despacho de recepciones eliminadas");
        }).catch(() => {
            cogoToast.error("No fue posible eliminar las guías de despacho de recepciones");
        }));
        getImagenesRegistrosRecepcionSize().then((response) => {
            setImagenesSize(response.data);
        }).catch(() => {
            cogoToast.error("No fue posible obtener el tamaño de almacenamiento de las imágenes de recepciones");
        });
        getGuiasDespachoRecepcionSize().then((response) => {
            setGuiasSize(response.data);
        }).catch(() => {
            cogoToast.error("No fue posible obtener el tamaño de almacenamiento de las guías de recepción");
        });
    }

    const onDeleteGuias = async (): Promise<void> => {
        await (deleteGuiasDespachoRecepcion().then(() => {
            cogoToast.success("Guías de despacho de recepciones eliminadas");
        }).catch(() => {
            cogoToast.error("No fue posible eliminar las guías de despacho de recepciones");
        }));
        getGuiasDespachoRecepcionSize().then((response) => {
            setGuiasSize(response.data);
        }).catch(() => {
            cogoToast.error("No fue posible obtener el tamaño de almacenamiento de las guías de recepción");
        });
    }

    const onDeleteImagenes = async (): Promise<void> => {
        await (deleteImagenesRegistrosRecepcion().then(() => {
            cogoToast.success("Imágenes de recepciones eliminadas");
        }).catch(() => {
            cogoToast.error("No fue posible eliminar las imágenes de recepciones");
        }));
        getImagenesRegistrosRecepcionSize().then((response) => {
            setImagenesSize(response.data);
        }).catch(() => {
            cogoToast.error("No fue posible obtener el tamaño de almacenamiento de las imágenes de recepciones");
        });
    }

    const getTotalSize = (guias?: string, imagenes?: string): string | undefined => {
        let total;
        if (guias && imagenes) {
            total = parseFloat(guias)+parseFloat(imagenes);
            //total = (Math.round((total * 100) / 100).toFixed(3));
            total = total.toFixed(2).toString();
        } else if (guias) {
            total = parseFloat(guias);
            total = total.toFixed(2).toString();
        } else if (imagenes) {
            total = parseFloat(imagenes);
            total = total.toFixed(2).toString();
        } else {
            total = '0';
        }
        return total;
    }
    */

    return (
        <>
            <EstatusGeneralRespaldo
                userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'PLANIFICACION'] })}
                isSomethingDownloading = {isSomethingDownloading}
                changeIsSomethingDownloading = {changeIsSomethingDownloading}
            />
            {/* <Card className = "cards-container">
                <Card.Header className = "cards-body">
                    <div className = "body-title">
                        Archivos recepciones de componentes
                    </div>
                </Card.Header>
                <Card.Body className = "cards-body">
                    <div className = "body-subtitle">Todos los archivos: {getTotalSize(guiasSize, imagenesSize)} MBs</div>
                    <div>
                        <Button onClick = {onDeleteAll} variant = "danger" disabled = {!rolPermission()} className = "body-buttons">
                            Eliminar
                        </Button>
                        <Button onClick = {onDownloadAll} disabled = {!rolPermission()} className = "body-buttons">
                            Descargar
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className = "cards-body">
                    <div className = "body-subtitle">Guías de despacho: {guiasSize} MBs</div>
                    <div>
                        <Button onClick = {onDeleteGuias} variant = "danger" disabled = {!rolPermission()} className = "body-buttons">
                            Eliminar
                        </Button>
                        <Button onClick = {onDownloadGuias} disabled = {!rolPermission()} className = "body-buttons">
                            Descargar
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className = "cards-body">
                    <div className = "body-subtitle">Imágenes de registros: {imagenesSize} MBs</div>
                    <div>
                        <Button onClick = {onDeleteImagenes} variant = "danger" disabled = {!rolPermission()} className = "body-buttons">
                            Eliminar
                        </Button>
                        <Button onClick = {onDownloadImagenes} disabled = {!rolPermission()} className = "body-buttons">
                            Descargar
                        </Button>
                    </div>
                </Card.Body>
            </Card> */}
        </>
    );
}

export default BackupPage;