import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getComponentesReparados } from "../../api/ComponentesApi";
import { ComponenteReparadoTabla, ComponentesReparadosFilters } from "../../models/componentes/Componente";
import Table, { TableColumn } from "../form/table/Table";

interface ComponentesReparadosListProps {
    componentesReparadosFilters: ComponentesReparadosFilters,
    changeComponentesReparadosFilters: (componentesReparadosFilters: ComponentesReparadosFilters) => void
}

const ComponentesReparadosList = ({
    componentesReparadosFilters, changeComponentesReparadosFilters
}: ComponentesReparadosListProps): ReactElement => {

    const [ componentesReparados, setComponentesReparados ] = useState<ComponenteReparadoTabla[]>();
    const history = useHistory();
    const [ totalPages, setTotalPages ] = useState<number>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect(() => {
        getComponentesReparados(componentesReparadosFilters)
            .then((response) => {
                if (response.data.componentes) {
                    setComponentesReparados(response.data.componentes);
                    setTotalPages(response.data.totalPages);
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible cargar los componentes reparados');
                }
                setIsLoading(false);
            })
            .catch(() => {
                cogoToast.error('No fue posible cargar los componentes reparados');
                setIsLoading(false);
            })
    }, [componentesReparadosFilters]);

    const handleComponenteReparadoClick = (componenteReparadoTabla: ComponenteReparadoTabla): void => {
        history.push(`/componentesQR/${componenteReparadoTabla._id}`);
    };

    const tableColumns: TableColumn<ComponenteReparadoTabla>[] = [
        {
            title: 'OP',
            accessor: 'codigoOP'
        },
        {
            title: 'Descripción',
            accessor: 'descripcionComponente'
        },
        {
            title: 'N/S',
            accessor: 'numeroSerieComponente',
        },
        {
            title: 'Modelo',
            accessor: 'modeloComponente',
            hideUntil: 'sm'
        },
        {
            title: 'Garantía(Meses)',
            accessor: 'periodoGarantia',
            hideUntil: 'md'
        },
        {
            title: 'Peso (Kg)',
            accessor: 'pesoComponente',
            hideUntil: 'md'
        },
        {
            title: 'Código Material',
            accessor: 'codigoMaterial',
            hideUntil: 'md'
        },
        {
            title: 'Orden Trabajo Cliente',
            accessor: 'ordenTrabajoCliente',
            hideUntil: 'md'
        },
        {
            title: 'Fecha Reparación',
            accessor: 'fechaReparacion'
        }
    ];

    if (!componentesReparados) {
        if (isLoading) {
            return (
                <div className = "text-center">
                    <Spinner animation = "border"/>
                </div>
            );
        } else {
            return <p className = "text-center">No fue posible cargar los componentes</p>;
        }
    } else {
        return (
            <>
                <Table
                    hover
                    items = {componentesReparados}
                    columns = {tableColumns}
                    onItemClick = {handleComponenteReparadoClick}
                    pages = {totalPages}
                    activePage = {componentesReparadosFilters.page}
                    onPageSelect = {(page) => changeComponentesReparadosFilters({ ...componentesReparadosFilters, page: page})}
                />
            </>
        )
    }
}

export default ComponentesReparadosList;