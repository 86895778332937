import { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import { EvaluacionOrReparacionComponenteEtapa, InsumosList } from "../../models/EvaluacionesReparaciones";
import EvaluacionReparacionComponenteEditInsumosList from "./EvaluacionReparacionComponenteEditInsumosList";

interface Props {
    insumosList: InsumosList,
    nombreTarea: string,
    isBusy: boolean,
    updateEvaluacionReparacionComponenteEtapaTarea: (hideModal: () => void) => void,
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    showAmounts?: boolean
}

const EvaluacionReparacionComponenteInsumosList = ({
    insumosList, nombreTarea, isBusy, updateEvaluacionReparacionComponenteEtapaTarea, isNotEditable, changeEvaluacionReparacionComponenteEtapa, evaluacionReparacionComponenteEtapa, showAmounts
}: Props): ReactElement => 
    <Card>
        <Card.Header className = "cards-header">
            <div className = "body-subtitle">
                {insumosList.name}
            </div>
            <EvaluacionReparacionComponenteEditInsumosList
                nombreTarea = {nombreTarea}
                insumoList = {insumosList}
                isBusy = {isBusy}
                isNotEditable = {isNotEditable}
                updateEvaluacionReparacionComponenteEtapaTarea = {updateEvaluacionReparacionComponenteEtapaTarea}
                changeEvaluacionReparacionComponenteEtapa = {changeEvaluacionReparacionComponenteEtapa}
                evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
            />
        </Card.Header>
        <Card.Body>
            <Table striped bordered size = 'sm' className = "zebra">
                <tr>
                    <th>Insumo</th>
                    <th className = "centerTextCell">N/P Electrans</th>
                    <th className = "centerTextCell">Cantidad</th>
                    {/* <th><div className = "centerTextCell">N/P Electrans</div></th>
                    <th><div className = "centerTextCell">Cantidad</div></th> */}
                </tr>
                {insumosList.insumos.map((insumo) => (
                    <tr>
                        <td>{insumo.name}</td>
                        <td className = "centerTextCell">
                            {insumo.electransPartNumber || ''}
                        </td>
                        <td className = "centerTextCell">
                            {showAmounts && (`${insumo.amountRequested || ''}${insumo.amountRequested && insumo.unitName ? ` (${insumo.unitName})` : ''}`)}
                        </td>
                        {/* <td>
                            <div className = "centerTextCell">
                                {insumo.electransPartNumber || ''}
                            </div>
                        </td>
                        <td>
                            <div className = "centerTextCell">
                                {showAmounts && (`${insumo.amountRequested || ''}${insumo.amountRequested && insumo.unitName ? ` (${insumo.unitName})` : ''}`)}
                            </div>
                        </td> */}
                    </tr>
                ))}
            </Table>
        </Card.Body>
    </Card>

export default EvaluacionReparacionComponenteInsumosList;