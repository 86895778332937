import { EvaluacionOrReparacionElements, ModeloComponenteEvaluacionOrReparacionElements } from "../../models/EvaluacionesReparaciones";
import blowersElementsList from "./elementsLists/blowersElementsList";
import motoresTraccionElementsList from "./elementsLists/motoresTraccionElementsList";

export const getComponenteEvaluacionOrReparacionElement = (
    tipoComponente: string, modeloComponente: string, subModeloComponente?: string
): EvaluacionOrReparacionElements | void => ((list?: ModeloComponenteEvaluacionOrReparacionElements[]): EvaluacionOrReparacionElements | void => {
    if (list) {
        for (let i = 0; i < list.length; i++) {
            if (modeloComponente === list[i].modeloComponente) {
                const subModelosComponenteElements = list[i].subModelosComponenteElements;
                let response = list[i] as EvaluacionOrReparacionElements;
                if (subModelosComponenteElements && subModelosComponenteElements.length > 0 && subModeloComponente) {
                    for (let e = 0; e < subModelosComponenteElements.length; e++) {
                        if (subModelosComponenteElements[e].subModelosComponente.includes(subModeloComponente)) {
                            response = subModelosComponenteElements[e] as EvaluacionOrReparacionElements;
                            break;
                        }
                    }
                }
                return response;
            }
        }
    }
})(((): ModeloComponenteEvaluacionOrReparacionElements[] | undefined => {
    if (tipoComponente === 'BLOWER') {
        return blowersElementsList;
    } else if (tipoComponente === 'MOTOR DE TRACCION') {
        return motoresTraccionElementsList;
    }
})())