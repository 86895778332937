import { ReactElement, useContext } from "react";
import { useHistory } from "react-router-dom";
import LayoutContext from "../../../contexts/LayoutContext";
import MenuBarItemModel from "../../../models/layout/MenuBarItemModel";

interface MenuBarItemProps {
    menuBarItemModel: MenuBarItemModel;
}

const MenuBarItem = ({ menuBarItemModel }: MenuBarItemProps): ReactElement => {
    const { href, name } = menuBarItemModel;
    const { toggleMenuBar } = useContext(LayoutContext);
    const history = useHistory();
    //const changeMenuBarState = (): void => toggleMenuBar();
    const onClickButton = (): void => {
        toggleMenuBar();
        history.push(href);
    };

    return (
        <div className = "menuButton" onClick = {onClickButton} >
            {name}
        </div>
    );

    /*
    return (
        <li>
            <Link to = {href} onClick = {changeMenuBarState}>
                {name}
            </Link>
        </li>
    )
    */
}

export default MenuBarItem;