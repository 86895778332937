import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getComponentes } from "../../api/ComponentesApi";
import { ComponenteTabla, ComponentesFilters } from "../../models/componentes/Componente";
import cogoToast from 'cogo-toast';
import Table, { TableColumn } from "../form/table/Table";
import { Card, Spinner } from "react-bootstrap";
import { fixUrlSpecialCharacters } from "../../utils/urls";

interface ComponentesListProps {
    componentesFilters: ComponentesFilters,
    setComponentesFilters: Dispatch<SetStateAction<ComponentesFilters>>,
    addComponenteToOrdenProceso?: (componente: ComponenteTabla) => void
}

const ComponentesList = ({ componentesFilters, addComponenteToOrdenProceso, setComponentesFilters }: ComponentesListProps): ReactElement => {
    const [componentes, setComponentes] = useState<ComponenteTabla[]>();
    const history = useHistory();
    const [ totalPages, setTotalPages ] = useState<number>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect(() => {
        getComponentes(componentesFilters)
            .then((response) => {
                if (response.data.componentes) {
                    setComponentes(response.data.componentes);
                    setTotalPages(response.data.totalPages);
                } else {
                    cogoToast.error('No fue posible obtener los componentes');
                }
                setIsLoading(false);
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener los componentes');
                setIsLoading(false);
            });
    }, [componentesFilters]);

    const handleComponenteClick = (componente: ComponenteTabla): void => {
        if (addComponenteToOrdenProceso) {
            addComponenteToOrdenProceso(componente);
        } else {
            history.push(`/componentes/${fixUrlSpecialCharacters(componente.modelo)}/descripcion/${fixUrlSpecialCharacters(componente.descripcion)}`);
        }
    };

    const tableColumns: TableColumn<ComponenteTabla>[] = [
        {
            title: 'Descripción',
            accessor: 'descripcion'
        },
        {
            title: 'Modelo',
            accessor: 'modelo',
            hideUntil: 'sm'
        },
        /*
        {
            title: 'Número de Parte',
            accessor: 'numeroParte'
        },
        */
        {
            title: 'Galpón',
            accessor: 'galpon'
        },
        {
            title: 'Tipo',
            accessor: 'tipoComponente',
            hideUntil: 'sm'
        },
    ];

    if (!componentes) {
        if (isLoading) {
            return (
                <Card className = "cards-container">
                    <Card.Body>
                        <div className = "text-center">
                            <Spinner animation = "border"/>
                        </div>
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">No fue posible cargar los componentes</p>;
        }
    } else {
        return (
            <>
                <Table
                    hover
                    items = {componentes}
                    columns = {tableColumns}
                    onItemClick = {handleComponenteClick}
                    pages = {totalPages}
                    activePage = {componentesFilters.page}
                    onPageSelect = {(page) => setComponentesFilters({ ...componentesFilters, page: page})}
                />
            </>
        )
    }
}

export default ComponentesList;