import axios, { AxiosPromise } from "axios";
import { Destinatario } from "../models/Email";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { Evaluacion, EvaluacionRequestBody } from "../models/Evaluaciones";
import { PersonasResponse } from "../models/users/Users";

export function postEvaluacion(requestBody: EvaluacionRequestBody, codigoOP: string): AxiosPromise<OrdenProceso> {
    return axios.post(`api/evaluaciones/ordenProceso/${codigoOP}`, requestBody);
}

export function putEvaluacionCodigoOP(requestBody: EvaluacionRequestBody, codigoOP: string): AxiosPromise<Evaluacion> {
    return axios.put(`api/evaluaciones/ordenProceso/${codigoOP}`, requestBody);
}

export function deleteEvaluacion(requestBody: string): AxiosPromise<OrdenProceso> {
    return axios.delete(`api/evaluaciones/ordenProceso/${requestBody}`);
}

export function getEvaluacion(codigoOP: string): AxiosPromise<Evaluacion> {
    return axios.get(`api/evaluaciones/ordenProceso/${codigoOP}`);
}

export function getDestinatariosReporteOPNS(): AxiosPromise<PersonasResponse> {
    return axios.get('api/evaluaciones/correosPersonas');
}

export function deleteDestinatarioReporteOPNS(requestBody: string): AxiosPromise<PersonasResponse> {
    return axios.delete(`api/evaluaciones/correosPersonas/${requestBody}`);
}

export function postDestinatarioReporteOPNS(requestBody: Destinatario[]): AxiosPromise<PersonasResponse> {
    return axios.post(`api/evaluaciones/correosPersonas`, { destinatarios: requestBody });
}