import { ReactElement } from "react";
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import DocumentsManager from "./DocumentsManager";
import { deleteCertificadoPersonal, getCertificadoPersonal, postCertificadoPersonal } from "../../api/DocumentosApi";

interface Props {
    codigoOP: string,
    userPermission: boolean,
    isRolCliente: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    certificadoPersonalDoc?: ArchivoNombre
}

const CertificadoPersonal = ({ codigoOP, userPermission, isRolCliente, isBusy, changeBusyState, certificadoPersonalDoc }: Props): ReactElement => (
    <DocumentsManager
        documentosListado = {certificadoPersonalDoc ? [certificadoPersonalDoc] : undefined}
        isNotEditable = {isRolCliente}
        userPermission = {userPermission}
        title = {'Certificados del personal'}
        externalIsBusy = {isBusy}
        changeExternalBusyState = {changeBusyState}
        getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getCertificadoPersonal({ _id: _id }, onDownloadProgressFunction)}
        postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postCertificadoPersonal({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
        deleteDocumento = {(_id: string) => deleteCertificadoPersonal({ _id: _id })}
        // getDocumentosListado = {() => getCertificadoPersonalListado(codigoOP)}
    />
)

export default CertificadoPersonal;