import React from 'react';

interface InputAppendProps {
  content: React.ReactNode;
  onClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  type: 'prepend' | 'append';
  disabled?: boolean;
}

const InputAppend = ({
  content,
  onClick,
  backgroundColor,
  textColor,
  type,
  disabled,
}: InputAppendProps): React.ReactElement => {
  let appendComponent;
  if (onClick) {
    let className = `btn btn-${backgroundColor || 'outline-secondary'} `;
    if (textColor) className += `text-${textColor} `;
    appendComponent = (
      <button type="button" className={className} disabled={disabled} onClick={onClick}>
        {content}
      </button>
    );
  } else {
    let className = `input-group-text bg-${backgroundColor} `;
    if (textColor) className += `text-${textColor} `;
    appendComponent = <span className={className}>{content}</span>;
  }

  return <div className={`input-group-${type}`}>{appendComponent}</div>;
};

export default InputAppend;
