import { ReactElement, useEffect, useState } from "react";
import ReactSelect from "react-select";
import { SelectOption } from "../../../models/optionsFilters/Select";

interface Props {
    title: string,
    options: SelectOption<string>[],
    onFieldChange: (value?: SelectOption<string>[]) => void,
    isDisabled?: boolean,
    defaultValues?: SelectOption<string>[],
    placeholder?: string
}

const SelectMultiple = ({ title, options, onFieldChange, isDisabled, defaultValues, placeholder }: Props): ReactElement => {

    const getSelection = (selected?: SelectOption<string>[]): SelectOption<string>[] => {
        if (selected) {
            let optionsHidingSelected: SelectOption<string>[] = [];
            options.forEach((option) => {
                let isSelected: boolean = false;
                selected.forEach((optionSelected) => {
                    if (optionSelected.label === option.label) {
                        isSelected = true;
                    }
                });
                if (!isSelected) {
                    optionsHidingSelected.push(option);
                }
            });
            return optionsHidingSelected;
        } else {
            return options;
        }
    }

    const [ selection, setSelection ] = useState<SelectOption<string>[]>(getSelection(defaultValues));
    const [ selected, setSelected ] = useState<SelectOption<string>[] | undefined>(defaultValues);

    useEffect(() => {
        onFieldChange(selected);
        setSelection(getSelection(selected));
    }, [selected]);

    return (
        <>
            <div className = "form-group">
                {title}
            </div>
            <ReactSelect
                defaultValue = {selected}
                options = {selection}
                onChange = {setSelected}
                isDisabled = {isDisabled}
                placeholder = {placeholder}
                isMulti
                // hideSelectedOptions
            />
        </>
    );
}

export default SelectMultiple;