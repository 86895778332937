import { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { BodyCardElement } from "../models/BodyCardElement";

export const bodyCardsFields = (fields: BodyCardElement[]): ReactElement[] => {
    const itemsPerRow = 3;
    const detallesLenght = fields.length;
    const colsToCompleteRow = detallesLenght % itemsPerRow;
    let rowsCounter = detallesLenght / itemsPerRow;
    if (rowsCounter === 0) {
        return [];
    } else if (rowsCounter <= 1) {
        rowsCounter = 1;
    } else {
        const integerRowsCounter = Math.trunc(rowsCounter);
        if ((rowsCounter / integerRowsCounter) !== 1) {
            rowsCounter = integerRowsCounter + 1;
        }
    }
    let rows = [];
    let cols: ReactElement[] = [];
    fields.forEach((field, index) => {
        cols.push(
            <Col>
                <u>{field.title}</u>
                <h3>{field.value}</h3>
            </Col>
        );
        if (index + 1 === detallesLenght && colsToCompleteRow > 0) {
            for (let i = 0; i < colsToCompleteRow; i++) {
                cols.push(<Col></Col>);
            }
        }
    })
    for (let i = 0, j = 0; i < rowsCounter; i++, j = j + itemsPerRow) {
        let row: ReactElement[] = [];
        for (let k = 0; k < itemsPerRow; k++) {
            row.push(cols[j + k]);
        }
        rows.push(<Row>{row}</Row>);
    }
    return rows;
}