import { Dispatch, SetStateAction, useState } from 'react';

export interface FormState<T> {
  value: T;
  handleFieldChange: (fieldName: string, value: unknown) => void;
  areValidationsEnabled: boolean;
  setAreValidationsEnabled: (areActive: boolean) => void;
  isDisabled: boolean;
  setDisabled: (isDisabled: boolean) => void;
  isSubmitting: boolean;
  setSubmitting: (isSubmitting: boolean) => void;
  isConfirmingDelete: boolean;
  setConfirmingDelete: (isConfirmingDelete: boolean) => void;
  reset: () => void;
  setForm: Dispatch<SetStateAction<T>>;
}

const useFormState = <T>(initialFormValue: T): FormState<T> => {
  const [formValue, setFormValue] = useState<T>(initialFormValue);

  const [areValidationsEnabled, setAreValidationsEnabled] = useState(false);

  const [isDisabled, setDisabled] = useState(false);

  const [isSubmitting, setSubmitting] = useState(false);

  const [isConfirmingDelete, setConfirmingDelete] = useState(false);

  const handleFieldChange = (fieldName: string, value: unknown): void => {
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      [fieldName]: value,
    }));
  };

  const reset = (): void => {
    setFormValue(initialFormValue);

    setAreValidationsEnabled(false);

    setSubmitting(false);

    setDisabled(false);

    setConfirmingDelete(false);
  };

  return {
    value: formValue,
    handleFieldChange,
    areValidationsEnabled,
    setAreValidationsEnabled,
    isDisabled,
    setDisabled,
    isSubmitting,
    setSubmitting,
    isConfirmingDelete,
    setConfirmingDelete,
    reset,
    setForm: setFormValue,
  };
};

export default useFormState;
