import axios, { AxiosPromise } from "axios";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { Recepcion, RecepcionForm } from "../models/recepciones/Recepcion";
//import axios from './axiosToken';

export function getRecepcionCodigoOP(requestBody: String): AxiosPromise<Recepcion> {
    return axios.get(`api/recepciones/ordenProceso/${requestBody}`);
}

export function putRecepcionCodigoOP(requestBody: RecepcionForm, codigoOP: String): AxiosPromise<Recepcion> {
    return axios.put(`api/recepciones/ordenProceso/${codigoOP}`, requestBody);
}

export function postRecepcion(requestBody: RecepcionForm, codigoOP: String): AxiosPromise<OrdenProceso> {
    return axios.post(`api/recepciones/ordenProceso/${codigoOP}`, requestBody);
}

/*
export function getRecepciones(requestBody: RecepcionesFilters): AxiosPromise<RecepcionesTablaResponse> {
    return axios.get('recepciones', { params: requestBody });
}

export function getRecepcion(requestBody: String): AxiosPromise<Recepcion> {
    return axios.get(`recepciones/${requestBody}`);
}

export function putRecepcion(requestBody: RecepcionForm, _id: String): AxiosPromise<Recepcion> {
    return axios.put(`recepciones/${_id}`, requestBody);
}
*/

export function deleteRecepcion(requestBody: String): AxiosPromise<OrdenProceso> {
    return axios.delete(`api/recepciones/ordenProceso/${requestBody}`);
}