import NumeroGalpon from "../models/componentes/NumeroGalpon";
import TipoComponente from "../models/componentes/TipoComponente";
import { SelectOption } from "../models/optionsFilters/Select";
import {
    Componente,
    ComponenteForm,
    ComponenteReparadoDetails,
    ComponenteReparadoForm,
    ComponenteReparadoRequestBody,
    ComponenteRequestBody,
    ComponentesFilters,
    ComponentesReparadosFilters,
    SubComponenteReparado
} from "../models/componentes/Componente";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { BodyCardElement } from "../models/BodyCardElement";

export const getEmptyComponentesFilters = (): ComponentesFilters => ({
    descripcion: '',
    modelo: '',
    numeroParte: '',
    galpon: '',
    tipoComponente: '',
    page: 1
});

export const getEmptyComponenteForm = (): ComponenteForm => ({
    descripcion: '',
    modelo: '',
    numeroParte: ''
});

export const formatComponenteRequestBody = (componenteForm: ComponenteForm): ComponenteRequestBody => {
    const { descripcion, modelo, numeroParte, tipoComponente, galpon } = componenteForm;

    return {
        descripcion: descripcion,
        modelo: modelo,
        numeroParte: numeroParte || undefined,
        tipoComponente: tipoComponente?.value.tipo,
        galpon: galpon?.value.numero,
    }
}
/*
export const tiposComponentesStrings = (tiposComponentesOptions: SelectOption<TipoComponente>[]): string[] => {
    return tiposComponentesOptions.map((tipoComponenteSelect) => {
        return tipoComponenteSelect.value.tipo;
    })
}
*/

export const formatComponenteForm = (
    componente: Componente,
    numeroGalponOptions: SelectOption<NumeroGalpon>[],
    tiposComponentesOptions: SelectOption<TipoComponente>[]
    ): ComponenteForm => {
    const {
        descripcion = '',
        modelo = '',
        numeroParte = ''
    } = componente;

    const galpon = numeroGalponOptions.find((numero) => 
        numero.value.numero === componente.galpon
    );

    const tipoComponente = tiposComponentesOptions.find((tipo) => 
        tipo.value.tipo === componente.tipoComponente
    );

    return {
        descripcion, modelo, numeroParte, galpon, tipoComponente
    }
}

export const getEmptyComponentesReparadosFilters = (): ComponentesReparadosFilters => (
    {
        codigoOP: '',
        numeroSerieComponente: '',
        descripcionComponente: '',
        modeloComponente: '',
        codigoMaterial: '',
        ordenTrabajoCliente: '',
        fechaReparacionInicio: '',
        fechaReparacionFinal: '',
        periodoGarantia: '',
        pesoComponente: '',
        page: 1
    }
);

export const getEmptyComponenteReparadoForm = (): ComponenteReparadoForm => (
    {
        pesoComponente: '',
        rsproveedor: '',
        responsableReparacion: '',
        emailContactoReparacion: '',
        telefonoContactoReparacion: ''
    }
);

export const formatComponenteReparadoRequestBody = (ordenProceso: string, componenteReparadoForm: ComponenteReparadoForm, subComponentesReparados?: string[]): ComponenteReparadoRequestBody => {
    return {
        pesoComponente: componenteReparadoForm.pesoComponente,
        rsproveedor: componenteReparadoForm.rsproveedor,
        responsableReparacion: componenteReparadoForm.responsableReparacion,
        emailContactoReparacion: componenteReparadoForm.emailContactoReparacion,
        telefonoContactoReparacion: componenteReparadoForm.telefonoContactoReparacion,

        codigoOP: ordenProceso,

        subComponentesReparados: subComponentesReparados || undefined,
    }
}

export const formatComponenteReparadoForm = (componenteReparadoDetails: ComponenteReparadoDetails): ComponenteReparadoForm => {
    return {
        pesoComponente: componenteReparadoDetails.pesoComponente,
        rsproveedor: componenteReparadoDetails.rsproveedor,
        responsableReparacion: componenteReparadoDetails.responsableReparacion,
        emailContactoReparacion: componenteReparadoDetails.emailContactoReparacion,
        telefonoContactoReparacion: componenteReparadoDetails.telefonoContactoReparacion
    }
}

export const datosComponenteOPArray = (ordenProceso: OrdenProceso): BodyCardElement[] => {
    let arrayResponse = [];
    arrayResponse.push({ title: 'Descripción', value: ordenProceso.componente?.descripcion ? ordenProceso.componente.descripcion : '' });
    arrayResponse.push({ title: 'Modelo', value: ordenProceso.componente?.modelo ? ordenProceso.componente.modelo : '' });
    // arrayResponse.push({ title: 'N° parte', value: ordenProceso.componente?.numeroParte ? ordenProceso.componente.numeroParte : '' });
    arrayResponse.push({ title: 'N° Serie', value: ordenProceso.numeroSerieComponente ? ordenProceso.numeroSerieComponente : '' });
    arrayResponse.push({ title: 'Tipo', value: ordenProceso.componente?.tipoComponente ? ordenProceso.componente.tipoComponente : '' });
    arrayResponse.push({
        title: 'Diferencia Modelo',
        value: ordenProceso?.recepcion?.subModeloComponente ?
            `${ordenProceso.componente?.modelo}${ordenProceso.recepcion.subModeloComponente}` :
            ordenProceso.componente?.modelo ? ordenProceso.componente?.modelo : ''
    });
    arrayResponse.push({ title: 'Galpón', value: ordenProceso.componente?.galpon ? ordenProceso.componente.galpon : '' });
    return arrayResponse;
}

export const datosComponenteReparadoArray = (componenteReparado: ComponenteReparadoDetails): BodyCardElement[] => {
    let arrayResponse = [];
    arrayResponse.push({ title: 'N/S Componente', value: componenteReparado.numeroSerieComponente ? componenteReparado.numeroSerieComponente : '' });
    arrayResponse.push({ title: 'Modelo componente', value: componenteReparado.modeloComponente ? componenteReparado.modeloComponente : '' });
    arrayResponse.push({ title: 'RS Proveedor', value: componenteReparado.rsproveedor ? componenteReparado.rsproveedor : '' });
    arrayResponse.push({ title: 'Orden de proceso', value: componenteReparado.codigoOP ? componenteReparado.codigoOP : '' });
    arrayResponse.push({ title: 'Estado', value: componenteReparado.estadoComponente ? componenteReparado.estadoComponente : '' });
    if (componenteReparado.ordenTrabajoCliente) {
        arrayResponse.push({ title: 'Orden trabajo cliente', value: componenteReparado.ordenTrabajoCliente });
    }
    if (componenteReparado.codigoMaterial) {
        arrayResponse.push({ title: 'Código material (SAP)', value: componenteReparado.codigoMaterial });
    }
    if (componenteReparado.pesoComponente) {
        arrayResponse.push({ title: 'Peso(KG)', value: componenteReparado.pesoComponente });
    }
    return arrayResponse;
}

export const datosReparacionComponenteReparadoArray = (componenteReparado: ComponenteReparadoDetails): BodyCardElement[] => {
    let arrayResponse = [];
    arrayResponse.push({ title: 'Fecha', value: componenteReparado.fechaReparacion ? componenteReparado.fechaReparacion : '' });
    if (componenteReparado.ordenCompra ) {
        arrayResponse.push({ title: 'Orden de compra', value: componenteReparado.ordenCompra });
    }
    if (componenteReparado.periodoGarantia) {
        arrayResponse.push({ title: 'Garantía (Meses)', value: componenteReparado.periodoGarantia });
    }
    if (componenteReparado.responsableReparacion) {
        arrayResponse.push({ title: 'Responsable', value: componenteReparado.responsableReparacion });
    }
    if (componenteReparado.emailContactoReparacion) {
        arrayResponse.push({ title: 'Email', value: componenteReparado.emailContactoReparacion });
    }
    if (componenteReparado.telefonoContactoReparacion) {
        arrayResponse.push({ title: 'Teléfono', value: componenteReparado.telefonoContactoReparacion });
    }
    return arrayResponse;
}

export const datosSubComponenteReparadoArray = (subComponenteReparado: SubComponenteReparado): BodyCardElement[] => {
    let arrayResponse = [];
    if (subComponenteReparado.descripcionComponente) {
        arrayResponse.push({ title: 'Descripción', value: subComponenteReparado.descripcionComponente });
    }
    if (subComponenteReparado.codigoMaterial) {
        arrayResponse.push({ title: 'Código Material (SAP)', value: subComponenteReparado.codigoMaterial });
    }
    if (subComponenteReparado.modeloComponente) {
        arrayResponse.push({ title: 'Modelo', value: subComponenteReparado.modeloComponente });
    }
    if (subComponenteReparado.codigoOP) {
        arrayResponse.push({ title: 'Orden de Proceso', value: subComponenteReparado.codigoOP });
    }
    if (subComponenteReparado.numeroSerieComponente) {
        arrayResponse.push({ title: 'N/S Componente', value: subComponenteReparado.numeroSerieComponente });
    }
    return arrayResponse;
}
/*
export const formatSubComponenteReparadoForm = (subComponenteReparado: SubComponenteReparado): SubComponenteReparadoForm => {
    return {
        codigoOP: subComponenteReparado.codigoOP,
        descripcionComponente: subComponenteReparado.descripcionComponente,
        numeroSerieComponente: subComponenteReparado.numeroSerieComponente,
        modeloComponente: subComponenteReparado.modeloComponente,
        codigoMaterial: subComponenteReparado.codigoMaterial,
        fechaRecepcion: subComponenteReparado.fechaRecepcion,
        codigoSolped: subComponenteReparado.codigoSolped,
        numeroGuiaDespacho: subComponenteReparado.numeroGuiaDespacho,
        fechaEvaluacion: subComponenteReparado.fechaEvaluacion,
        fechaReparacion: subComponenteReparado.fechaReparacion,
        ordenCompra: subComponenteReparado.ordenCompra
    }
}

export const getEmptySubComponenteReparadoForm = (): SubComponenteReparadoForm => {
    return {
        codigoOP: '',
        descripcionComponente: '',
        numeroSerieComponente: '',
        modeloComponente: '',
        codigoMaterial: '',
        fechaRecepcion: '',
        codigoSolped: '',
        numeroGuiaDespacho: '',
        fechaEvaluacion: '',
        fechaReparacion: '',
        ordenCompra: ''
    }
}
*/