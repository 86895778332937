import { ReactElement, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";

interface DownloadProgressProps {
    text: string,
    userPermission: boolean,
    onDownload: (
        // changeDownloadProgress: (progress: number) => void,
        stopDownloadingStatus: () => void) => void,
    // getDownload: () => void,
    isSomethingDownloading: boolean
}

const DownloadProgress = ({ text, userPermission, onDownload, isSomethingDownloading }: DownloadProgressProps): ReactElement => {

    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    // const [ downloadProgress, setDownloadProgress ] = useState<number>(0);
    // const [ downloadBarProgress, setDownloadBarProgress ] = useState<number>(0);

    const stopDownloadingStatus = () => setIsDownloading(false);

    // const changeDownloadProgress = (progress: number) => {
    //     setDownloadProgress(progress);
    // }

    const download = () => {
        setIsDownloading(true);
        onDownload(
            // changeDownloadProgress,
            stopDownloadingStatus);
    }

    const isDisabled = (): boolean => {
        if (userPermission) {
            return isSomethingDownloading;
        } else {
            return false;
        }
    }

    // const updateBarProgress = (finalValue: number, initialValue: number) => {
    //     const currentValueToUpdate = initialValue + 1;
    //     setTimeout(() => {
    //         setDownloadBarProgress(currentValueToUpdate);
    //         if (initialValue < finalValue) {
    //             updateBarProgress(finalValue, currentValueToUpdate);
    //         }
    //     }, 20);
    // };

    // useEffect(() => {
    //     if (isDownloading) {
    //         updateBarProgress(downloadProgress, downloadBarProgress);
    //     }
    // }, [downloadProgress]);

    // useEffect(() => {
    //     if (downloadBarProgress >= 100) {
    //         setIsDownloading(false);
    //         getDownload();
    //         setDownloadProgress(0);
    //         setDownloadBarProgress(0);
    //     }
    // }, [downloadBarProgress]);

    // const isServerGettingData = (): string => {
    //     if (isDownloading) {
    //             return `Obteniendo información ...`;
            
    //     } else {
    //         return '';
    //     }
    // }

    return (
        <>
            <Card.Body>
                <div className = "cards-header">
                    <div className = "body-subtitle">{text}</div>
                    {isDownloading ?
                        <div>{isDownloading && `Obteniendo información ...`} <Spinner animation = "border" size = "sm"/></div> :
                        <div className = "body-buttons">
                            <Button onClick = {download} disabled = {isDisabled()}>
                                {isSomethingDownloading ? <Spinner animation = "border" size = "sm"/> : 'Descargar'}
                            </Button>
                        </div>}
                </div>
            </Card.Body>
        </>
    );
}

export default DownloadProgress;