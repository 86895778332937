import cogoToast from "cogo-toast";
import { ReactElement, useContext } from "react";
import { Button, Card, Form, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { login } from "../../api/LoginApi";
import { Input } from "../../components/form/input/Input";
import AuthContext from "../../contexts/AuthContext";
import useFormState from "../../hooks/useFormState";
import Credentials from "../../models/users/Credentials";
import fondo from '../../assets/images/login_img.jpg';
import logo from '../../assets/images/logotipo.jpg';
import jwt from 'jsonwebtoken';
import JWT from "../../models/users/JWT";
import { isUrlCliente } from "../../utils/urls";

const LoginPage = (): ReactElement => {
    const {
        value: credentials,
        handleFieldChange,
        isSubmitting,
        areValidationsEnabled,
        setAreValidationsEnabled,
        setSubmitting,
    } = useFormState<Credentials>({ email: '', password: '' });

    const { email, password } = credentials;
    const history = useHistory();
    const { setJWT, previuosLocation } = useContext(AuthContext);

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (!email || !password) return;
        setSubmitting(true);
        login(credentials)
            .then((response) => {
                if (typeof response.data === 'string') {
                    setJWT(response.data);
                    if ((jwt.decode(response.data) as JWT).roles.includes('CLIENTE')) {
                        if (previuosLocation !== 'no guardar' && isUrlCliente(previuosLocation)) {
                            history.replace(previuosLocation);
                        } else {
                            history.replace("/");
                        }
                    } else {
                        if (previuosLocation !== 'no guardar' && previuosLocation !== '/' && previuosLocation !== '') {
                            history.replace(previuosLocation);
                        } else {
                            history.replace('/ordenesProceso');
                        }
                    }
                    // setPreviuosLocation('');
                } else {
                    cogoToast.error('Login inválido');
                    setSubmitting(false);
                }
            })
            .catch(() => {
                cogoToast.error('No fue posible ingresar');
                setSubmitting(false);
            })
    }

    return (
        <>
            <div className = "login-background">
                <div className = "login-img-container">
                    <img className = "login-img" src = {fondo} alt = "Fondo Electrans"/>
                </div>
            </div>
            <div className = "login-container">
                <Card>
                    <Card.Body className = "login-card">
                        <div className = "login-logo">
                            <img src = {logo} alt = "Logotipo Electrans"/>
                        </div>
                        <Form>
                            <Row>
                                <Input
                                    title="Email / ID"
                                    name="email"
                                    value={email}
                                    type="text"
                                    onChangeWithName={handleFieldChange}
                                    invalid={areValidationsEnabled && !email}
                                    invalidText="Debe ingresar su correo"
                                    invalidTextColor="#9a0024"
                                    disabled={isSubmitting}
                                    />
                            </Row>
                            <Row>
                                <Input
                                    title="Contraseña"
                                    name="password"
                                    value={password}
                                    type="password"
                                    onChangeWithName={handleFieldChange}
                                    invalid={areValidationsEnabled && !password}
                                    invalidText="Debe ingresar su contraseña"
                                    invalidTextColor="#9a0024"
                                    disabled={isSubmitting}
                                />
                            </Row>
                            <div className = "modal-buttons">
                                <Button
                                    type="submit"
                                    onClick={handleSubmitClick}
                                    //variant="outline-light"
                                    disabled={isSubmitting}>
                                        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Ingresar'}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default LoginPage;