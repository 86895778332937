import React, { ChangeEvent, CSSProperties, ReactElement, RefObject } from 'react';
import classNames from 'classnames';
import { format, isSameDay, parse } from 'date-fns';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import InputAppend from './InputAppend';
import 'react-datepicker/src/stylesheets/datepicker.scss';

interface IInputAppendOptions {
  content: React.ReactNode;
  onClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
}

export interface InputProps {
  formGroupClassName?: string;
  name?: string;
  title?: string;
  type?: string;
  value: string | number;
  checked?: boolean;
  prepend?: IInputAppendOptions;
  append?: IInputAppendOptions;
  onChangeWithName?: (name: string, value: string) => void;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  preventDefault?: boolean;
  horizontal?: boolean;
  tabIndex?: number;
  maxLength?: number;
  min?: number | string;
  max?: number | string;
  disabled?: boolean;
  helpText?: string;
  helpTextColor?: string;
  invalidText?: string;
  invalidTextColor?: string;
  invalid?: boolean;
  minDate?: string;
  maxDate?: string;
  disableFutureDates?: boolean;
  customRef?: React.RefObject<HTMLTextAreaElement> | React.RefObject<HTMLInputElement> | null;
  style?: CSSProperties;
  size?: 'lg' | 'sm';
  datePickerProps?: Omit<ReactDatePickerProps, 'onChange'>
}

const Input = ({
  formGroupClassName,
  name,
  title,
  type,
  value,
  prepend,
  append,
  onChangeWithName,
  onChange,
  onBlur,
  onClick,
  onFocus,
  placeholder,
  isRequired = false,
  isReadOnly = false,
  preventDefault = false,
  horizontal = false,
  tabIndex,
  maxLength,
  max,
  min,
  disabled,
  helpText,
  helpTextColor,
  invalidText,
  invalid,
  invalidTextColor,
  minDate,
  maxDate,
  disableFutureDates,
  customRef: ref,
  style,
  size,
  checked,
  datePickerProps,
}: InputProps): ReactElement => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    if (preventDefault) event.preventDefault();

    const { target } = event;

    let newValue = target.value;
    if (maxLength && maxLength > 0) newValue = newValue.slice(0, maxLength);

    if (onChangeWithName && name) onChangeWithName(name, newValue);
    if (onChange) onChange(newValue);
  };

  const formGroupClassNames = classNames('form-group', formGroupClassName, {
    row: horizontal && title,
  });

  const inputWrapperClassNames = classNames({
    'col-8': horizontal && title,
  });

  const inputClassNames = classNames('form-control', {
    'is-invalid': invalid,
    [`form-control-${size}`]: !append && !prepend && size,
  });

  const inputGroupClassNames = classNames({
    'input-group': append || prepend,
    [`input-group-${size}`]: (append || prepend) && size,
  });

  let label;
  if (title) {
    const labelClassNames = classNames({
      'col-4 col-form-label': horizontal && title,
    });

    label = (
      <label htmlFor={name} className={labelClassNames}>
        {title}
      </label>
    );
  }

  let inputType = type;
  if (inputType === 'number' || !inputType) inputType = 'text';

  let inputComponent;

  if (type === 'textarea') {
    inputComponent = (
      <textarea
        ref={(ref as RefObject<HTMLTextAreaElement>) || null || undefined}
        className={inputClassNames}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        required={!isRequired}
        readOnly={isReadOnly}
        disabled={disabled}
        maxLength={maxLength}
        tabIndex={tabIndex}
        style={style}
      />
    );
  } else if (type === 'datetime' || type === 'date') {
    const includeTime = type === 'datetime';
    const formatString = includeTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';

    let maxDateProp;
    const maxDateObject = maxDate && parse(maxDate, formatString, new Date());
    const now = new Date();

    const minDateProp = minDate ? parse(minDate.toString(), formatString, new Date()) : null;

    if (disableFutureDates && maxDate && maxDateObject) {
      maxDateProp = now > maxDateObject ? maxDateObject : now;
    } else if (maxDate && maxDateObject) {
      maxDateProp = maxDateObject;
    } else if (disableFutureDates) {
      maxDateProp = now;
    }

    const dateValue = parse(value.toString(), formatString, new Date());

    let maxTimeProp = new Date();
    if (includeTime) {
      if (maxDateProp && isSameDay(dateValue, maxDateProp)) {
        maxTimeProp = maxDateProp;
      } else {
        maxTimeProp.setHours(23);
        maxTimeProp.setMinutes(59);
      }
    }

    let minTimeProp = new Date();
    if (includeTime) {
      if (minDateProp && isSameDay(dateValue, minDateProp)) {
        minTimeProp = minDateProp;
      } else {
        minTimeProp.setHours(0);
        minTimeProp.setMinutes(0);
      }
    }

    const wrapperClassName = classNames('w-100', {
      'is-invalid': invalid,
    });
    inputComponent = (
      <DatePicker
        className={inputClassNames}
        wrapperClassName={wrapperClassName}
        name={name}
        selected={value ? dateValue : null}
        onChange={(date): void => {
          const newValue = format((date as Date) || new Date(), formatString);
          if (onChangeWithName && name) onChangeWithName(name, newValue);
          if (onChange) onChange(newValue);
        }}
        dateFormat={formatString}
        placeholderText={placeholder || 'Seleccione la fecha'}
        timeFormat={includeTime ? 'HH:mm' : undefined}
        showTimeSelect={includeTime}
        minDate={minDateProp}
        maxDate={maxDateProp}
        maxTime={maxTimeProp}
        minTime={minTimeProp}
        timeIntervals={5}
        disabled={disabled}
        tabIndex={tabIndex}
        isClearable
        {...datePickerProps}
      />
    );
  } else if (type === 'checkbox') {
    inputComponent = (
      <input
        ref={(ref as RefObject<HTMLInputElement>) || null || undefined}
        className={inputClassNames}
        name={name}
        type="checkbox"
        checked={checked}
        value={value}
        onChange={handleChange}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        required={!isRequired}
        readOnly={isReadOnly}
        disabled={disabled}
        tabIndex={tabIndex}
        max={max}
        min={min}
        style={style}
      />
    );
  } else {
    inputComponent = (
      <input
        ref={(ref as RefObject<HTMLInputElement>) || null || undefined}
        className={inputClassNames}
        name={name}
        type={inputType}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        required={!isRequired}
        readOnly={isReadOnly}
        disabled={disabled}
        tabIndex={tabIndex}
        max={max}
        min={min}
        style={style}
      />
    );
  }

  return (
    <div className={formGroupClassNames}>
      {label}
      <div className={inputWrapperClassNames}>
        <div className={inputGroupClassNames}>
          {prepend && (
            <InputAppend
              content={prepend.content}
              type="prepend"
              onClick={prepend.onClick}
              backgroundColor={prepend.backgroundColor}
              textColor={prepend.textColor}
              disabled={disabled}
            />
          )}
          {inputComponent}
          {append && (
            <InputAppend
              content={append.content}
              type="append"
              onClick={append.onClick}
              backgroundColor={append.backgroundColor}
              textColor={append.textColor}
              disabled={disabled}
            />
          )}
        </div>
        {helpText && <small style={{ color: helpTextColor }}>{helpText}</small>}
        {/* {invalid && invalidText && (
          <small
            style={{ color: invalidTextColor }}
            className={invalidTextColor ? '' : 'text-danger'}>
            {invalidText}
          </small>
        )} */}
      </div>
    </div>
  );
};

export { Input };
