import { Dispatch, ReactElement, ReactText, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentesReparadosFilters } from "../../models/componentes/Componente";
import { Input } from "../form/input/Input";
import DateSelect from "../form/DateSelect";

interface QRComponentesFiltersProps {
    onFiltersChange: Dispatch<SetStateAction<ComponentesReparadosFilters>>,
    componentesReparadosFilters: ComponentesReparadosFilters
}

const QRComponentesFilters = ({
    onFiltersChange, componentesReparadosFilters
}: QRComponentesFiltersProps): ReactElement => {

    const handleFilterChange = (name: string, value?: ReactText): void =>
        onFiltersChange((prevFilters) => ({
            ...prevFilters,
            page: 1,
            [name]: value,
        }));

    return (
        <>
            <Row>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.codigoOP}
                        name = "codigoOP"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Codigo OP"
                    />
                </Col>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.descripcionComponente}
                        name = "descripcionComponente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Descripcion"
                    />
                </Col>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.numeroSerieComponente}
                        name = "numeroSerieComponente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "N/S"
                    />
                </Col>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.modeloComponente}
                        name = "modeloComponente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Modelo"
                    />
                </Col>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.periodoGarantia}
                        name = "periodoGarantia"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Garantía (Meses)"
                    />
                </Col>
            </Row>
            <Row>
                
                <Col>
                    <Input
                        value = {componentesReparadosFilters.pesoComponente}
                        name = "pesoComponente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Peso (KG)"
                    />
                </Col>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.codigoMaterial}
                        name = "codigoMaterial"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Código Material"
                    />
                </Col>
                <Col>
                    <Input
                        value = {componentesReparadosFilters.ordenTrabajoCliente}
                        name = "ordenTrabajoCliente"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Orden Trabajo Cliente"
                    />
                </Col>
                <Col>
                    <DateSelect
                        title = "Fecha Reparación"
                        name = "fechaReparacionInicio"
                        name2 = "fechaReparacionFinal"
                        selected = {componentesReparadosFilters.fechaReparacionInicio}
                        selected2 = {componentesReparadosFilters.fechaReparacionFinal}
                        onChangeField = {handleFilterChange}
                        startDate = {componentesReparadosFilters.fechaReparacionInicio}
                        endDate = {componentesReparadosFilters.fechaReparacionFinal}
                        isClearable
                        isInterval
                    />
                    {/* <div className = "form-group">Fecha Reparación</div>
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            componentesReparadosFilters.fechaReparacionInicio ?
                            parse(componentesReparadosFilters.fechaReparacionInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFilterChange("fechaReparacionInicio",  changedFilter);
                        }}
                        selectsStart
                        startDate = {
                            componentesReparadosFilters.fechaReparacionInicio ?
                            parse(componentesReparadosFilters.fechaReparacionInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        endDate = {
                            componentesReparadosFilters.fechaReparacionFinal ?
                            parse(componentesReparadosFilters.fechaReparacionFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        isClearable
                        placeholderText = {'Desde'}
                    />
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            componentesReparadosFilters.fechaReparacionFinal ?
                            parse(componentesReparadosFilters.fechaReparacionFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFilterChange("fechaReparacionFinal",  changedFilter);
                        }}
                        selectsEnd
                        startDate = {
                            componentesReparadosFilters.fechaReparacionInicio ?
                            parse(componentesReparadosFilters.fechaReparacionInicio, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        endDate = {
                            componentesReparadosFilters.fechaReparacionFinal ?
                            parse(componentesReparadosFilters.fechaReparacionFinal, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        isClearable
                        placeholderText = {'Hasta'}
                    /> */}
                </Col>
            </Row>
        </>
    );
}

export default QRComponentesFilters;