import { ReactElement } from "react";
import { Table } from "react-bootstrap";
import ReactSelect from "react-select";
import { SelectOption } from "../../models/optionsFilters/Select";
import { EvaluacionOrReparacionComponenteEtapa, TareaCheck } from "../../models/EvaluacionesReparaciones";

interface Props {
    tareaCheck: TareaCheck
    changeEvaluacionReparacionComponenteEtapa?: (changeEvaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa?: EvaluacionOrReparacionComponenteEtapa,
    nombreTarea: string,
    isNotEditable: boolean,
    isGenerarInformeTarea?: boolean,
    changeAprobacionState?: (check: TareaCheck) => void
}

const EvaluacionReparacionComponenteDetallesChecks = ({
    tareaCheck, changeEvaluacionReparacionComponenteEtapa, evaluacionReparacionComponenteEtapa, isNotEditable, nombreTarea, isGenerarInformeTarea, changeAprobacionState
}: Props): ReactElement => {

    const changeCheck = (checkOption?: string) => {
        if (changeEvaluacionReparacionComponenteEtapa && evaluacionReparacionComponenteEtapa) {
            const newEvaluacionComponenteEtapa = evaluacionReparacionComponenteEtapa;
            for (let i = 0; i < newEvaluacionComponenteEtapa.tareas.length; i++) {
                if (newEvaluacionComponenteEtapa.tareas[i].nombre === nombreTarea) {
                    const check = newEvaluacionComponenteEtapa.tareas[i].check;
                    if (check && check.name === tareaCheck.name) {
                        let newValue = !check.check;
                        if (checkOption) {
                            if (checkOption === 'SI') {
                                newValue = true;
                            } else if (checkOption === 'NO') {
                                newValue = false;
                            }
                        }
                        check.check = newValue;
                    }
                    newEvaluacionComponenteEtapa.tareas[i].check = check;
                    break;
                }
            }
            changeEvaluacionReparacionComponenteEtapa(newEvaluacionComponenteEtapa);
        }
        if (changeAprobacionState) {
            const newTareaCheck = tareaCheck;
            if (newTareaCheck) {
                if (newTareaCheck.select) {
                    if (checkOption) {
                        if (checkOption === 'SI') {
                            newTareaCheck.check = true;
                        } else if (checkOption === 'NO') {
                            newTareaCheck.check = false;
                        }
                    }
                } else {
                    newTareaCheck.check = !newTareaCheck.check;
                }
                changeAprobacionState(newTareaCheck);
            }
        }
    }

    return (
        <Table bordered size = 'sm'>
            <tbody>
                <tr>
                    <td><h5>{tareaCheck.name}</h5></td>
                    <td>
                        {isGenerarInformeTarea ?
                            <h5>{tareaCheck.check ? 'SÍ' : 'NO'}</h5>
                        :
                            tareaCheck.select === true ?
                                <ReactSelect
                                    defaultValue = {((): SelectOption<string> => {
                                        let response = { label: 'NO', value: 'NO' };
                                        if (tareaCheck.check) {
                                            response.label = 'SÍ';
                                            response.value = 'SI';
                                        }
                                        return response;
                                    })()}
                                    options = {[{ label: 'SÍ', value: 'SI' }, { label: 'NO', value: 'NO' }]}
                                    onChange = {(value: SelectOption<string>) => {changeCheck(value.value)}}
                                    isDisabled = {isNotEditable}
                                    hideSelectedOptions
                                />
                            :
                                <div className = "centerTextCell">
                                    <input
                                        className = "largeCheckBox"
                                        type = "checkbox"
                                        defaultChecked = {tareaCheck.check}
                                        disabled = {isNotEditable}
                                        onChange = {() => changeCheck()}
                                    />
                                </div>
                        }
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default EvaluacionReparacionComponenteDetallesChecks;