import { ReactElement, useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import useTiposComponentesOptions from "../../../hooks/componentes/useTiposComponentes";
import ReactSelect from "react-select";
import { SelectOption } from "../../../models/optionsFilters/Select";
// import TipoComponente from "../../../models/componentes/TipoComponente";
import { getModelosComponente } from "../../../api/ComponentesApi";
import cogoToast from "cogo-toast";
// import { deleteParticipanteAdicional, getParticipantesAdicionales, postParticipantesAdicionales } from "../../../api/UsersApi";
import ParticipantesAdicionalesItem from "./ParticipantesAdicionalesItem";
import useRolUsuarioOptions from "../../../hooks/users/useRolUsuario";
// import { Persona } from "../../../models/users/Users";
// import Select from "react-select/dist/declarations/src/Select";

interface Props {
    userPermission: boolean
}

const ParticipantesAdicionales = ({ userPermission }: Props): ReactElement => {

    const tiposComponentesOptions = useTiposComponentesOptions();
    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ tipoComponente, setTipoComponente ] = useState<string>();
    const [ modeloComponente, setModeloComponente ] = useState<string>();
    const [ modelosComponenteOptions, setModelosComponenteOptions ] = useState<SelectOption<string>[]>();
    const isNotDisabled = !(!tipoComponente || !modeloComponente);

    useEffect(() => {
        if (tipoComponente) {
            setIsBusy(true);
            getModelosComponente(tipoComponente)
                .then((response) => {
                    setModelosComponenteOptions(response.data.map((modelo) => ({ label: modelo, value: modelo })));
                    setIsBusy(false);
                }).catch(() => {
                    // setModeloComponente(undefined);
                    setModelosComponenteOptions(undefined);
                    setIsBusy(false);
                    cogoToast.error('No fue posible obtener los modelos de componente');
                });
        }
    }, [tipoComponente]);

    return (
        <Card className = "cards-container">
            <Card.Header className = "cards-header">
                <div className = "body-subtitle">
                    Opciones de participantes adicionales en informe de evaluación de un componente
                </div>
            </Card.Header>
            <Card.Body className = "cards-body-groups">
                <div>
                    <div className = "body-filters">
                        <Col>
                            <div>Tipo de componente</div>
                            <ReactSelect
                                isDisabled = {isBusy}
                                options = {tiposComponentesOptions}
                                onChange = {(value: SelectOption<{ tipo: string }>) => {
                                    if (value.value.tipo !== tipoComponente) {
                                        setModeloComponente(undefined);
                                    }
                                    setTipoComponente(value.value.tipo);
                                }}
                            />
                        </Col>
                        <Col>
                            <div>Modelo de componente</div>
                            <ReactSelect
                                placeholder = {isBusy ? 'Cargando ...' : 'Select...'}
                                value = {modeloComponente ? { label: modeloComponente, value: modeloComponente }: null}
                                isDisabled = {isBusy || !modelosComponenteOptions}
                                options = {modelosComponenteOptions}
                                onChange = {(value: SelectOption<string>) => setModeloComponente(value?.value)}
                            />
                        </Col>
                        {/* <div className = "buttons-group">
                            <Button onClick = {() => getModelosComponenteFunction()} disabled = {isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm"/> : 'Recargar'}
                            </Button>
                        </div> */}
                    </div>
                </div>
                <ParticipantesAdicionalesItem
                    title = "Revisado por"
                    funcion = 'revisadoPor'
                    tipoComponente = {tipoComponente}
                    modeloComponente = {modeloComponente}
                    isNotDisabled = {isNotDisabled}
                    userPermission = {userPermission}
                    rolUsuarioOptions = {useRolUsuarioOptions('emails')}
                    isBusy = {isBusy}
                />
                <ParticipantesAdicionalesItem
                    title = "Control de calidad"
                    funcion = 'controlCalidad'
                    tipoComponente = {tipoComponente}
                    modeloComponente = {modeloComponente}
                    isNotDisabled = {isNotDisabled}
                    userPermission = {userPermission}
                    rolUsuarioOptions = {useRolUsuarioOptions('controlCalidadList')}
                    isBusy = {isBusy}
                />
                <ParticipantesAdicionalesItem
                    title = "Aprobado por"
                    funcion = 'aprobadoPor'
                    tipoComponente = {tipoComponente}
                    modeloComponente = {modeloComponente}
                    isNotDisabled = {isNotDisabled}
                    userPermission = {userPermission}
                    rolUsuarioOptions = {useRolUsuarioOptions('emails')}
                    isBusy = {isBusy}
                />
            </Card.Body>
        </Card>
    );
}

export default ParticipantesAdicionales;