import TipoProceso from "../../models/estatusGeneral/TipoOrdenProceso";
import { SelectOption } from "../../models/optionsFilters/Select";


const useTipoProceso = (showAPP?: boolean): SelectOption<TipoProceso>[] => {

    if (showAPP) {
        return [
            { label: 'OP', value: {tipo: 'OP'} },
            { label: 'AG', value: {tipo: 'AG'} },
            { label: 'APP', value: {tipo: 'APP'} },
        ];
    } else {
        return [
            { label: 'OP', value: {tipo: 'OP'} },
            { label: 'AG', value: {tipo: 'AG'} }
        ];
    }
}

export default useTipoProceso;