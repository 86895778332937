import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import NuevaOrdenProceso from "../../components/estatusGeneral/NuevaOrdenProceso";
import OrdenesProcesoFiltersComponent from "../../components/estatusGeneral/OrdenesProcesoFiltersComponent";
import OrdenesProcesoList from "../../components/estatusGeneral/OrdenesProcesoList";
import AuthContext from "../../contexts/AuthContext";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";
import useEstadosOrdenProceso from "../../hooks/estatusGeneral/useEstadosOrdenProceso";
import useTipoOrdenProceso from "../../hooks/estatusGeneral/useTipoOrdenProceso";
import useTipoProceso from "../../hooks/estatusGeneral/useTipoProceso";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { OrdenesProcesoFilters } from "../../models/estatusGeneral/OrdenProceso";
import { getEmptyOrdenesProcesoFilters } from "../../utils/estatusGeneral";
import { getUserPermissionFunction } from "../../utils/users";

const EstatusGeneralPage = (): ReactElement => {

    const { userRoles, userEmail } = useContext(AuthContext);
    const [ordenesProcesoFilters, setOrdenesProcesoFilters] = useState<OrdenesProcesoFilters>(getEmptyOrdenesProcesoFilters());
    const estadosOrdenProcesoOptions = useEstadosOrdenProceso('filtros');
    const centroCostoOptions = useCentroCostoProceso();
    const tipoOrdenProcesoOptions = useTipoOrdenProceso();
    const tipoProcesoOptionsFilters = useTipoProceso(true);
    const tipoProcesoOptions = useTipoProceso();
    const breadcrumb = useBreadcrumb();

    const ordenProcesoChange = (ordenesProcesoFilters: OrdenesProcesoFilters) => {
        setOrdenesProcesoFilters(ordenesProcesoFilters);
    };

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Estatus General',
                inactive: true,
            },
        ]);
    }, []);

    useEffect(() => {
        if (!ordenesProcesoFilters.operarioID && userRoles.some((rol) => rol.includes('OPERARIO'))) {
            setOrdenesProcesoFilters((previousFilters) => ({ ...previousFilters, operarioID: userEmail }));
        }
    }, [ordenesProcesoFilters, userRoles, userEmail])

    return (
        <>
            <Card className = "cards-container">
                <Card.Body className = "cards-body-groups">
                    <div className = "cards-header">
                        {estadosOrdenProcesoOptions && (
                            <OrdenesProcesoFiltersComponent 
                                ordenesProcesoFilters = {ordenesProcesoFilters}
                                estadosOrdenProcesoOptions = {estadosOrdenProcesoOptions}
                                centroCostoOptions = {centroCostoOptions}
                                tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                                onFiltersChange = {setOrdenesProcesoFilters}
                                tipoProcesoOptions = {tipoProcesoOptionsFilters}
                            />
                        )}
                        <div className = "filters-buttons">
                            <NuevaOrdenProceso
                                userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'PLANIFICACION'] })}
                                tipoProcesoOptions = {tipoProcesoOptions}
                                tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                            />
                        </div>
                    </div>
                    <OrdenesProcesoList
                        ordenesProcesoFilters = {ordenesProcesoFilters}
                        setOrdenesProcesoFilters = {ordenProcesoChange}
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default EstatusGeneralPage;