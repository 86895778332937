import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react"
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { blobToBase64, downloadBlobFromBase64 } from "../../utils/archivos";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { AxiosPromise } from "axios";

interface Props {
    // code: string,
    paramToCheck?: any,
    isNotEditable?: boolean,
    userPermission?: boolean,
    title?: string,
    subtitle?: string,
    externalIsBusy?: boolean,
    changeExternalBusyState?: (state: boolean) => void,
    additionalActionOnUpload?: () => void,
    additionalActionOnDelete?: () => void,
    message?: string,
    isMultiple?: boolean,
    getDocumento: (_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => AxiosPromise<Archivo | void>,
    postDocumento: (archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => AxiosPromise<ArchivoNombre>,
    deleteDocumento: (_id: string) => AxiosPromise<void>,
    // getDocumentosListado: () => AxiosPromise<ArchivoNombre[]>
    documentosListado?: ArchivoNombre[]
}

const DocumentsManager = ({
    paramToCheck, isNotEditable, userPermission, title, subtitle, changeExternalBusyState, additionalActionOnUpload, additionalActionOnDelete, externalIsBusy, message, isMultiple, getDocumento, postDocumento, deleteDocumento, documentosListado
}: Props): ReactElement => {

    const isEditable = !isNotEditable && userPermission;
    const [ internalIsBusy, setInternalIsBusy ] = useState<boolean>(false);
    const [ titulosArchivo, setTitulosArchivo ] = useState<ArchivoNombre[]>(documentosListado || []);
    const [ isDeleting, setIsDeleting ] = useState<boolean>(false);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ loadProgress, setLoadProgress ] = useState<number[]>([0]);
    const [ loadBarProgress, setLoadBarProgress ] = useState<number>(0);
    const [ isUpdatingProgress, setIsUpdatingProgress ] = useState<boolean>(false);
    const [ isLoadingElementList, setIsLoadingElementList ] = useState<boolean[]>(documentosListado ? documentosListado.map(() => false) : []);
    const isBusy = externalIsBusy === undefined ? internalIsBusy : externalIsBusy;

    useEffect(() => {
        setTitulosArchivo(documentosListado || []);
        setIsLoadingElementList((documentosListado || []).map(() => false));
    }, [paramToCheck]);

    const changeBusyState = changeExternalBusyState || ((state: boolean) => setInternalIsBusy(state));

    const updateLoadProgress = (progressEvent: any) => {
        const newPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (newPercentage > 0 && newPercentage > loadProgress[loadProgress.length - 1] && newPercentage <= 100) {
            setLoadProgress((prevValues) => [...prevValues, newPercentage]);
        }
    }

    const changeLoadState = (state: boolean, isChangeUploadingState?: boolean) => {
        if (isChangeUploadingState) {
            setIsUploading(state);
        } else {
            setIsDownloading(state);
        }
        changeBusyState(state);
        if (!state) {
            setLoadProgress([0]);
            setLoadBarProgress(0);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .docx, .doc, .xlsx, .rar, .zip',
        multiple: isMultiple,
        disabled: isBusy,
        onDrop: (async (onDropAcceptedFiles) => await Promise.all(onDropAcceptedFiles.map(async (acceptedfile) => {
            changeLoadState(true, true);
            postDocumento({ nombre: acceptedfile.name, base64: await blobToBase64(acceptedfile) }, updateLoadProgress)
                .then((response) => {
                    if (response.data) {
                        setTitulosArchivo((previousValue) => [ ...previousValue, response.data ]);
                        setIsLoadingElementList((previousValue) => [...previousValue, false]);
                        cogoToast.success('Documento subido!');
                        if (additionalActionOnUpload) {
                            additionalActionOnUpload();
                        }
                    } else {
                        // cogoToast.error(response.data);
                        cogoToast.error('No fue posible subir el documento');
                    }
                    changeLoadState(false, true);
                }).catch(() => {
                    changeLoadState(false, true);
                    cogoToast.error('No fue posible subir el documento');
                    // cogoToast.error(error);
                })
        })))
    });

    const onDocumentDelete = (archivoNombre: ArchivoNombre, deletingComplete: () => void) => {
        const changeDeleteState = (state: boolean) => {
            setIsDeleting(state);
            deletingComplete();
            changeBusyState(state);
        }
        changeDeleteState(true);
        deleteDocumento(archivoNombre._id)
            .then(() => {
                setTitulosArchivo((previousValues) => previousValues.filter((value) => value._id !== archivoNombre._id));
                changeDeleteState(false);
                cogoToast.success('Documento eliminado!');
                if (additionalActionOnDelete) {
                    additionalActionOnDelete();
                }
            }).catch(() => {
                changeDeleteState(false);
                cogoToast.error('No fue posible eliminar el documento');
            })
    }

    const downloadFile = (archivoNombre: ArchivoNombre, downloadComplete: () => void) => {
        const endDownload = () => {
            downloadComplete();
            changeLoadState(false);
        };
        changeLoadState(true);
        getDocumento(archivoNombre._id, updateLoadProgress)
            .then((response) => {
                if (response.data?.base64) {
                    downloadBlobFromBase64(response.data.base64, response.data.nombre);
                    cogoToast.success('Documento descargado');
                } else {
                    cogoToast.error('No fue posible obtener el documento');
                }
                endDownload();
            }).catch(() => {
                cogoToast.error('No fue posible obtener el documento');
                endDownload();
            })
    }

    const cardBodyHTML = (): ReactElement => {
        const uploadZone = (): ReactElement => {
            if (!isDeleting && !isDownloading) {
                if (isUploading) {
                    return (
                        <div className = "text-center">
                            Subiendo ... {loadBarProgress}% completado
                        </div>
                    );
                } else {
                    return (
                        <div {...getRootProps()} className = {classNames("dropzone", { disabled: isBusy })}>
                            <input {...getInputProps()}/>
                            <p className = "text-center">Click o arrastre un documento para agregar ...</p>
                        </div>
                    );
                }
            } else {
                return (
                    <div className = "text-center"><Spinner animation = "border"/></div>
                );
            }
        }
        const filesBody = (): ReactElement | undefined => {
            const fileElement = (archivoNombre: ArchivoNombre, index: number): ReactElement => {
                const editElementLoadingState = (state: boolean, isDeleting?: boolean) => {
                    setIsLoadingElementList((previousValues) => {
                        const newValues = [...previousValues];
                        newValues[index] = state;
                        return newValues;
                    });
                    if (isDeleting) {
                        setIsLoadingElementList((previousValues) => {
                            const newValues = [...previousValues];
                            newValues.splice(index, 1);
                            return newValues;
                        });
                    }
                };
                return (
                    <div className = "cards-header">
                        <div className = "body-subtitle"><h4>{archivoNombre.nombre}</h4></div>
                        {!isLoadingElementList[index] ?
                            <div className = "buttons-group">
                                <Button
                                    variant = "success"
                                    disabled = {isBusy}
                                    onClick = {() => {
                                        editElementLoadingState(true);
                                        downloadFile(archivoNombre, () => editElementLoadingState(false));
                                    }}
                                >
                                    {isBusy ? <Spinner animation = "border" size = "sm"/> : 'Descargar'}
                                </Button>
                                {!isEditable ? <></> :
                                    <Button
                                        variant = "danger"
                                        disabled = {isBusy}
                                        onClick = {() => {
                                            editElementLoadingState(true);
                                            onDocumentDelete(archivoNombre, () => editElementLoadingState(false, true))
                                        }}
                                    >
                                        {isBusy ? <Spinner animation = "border" size = "sm"/> : 'Eliminar'}
                                    </Button>
                                }
                            </div>
                        :
                            isDeleting ?
                                <div>
                                    Eliminando ... <Spinner animation = "border" size = "sm"/>
                                </div>
                            :
                                <div>
                                    Descargando ... {loadBarProgress}% completado
                                </div>
                        }
                    </div>
                )
            };
            if (isMultiple) {
                return (
                    <>
                        {titulosArchivo.map((tituloArchivo, tituloArchivoIndex) => (
                            <Card>
                                <Card.Body>
                                    {fileElement(tituloArchivo, tituloArchivoIndex)}
                                </Card.Body>
                            </Card>
                        ))}
                    </>
                )
            } else {
                const file = titulosArchivo[0];
                if (file) {
                    return fileElement(file, 0);
                }
            }
        }
        if (isMultiple) {
            return (
                <>
                    {titulosArchivo.length > 0 && filesBody()}
                    {isEditable && <Card.Body>{uploadZone()}</Card.Body>}
                </>
            );
        } else {
            return (
                <Card>
                    <Card.Body>
                        {titulosArchivo.length > 0 ? filesBody() : (isEditable && uploadZone())}
                    </Card.Body>
                </Card>
            );
        }
    }

    // useEffect(() => {
    //     const jwt = localStorage.getItem('jwt');
    //     if (jwt) {
    //         getDocumentosListado()
    //             .then((response) => {
    //                 if (response.data) {
    //                     setIsLoadingElementList(response.data.map(() => false));
    //                     setTitulosArchivo(response.data);
    //                 }
    //                 setIsInitialLoading(false);
    //             }).catch(() => {
    //                 // console.log(error)
    //                 setIsInitialLoading(false);
    //                 cogoToast.error('No fue posible obtener los documentos');
    //             })
    //     }
    // }, [code, code2]);

    const updateBarProgress = (newProgress: number) => setTimeout(() => setLoadBarProgress(newProgress), 50);

    useEffect(() => {
        if (!isUpdatingProgress) {
            setIsUpdatingProgress(true);
            let initialIndex = 0;
            if (loadBarProgress > 0) {
                for (let i = loadProgress.length - 1; i > 0; i = i - 1) {
                    if (loadProgress[i] === loadBarProgress) {
                        initialIndex = i;
                        break;
                    }
                }
            }
            for (let i = initialIndex; i < loadProgress.length; i++) {
                const difference = loadProgress[i] - loadBarProgress;
                if (difference > 0) {
                    if (difference > 1) {
                        for (let e = loadBarProgress + 1; e <= loadProgress[i]; e++) {
                            updateBarProgress(e);
                        }
                    } else {
                        updateBarProgress(loadProgress[i]);
                    }
                }
            }
            setIsUpdatingProgress(false);
        }
    }, [loadProgress]);

    if (!((!titulosArchivo || titulosArchivo.length < 1) && !isEditable)) {
        if (title) {
            return (
                <Card className = "cards-container">
                    <Card.Header className = "cards-header">
                        <div className = "body-subtitle">{title}</div>
                    </Card.Header>
                    {message &&
                        <Card>
                            <Card.Body>
                                <h5>{message}</h5>
                            </Card.Body>
                        </Card>
                    }
                    {cardBodyHTML()}
                </Card>
            );
        } else {
            return (
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <u><h6>{subtitle}</h6></u>
                                {cardBodyHTML()}  
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            );
        }
    } else {
        return (<></>)
        // return(
        //     <>
        //         {isInitialLoading && (
        //             <Card>
        //                 <Card.Body>
        //                     <div className = "text-center"><Spinner animation = "border"/></div>
        //                 </Card.Body>
        //             </Card>
        //         )}
        //     </>
        // );
    }
}

export default DocumentsManager;