import { UnidadEstrategicaNegocio } from "../../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../../models/optionsFilters/Select";


const useUENElectromecanico = (): SelectOption<UnidadEstrategicaNegocio>[] => {
    
    return [
        { label: 'MOTORES TRACCIÓN', value: { uen: 'MOTORES TRACCION' } },
        { label: 'BLOWERS', value: { uen: 'BLOWERS' } },
        { label: 'ALTERNADORES', value: { uen: 'ALTERNADORES' } }
    ];

}

export default useUENElectromecanico;