import { ModeloComponenteEvaluacionOrReparacionElements } from "../../../models/EvaluacionesReparaciones";
import { blower5GY19_U_LTableCalculations } from "../tablesCalculations/blowers";
import informeEvaluacion5GY19_U_LE from "../../evaluaciones/blowers/informe5GY19_U_LE";

const blowersElementsList: ModeloComponenteEvaluacionOrReparacionElements[] = [
    {
        modeloComponente: '5GY19L',
        getCalculations: blower5GY19_U_LTableCalculations,
        getInformeEvaluacion: informeEvaluacion5GY19_U_LE
    },
    {
        modeloComponente: '5GY19U',
        getCalculations: blower5GY19_U_LTableCalculations,
        getInformeEvaluacion: informeEvaluacion5GY19_U_LE
    }
];

export default blowersElementsList;