import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import { putRecepcionCodigoOP } from "../../api/RecepcionesApi";
import useFormState from "../../hooks/useFormState";
import { Recepcion, RecepcionForm } from "../../models/recepciones/Recepcion";
import { formatRecepcionForm } from "../../utils/recepciones";
import RecepcionFormComponent from "./RecepcionFormComponent";
// import { Archivo } from "../../models/Archivos";
// import UploadImagesComponent from "../files/UploadImagesComponent";
// import UploadDocumentComponent from "../files/UploadDocumentComponent";
// import { putImagenesRegistroRecepcion } from "../../api/DocumentosApi";
import GuiaDespachoRecepcion from "../files/GuiaDespachoRecepcion";
import RegistroRecepcion from "../files/RegistroRecepcion";
import InformeFallasRecepcion from "../files/InformeFallasRecepcion";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import NoConformidad from "../files/NoConformidad";
// import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";

interface RecepcionDetailsProps {
    recepcion: Recepcion,
    onRecepcionChange: (recepcion: Recepcion) => void,
    // images?: Archivo[],
    // onImagesChange: (images: Archivo[]) => void,
    ordenProceso: OrdenProceso,
    userPermission: boolean
}

const RecepcionDetails = ({
    recepcion,
    onRecepcionChange,
    // images,
    // onImagesChange,
    ordenProceso,
    userPermission
}: RecepcionDetailsProps): ReactElement => {

    // const history = useHistory();

    const formState = useFormState(formatRecepcionForm(recepcion, ordenProceso.codigoOP));

    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    }

    const changeBusyState = (state: boolean) => setIsBusy(state);

    const submitForm = (recepcionForm: RecepcionForm) => {
        if (ordenProceso) {
            putRecepcionCodigoOP(recepcionForm, ordenProceso.codigoOP)
                .then((response) => {
                    if (response.data.fechaRecepcion) {
                        formState.setSubmitting(false);
                        formState.setForm(recepcionForm);
                        setShow(false);
                        onRecepcionChange(response.data);
                        cogoToast.success('Recepción Editada');
                    } else {
                        // cogoToast.error(response.data);
                        cogoToast.error('No fue posible editar la recepción');
                        formState.setSubmitting(false);
                    }
                })
                .catch(() => {
                    // cogoToast.error(error.message);
                    cogoToast.error('No fue posible editar la recepción');
                    formState.setSubmitting(false);
                })
        }/* else if (_id) {
            putRecepcion(recepcionForm, _id)
                .then((response) => {
                    formState.setSubmitting(false);
                    formState.setForm(recepcionForm);
                    setShow(false);
                    onRecepcionChange(response.data);
                    cogoToast.success('Recepción Editada');
                })
                .catch((error) => {
                    cogoToast.error(error.message);
                    formState.setSubmitting(false);
                })
        }*/
    }

    /*
    const uploadImages = (files: Archivo[]) => {
        console.log(files);
        if (codigoOP) {
            putImagenesRegistroRecepcion(files, codigoOP)
                .then((response) => {
                    onImagesChange(response.data);
                    cogoToast.success('Registro añadido');
                })
                .catch((error) => {
                    cogoToast.error(error.message);
                })
        }
    }
    
    
    const onGuiaDespachoUpload = (file: Archivo): void => {
        postGuiaDespachoRecepcion(file, recepcion.numeroGuiaDespacho)
                .then((response) => {
                    onGuiaDespachoChange(response.data);
                    cogoToast.success('Guía de Despacho añadida');
                })
                .catch((error) => {
                    cogoToast.error(error.message);
                })
    }

    const onGuiaDespachoDelete = (): void => {
        if (recepcion.numeroGuiaDespacho) {
            deleteGuiaDespachoRecepcion(recepcion.numeroGuiaDespacho)
            .then(() => {
                onGuiaDespachoChange();
                cogoToast.success('Guía de Despacho eliminada');
            })
            .catch((error) => {
                cogoToast.error(error.message);
            });
        }
    }
    
    
    const removeImage = (imagePosition: number): void => {
        if (codigoOP && images) {
            images.splice(imagePosition, 1);
            putImagenesRegistroRecepcion(images, codigoOP)
                    .then((response) => {
                        onImagesChange(response.data);
                    })
                    .catch((error) => {
                        cogoToast.error(error.message);
                    })
        }
    }
    */

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        Recepción de componente
                    </div>
                    {userPermission &&
                        <div className = "buttons-group">
                            <Button onClick = {handleShow} disabled = {isBusy}>
                                {isBusy ? <Spinner animation = "border" size = "sm"/> : 'Editar'}
                            </Button>
                        </div>
                    }
                </Card.Header>
                <Card.Body>
                    <Row>
                        {/*recepcion.numeroGuiaDespacho &&
                            (<Col>
                                Código OP
                                <h3>{recepcion.ordenesProceso[0].ordenProceso}</h3>
                            </Col>)
                        */}
                        <Col>
                            Número guía de despacho
                            <h3>{recepcion.numeroGuiaDespacho}</h3>
                        </Col>
                        <Col>
                            Fecha de Recepción
                            <h3>{recepcion.fechaRecepcion}</h3>
                        </Col>
                        <Col>
                            Número de serie componente
                            {recepcion.ordenesProceso.map((op) => {
                                if (op.ordenProceso === ordenProceso.codigoOP) {
                                    return (
                                        <h3>{op.numeroSerieComponente}</h3>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col>
                            Diferencia Modelo componente
                            {recepcion.ordenesProceso.map((op) => {
                                if (op.ordenProceso === ordenProceso.codigoOP) {
                                    return (
                                        <h3>{`${ordenProceso.componente?.modelo}${op.subModeloComponente}`}</h3>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col>
                            Cliente
                            <h3>{recepcion.cliente}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {/* <Card className = "cards-container">
                <Card.Header className = "cards-body">
                    <div className = "body-subtitle">
                        Número de serie componente:
                    </div>
                    !codigoOP ?
                        <div className = "body-subtitle">
                            Números de serie componentes:
                        </div> :
                        <div className = "body-subtitle">
                            Número de serie componente:
                        </div>
                   
                </Card.Header>
                <Card.Body>
                    {recepcion.ordenesProceso.map((op) => {
                            if (op.ordenProceso === codigoOP) {
                                return(
                                    <Row>
                                        <h3>{op.numeroSerieComponente}</h3>
                                    </Row>
                                );
                            }
                            return null;
                        })
                    }
                    {codigoOP ?
                        recepcion.ordenesProceso.map((op) => {
                            if (op.ordenProceso === codigoOP) {
                                return(
                                    <Row>
                                        <h3>{op.numeroSerieComponente}</h3>
                                    </Row>
                                );
                            }
                            return null;
                        }) :
                        recepcion.ordenesProceso.map((op) => {
                            const currentOPDetails = (): void => {
                                history.push(`/recepciones/${recepcion._id}/${op.ordenProceso}`);
                            }
                            return (
                                <div className = "cards-body">
                                    <h3 className = "body-subtitle">{`N°: ${op.numeroSerieComponente}`}</h3>
                                    <h3 className = "body-subtitle">{`OP: ${op.ordenProceso}`}</h3>
                                    <Button className = "body-buttons" variant = "success" onClick = {currentOPDetails}>
                                        Ver OP
                                    </Button>
                                </div>
                            );
                        })
                   }
                </Card.Body>
            </Card> */}
            {recepcion.numeroGuiaDespacho && userPermission &&
                (<GuiaDespachoRecepcion
                    paramToCheck = {recepcion}
                    codigoOP = {ordenProceso.codigoOP}
                    userPermission = {userPermission}
                    isBusy = {isBusy}
                    changeBusyState = {changeBusyState}
                    guiaDespachoDoc = {recepcion.guiaDespachoDoc}
                />)
            }
            {recepcion.numeroGuiaDespacho && userPermission &&
                (<RegistroRecepcion
                    paramToCheck = {recepcion}
                    codigoOP = {ordenProceso.codigoOP}
                    userPermission = {userPermission}
                    isBusy = {isBusy}
                    changeBusyState = {changeBusyState}
                    registroDoc = {recepcion.registroDoc}
                />)
            }
            <InformeFallasRecepcion
                codigoOP = {ordenProceso.codigoOP}
                userPermission = {userPermission}
                isBusy = {isBusy}
                changeBusyState = {changeBusyState}
                informeFallasRecepcionDoc = {ordenProceso.informeFallasRecepcionDoc}
            />
            {ordenProceso.proceso && (ordenProceso.proceso !== 'OP') &&
                (<NoConformidad
                    codigoOP = {ordenProceso.codigoOP}
                    userPermission = {userPermission}
                    isBusy = {isBusy}
                    changeBusyState = {changeBusyState}
                    noConformidadRecepcionDoc = {ordenProceso.noConformidadRecepcionDoc}
                />)
            }
            {/*
            <UploadImagesComponent
                images = {images}
                removeImage = {removeImage}
                uploadImages = {uploadImages}
                rolPermission = {rolPermission}
            />
            codigoOP &&
                (<UploadImagesComponent
                    images = {images}
                    removeImage = {removeImage}
                    uploadImages = {uploadImages}
                    rolPermission = {rolPermission}
                />)
            */}
            {show && ordenProceso.componente && (
                <Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar Recepción</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <RecepcionFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            modeloComponente = {ordenProceso.componente.modelo}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default RecepcionDetails;