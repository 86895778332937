import { CentroCostoProceso } from "../../models/estatusGeneral/CentroCostoProceso";
import { SelectOption } from "../../models/optionsFilters/Select";


const useCentroCostoProceso = (): SelectOption<CentroCostoProceso>[] => {

    return [
        { label: 'ELECTROMECÁNICO', value: { centroCosto: 'ELECTROMECANICO' } },
        { label: 'LABORATORIO', value: { centroCosto: 'LABORATORIO' } },
        { label: 'MECÁNICO', value: { centroCosto: 'MECANICO' } },
        { label: 'POTENCIA', value: { centroCosto: 'POTENCIA' } },
        { label: 'INGENIERIA', value: { centroCosto: 'INGENIERIA' } },
        { label: 'SERVICIO TERRENO', value: { centroCosto: 'SERVICIO TERRENO' } },
        { label: 'VENTA', value: { centroCosto: 'VENTA' } }
    ];
}

export default useCentroCostoProceso;