import { Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { ComponenteForm } from "../../models/componentes/Componente";
import NumeroGalpon from "../../models/componentes/NumeroGalpon";
import { SelectOption } from "../../models/optionsFilters/Select";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";
import { ReactElement } from "react";
// import { Typeahead } from 'react-bootstrap-typeahead';
import FormButtons from "../form/buttons/FormButtons";
import TipoComponente from "../../models/componentes/TipoComponente";

/*
interface typeaheadObject {
    customOption: boolean,
    label: string,
    id: string
}
*/

interface ComponenteFormComponentProps {
    formState: FormState<ComponenteForm>,
    submitForm: (componenteForm: ComponenteForm) => void,
    onDelete?: () => void,
    onCancel: () => void,
    tiposComponentesOptions: SelectOption<TipoComponente>[],
    numeroGalponOptions: SelectOption<NumeroGalpon>[],
}

const ComponenteFormComponent = ({
    formState,
    submitForm,
    onDelete,
    onCancel,
    tiposComponentesOptions,
    numeroGalponOptions,
}: ComponenteFormComponentProps): ReactElement => {
    const {
        value: componenteForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    const isFormInvalid =
      !componenteForm.descripcion ||
      !componenteForm.modelo ||
      !componenteForm.tipoComponente;

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(componenteForm);
    };

    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);
    
    const isEditting = (): boolean => {
        if (onDelete === undefined) {
            return isSubmitting;
        } else {
            return true;
        }
    }

    return (
        <>
            <Form>
                <Row>
                    <Input 
                        title = "Descripcion"
                        name = "descripcion"
                        value = {componenteForm.descripcion}
                        onChangeWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && !componenteForm.descripcion}
                        invalidText = "Ingrese descripción del componente"
                        disabled = {isSubmitting}
                        placeholder = "Obligatorio"
                    />
                </Row>
                <Row>
                    <Input 
                        title = "Modelo"
                        name = "modelo"
                        value = {componenteForm.modelo}
                        onChangeWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && !componenteForm.modelo}
                        invalidText = "Ingrese modelo del componente"
                        disabled = {isEditting()}
                        placeholder = "Obligatorio"
                    />
                </Row>
                {/* <Row>
                    <Input 
                        title = "Número Parte"
                        name = "numeroParte"
                        value = {componenteForm.numeroParte}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row> */}
                <Row>
                    <Select
                        title = "Tipo"
                        name = "tipoComponente"
                        value = {componenteForm.tipoComponente}
                        options = {tiposComponentesOptions}
                        onSelectWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                        invalid = {areValidationsEnabled && !componenteForm.tipoComponente}
                        placeholder = "Obligatorio"
                        // invalidText = "Ingrese tipo del componente"
                    />
                </Row>
                <Row>
                    <Select
                        title = "Galpón"
                        name = "galpon"
                        value = {componenteForm.galpon}
                        options = {numeroGalponOptions}
                        onSelectWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                        onClearWithName = {(name): void => handleFieldChange(name, undefined)}
                    />
                </Row>
                {/* <Row>
                    <div className = "form-group">
                        Tipo Componente
                    </div>
                    <Typeahead
                        allowNew
                        clearButton
                        ignoreDiacritics
                        onChange = {(selected: Array<typeaheadObject|string>):void => {
                            const input = selected.pop();
                            if (typeof input === 'string') {
                                handleFieldChange("tipoComponente", input);
                            } else if (input) {
                                handleFieldChange("tipoComponente", input.label);
                            }
                        }}
                        defaultInputValue = {componenteForm.tipoComponente}
                        options = {tiposComponentesOptions}
                        disabled = {isSubmitting}
                    />
                    <small>Click en la opción nueva si escribe nuevo tipo</small>
                </Row> */}
                {isFormInvalid && areValidationsEnabled && (
                <div className="text-danger text-center mb-3">
                    <small>Hay campos obligatorios sin ingresar</small>
                </div>
                )}
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                />
            </Form>
        </>
    )
}

export default ComponenteFormComponent;