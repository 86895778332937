import NumeroGalpon from "../../models/componentes/NumeroGalpon";
import { SelectOption } from "../../models/optionsFilters/Select";

const useNumeroGalponOptions = (): SelectOption<NumeroGalpon>[] => [
    {label: '1', value: {numero: "1"}},
    {label: '2', value: {numero: "2"}},
    {label: '3', value: {numero: "3"}},
    {label: '4', value: {numero: "4"}}
]

export default useNumeroGalponOptions;