import { ReactElement, useEffect, useState } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { CentroCostoProceso } from "../../models/estatusGeneral/CentroCostoProceso";
import { OrdenProcesoForm } from "../../models/estatusGeneral/OrdenProceso";
import TipoOrdenProceso from "../../models/estatusGeneral/TipoProceso";
import TipoProceso from "../../models/estatusGeneral/TipoOrdenProceso";
import { UnidadEstrategicaNegocio } from "../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../models/optionsFilters/Select";
import FormButtons from "../form/buttons/FormButtons";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";
import EstadoOrdenProceso from "../../models/estatusGeneral/EstadoOrdenProceso";

interface OrdenProcesoFormComponentProps {
    formState: FormState<OrdenProcesoForm>,
    submitForm: (ordenProcesoForm: OrdenProcesoForm, setIsEmailSending: (state: boolean) => void) => void,
    onDelete?: () => void,
    onCancel: () => void,
    tipoOrdenProcesoOptions: SelectOption<TipoOrdenProceso>[],
    tipoProcesoOptions: SelectOption<TipoProceso>[],
    centroCostoProcesoOptions: SelectOption<CentroCostoProceso>[],
    uenOptions?: SelectOption<UnidadEstrategicaNegocio>[],
    onUenOptionsChange: (uens?: SelectOption<UnidadEstrategicaNegocio>[]) => void,
    centroCostoAnterior: string,
    onCentroCostoAnteriorChange: (cc: string) => void
    uenElectromecanicoOptions: SelectOption<UnidadEstrategicaNegocio>[],
    uenPotenciaOptions: SelectOption<UnidadEstrategicaNegocio>[],
    uenLaboratorioOptions: SelectOption<UnidadEstrategicaNegocio>[],
    uenMecanicoOptions: SelectOption<UnidadEstrategicaNegocio>[],
    estadoOrdenProcesoOptions?: SelectOption<EstadoOrdenProceso>[],
    isCreating?: boolean,
    currentEstado?: string
}

const OrdenProcesoFormComponent = ({
    formState,
    submitForm,
    onDelete,
    onCancel,
    tipoOrdenProcesoOptions,
    tipoProcesoOptions,
    centroCostoProcesoOptions,
    uenOptions,
    onUenOptionsChange,
    centroCostoAnterior,
    onCentroCostoAnteriorChange,
    uenElectromecanicoOptions,
    uenPotenciaOptions,
    uenLaboratorioOptions,
    uenMecanicoOptions,
    estadoOrdenProcesoOptions,
    isCreating,
    currentEstado
}: OrdenProcesoFormComponentProps): ReactElement => {
    const {
        value: ordenProcesoForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete
    } = formState;

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(ordenProcesoForm, setIsEmailSending);
    }

    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);

    const [ isEmailSending, setIsEmailSending ] = useState<boolean>(false);

    useEffect(() => {
        if (centroCostoAnterior !== 'ELECTROMECANICO' && ordenProcesoForm.centroCosto?.value.centroCosto === 'ELECTROMECANICO') {
            if (centroCostoAnterior !== 'INICIAL') {
                handleFieldChange('uen', null);
            }
            onCentroCostoAnteriorChange('ELECTROMECANICO');
            onUenOptionsChange(uenElectromecanicoOptions);
        } else if (centroCostoAnterior !== 'POTENCIA' && ordenProcesoForm.centroCosto?.value.centroCosto === 'POTENCIA') {
            if (centroCostoAnterior !== 'INICIAL') {
                handleFieldChange('uen', null);
            }
            onCentroCostoAnteriorChange('POTENCIA');
            onUenOptionsChange(uenPotenciaOptions);
        } else if (centroCostoAnterior !== 'LABORATORIO' && ordenProcesoForm.centroCosto?.value.centroCosto === 'LABORATORIO') {
            if (centroCostoAnterior !== 'INICIAL') {
                handleFieldChange('uen', null);
            }
            onCentroCostoAnteriorChange('LABORATORIO');
            onUenOptionsChange(uenLaboratorioOptions);
        } else if (centroCostoAnterior !== 'MECANICO' && ordenProcesoForm.centroCosto?.value.centroCosto === 'MECANICO') {
            if (centroCostoAnterior !== 'INICIAL') {
                handleFieldChange('uen', null);
            }
            onCentroCostoAnteriorChange('MECANICO');
            onUenOptionsChange(uenMecanicoOptions);
        } else if (ordenProcesoForm.centroCosto?.value.centroCosto === 'SERVICIO TERRENO' ||
                    ordenProcesoForm.centroCosto?.value.centroCosto === 'VENTA' ||
                    ordenProcesoForm.centroCosto?.value.centroCosto === 'INGENIERIA') {
            if (centroCostoAnterior !== '') {
                if (centroCostoAnterior !== 'INICIAL') {
                    handleFieldChange('uen', null);
                }
                onCentroCostoAnteriorChange('');
                onUenOptionsChange(undefined);
            }
        }
    }, [
        ordenProcesoForm,
        uenOptions,
        uenElectromecanicoOptions,
        uenPotenciaOptions,
        uenLaboratorioOptions,
        centroCostoAnterior,
        onCentroCostoAnteriorChange,
        onUenOptionsChange
    ]);

    const isEditting = (): boolean => {
        if (isCreating) {
            return isSubmitting;
        } else {
            return true;
        }
    }

    const isFormInvalid = !ordenProcesoForm.cliente;

    return (
        <>
            <Form>
                    <Row>
                        <Input
                            title = "Cliente"
                            name = "cliente"
                            value = {ordenProcesoForm.cliente}
                            onChangeWithName = {handleFieldChange}
                            invalid = {areValidationsEnabled && !ordenProcesoForm.cliente}
                            invalidText = "Ingrese nombre de cliente"
                            disabled = {isEditting()}
                            placeholder = "Obligatorio"
                        />
                    </Row>
                    <Row>
                        <Select
                            title = "Tipo"
                            name = "tipoOP"
                            value = {ordenProcesoForm.tipoOP}
                            options = {tipoOrdenProcesoOptions}
                            onSelectWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                        />
                    </Row>
                    <Row>
                        <Select
                            title = "Proceso (Garantía)"
                            name = "proceso"
                            value = {ordenProcesoForm.proceso}
                            options = {tipoProcesoOptions}
                            onSelectWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                        />
                    </Row>
                    <Row>
                        <Select
                            title = "Centro Costo"
                            name = "centroCosto"
                            value = {ordenProcesoForm.centroCosto}
                            options = {centroCostoProcesoOptions}
                            onSelectWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                            onClearWithName = {(name): void => {
                                handleFieldChange('uen', null);
                                handleFieldChange(name, undefined);
                                onUenOptionsChange(undefined);
                            }}
                        />
                    </Row>
                    <Row>
                        <Select
                            title = "Área"
                            name = "uen"
                            value = {ordenProcesoForm.uen}
                            options = {uenOptions ? uenOptions :
                                [{ label: '', value: { uen: '' } }]
                            }
                            onSelectWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                            onClearWithName = {(name): void => handleFieldChange(name, undefined)}
                        />
                    </Row>
                    {!isCreating &&
                        <>
                            <Row>
                                <Select
                                    title = "Estado"
                                    name = "estado"
                                    value = {ordenProcesoForm.estado}
                                    options = {estadoOrdenProcesoOptions ? estadoOrdenProcesoOptions : []}
                                    onSelectWithName = {handleFieldChange}
                                    hideSelectedOptions = {true}
                                    disabled = {estadoOrdenProcesoOptions ? false : true}
                                />
                            </Row>
                            {(ordenProcesoForm.estado?.value.estado === 'APROBADA' && currentEstado === 'PRESUPUESTADA') &&
                                <div className = "text-center">
                                    <small>
                                        {isEmailSending ?
                                            <>Enviando correo ... <Spinner animation="border" size="sm"/></>
                                        :
                                            'Al presionar el botón guardar se enviará un correo reportando el cambio de estado para su posterior reparación'
                                        }
                                    </small>
                                </div>
                            }
                        </>
                    }
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting || isEmailSending}
                    isLoading={isSubmitting || isEmailSending}
                    isFormInvalid = {isFormInvalid}
                    areValidationsEnabled = {areValidationsEnabled}
                />
            </Form>
        </>
    )
}

export default OrdenProcesoFormComponent;