import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { getUser } from "../../api/UsersApi";
import UserDetails from "../../components/users/UserDetails";
import AuthContext from "../../contexts/AuthContext";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { User } from "../../models/users/Users";

const UserPage = (): ReactElement => {

    const [ user, setUser ] = useState<User>();
    const { email, currentUser } = useParams<{ email: string, currentUser: string }>();
    const breadcrumb = useBreadcrumb();
    const { logout, userEmail } = useContext(AuthContext);

    const rolPermissionEditButton = (): boolean => {
        if (!user?.roles?.includes('ADMINISTRADOR') ||
            user?.email === userEmail ||
            user?.estado === 'DESHABILITADO' ||
            userEmail === 'adminERPElectrans') {
            return true;
        } else {
            return false;
        }
    }

    const rolPermissionEditFields = (isSubmitting: boolean, field?: string): boolean => {
        const isSubmittingWithPermission = !isSubmitting;
        if (currentUser) {
            if (user?.roles?.includes('ADMINISTRADOR')) {
                if (userEmail === 'adminERPElectrans') {
                    if (field === 'roles' || field === 'estado' || field === 'eliminar') {
                        return false;
                    } else {
                        return isSubmittingWithPermission;
                    }
                } else {
                    return isSubmittingWithPermission;
                }
            } else {
                if (field === 'roles' || field === 'estado' || field === 'nombre' || field === 'apellidos') {
                    return false;
                } else {
                    return isSubmittingWithPermission;
                }
            }
        } else {
            if (!user?.roles?.includes('ADMINISTRADOR') ||
                (user.estado === 'DESHABILITADO' && field === 'estado') ||
                user.email === userEmail ||
                userEmail === 'adminERPElectrans') {
                return isSubmittingWithPermission;
            } else {
                return false;
            }
        }
    }

    useEffect(() => {
        getUser(email)
            .then((response) => {
                setUser(response.data);
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener el usuario');
            })
    }, [email]);

    useEffect(() => {
        if (user) {
            if (!currentUser) {
                breadcrumb.setItems([
                    {
                        label: 'Usuarios',
                        href: '/users',
                    },
                    {
                        label: `${user.nombre} ${user.apellidos}`,
                        inactive: true
                    }
                ]);
            } else {
                breadcrumb.setItems([
                    {
                        label: 'Perfil usuario',
                        inactive: true
                    }
                ]);
            }
        }
    }, [user, currentUser]);

    const handleUserChange = (user: User): void => {
        setUser(user);
    }

    if (user) {
        return (
            <UserDetails
                user = {user}
                onUserChange = {handleUserChange}
                logout = {logout}
                rolPermissionEditButton = {rolPermissionEditButton()}
                userEmail = {userEmail}
                rolPermissionEditFields = {rolPermissionEditFields}
            />
        );
    } else {
        return (
            <Card>
                <Card.Body className = "text-center">
                    <Spinner animation = "border" />
                </Card.Body>
            </Card>
        );
    }
}

export default UserPage;