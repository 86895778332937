import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { postComponente } from "../../api/ComponentesApi";
import useFormState from "../../hooks/useFormState";
import { ComponenteForm } from "../../models/componentes/Componente";
import NumeroGalpon from "../../models/componentes/NumeroGalpon";
import TipoComponente from '../../models/componentes/TipoComponente';
import { SelectOption } from "../../models/optionsFilters/Select";
import ComponenteFormComponent from "./ComponenteFormComponent";
import { formatComponenteRequestBody, getEmptyComponenteForm } from "../../utils/componentes";

interface NuevoComponenteProps {
    tiposComponentesOptions: SelectOption<TipoComponente>[],
    numeroGalponOptions: SelectOption<NumeroGalpon>[],
    userPermission: boolean
}

const NuevoComponente = ({ tiposComponentesOptions, numeroGalponOptions, userPermission }: NuevoComponenteProps): ReactElement => {

    const formState = useFormState(getEmptyComponenteForm());
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        formState.reset();
    }
    const handleShow = () => setShow(true);

    // const tiposComponentesOptionsStrings = tiposComponentesStrings(tiposComponentesOptions);

    const submitForm = (componenteForm: ComponenteForm) => {
        postComponente(formatComponenteRequestBody(componenteForm))
            .then((response) => {
                if (response.data.modelo) {
                    history.push(`/componentes/${response.data.modelo}/descripcion/${response.data.descripcion}`);
                    cogoToast.success('Componente creado');
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible crear el componente');
                }
                formState.setSubmitting(false);
            })
            .catch(() => {
                cogoToast.error('No fue posible crear el componente');
                formState.setSubmitting(false);
            })
    };

    return (
        <>
            {userPermission &&
                <div className = "filters-buttons">
                    <Button onClick = {handleShow}>
                        Nuevo Componente
                    </Button>
                </div>
            }
            {show && (
                <Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className="text-center">
                            <Modal.Title>Nuevo Componente</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <ComponenteFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            tiposComponentesOptions = {tiposComponentesOptions}
                            numeroGalponOptions = {numeroGalponOptions}
                            onCancel = {handleClose}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default NuevoComponente;