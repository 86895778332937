import axios, { AxiosPromise } from "axios";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { Reparacion, ReparacionRequestBody } from "../models/Reparaciones";

export function deleteReparacion(requestBody: string): AxiosPromise<OrdenProceso> {
    return axios.delete(`api/reparaciones/ordenProceso/${requestBody}`);
}

export function postReparacion(requestBody: ReparacionRequestBody, codigoOP: string): AxiosPromise<OrdenProceso> {
    return axios.post(`api/reparaciones/ordenProceso/${codigoOP}`, requestBody);
}

export function getReparacion(codigoOP: string): AxiosPromise<Reparacion> {
    return axios.get(`api/reparaciones/ordenProceso/${codigoOP}`)
}

export function putReparacion(requestBody: ReparacionRequestBody, codigoOP: string): AxiosPromise<Reparacion> {
    return axios.put(`api/reparaciones/ordenProceso/${codigoOP}`, requestBody);
}