import TipoOrdenProceso from "../../models/estatusGeneral/TipoProceso";
import { SelectOption } from "../../models/optionsFilters/Select";


const useTipoOrdenProceso = (): SelectOption<TipoOrdenProceso>[] => {

    return [
        { label: 'PROCESO', value: {tipo: 'PROCESO'} },
        { label: 'VENTA', value: {tipo: 'VENTA'} },
        { label: 'INTERCAMBIO', value: {tipo: 'INTERCAMBIO'} },
    ];
}

export default useTipoOrdenProceso;