import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { putEntrega } from "../../api/EntregasApi";
import useFormState from "../../hooks/useFormState";
import { Entrega, EntregaForm } from "../../models/Entregas";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { formatEntregaForm, formatEntregaRequestBody } from "../../utils/entregas";
import GuiaDespachoEntrega from "../files/GuiaDespachoEntrega";
import EntregaFormComponent from "./EntregaFormComponent";

interface EntregaDetailsProps {
    entrega: Entrega,
    changeEntrega: (entrega: Entrega) => void,
    ordenProceso: OrdenProceso,
    userPermission: boolean
}

const EntregaDetails = ({ entrega, userPermission, ordenProceso, changeEntrega }: EntregaDetailsProps): ReactElement => {

    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const formState = useFormState(formatEntregaForm(entrega));
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };

    
    const submitForm = (entregaForm: EntregaForm) => {
        putEntrega(formatEntregaRequestBody(entregaForm, ordenProceso.codigoOP, ordenProceso.cliente))
            .then((response) => {
                if (response.data.fechaEntrega) {
                    formState.setSubmitting(false);
                    formState.setForm(entregaForm);
                    setShow(false);
                    changeEntrega(response.data);
                    cogoToast.success('Entrega de componente editada');
                } else {
                    cogoToast.error(response.data);
                    cogoToast.error('No fue posible editar la entrega del componente');
                    formState.setSubmitting(false);
                }
            })
            .catch((error) => {
                cogoToast.error(error);
                cogoToast.error('No fue posible editar la entrega del componente');
            })
    }


    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        Entrega de componente
                    </div>
                    {userPermission &&
                        <div className = "buttons-group">
                            <Button onClick = {handleShow} disabled = {isBusy}>
                                {isBusy ? <Spinner animation = "border" size = "sm"/> : 'Editar'}
                            </Button>
                        </div>
                    }
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            Fecha de entrega
                            <h3>{entrega.fechaEntrega}</h3>
                        </Col>
                        <Col>
                            Número guía de despacho
                            <h3>{entrega.numeroGuiaDespacho}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {userPermission &&
                <GuiaDespachoEntrega
                    paramToCheck = {entrega}
                    codigoOP = {ordenProceso.codigoOP}
                    userPermission = {userPermission}
                    isBusy = {isBusy}
                    changeBusyState = {(state: boolean) => setIsBusy(state)}
                    guiaDespachoDoc = {entrega.guiaDespachoDoc}
                />
            }
            {show &&
                // ordenProceso.reparacion?.fechaFinal &&
                ordenProceso.recepcion?.fechaRecepcion && 
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = 'text-center'>
                            <Modal.Title>Editar Entrega</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <EntregaFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            minDate = {ordenProceso.reparacion?.fechaFinal || ordenProceso.evaluacion?.fechaFinal || ordenProceso.recepcion?.fechaRecepcion}
                        />
                    </Modal.Body>
                </Modal>)
            }
        </>
    )
}

export default EntregaDetails;