import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { getEvaluacion } from "../api/EvaluacionesApi";
import { getOrdenProceso } from "../api/OrdenesProcesoApi";
import EvaluacionDetails from "../components/evaluaciones/EvaluacionDetails";
import AuthContext from "../contexts/AuthContext";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { Evaluacion } from "../models/Evaluaciones";
import { getUserPermissionFunction } from "../utils/users";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../models/EvaluacionesReparaciones";
import DetallesEvaluacionReparacionApi from "../api/EvaluacionesReparacionesApi";

const EvaluacionPage = (): ReactElement => {

    const [ evaluacion, setEvaluacion ] = useState<Evaluacion>();
    const [ evaulacionReparacionComponenteResumen, setEvaulacionReparacionComponenteResumen ] = useState<EvaluacionOrReparacionComponenteResumenEtapa[]>();
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const { codigoOP } = useParams<{ codigoOP: string }>();
    const { userRoles, userEmail, userName } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const detallesApi = new DetallesEvaluacionReparacionApi(codigoOP);

    const changeEvaluacion = (evaluacion: Evaluacion) => {
        setEvaluacion(evaluacion);
    }

    useEffect(() => {
        getEvaluacion(codigoOP)
            .then((response) => {
                if (response.data.fechaInicial) {
                    setEvaluacion(response.data);
                    getOrdenProceso(codigoOP)
                        .then((response) => {
                            if (response.data.codigoOP) {
                                setOrdenProceso(response.data);
                                detallesApi.getEvaluacionOrReparacionComponenteResumen()
                                    .then((response) => {
                                        if (response.data) {
                                            setEvaulacionReparacionComponenteResumen(response.data);
                                        }
                                    })
                                    .catch(() => {
                                        cogoToast.error('No fue posible obtener el detalle de la evaluación')
                                    })
                            } else {
                                // cogoToast.error(response.data);
                                cogoToast.error('No fue posible obtener la orden de proceso asociada al componente');
                            }
                        })
                        .catch(() => {
                            // cogoToast.error(error);
                            cogoToast.error('No fue posible obtener la orden de proceso asociada al componente');
                        });
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible obtener la evaluación del componente');
                }
                setIsLoading(false);
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener la evaluación del componente');
            });
    }, [codigoOP]);

    useEffect(() => {
        if (evaluacion) {
            breadcrumb.setItems([
                {
                    label: 'Estatus General',
                    href: '/ordenesProceso'
                },
                {
                    label: codigoOP,
                    href: `/ordenesProceso/${codigoOP}`
                },
                {
                    label: 'Evaluación',
                    inactive: true
                }
            ]);
        }
    }, [evaluacion])

    if (!evaluacion || !ordenProceso) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border" />
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">No fue posible cargar la evaluación del componente</p>;
        }
    } else {
        return (
            <>
                <EvaluacionDetails
                    evaluacion = {evaluacion}
                    changeEvaluacion = {changeEvaluacion}
                    userPermissionFunction = {getUserPermissionFunction(userRoles, userEmail)}
                    ordenProceso = {ordenProceso}
                    evaulacionComponenteResumenEtapas = {evaulacionReparacionComponenteResumen}
                    userData = {{ userName: userName, userEmail: userEmail, userRoles: userRoles }}
                    detallesApi = {detallesApi}
                    // changeEvaulacionComponenteResumenResponse = {changeEvaulacionComponenteResumenResponse}
                />
            </>
        );
    }
}

export default EvaluacionPage;