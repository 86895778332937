import { ReactElement } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { SelectGroup, SelectOption } from '../../../models/optionsFilters/Select';
import './select.scss';

interface FormSelectProps<T> {
  formGroupClassName?: string;
  name?: string;
  title?: string;
  value?: SelectOption<T> | SelectOption<T>[];
  options: SelectOption<T>[] | SelectGroup<T>[];
  onSelectWithName?: (name: string, selected: SelectOption<T>) => void;
  onSelectArrayWithName?: (name: string, selected: SelectOption<T>[]) => void;
  onSelect?: (selected: SelectOption<T>) => void;
  onClear?: () => void;
  onClearWithName?: (name: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  horizontal?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  helpText?: string;
  helpTextColor?: string;
  invalidText?: string;
  invalid?: boolean;
  isClearable?: boolean;
  defaultValue?: SelectOption<T>;
  isMulti?: boolean;
  hideSelectedOptions?: boolean;
  closeMenuOnSelect?: boolean;
}

const Select = <T,>({
  formGroupClassName,
  disabled,
  invalidText,
  helpText,
  helpTextColor,
  horizontal,
  invalid,
  isClearable,
  name,
  onBlur,
  onFocus,
  onSelect,
  onSelectWithName,
  onSelectArrayWithName,
  onClear,
  onClearWithName,
  placeholder,
  tabIndex,
  title,
  value,
  options,
  defaultValue,
  isMulti,
  hideSelectedOptions,
  closeMenuOnSelect
}: FormSelectProps<T>): ReactElement => {

  const formGroupClassNames = classNames('form-group', formGroupClassName, {
    row: horizontal && title,
  });

  const inputWrapperClassNames = classNames({
    'col-8': horizontal && title,
  });

  let label;
  if (title) {
    const labelClassNames = classNames({
      'col-4 col-form-label': horizontal && title,
    });

    label = (
      <label htmlFor={name} className={labelClassNames}>
        {title}
      </label>
    );
  }

  // @ts-ignore
  const handleChange = (selected?: ValueType<SelectOption<T>> | ValueType<SelectOption<T>>[]): void => {
    if (selected) {
      if (onSelect) onSelect(selected as SelectOption<T>);
      if (onSelectWithName && name) onSelectWithName(name, selected as SelectOption<T>);
      if (onSelectArrayWithName && name) onSelectArrayWithName(name, selected as SelectOption<T>[]);
    } else {
      if (onClear) onClear();
      if (onClearWithName && name) onClearWithName(name);
    }
  };

  // @ts-ignore
  const styles: Partial<Styles> = {
    // @ts-ignore
    control: (base) => ({
      ...base,
      borderColor: invalid ? '#dc3545' : base.borderColor,
    }),
  };

  return (
    <div className={formGroupClassNames}>
      {label}
      <div className={inputWrapperClassNames}>
        <ReactSelect
          // className="reactselect"
          // classNamePrefix="select"
          name={name}
          placeholder={placeholder}
          isClearable={!!(onClear || onClearWithName)}
          value={value}
          options={options}
          onChange={handleChange}
          isDisabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          tabIndex={tabIndex?.toString()}
          isMulti = {isMulti}
          closeMenuOnSelect = {closeMenuOnSelect}
          hideSelectedOptions = {hideSelectedOptions}
          styles={styles}
        />
        {helpText && <small className={`text-${helpTextColor}`}>{helpText}</small>}
        {invalid && invalidText && <small style={{ color: '#dc3545' }}>{invalidText}</small>}
      </div>
    </div>
  );
};

export default Select;
