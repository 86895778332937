import { CentroCostoProceso } from "../models/estatusGeneral/CentroCostoProceso";
import { OrdenesProcesoFilters, OrdenProceso, OrdenProcesoForm, OrdenProcesoRequestBody, UpdateOrdenesProcesoEstado } from "../models/estatusGeneral/OrdenProceso";
import TipoOrdenProceso from "../models/estatusGeneral/TipoProceso";
import TipoProceso from "../models/estatusGeneral/TipoOrdenProceso";
import { UnidadEstrategicaNegocio } from "../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../models/optionsFilters/Select";
import { DetallesOPForm } from "../models/estatusGeneral/DetallesOP";
import { BodyCardElement } from "../models/BodyCardElement";
// import EstadoOrdenProceso from "../models/estatusGeneral/EstadoOrdenProceso";

export const getEmptyOrdenesProcesoFilters = (module?: string): OrdenesProcesoFilters => {
    let filters;
    filters = {
        codigoOP: '',
        cliente: '',
        descripcionComponente: '',
        numeroSerieComponente: '',
        // numeroParteComponente: '',
        fechaRecepcionInicio: '',
        fechaRecepcionFinal: '',
        fechaEntregaInicio: '',
        fechaEntregaFinal: '',
        // estado: '',
        tipoOP: '',
        proceso: '',
        page: 1
    }
    if (module === 'apertura') {
        filters = { ...filters, estados: ['NUEVA'], isAperturaOPFilters: true };
    } else if (module === 'qr') {
        filters = { ...filters, isQRFilters: true };
    }
    return filters;
};

export const getEmptyOrdenProcesoForm = (): OrdenProcesoForm => ({
    tipoOP: { label: 'PROCESO', value: { tipo: 'PROCESO' } },
    proceso: { label: 'OP', value: { tipo: 'OP' } },
    cliente: ''
})

export const formatOrdenProcesoRequestBody = (ordenProcesoForm: OrdenProcesoForm): OrdenProcesoRequestBody => ({
    tipoOP: ordenProcesoForm.tipoOP?.value.tipo,
    proceso: ordenProcesoForm.proceso?.value.tipo,
    centroCosto: ordenProcesoForm.centroCosto?.value.centroCosto,
    uen: ordenProcesoForm.uen?.value.uen,
    cliente: ordenProcesoForm.cliente,
})

export const formatOrdenProceso = ( ordenProceso: OrdenProceso, ordenProcesoForm?: OrdenProcesoForm, detallesOPForm?: DetallesOPForm, observaciones?: string): OrdenProceso => {

    let interfaceResponse = { ...ordenProceso }
    if (ordenProcesoForm) {
        interfaceResponse = {
            ...interfaceResponse,
            proceso: ordenProcesoForm.proceso?.value.tipo,
            tipoOP: ordenProcesoForm.tipoOP?.value.tipo,
            centroCosto: ordenProcesoForm.centroCosto?.value.centroCosto,
            uen: ordenProcesoForm.uen?.value.uen,
            estado: ordenProcesoForm.estado?.value.estado
        }
    }
    if (detallesOPForm) {
        interfaceResponse = {
            ...interfaceResponse,
            ...detallesOPForm,
            ordenCompra: { codigo: detallesOPForm.ordenCompraCodigo, item: detallesOPForm.ordenCompraItems }
        };
    }
    if (observaciones) {
        interfaceResponse = { ...interfaceResponse, observaciones: observaciones };
    }
    return interfaceResponse;

    /*
    return {
        ...ordenProceso,
        
        tipoOP: ordenProcesoForm?.tipoOP?.value.tipo ? ordenProcesoForm?.tipoOP?.value.tipo : ordenProceso.tipoOP ? ordenProceso.tipoOP : '',
        proceso: ordenProcesoForm?.proceso?.value.tipo ? ordenProcesoForm?.proceso?.value.tipo : ordenProceso.proceso ? ordenProceso.proceso : '',
        centroCosto: ordenProcesoForm?.centroCosto?.value.centroCosto ? ordenProcesoForm?.centroCosto?.value.centroCosto : ordenProceso.centroCosto ? ordenProceso.centroCosto : '',
        uen: ordenProcesoForm?.uen?.value.uen ? ordenProcesoForm?.uen?.value.uen : ordenProceso.uen ? ordenProceso.uen : '',
        estado: ordenProcesoForm?.estado?.value.estado ? ordenProcesoForm?.estado?.value.estado : ordenProceso.estado ? ordenProceso.estado : '',

        ordenTrabajoCliente: detallesOPForm?.ordenTrabajoCliente ? detallesOPForm.ordenTrabajoCliente : '',
        codigoSap: detallesOPForm?.codigoSap ? detallesOPForm.codigoSap : '',
        codigoSolped: detallesOPForm?.codigoSolped ? detallesOPForm?.codigoSolped : '',
        ordenCompra: {
            codigo: detallesOPForm?.ordenCompraCodigo ? detallesOPForm.ordenCompraCodigo : '',
            items: detallesOPForm?.ordenCompraItems ? detallesOPForm.ordenCompraItems : ''
        },
        numeroParteCliente: detallesOPForm?.numeroParteCliente ? detallesOPForm.numeroParteCliente : '',
        numeroCotizacion: detallesOPForm?.numeroCotizacion ? detallesOPForm.numeroCotizacion : '',
        fechaRecepcionOC: detallesOPForm?.fechaRecepcionOC ? detallesOPForm.fechaRecepcionOC : '',
        fechaEntregaOC: detallesOPForm?.fechaEntregaOC ? detallesOPForm?.fechaEntregaOC : '',
        observaciones: observaciones ? observaciones : '',
        numeroFacturaElectrans: detallesOPForm?.numeroFacturaElectrans ? detallesOPForm.numeroFacturaElectrans : '',
        fechaFactura: detallesOPForm?.fechaFactura ? detallesOPForm.fechaFactura : ''
    }
    */
}

export const formatOrdenProcesoForm = (
    ordenProceso: OrdenProceso,
    tipoOrdenProcesoOptions: SelectOption<TipoOrdenProceso>[],
    tipoProcesoOptions: SelectOption<TipoProceso>[],
    centroCostoProcesoOptions: SelectOption<CentroCostoProceso>[],
    uenElectromecanicoOptions: SelectOption<UnidadEstrategicaNegocio>[],
    uenPotenciaOptions: SelectOption<UnidadEstrategicaNegocio>[],
    uenLaboratorioOptions: SelectOption<UnidadEstrategicaNegocio>[],
    uenMecanicoOptions: SelectOption<UnidadEstrategicaNegocio>[],
    // estadoOrdenProcesoOptions : SelectOption<EstadoOrdenProceso>[]
    ): OrdenProcesoForm => {
    
    const tipoOP = tipoOrdenProcesoOptions.find((tipo) => 
        tipo.value.tipo === ordenProceso.tipoOP
    );
    const proceso = tipoProcesoOptions.find((proceso) =>
        proceso.value.tipo === ordenProceso.proceso
    );
    const centroCosto = centroCostoProcesoOptions.find((cc) =>
        cc.value.centroCosto === ordenProceso.centroCosto
    );
    let uen;
    if (centroCosto?.value.centroCosto === 'ELECTROMECANICO') {
        uen = uenElectromecanicoOptions.find((uen) => 
            uen.value.uen === ordenProceso.uen
        );
    } else if (centroCosto?.value.centroCosto === 'POTENCIA') {
        uen = uenPotenciaOptions.find((uen) => 
            uen.value.uen === ordenProceso.uen
        );
    } else if (centroCosto?.value.centroCosto === 'LABORATORIO') {
        uen = uenLaboratorioOptions.find((uen) => 
            uen.value.uen === ordenProceso.uen
        );
    } else if (centroCosto?.value.centroCosto === 'MECANICO') {
        uen = uenMecanicoOptions.find((uen) => 
            uen.value.uen === ordenProceso.uen
        );
    } else {
        uen = undefined;
    }
    
    return {
        tipoOP: tipoOP,
        proceso: proceso,
        centroCosto: centroCosto,
        uen: uen,
        cliente: ordenProceso.cliente,
        estado: ordenProceso.estado ? {label: ordenProceso.estado, value: { estado: ordenProceso.estado } } : undefined
    }
}

export const formatDetallesOPForm = (ordenProceso: OrdenProceso): DetallesOPForm => ({
    ordenTrabajoCliente: ordenProceso.ordenTrabajoCliente,
    codigoSap: ordenProceso.codigoSap,
    codigoSolped: ordenProceso.codigoSolped,
    ordenCompraCodigo: ordenProceso.ordenCompra.codigo,
    ordenCompraItems: ordenProceso.ordenCompra.item,
    numeroParteCliente: ordenProceso.numeroParteCliente,
    numeroCotizacion: ordenProceso.numeroCotizacion,
    fechaRecepcionOC: ordenProceso.fechaRecepcionOC,
    fechaEntregaOC: ordenProceso.fechaEntregaOC,
    // observaciones: ordenProceso.observaciones,
    numeroFacturaElectrans: ordenProceso.numeroFacturaElectrans,
    fechaFactura: ordenProceso.fechaFactura,
    fechaEnvioPPTO: ordenProceso.fechaEnvioPPTO,
    fechaRecepcionFactura: ordenProceso.fechaRecepcionFactura
})

export const formatUpdateOrdenesProcesoEstado = (ordenesProceso: OrdenProceso[], estado: string): UpdateOrdenesProcesoEstado => ({
    estado: estado,
    ordenesProceso: ordenesProceso.map((op) => op.codigoOP)
})

export const detallesOPArray = (ordenProceso: OrdenProceso): BodyCardElement[] => {
    let arrayResponse = [];
    if (ordenProceso.cliente) {
        arrayResponse.push({ title: 'Cliente', value: ordenProceso.cliente })
    }
    if (ordenProceso.opTrazabilidad?.op) {
        arrayResponse.push({ title: 'OP anterior', value: ordenProceso.opTrazabilidad.op })
    }
    if (ordenProceso.opTrazabilidad?.fechaEntrega) {
        arrayResponse.push({ title: 'Fecha OP anterior', value: ordenProceso.opTrazabilidad.fechaEntrega })
    }
    if (ordenProceso.codigoSap) {
        arrayResponse.push({ title: 'Código SAP', value: ordenProceso.codigoSap });
    }
    if (ordenProceso.codigoSolped) {
        arrayResponse.push({ title: 'Código SOLPED', value: ordenProceso.codigoSolped });
    }
    if (ordenProceso.ordenTrabajoCliente) {
        arrayResponse.push({ title: 'Orden Trabajo Cliente', value: ordenProceso.ordenTrabajoCliente });
    }
    if (ordenProceso.ordenCompra.codigo) {
        arrayResponse.push({
            title: `Orden Compra${ordenProceso.ordenCompra.item ? ' - Items' : ''}`,
            value: `${ordenProceso.ordenCompra.codigo}${ordenProceso.ordenCompra.item ? ` - ${ordenProceso.ordenCompra.item}` : ''}`
        });
    }
    if (ordenProceso.numeroParteCliente) {
        arrayResponse.push({ title: 'N° Parte Cliente', value: ordenProceso.numeroParteCliente });
    }
    if (ordenProceso.numeroCotizacion) {
        arrayResponse.push({ title: 'N° Cotización', value: ordenProceso.numeroCotizacion });
    }
    if (ordenProceso.fechaRecepcionOC) {
        arrayResponse.push({ title: 'Fecha Recepción (Orden Compra)', value: ordenProceso.fechaRecepcionOC });
    }
    if (ordenProceso.fechaEntregaOC) {
        arrayResponse.push({ title: 'Fecha Entrega (Orden Compra)', value: ordenProceso.fechaEntregaOC });
    }
    if (ordenProceso.fechaFactura) {
        arrayResponse.push({ title: 'Fecha Factura', value: ordenProceso.fechaFactura });
    }
    if (ordenProceso.numeroFacturaElectrans) {
        arrayResponse.push({ title: 'N° Factura Electrans', value: ordenProceso.numeroFacturaElectrans });
    }
    if (ordenProceso.fechaRecepcionFactura) {
        arrayResponse.push({ title: 'Fecha recepción factura', value: ordenProceso.fechaRecepcionFactura });
    }
    if (ordenProceso.fechaEnvioPPTO) {
        arrayResponse.push({ title: 'Fecha envío presupuesto', value: ordenProceso.fechaEnvioPPTO });
    }
    return arrayResponse;
}

export const datosOPArray = (ordenProceso: OrdenProceso): BodyCardElement[] => {
    let arrayResponse = [];
    arrayResponse.push({ title: 'Código OP', value: ordenProceso.codigoOP ? ordenProceso.codigoOP : ''});
    arrayResponse.push({ title: 'Estado', value: ordenProceso.estado ? ordenProceso.estado : '' });
    arrayResponse.push({ title: 'Tipo OP', value: ordenProceso.tipoOP ? ordenProceso.tipoOP : '' });
    arrayResponse.push({ title: 'Proceso', value: ordenProceso.proceso ? ordenProceso.proceso : '' });
    let valueCC = ordenProceso.centroCosto;
    if (valueCC) {
        if (valueCC === 'ELECTROMECANICO') {
            valueCC = 'ELECTROMECÁNICO'
        }
        if (valueCC === 'MECANICO') {
            valueCC = 'MECÁNICO'
        }
    } else {
        valueCC = '';
    }
    arrayResponse.push({ title: 'Centro de costo', value: valueCC });
    arrayResponse.push({ title: 'Área', value: ordenProceso.uen ? ordenProceso.uen : '' });
    return arrayResponse;
}