import React, { ReactElement, ReactNode } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface FormButtonsProps {
  submitButtonContent?: ReactNode;
  cancelButtonContent?: ReactNode;
  onSubmitClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancelClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeleteConfirm?: () => void;
  isConfirmingDelete?: boolean;
  onCancelDelete?: () => void;
  deleteButtonContent?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFormInvalid?: boolean;
  areValidationsEnabled?: boolean,
  isNotDeleteable?: boolean
}

const FormButtons = ({
  submitButtonContent = 'Guardar',
  cancelButtonContent = 'Cancelar',
  deleteButtonContent = 'Eliminar',
  isConfirmingDelete,
  isDisabled,
  isLoading,
  onCancelClick,
  onDeleteClick,
  onSubmitClick,
  onDeleteConfirm,
  onCancelDelete,
  isFormInvalid,
  areValidationsEnabled,
  isNotDeleteable
}: FormButtonsProps): ReactElement => {

  const invalidForm = (): ReactElement => {
    if (isFormInvalid && areValidationsEnabled) {
      return (
        <div className="text-danger text-center mb-3">
          <small>Hay campos obligatorios sin ingresar</small>
        </div>
      );
    } else {
      return (<></>);
    }
  }

  if (isConfirmingDelete) {
    return (
      <>
        <h6 className = "text-center">
          <span className="text-danger font-weight-bold">¿Confirma la eliminación?</span>
        </h6>
        <div className = "modal-buttons">
          <Button
            className="mr-3"
            variant="secondary"
            onClick={onCancelDelete}
            disabled={isDisabled}>
            No
          </Button>
          <Button variant="danger" onClick={onDeleteConfirm} disabled={isDisabled}>
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Sí'}
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <>
        {invalidForm()}
        <div className = "modal-buttons">
          {!!onDeleteClick && (
            <Button variant="danger" onClick={onDeleteClick} disabled={isNotDeleteable ? isNotDeleteable : isDisabled} className="mr-3 float-left">
              <span className="d-sm-none">Eliminar</span>
              <span className="d-none d-sm-block">{deleteButtonContent}</span>
            </Button>
          )}
          <Button
            className="float-right"
            variant="success"
            type="submit"
            onClick={onSubmitClick}
            disabled={isDisabled}>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <span className="d-lg-none">{onDeleteClick ? 'Guardar' : submitButtonContent}</span>
                <span className="d-none d-lg-block">{submitButtonContent}</span>
              </>
            )}
          </Button>
          <Button
            className="mr-3 float-right"
            variant="secondary"
            disabled={isDisabled}
            onClick={onCancelClick}>
              {!isLoading ? cancelButtonContent : <Spinner animation="border" size="sm" />}
          </Button>
        </div>
      </>
    );
  }
};

export default FormButtons;
