import { Recepcion, RecepcionesFilters, RecepcionForm } from "../models/recepciones/Recepcion";

export const getEmptyRecepcionesFilters = (): RecepcionesFilters => {
    return {
        codigoOP: '',
        numeroGuiaDespacho: '',
        fechaRecepcionInicio: '',
        fechaRecepcionFinal: '',
        cliente: '',
        page: 1
    };
}

export const getEmptyRecepcionForm = (): RecepcionForm => {
    return {
        fechaRecepcion: '',
        numeroGuiaDespacho: '',
        numeroSerieComponente: '',
        subModeloComponente: ''
    }
};

export const formatRecepcionForm = (recepcion: Recepcion, codigoOP: string): RecepcionForm => {

    const ordenProceso = (recepcion.ordenesProceso.find((op) => op.ordenProceso === codigoOP));

    return {
        fechaRecepcion: recepcion.fechaRecepcion,
        numeroGuiaDespacho: recepcion.numeroGuiaDespacho ? recepcion.numeroGuiaDespacho : '',
        numeroSerieComponente: ordenProceso?.numeroSerieComponente ? ordenProceso.numeroSerieComponente : '',
        subModeloComponente: ordenProceso?.subModeloComponente ? ordenProceso.subModeloComponente : ''
    }

    // const {
    //     fechaRecepcion = '',
    //     numeroGuiaDespacho = '',
    // } = recepcion;
    
    // if (numeroSerieComponente) {
    //     return { fechaRecepcion, numeroGuiaDespacho, numeroSerieComponente }
    // } else {
    //     const numeroSerieComponente = '';
    //     return { fechaRecepcion, numeroGuiaDespacho, numeroSerieComponente }
    // }
}