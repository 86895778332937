import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { Entrega } from "../models/Entregas";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import AuthContext from "../contexts/AuthContext";
import { getEntrega } from "../api/EntregasApi";
import { formatGetEntregaRequest } from "../utils/entregas";
import { getOrdenProceso } from "../api/OrdenesProcesoApi";
import cogoToast from "cogo-toast";
import { Card, Spinner } from "react-bootstrap";
import EntregaDetails from "../components/entregas/EntregaDetails";
import { getUserPermissionFunction } from "../utils/users";

const EntregaPage = (): ReactElement => {

    const [ entrega, setEntrega ] = useState<Entrega>();
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const { codigoOP } = useParams<{ codigoOP: string }>();
    const { userRoles } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const changeEntrega = (entrega: Entrega) => {
        setEntrega(entrega);
    }

    useEffect(() => {
        if (entrega) {
            breadcrumb.setItems([
                {
                    label: 'Estatus General',
                    href: '/ordenesProceso'
                },
                {
                    label: codigoOP,
                    href: `/ordenesProceso/${codigoOP}`
                },
                {
                    label: 'Entrega',
                    inactive: true
                }
            ]);
        }
    }, [entrega, codigoOP]);

    useEffect(() => {
        getOrdenProceso(codigoOP)
            .then((response) => {
                if (response.data.entrega) {
                    setOrdenProceso(response.data);
                    getEntrega(formatGetEntregaRequest(response.data))
                        .then((response) => {
                            if (response.data.fechaEntrega) {
                                setEntrega(response.data);
                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                                // cogoToast.error(response.data);
                                cogoToast.error('No fue posible obtener la entrega del componente');
                            }
                        })
                        .catch(() => {
                            setIsLoading(false);
                            // cogoToast.error(error);
                            cogoToast.error('No fue posible obtener la entrega del componente');
                        })
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible obtener la entrega del componente');
                }
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener la entrega del componente');
            })
    }, [codigoOP]);

    if (!entrega || !ordenProceso) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border"/>
                    </Card.Body>
                </Card>
            )
        } else {
            return <p className = "text-center">No fue posible cargar la entrega del componente</p>
        }
    }
    return (
        <>
            <EntregaDetails
                entrega = {entrega}
                changeEntrega = {changeEntrega}
                userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'PLANIFICACION', 'CALIDAD'] })}
                ordenProceso = {ordenProceso}
            />
        </>
    )
}

export default EntregaPage;