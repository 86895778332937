import { ReactElement } from "react";
import { Form } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import FormButtons from "../form/buttons/FormButtons";
import { Input } from "../form/input/Input";

interface ObservacionesOPFormComponentProps {
    formState: FormState<{ observaciones: string }>,
    submitForm: (obs: { observaciones: string }) => void,
    onCancel: () => void
}

const ObservacionesOPFormComponent = ({ formState, submitForm, onCancel }: ObservacionesOPFormComponentProps): ReactElement => {

    const {
        value: observacionesForm,
        handleFieldChange,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
    } = formState;

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(observacionesForm);
    }

    return (
        <>
            <Form>
                <Input
                    title = ""
                    name = "observaciones"
                    type = "textarea"
                    value = {observacionesForm.observaciones}
                    onChangeWithName = {handleFieldChange}
                    disabled = {isSubmitting}
                />
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                />
            </Form>
        </>
    );
}

export default ObservacionesOPFormComponent;