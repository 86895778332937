import axios, { AxiosPromise } from "axios";
import { Archivo, ArchivoNombre, CodigoOPArchivo, DocumentRequest, ProcesoPhotoRequest } from "../models/Archivos";
//import axios from './axiosToken';

/*
export function putImagenesRegistroRecepcion(requestBody: Archivo[], codigoOP: String): AxiosPromise<Archivo[]> {
    return axios.put(`api/documentos/recepciones/imagenes/${codigoOP}`, requestBody);
}

export function getImagenesRegistroRecepcion(requestBody: String): AxiosPromise<Archivo[]> {
    return axios.get(`api/documentos/recepciones/imagenes/${requestBody}`);
}

export function deleteImagenesRegistrosRecepcion(): AxiosPromise<void> {
    return axios.delete('api/documentos/recepciones/imagenes');
}

export function getRecepciones(): AxiosPromise<Recepcion[]> {
    return axios.get('api/documentos/recepciones');
}

export function getGuiasDespachoRecepcion(): AxiosPromise<CodigoOPArchivo[]> {
    return axios.get('api/documentos/recepciones/guiasDespacho');
}

export function deleteGuiasDespachoRecepcion(): AxiosPromise<void> {
    return axios.delete('api/documentos/recepciones/guiasDespacho');
}

export function getImagenesRegistrosRecepcionSize(): AxiosPromise<string> {
    return axios.get('api/documentos/recepciones/imagenesSize');
}

export function getGuiasDespachoRecepcionSize(): AxiosPromise<string> {
    return axios.get('api/documentos/recepciones/guiasDespachoSize');
}

export function getAllDocumentsSize(): AxiosPromise<SizeResponse> {
    return axios.get('api/documentos/size');
}
*/

///////////////////////////////////////////////

// export function postGuiaDespachoRecepcion(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/recepciones/guiasDespacho`, requestBody);
// }

// export function getGuiaDespachoRecepcion(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/recepciones/guiasDespacho/${codigoOP}`);
// }

// export function deleteGuiaDespachoRecepcion(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/recepciones/guiasDespacho`, { params: requestBody });
// }

export function getGuiaDespachoRecepcion(requestBody: DocumentRequest, onDownloadProgressFunction?: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/recepciones/guiasDespacho', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postGuiaDespachoRecepcion(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/recepciones/guiasDespacho', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteGuiaDespachoRecepcion(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/recepciones/guiasDespacho', { params: requestBody });
}

// export function getGuiaDespachoRecepcionListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/recepciones/guiasDespacho/listado/${codigoOP}`);
// }

///////////////////////////////////////////////
// export function postRegistroRecepcion(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/recepciones/registros`, requestBody);
// }

// export function getRegistroRecepcion(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/recepciones/registros/${codigoOP}`);
// }

// export function deleteRegistroRecepcion(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/recepciones/registros`, { params: requestBody });
// }

export function getRegistroRecepcion(requestBody: DocumentRequest, onDownloadProgressFunction?: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/recepciones/registros', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postRegistroRecepcion(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/recepciones/registros', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteRegistroRecepcion(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/recepciones/registros', { params: requestBody });
}

// export function getRegistroRecepcionListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/recepciones/registros/listado/${codigoOP}`);
// }

///////////////////////////////////////////////
// export function postInformeFallasRecepcion(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/recepciones/informesFallas`, requestBody);
// }

// export function getInformeFallasRecepcion(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/recepciones/informesFallas/${codigoOP}`);
// }

// export function deleteInformeFallasRecepcion(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/recepciones/informesFallas`, { params: requestBody });
// }

export function getInformeFallasRecepcion(requestBody: DocumentRequest, onDownloadProgressFunction?: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/recepciones/informesFallas', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postInformeFallasRecepcion(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/recepciones/informesFallas', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteInformeFallasRecepcion(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/recepciones/informesFallas', { params: requestBody });
}

// export function getInformeFallasRecepcionListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/recepciones/informesFallas/listado/${codigoOP}`);
// }

///////////////////////////////////////////////

// export function postNoConformidad(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/recepciones/noConformidades`, requestBody);
// }

// export function getNoConformidad(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/recepciones/noConformidades/${codigoOP}`);
// }

// export function deleteNoConformidad(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/recepciones/noConformidades`, { params: requestBody });
// }

export function getNoConformidad(requestBody: DocumentRequest, onDownloadProgressFunction?: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/recepciones/noConformidades', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postNoConformidad(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/recepciones/noConformidades', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteNoConformidad(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/recepciones/noConformidades', { params: requestBody });
}

// export function getNoConformidadListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/recepciones/noConformidades/listado/${codigoOP}`);
// }

///////////////////////////////////////////////
// export function getDocumentosEvaluacionTecnica(codigoOP: string): AxiosPromise<Archivo[]> {
//     return axios.get(`api/documentos/informes/evaluacion/${codigoOP}`);
// }

export function getDocumentoEvaluacionResumen(codigoOP: string): AxiosPromise<Archivo> {
    return axios.get(`api/documentos/informes/evaluacion/resumen/${codigoOP}`);
}

export function getDocumentoEvaluacion(requestBody: DocumentRequest, onDownloadProgressFunction: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/informes/evaluacion', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postDocumentoEvaluacion(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/informes/evaluacion', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteDocumentoEvaluacion(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/informes/evaluacion', { params: requestBody });
}

// export function getDocumentosEvaluacionListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/informes/evaluacion/listado/${codigoOP}`);
// }

/////////////////////////////////////////////////

export function getDocumentoReparacion(requestBody: DocumentRequest, onDownloadProgressFunction: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/informes/reparacion', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postDocumentoReparacion(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/informes/reparacion', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteDocumentoReparacion(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete(`api/documentos/informes/reparacion`, { params: requestBody });
}

// export function getDocumentosReparacionListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/informes/reparacion/listado/${codigoOP}`);
// }

///////////////////////////////////////////////

// export function postGuiaDespachoEntrega(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/entregas/guiasDespacho`, requestBody);
// }

// export function getGuiaDespachoEntrega(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/entregas/guiasDespacho/${codigoOP}`);
// }

// export function deleteGuiaDespachoEntrega(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/entregas/guiasDespacho`, { params: requestBody });
// }

export function getGuiaDespachoEntrega(requestBody: DocumentRequest, onDownloadProgressFunction: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/entregas/guiasDespacho', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postGuiaDespachoEntrega(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/entregas/guiasDespacho', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteGuiaDespachoEntrega(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/entregas/guiasDespacho', { params: requestBody });
}

// export function getGuiaDespachoEntregaListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/entregas/guiasDespacho/listado/${codigoOP}`);
// }

/////////////////////////////////////////////////

// export function getCertificadoCalibracionEquiposHerramientas(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/certificados/calibracion/equipos/${codigoOP}`);
// }

// export function postCertificadoCalibracionEquiposHerramientas(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/certificados/calibracion/equipos/${requestBody.codigoOP}`, requestBody);
// }

// export function deleteCertificadoCalibracionEquiposHerramientas(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/certificados/calibracion/equipos`, { params: requestBody });
// }

export function getCertificadoCalibracionEquiposHerramientas(requestBody: DocumentRequest, onDownloadProgressFunction: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/certificados/calibracion/equipos', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postCertificadoCalibracionEquiposHerramientas(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/certificados/calibracion/equipos', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteCertificadoCalibracionEquiposHerramientas(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/certificados/calibracion/equipos', { params: requestBody });
}

// export function getCertificadoCalibracionEquiposHerramientasListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/certificados/calibracion/equipos/listado/${codigoOP}`);
// }

///////////////////////////////////////////////
// export function getCertificadoPersonal(codigoOP: string): AxiosPromise<Archivo> {
//     return axios.get(`api/documentos/certificados/personal/${codigoOP}`);
// }

// export function postCertificadoPersonal(requestBody: CodigoOPArchivo): AxiosPromise<Archivo> {
//     return axios.post(`api/documentos/certificados/personal/${requestBody.codigoOP}`, requestBody);
// }

// export function deleteCertificadoPersonal(requestBody: DocumentRequest): AxiosPromise<void> {
//     return axios.delete(`api/documentos/certificados/personal`, { params: requestBody });
// }

export function getCertificadoPersonal(requestBody: DocumentRequest, onDownloadProgressFunction: (progressEvent: any) => void): AxiosPromise<Archivo | void> {
    return axios.get('api/documentos/certificados/personal', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

export function postCertificadoPersonal(requestBody: CodigoOPArchivo, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/documentos/certificados/personal', requestBody, { onUploadProgress: onUploadProgressFunction });
}

export function deleteCertificadoPersonal(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/documentos/certificados/personal', { params: requestBody });
}

// export function getCertificadoPersonalListado(codigoOP: string): AxiosPromise<ArchivoNombre[]> {
//     return axios.get(`api/documentos/certificados/personal/listado/${codigoOP}`);
// }

///////////////////////////////////////////////

export function postImagenProceso(requestBody: ProcesoPhotoRequest): AxiosPromise<Archivo> {
    return axios.post(`api/documentos/fotos/${requestBody.nombreProceso}/ordenProceso/${requestBody.codigoOP}`, requestBody)
}

export function deleteImagenProceso(requestBody: ProcesoPhotoRequest): AxiosPromise<void> {
    return axios.delete(`api/documentos/fotos/${requestBody.nombreProceso}/ordenProceso/${requestBody.codigoOP}`, { params: requestBody })
}

export function getImagenProceso(requestBody: ProcesoPhotoRequest): AxiosPromise<Archivo> {
    return axios.get(`api/documentos/fotos/${requestBody.nombreProceso}/ordenProceso/${requestBody.codigoOP}`, { params: requestBody })
}
/*
export function getCodigosOrdenesProceso(): AxiosPromise<CodigosOPs> {
    return axios.get('api/documentos/ordenesProceso/codigosOPs');
}
*/