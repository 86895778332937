import classNames from "classnames";
import { ReactElement, useEffect, useState } from "react"
import { Table } from "react-bootstrap";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { SelectOption } from "../../models/optionsFilters/Select";
import { adjustDecimalTextLessThan1, checkNumber } from "../../utils/inputs";
import { EvaluacionOrReparacionComponenteDetallesTable, EvaluacionOrReparacionComponenteEtapa, SharedValue, TableThatNeedSharedValues, columnOptionInCustomApply } from "../../models/EvaluacionesReparaciones";
import { getComponenteEvaluacionOrReparacionElement } from "../../utils/evaluacionesReparaciones/evaluacionesReparaciones";

interface Props {
    etapa: string,
    nombreTarea: string,
    table: EvaluacionOrReparacionComponenteDetallesTable,
    changeEvaluacionReparacionComponenteEtapa: (changeEvaluacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    isNotEditable: boolean,
    requireElementTitle: boolean,
    changeSharedValues: (newSharedValue: SharedValue) => void,
    sharedValues?: SharedValue[]
}

const EvaluacionReparacionComponenteDetallesTables = ({
    etapa, table, changeEvaluacionReparacionComponenteEtapa, evaluacionReparacionComponenteEtapa, isNotEditable, nombreTarea, requireElementTitle, changeSharedValues, sharedValues
}: Props): ReactElement => {

    const componenteElement = getComponenteEvaluacionOrReparacionElement(
        evaluacionReparacionComponenteEtapa.tipoComponente, evaluacionReparacionComponenteEtapa.modeloComponente, evaluacionReparacionComponenteEtapa.subModeloComponente
    );

    const [ refreshTable, setRefreshTable ] = useState<boolean>(false);
    const getApplyRows = (): { rowApply: boolean, cellsApply: boolean[] }[] => table.rows.map((row) => {
        const response: { rowApply: boolean, cellsApply: boolean[] } = { rowApply: row[0].apply !== undefined ? row[0].apply : true, cellsApply: [] };
        row.forEach((cell, cellIndex) => {
            if (cellIndex > 0) {
                response.cellsApply.push(cell.apply !== undefined ? cell.apply : true);
            }
        });
        return response;
    });

    const [ applyRowsElements, setApplyRowsElements ] = useState<{ rowApply: boolean, cellsApply: boolean[] }[]>(getApplyRows());

    const getOptionsSelection = (): { content: SelectOption<string>[], rowIndex: number, rowElementIndex: number }[] => {
        let response: { content: SelectOption<string>[], rowIndex: number, rowElementIndex: number }[] = [];
        table.rows.forEach((row, rowIndex) => {
            row.forEach((rowElement, rowElementIndex) => {
                if (rowElement.options) {
                    const rowElementOptions = rowElement.options;
                    let newRowElementOptions: string[] = [];
                    rowElementOptions.forEach((option) => {
                        if (!rowElement.content.includes(option)) {
                            newRowElementOptions.push(option);
                        }
                    });
                    response.push({
                        content: newRowElementOptions.map((option) => ({ label: option, value: option })),
                        rowIndex: rowIndex,
                        rowElementIndex: rowElementIndex
                    });
                }
            });
        });
        return response;
    }

    const [ selectsOptions, setSelectsOptions ] = useState<{ content: SelectOption<string>[], rowIndex: number, rowElementIndex: number }[]>(getOptionsSelection());

    const getOptions = (rowIndex: number, rowElementIndex: number): SelectOption<string>[] => selectsOptions.find((option) => option.rowIndex === rowIndex && option.rowElementIndex === rowElementIndex)?.content || [];

    const editEvaluacionReparacionComponenteEtapaTableForm = (
        tableRowIndex: number,
        value?: string | string[],
        tableRowElementIndex?: number,
        requestUpdateTable?: boolean
    ) => {
        const response = evaluacionReparacionComponenteEtapa;
        const valueAndTableRowElementIndexExists = value !== undefined && tableRowElementIndex !== undefined;
        let updateApplies = false;
        let updateSelects = false;
        const updateAppliesOrSelects = (isSelects?: boolean) => {
            if (isSelects) {
                if (!updateSelects) {
                    updateSelects = true;
                }
            } else {
                if (!updateApplies) {
                    updateApplies = true;
                }
            }
        }
        let updateTable = false;
        if (requestUpdateTable) {
            updateTable = true;
        }
        for (let i = 0; i < response.tareas.length; i++) {
            if (response.tareas[i].nombre === nombreTarea) {
                let responseTables = response.tareas[i].tables;
                if (responseTables) {
                    for (let e = 0; e < responseTables.length; e++) {
                        if (responseTables[e].name === table.name) {
                            const currentTable = responseTables[e];
                            const resetCell = (rowIndex: number, cellIndex: number) => {
                                if (!currentTable.applySettings.customApply?.columns.some((column) => column.name === currentTable.columnsSettings[cellIndex].columnTitle)) {
                                    const cellOptions = currentTable.rows[rowIndex][cellIndex].options;
                                    if (cellOptions && !currentTable.columnsSettings[cellIndex].isDefaultStateEmpty) {
                                        currentTable.rows[rowIndex][cellIndex].content = [cellOptions[0]];
                                    } else {
                                        const defaultState = currentTable.columnsSettings[cellIndex].defaultState;
                                        currentTable.rows[rowIndex][cellIndex].content = defaultState ? [defaultState] : [];
                                    }
                                }
                            };
                            if (valueAndTableRowElementIndexExists) {
                                const checkTriggerInCellContent = (triggerValue: string, rowElementIndex: number): boolean => currentTable.rows[tableRowIndex][rowElementIndex].content.includes(triggerValue);
                                if (typeof value === 'string') {
                                    currentTable.rows[tableRowIndex][tableRowElementIndex].content = [value];
                                } else {
                                    if (currentTable.rows[tableRowIndex][tableRowElementIndex].options) {
                                        currentTable.rows[tableRowIndex][tableRowElementIndex].content = value;
                                        updateAppliesOrSelects(true);
                                    }
                                }
                                currentTable.columnsSettings.forEach((columnSetting, columnSettingIndex) => {
                                    if (columnSetting.parentColumns &&
                                        columnSetting.parentColumns.some((parentColumn) => parentColumn.name === currentTable.columnsSettings[tableRowElementIndex].columnTitle)
                                    ) {
                                        let apply = true;
                                        columnSetting.parentColumns.forEach((parentColumn) => {
                                            for (let a = 0; a < currentTable.columnsSettings.length; a++) {
                                                if (currentTable.columnsSettings[a].columnTitle === parentColumn.name &&
                                                    !(parentColumn.allOptionsToTrigger ?
                                                        parentColumn.triggerValues.every((triggerValue) => checkTriggerInCellContent(triggerValue, a))
                                                    :
                                                        parentColumn.triggerValues.some((triggerValue) => checkTriggerInCellContent(triggerValue, a))
                                                    )
                                                ) {
                                                    apply = false;
                                                    break;
                                                }
                                            }
                                        });
                                        currentTable.rows[tableRowIndex][columnSettingIndex].apply = apply;
                                        updateAppliesOrSelects();
                                    }
                                });
                            }
                            if (currentTable.applySettings.applyOption) {
                                if (value === undefined && tableRowElementIndex === undefined && !currentTable.applySettings.customApply) {
                                    currentTable.rows[tableRowIndex][0].apply = !applyRowsElements[tableRowIndex].rowApply;
                                    updateAppliesOrSelects();
                                } else if (valueAndTableRowElementIndexExists) {
                                    const customApply = currentTable.applySettings.customApply;
                                    if (customApply) {
                                        const checkColumn = (column: columnOptionInCustomApply) => {                                            
                                            let response = false;
                                            for (let a = 0; a < currentTable.columnsSettings.length; a++) {
                                                if (currentTable.columnsSettings[a].columnTitle === column.name) {
                                                    const checkApplyOptionInCell = (applyOption: string): boolean => currentTable.rows[tableRowIndex][a].content.includes(applyOption);
                                                    response = column.allOptionsToApply ? column.applyOptions.every(checkApplyOptionInCell) : column.applyOptions.some(checkApplyOptionInCell);
                                                    break;
                                                }
                                            }
                                            return response;
                                        }
                                        currentTable.rows[tableRowIndex][0].apply = customApply.oneColumnTriggerApply ? customApply.columns.some(checkColumn) : customApply.columns.every(checkColumn);
                                    }
                                    updateAppliesOrSelects();
                                }
                            }
                            for (let a = 0; a < currentTable.rows.length; a++) {
                                if (currentTable.rows[a][0].apply === false) {
                                    for (let u = 1; u < currentTable.rows[a].length; u++) {
                                        if (!currentTable.columnsSettings[u].isConst || currentTable.columnsSettings[u].defaultState) {
                                            resetCell(a, u);
                                        }
                                    }
                                    updateAppliesOrSelects();
                                    updateAppliesOrSelects(true);
                                } else {
                                    for (let u = 1; u < currentTable.rows[a].length; u++) {
                                        if (currentTable.rows[a][u].apply === false && (!currentTable.columnsSettings[u].isConst || currentTable.columnsSettings[u].defaultState)) {
                                            resetCell(a, u);
                                            updateAppliesOrSelects();
                                            updateAppliesOrSelects(true);
                                        }
                                    }
                                }
                            }
                            responseTables[e] = currentTable;
                            response.tareas[i].tables = responseTables;
                            changeEvaluacionReparacionComponenteEtapa({ ...response });
                            if (updateApplies) {
                                setApplyRowsElements(getApplyRows());
                                updateTable = false;
                            }
                            if (updateSelects) {
                                setSelectsOptions(getOptionsSelection());
                                updateTable = false;
                            }
                            if (updateTable) {
                                setRefreshTable(!refreshTable);
                            }
                            break;
                        }
                    }
                }
                break;
            }
        }
    }

    const updateTable = (tableRowIndex: number, value?: string | string[], tableRowElementIndex?: number, skipCalculations?: boolean) => {
        editEvaluacionReparacionComponenteEtapaTableForm(tableRowIndex, value, tableRowElementIndex);
        if (!skipCalculations) {
            if (componenteElement?.getCalculations) {
                componenteElement.getCalculations({
                    stage: etapa,
                    table: table,
                    taskName: nombreTarea,
                    variableName: table.name,
                    tableRowIndex: tableRowIndex,
                    updateTable: (calculationsTableRowElementIndex: number, calculatedValue: string) => editEvaluacionReparacionComponenteEtapaTableForm(tableRowIndex, calculatedValue, calculationsTableRowElementIndex, true),
                    updateSharedValues: changeSharedValues
                });
            }
        }
    }

    useEffect(() => {
        if (componenteElement?.tablesThatNeedSharedValues && sharedValues) {
            const specialTable = ((): TableThatNeedSharedValues | void => {
                for (let i = 0; i < componenteElement.tablesThatNeedSharedValues.length; i++) {
                    if (componenteElement.tablesThatNeedSharedValues[i].tableName === table.name &&
                        componenteElement.tablesThatNeedSharedValues[i].taskName === nombreTarea &&
                        componenteElement.tablesThatNeedSharedValues[i].stageName === etapa
                    ) {
                        return componenteElement.tablesThatNeedSharedValues[i];
                    }
                }
            })();
            if (specialTable) {
                specialTable.calculation(sharedValues, (tableRowIndex: number, tableRowElementIndex: number, value?: string) => updateTable(tableRowIndex, value, tableRowElementIndex));
            }
        }
    }, [sharedValues]);

    return (
        <>
            {table.name !== '1' && requireElementTitle && <u><h5>{table.name}</h5></u>}
            <Table bordered size = 'sm'>
                <tr>
                    {table.columnsSettings.map((columnSetting, columnIndex) => {
                        return (
                            <th>
                                {columnIndex === 0 ? columnSetting.columnTitle : <div className = "centerTextCell">{columnSetting.columnTitle}</div>}
                            </th>
                        )
                    })}
                    {table.applySettings.applyOption && !table.applySettings.customApply &&
                        (<th><div className = "centerTextCell">¿Aplica?</div></th>)
                    }
                </tr>
                {table.rows.map((row, rowIndex) => {
                    const elementOnChange = (newValue?: { value: any, rowElementIndex: number }) => {
                        if (newValue) {
                            if (typeof newValue.value === 'string') {
                                if (table.columnsSettings[newValue.rowElementIndex].onlyNumbers) {
                                    // updateTable(rowIndex, newValue.value, newValue.rowElementIndex);
                                    if (!newValue.value || checkNumber(newValue.value).isNumber) {
                                        updateTable(rowIndex, adjustDecimalTextLessThan1(newValue.value, true), newValue.rowElementIndex);
                                    }
                                } else {
                                    updateTable(rowIndex, newValue.value, newValue.rowElementIndex);
                                }
                            } else {
                                let entryVar;
                                if (table.columnsSettings[newValue.rowElementIndex].isMultiple === true) {
                                    entryVar = newValue.value.map((value: SelectOption<string>) => value.value);
                                } else {
                                    entryVar = [newValue.value.value];
                                }
                                updateTable(rowIndex, entryVar, newValue.rowElementIndex);
                            }
                        } else {
                            updateTable(rowIndex, undefined, undefined);
                        }
                    }
                    return (
                        <tr>
                            {row.map((cell, cellIndex) => {
                                if (table.columnsSettings[cellIndex].isConst === true) {
                                    const finalContentHTML = ((): ReactElement => {
                                        let finalContent = cell.content[0];
                                        if (cell.content.length > 1) {
                                            cell.content.forEach((text, index) => {
                                                if (index > 0) {
                                                    finalContent = finalContent + ' - ' + text;
                                                }
                                            });
                                        }
                                        const isResultadoInRangeText = ((): boolean | undefined => {
                                            if (table.columnsSettings[cellIndex].columnTitle === 'Resultado') {
                                                if (finalContent === 'Dentro de rango') {
                                                    return true;
                                                } else if (finalContent === 'Fuera de rango') {
                                                    return false;
                                                }
                                            }
                                        })();
                                        if (isResultadoInRangeText !== undefined) {
                                            return <div className = {classNames('measurementsResultText', { inRange: isResultadoInRangeText })}>
                                                {finalContent}
                                            </div>
                                        } else {
                                            return <>{finalContent}</>
                                        }
                                    })();
                                    return (
                                        <td>
                                            {cellIndex === 0 ? <h6>{finalContentHTML}</h6> : <div className = "centerTextCell">{finalContentHTML}</div>}
                                        </td>
                                    )
                                } else {
                                    const getIsDisabled = (): boolean => {
                                        const getIsCellDisabled = (): boolean => {                                        
                                            let response = !(applyRowsElements[rowIndex].rowApply && applyRowsElements[rowIndex].cellsApply[cellIndex - 1]);
                                            if (!response) {
                                                response = isNotEditable;
                                            }
                                            return response;
                                        }
                                        if (table.applySettings.customApply) {
                                            if (table.applySettings.customApply.columns.some((column) => column.name === table.columnsSettings[cellIndex].columnTitle)) {
                                                return isNotEditable;
                                            } else {
                                                return getIsCellDisabled();
                                            }
                                        } else {
                                            return getIsCellDisabled();
                                        }
                                    }
                                    return (
                                        <td>
                                            {cell.options ?
                                                table.columnsSettings[cellIndex].acceptNewEntry === true ?
                                                    <CreatableSelect
                                                        placeholder = {'Seleccionar y/o escribir otros ...'}
                                                        isDisabled = {getIsDisabled()}
                                                        value = {cell.content.map((text) => ({ label: text, value: text }))}
                                                        options = {getOptions(rowIndex, cellIndex)}
                                                        onChange = {(value: any) => elementOnChange({ value: value, rowElementIndex: cellIndex })}
                                                        isMulti = {table.columnsSettings[cellIndex].isMultiple}
                                                        // hideSelectedOptions
                                                    />
                                                :
                                                    <ReactSelect
                                                        isDisabled = {getIsDisabled()}
                                                        value = {cell.content.map((text) => ({ label: text, value: text }))}
                                                        options = {getOptions(rowIndex, cellIndex)}
                                                        onChange = {(value: any) => elementOnChange({ value: value, rowElementIndex: cellIndex })}
                                                        isMulti = {table.columnsSettings[cellIndex].isMultiple}
                                                        // hideSelectedOptions
                                                    />
                                            :
                                                <input
                                                    disabled = {getIsDisabled()}
                                                    className = "form-control text-center"
                                                    value = {cell.content[0] || ''}
                                                    onChange = {(value: any) => elementOnChange({ value: value.target.value, rowElementIndex: cellIndex })}
                                                />
                                            }
                                        </td>
                                    )
                                }
                            })}
                            {table.applySettings.applyOption && !table.applySettings.customApply &&
                                <td>
                                    <div className = "centerTextCell">
                                        <input
                                            className = "largeCheckBox"
                                            type = "checkbox"
                                            checked = {applyRowsElements[rowIndex].rowApply}
                                            onChange = {() => elementOnChange()}
                                            disabled = {isNotEditable}
                                        />
                                    </div>
                                </td>
                            }
                        </tr>
                    )
                })}
            </Table>
        </>
    )
}

export default EvaluacionReparacionComponenteDetallesTables;