import axios, { AxiosPromise } from 'axios';
// import TipoComponente from '../models/componentes/TipoComponente';
import { Archivo, ArchivoNombre, DocumentRequest, DocumentoModeloComponente, ModeloDescripcionComponente } from '../models/Archivos';
import {
    //ComponenteTabla,
    Componente,
    ComponenteRequestBody,
    ComponentesFilters,
    ComponenteReparadoRequestBody,
    ComponentesReparadosFilters,
    ComponenteReparadoDetails,
    ComponentesTablaResponse,
    ComponentesReparadosTablaResponse,
    ComponenteReparadoResponse
} from '../models/componentes/Componente';
import { fixUrlSpecialCharacters } from '../utils/urls';
//import customAxios from './axiosToken';
/*
export function getTiposComponentes(): AxiosPromise<TipoComponente[]> {
    return axios.get('componentes/tiposcomponentes');
}
*/

export function getComponentes(requestBody: ComponentesFilters): AxiosPromise<ComponentesTablaResponse> {
    return axios.get('api/componentes', { params: requestBody });
}

export function postComponente(requestBody: ComponenteRequestBody): AxiosPromise<Componente> {
    return axios.post('api/componentes', requestBody);
}

export function getComponente(requestBody: string, descripcionComponente: string): AxiosPromise<Componente> {
    return axios.get(`api/componentes/${requestBody}`, { params: { descripcionComponente: descripcionComponente } });
}

export function putComponente(requestBody: ComponenteRequestBody): AxiosPromise<Componente> {
    return axios.put(`api/componentes/${fixUrlSpecialCharacters(requestBody.modelo)}`, requestBody);
}

export function deleteComponente(requestBody: string, descripcionComponente: string): AxiosPromise<void> {
    return axios.delete(`api/componentes/${fixUrlSpecialCharacters(requestBody)}`, { params: { descripcionComponente: descripcionComponente } });
}

export function getManualComponente(requestBody: string, descripcionComponente: string): AxiosPromise<Archivo> {
    return axios.get(`api/componentes/manuales/${fixUrlSpecialCharacters(requestBody)}`, { params: { descripcionComponente: descripcionComponente } });
}

export function getImagenComponente(requestBody: string, descripcionComponente: string): AxiosPromise<Archivo> {
    return axios.get(`api/componentes/imagenes/${fixUrlSpecialCharacters(requestBody)}`, { params: { descripcionComponente: descripcionComponente } });
}

export function postManualComponente(requestBody: DocumentoModeloComponente): AxiosPromise<Archivo> {
    return axios.post(`api/componentes/manuales/${fixUrlSpecialCharacters(requestBody.modeloComponente)}`, requestBody);
}

export function deleteManualComponente(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/componentes/manuales/modeloComponente', { params: requestBody });
}

export function postImagenComponente(requestBody: Archivo, componenteModelo: string, descripcionComponente: string): AxiosPromise<Archivo> {
    return axios.post(`api/componentes/imagenes/${fixUrlSpecialCharacters(componenteModelo)}`, requestBody, { params: { descripcionComponente: descripcionComponente } });
}

export function deleteImagenComponente(requestBody: string, descripcionComponente: string): AxiosPromise<void> {
    return axios.delete(`api/componentes/imagenes/${fixUrlSpecialCharacters(requestBody)}`, { params: { descripcionComponente: descripcionComponente } });
}

////////////////////////////

export function postComponenteReparado(requestBody: ComponenteReparadoRequestBody): AxiosPromise<ComponenteReparadoResponse> {
    return axios.post('api/componentes/componentesReparados/qr', requestBody);
}

export function getComponentesReparados(requestBody: ComponentesReparadosFilters): AxiosPromise<ComponentesReparadosTablaResponse> {
    return axios.get('api/componentes/componentesReparados/qr', { params: requestBody });
}

export function getComponenteReparado(_id: string): AxiosPromise<ComponenteReparadoDetails> {
    return axios.get(`api/componentes/componentesReparados/qr/${_id}`);
}

export function putComponenteReparado(requestBody: ComponenteReparadoRequestBody, _id: string): AxiosPromise<ComponenteReparadoDetails> {
    return axios.put(`api/componentes/componentesReparados/qr/${_id}`, requestBody);
}

export function deleteComponenteReparado(_id: string): AxiosPromise<void> {
    return axios.delete(`api/componentes/componentesReparados/qr/${_id}`);
}

/////////////////////////

export function postListadoRepuestos(requestBody: DocumentoModeloComponente, onUploadProgressFunction: (progressEvent: any) => void): AxiosPromise<ArchivoNombre> {
    return axios.post('api/componentes/documentos/listadoRepuestos', requestBody, { onUploadProgress: onUploadProgressFunction });
    // return axios.post(`api/componentes/documentos/listadoRepuestos/${fixUrlSpecialCharacters(requestBody.modeloComponente)}`, requestBody);
}

// export function getListadosRepuestos(requestBody: ModeloDescripcionComponente): AxiosPromise<Archivo[]> {
//     return axios.get('api/componentes/documentos/listadoRepuestos/listados', { params: requestBody });
// }

export function deleteListadoRepuestos(requestBody: DocumentRequest): AxiosPromise<void> {
    return axios.delete('api/componentes/documentos/listadoRepuestos', { params: requestBody });
}

// export function getDocumentosListadoRepuestoslListado(requestBody: ModeloDescripcionComponente): AxiosPromise<ArchivoNombre[]> {
//     return axios.get('api/componentes/documentos/listadoRepuestos/listado', { params: requestBody });
// }

export function getListadoRepuesto(requestBody: DocumentRequest, onDownloadProgressFunction?: (progressEvent: any) => void): AxiosPromise<Archivo> {
    return axios.get('api/componentes/documentos/listadoRepuestos', { params: requestBody, onDownloadProgress: onDownloadProgressFunction });
}

/////////////////////////

export function getModelosComponente(requestBody: string): AxiosPromise<string[]> {
    return axios.get(`api/componentes/tiposComponentes/${requestBody}`);
}