import { ReactElement } from "react";
import { Table } from "react-bootstrap";
import ReactSelect from "react-select";
import { SelectOption } from "../../models/optionsFilters/Select";
import { EvaluacionOrReparacionComponenteEtapa, TareaCheckList } from "../../models/EvaluacionesReparaciones";

interface Props {
    tareaCheckList: TareaCheckList,
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (changeEvaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    nombreTarea: string,
    requireElementTitle: boolean
}

const EvaluacionReparacionComponenteDetallesCheckLists = ({ tareaCheckList, isNotEditable, changeEvaluacionReparacionComponenteEtapa, evaluacionReparacionComponenteEtapa, nombreTarea, requireElementTitle }: Props): ReactElement => {

    const changeCheckList = (checkListName: string, checkName: string, checkValue?: string) => {
        const newEvaluacionComponenteEtapa = evaluacionReparacionComponenteEtapa;
        for (let i = 0; i < newEvaluacionComponenteEtapa.tareas.length; i++) {
            if (newEvaluacionComponenteEtapa.tareas[i].nombre === nombreTarea) {
                const checkLists = newEvaluacionComponenteEtapa.tareas[i].checkLists;
                if (checkLists) {
                    for (let e = 0; e < checkLists.length; e++) {
                        if (checkLists[e].name === checkListName) {
                            for (let a = 0; a < checkLists[e].checkList.length; a++) {
                                if (checkLists[e].checkList[a].name === checkName) {
                                    let checkResponse = !checkLists[e].checkList[a].check;
                                    if (checkValue) {
                                        if (checkValue === 'SÍ') {
                                            checkResponse = true;
                                        } else if (checkValue === 'NO') {
                                            checkResponse = false;
                                        }
                                    }
                                    checkLists[e].checkList[a].check = checkResponse;
                                    break;
                                }
                            }
                        }
                    }
                }
                newEvaluacionComponenteEtapa.tareas[i].checkLists = checkLists;
                break;
            }
        }
        changeEvaluacionReparacionComponenteEtapa(newEvaluacionComponenteEtapa);
    }

    return (
        <>
            {tareaCheckList.name !== '1' && requireElementTitle && <u><h5>{tareaCheckList.name}</h5></u>}
            <Table bordered size = 'sm'>
                {tareaCheckList.checkList.map((check) =>
                    <tr>
                        <td><h5>{check.name}</h5></td>
                        <td>
                            {tareaCheckList.select ?
                                <ReactSelect
                                    defaultValue = {((): SelectOption<string> => {
                                        let response = { label: 'NO', value: 'NO' }
                                        if (check.check) {
                                            response.label = 'SÍ';
                                            response.value = 'SÍ';
                                        }
                                        return response;
                                    })()}
                                    options = {[{ label: 'SÍ', value: 'SÍ' }, { label: 'NO', value: 'NO' }]}
                                    onChange = {(value: SelectOption<string>) => changeCheckList(tareaCheckList.name, check.name, value.value)}
                                    isDisabled = {isNotEditable}
                                    hideSelectedOptions
                                /> :
                                <div className = "centerTextCell">
                                    <input
                                        className = "largeCheckBox"
                                        type = "checkbox"
                                        defaultChecked = {check.check}
                                        onChange = {() => changeCheckList(tareaCheckList.name, check.name)}
                                        disabled = {isNotEditable}
                                    />
                                </div>
                            }
                        </td>
                    </tr>
                )}
            </Table>
        </>
    );
}

export default EvaluacionReparacionComponenteDetallesCheckLists;