import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import axios from 'axios';
//import customAxios from './api/axiosToken';
// import dotenv from 'dotenv';
import './assets/styles/app.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.scss';

const connectToProductionBackend = true; // Cambiar por "true" si es producción
const isOnlineTest = false; // Cambiar por "true" si es una prueba online
const useLocalAreaNetwork = false; // Cambiar por "true" si se usa red de área local
// dotenv.config();
// const PORT = connectToProductionBackend ? '80' : '3001';
// const URL = connectToProductionBackend ? 'http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com' : isOnlineTest ? 'http://190.47.150.223' : 'http://192.168.0.2';
// const URL = `http://192.168.0.2`;
// const URL = `http://190.47.150.223`;
// axios.defaults.baseURL = 'http://localhost:3001';
//axios.defaults.baseURL = 'http://192.168.1.165:80';
//axios.defaults.baseURL = 'http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com:80';
//customAxios.defaults.baseURL = `${process.env.REACT_APP_API_URL}:${PORT}` || `http://localhost:${PORT}`;
axios.defaults.baseURL = `${connectToProductionBackend ? 'http://ec2-15-228-240-5.sa-east-1.compute.amazonaws.com' : isOnlineTest ? 'http://gzanbh.ddns.net' : useLocalAreaNetwork ? 'http://192.168.0.2' : 'http://127.0.0.1'}:${connectToProductionBackend ? '80' : '3001'}`;

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
