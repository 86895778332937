import EstadoOrdenProceso from "../../models/estatusGeneral/EstadoOrdenProceso";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { SelectOption } from "../../models/optionsFilters/Select";


const useEstadosOrdenProceso = (condition?: string, roles?: string[], hasBeenReparada?: boolean, op?: OrdenProceso): SelectOption<EstadoOrdenProceso>[] | undefined => {
    if (condition === 'NUEVA') {
        if (roles?.includes('ADMINISTRADOR')) {
            return [
                { label: 'RECEPCIONADA', value: { estado: "RECEPCIONADA" } }
            ];
        }
    } else if (condition === 'RECEPCIONADA') {
        const response = [{label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}}];
        if (roles?.includes('JEFETALLER') || roles?.includes('ADMINISTRADOR')) {
            if (op?.evaluacion && op.evaluacion.fechaFinal) {
                response.push({label: 'EVALUADA', value: {estado: "EVALUADA"}});
            }
            if (roles.includes('ADMINISTRADOR')) {
                response.push({ label: 'NUEVA', value: { estado: "NUEVA" } });
                if (op?.entrega?.fechaEntrega) {
                    response.push({label: 'DESPACHADA', value: {estado: "DESPACHADA"}});
                }
            }
        } else if (roles?.includes('PLANIFICACION') && op?.entrega?.fechaEntrega) {
            response.push({label: 'DESPACHADA', value: {estado: "DESPACHADA"}});
        }
        return response;
    } else if (condition === 'EVALUADA') {
        if (roles?.includes('ADMINISTRADOR')) {
            return [
                {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}},
                {label: 'EVALUADA', value: {estado: "EVALUADA"}},
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}}
            ];
        } else if (roles?.includes('JEFETALLER')) {
            return [
                // {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}},
                {label: 'EVALUADA', value: {estado: "EVALUADA"}}
            ];
        } else if (roles?.includes('PLANIFICACION')) {
            return [
                {label: 'EVALUADA', value: {estado: "EVALUADA"}},
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}}
            ];
        }
    } else if (condition === 'PRESUPUESTADA') {
        if (roles?.includes('PLANIFICACION') || roles?.includes('ADMINISTRADOR')) {
            const response = [
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
                {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}}
            ];
            if (roles?.includes('ADMINISTRADOR')) {
                response.push({label: 'EVALUADA', value: {estado: "EVALUADA"}});
            }
            return response;
        }
    } else if (condition === 'CERRADA' && !hasBeenReparada) {
        if (roles?.includes('ADMINISTRADOR')) {
            return [
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
                {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}}
            ];
        }
    } else if (condition === 'APROBADA') {
        const response = [{label: 'APROBADA', value: {estado: "APROBADA"}}];
        if (roles?.includes('JEFETALLER')) {
            if (op?.reparacion?.fechaFinal) {
                response.push({label: 'REPARADA', value: {estado: "REPARADA"}});
            }
        } else if (roles?.includes('PLANIFICACION') || roles?.includes('ADMINISTRADOR')) {
            // response.push({label: 'APROBADA', value: {estado: "APROBADA"}});
            if (roles?.includes('ADMINISTRADOR')) {
                response.push({label: 'CERRADA', value: {estado: "CERRADA"}});
                response.push({label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}});
                response.push({label: 'REPARADA', value: {estado: "REPARADA"}});
            }
            if (op?.entrega?.fechaEntrega) {
                response.push({label: 'DESPACHADA', value: {estado: "DESPACHADA"}})
            }
        }
        return response;
        // } else if (roles?.includes('PLANIFICACION')) {
        //     const response = [
        //         {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
        //         {label: 'APROBADA', value: {estado: "APROBADA"}},
        //         {label: 'CERRADA', value: {estado: "CERRADA"}}
        //     ];
        //     if (op?.entrega?.fechaEntrega) {
        //         response.push({label: 'DESPACHADA', value: {estado: "DESPACHADA"}})
        //     }
        //     return response;
        // } else if (roles?.includes('ADMINISTRADOR')) {
        //     const response = [
        //         {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
        //         {label: 'APROBADA', value: {estado: "APROBADA"}},
        //         {label: 'CERRADA', value: {estado: "CERRADA"}}
        //     ];
        //     if (op?.reparacion && op.reparacion.fechaFinal) {
        //         response.push({label: 'REPARADA', value: {estado: "REPARADA"}});
        //     }
        //     if (op?.entrega?.fechaEntrega) {
        //         response.push({label: 'DESPACHADA', value: {estado: "DESPACHADA"}});
        //     }
        //     return response;
        // }
    } else if (condition === 'REPARADA') {
        if (roles?.includes('PLANIFICACION')) {
            if (op?.entrega) {
                return [
                    {label: 'REPARADA', value: {estado: "REPARADA"}},
                    {label: 'DESPACHADA', value: {estado: "DESPACHADA"}}
                ];
            } else {
                return [
                    {label: 'REPARADA', value: {estado: "REPARADA"}}
                ];
            }
        } else if (roles?.includes('ADMINISTRADOR')) {
            if (op?.entrega) {
                return [
                    {label: 'APROBADA', value: {estado: "APROBADA"}},
                    {label: 'REPARADA', value: {estado: "REPARADA"}},
                    {label: 'DESPACHADA', value: {estado: "DESPACHADA"}}
                ];
            } else {
                return [
                    {label: 'APROBADA', value: {estado: "APROBADA"}},
                    {label: 'REPARADA', value: {estado: "REPARADA"}}
                ];
            }
        }
    } else if (condition === 'DESPACHADA') {
        if (roles?.includes('PLANIFICACION') || roles?.includes('ADMINISTRADOR')) {
            const response = [
                // {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}},
                // {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}},
            ];
            if (roles?.includes('ADMINISTRADOR')) {
                response.push({label: 'REPARADA', value: {estado: "REPARADA"}});
                if (op?.evaluacion?.fechaFinal) {
                    response.push({label: 'APROBADA', value: {estado: "APROBADA"}});
                } else {
                    response.push({label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}});
                }
            }
            return response;
        } else if (roles?.includes('FINANZAS')) {
            return [
                {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}}
            ];
        }
    } else if (condition === 'CERRADA') {
        if (roles?.includes('ADMINISTRADOR')) {
            return [
                {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}}
            ];
        }
    } else if (condition === 'filtros') {
        return [
            {label: 'NUEVA', value: {estado: "NUEVA"}},
            {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}},
            {label: 'EVALUADA', value: {estado: "EVALUADA"}},
            {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
            {label: 'APROBADA', value: {estado: "APROBADA"}},
            {label: 'REPARADA', value: {estado: "REPARADA"}},
            {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
            {label: 'CERRADA', value: {estado: "CERRADA"}},
        ];
    } else if (condition === 'qr') {
        return [
            {label: 'REPARADA', value: {estado: "REPARADA"}},
            {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
            {label: 'CERRADA', value: {estado: "CERRADA"}},
        ];
    } else {
        return undefined;
    }
    /*
    if (roles?.includes('JEFETALLER') && (condition === 'RECEPCIONADA' || condition === 'EVALUADA')) {
        if (condition === 'RECEPCIONADA') {
            return [
                {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}},
                {label: 'EVALUADA', value: {estado: "EVALUADA"}}
            ];
        } else {
            return [
                {label: 'EVALUADA', value: {estado: "EVALUADA"}},
                {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}}
            ];
        }
    } else if (roles?.includes('PLANIFICACION') &&
        (condition === 'EVALUADA' || condition === 'PRESUPUESTADA' || condition === 'APROBADA' || (condition === 'CERRADA' && hasBeenReparada === false))) {
        if (condition === 'EVALUADA') {
            return [
                {label: 'EVALUADA', value: {estado: "EVALUADA"}},
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}}
            ];
        } else if (condition === 'PRESUPUESTADA') {
            return [
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
                {label: 'EVALUADA', value: {estado: "EVALUADA"}},
                {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}}
            ];
        } else if (condition === 'APROBADA') {
            return [
                {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
                {label: 'CERRADA', value: {estado: "CERRADA"}}
            ];
        } else {
            return [
                {label: 'CERRADA', value: {estado: "CERRADA" } },
                {label: 'APROBADA', value: {estado: "APROBADA" } },
                {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}}
            ];
        }
    } else if (roles?.includes('JEFETALLER') && (condition === 'APROBADA' || condition === 'REPARADA')) {
        if (condition === 'APROBADA') {
            return [
                {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'REPARADA', value: {estado: "REPARADA"}}
            ];
        } else {
            return [                
                {label: 'APROBADA', value: {estado: "APROBADA"}},
                {label: 'REPARADA', value: {estado: "REPARADA"}}
            ];
        }
    } else if (roles?.includes('PLANIFICACION') && (condition === 'REPARADA' || condition === 'DESPACHADA')) {
        if (condition === 'REPARADA') {
            return [
                {label: 'REPARADA', value: {estado: "REPARADA"}},
                {label: 'DESPACHADA', value: {estado: "DESPACHADA"}}
            ];
        } else {
            return [
                {label: 'REPARADA', value: {estado: "REPARADA"}},
                {label: 'DESPACHADA', value: {estado: "DESPACHADA"}}
            ];
        }
    } else if (roles?.includes('PLANIFICACION') &&
        (condition === 'DESPACHADA' || (condition === 'CERRADA' && hasBeenReparada === true))) {
            if (condition === 'DESPACHADA') {
                return [
                    {label: 'CERRADA', value: {estado: "CERRADA"}},
                    {label: 'DESPACHADA', value: {estado: "DESPACHADA"}}
                ];
            } else {
                return [
                    {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
                    {label: 'CERRADA', value: {estado: "CERRADA"}}
                ];
            }
    } else if (condition === 'filtros') {
        return [
            {label: 'NUEVA', value: {estado: "NUEVA"}},
            {label: 'RECEPCIONADA', value: {estado: "RECEPCIONADA"}},
            {label: 'EVALUADA', value: {estado: "EVALUADA"}},
            {label: 'PRESUPUESTADA', value: {estado: "PRESUPUESTADA"}},
            {label: 'APROBADA', value: {estado: "APROBADA"}},
            {label: 'REPARADA', value: {estado: "REPARADA"}},
            {label: 'DESPACHADA', value: {estado: "DESPACHADA"}},
            {label: 'CERRADA', value: {estado: "CERRADA"}},
        ];
    } else {
        return undefined;
    }
    */
}

export default useEstadosOrdenProceso;