import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { putOrdenProceso } from "../../api/OrdenesProcesoApi";
import useUENElectromecanico from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENElectromecanico";
import useUENLaboratorio from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENLaboratorio";
import useUENMecanico from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENMecanico";
import useUENPotencia from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENPotencia";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";
import useEstadosOrdenProceso from "../../hooks/estatusGeneral/useEstadosOrdenProceso";
import useTipoOrdenProceso from "../../hooks/estatusGeneral/useTipoOrdenProceso";
import useTipoProceso from "../../hooks/estatusGeneral/useTipoProceso";
import useFormState from "../../hooks/useFormState";
import { OrdenProceso, OrdenProcesoForm } from "../../models/estatusGeneral/OrdenProceso";
import { UnidadEstrategicaNegocio } from "../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../models/optionsFilters/Select";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import { bodyCardsFields } from "../../utils/bodyCards";
import { datosOPArray, formatOrdenProceso, formatOrdenProcesoForm } from "../../utils/estatusGeneral";
import AsignarComponente from "../componentes/AsignarComponente";
import EntregaOPComponent from "../entregas/EntregaOPComponent";
import EvaluacionOPComponent from "../evaluaciones/EvaluacionOPComponent";
import RecepcionOPComponent from "../recepciones/RecepcionOPComponent";
import ReparacionOPComponent from "../reparaciones/ReparacionOPComponent";
import DetallesOP from "./DetallesOP";
import ObservacionesOPComponent from "./ObservacionesOPComponent";
import OrdenProcesoFormComponent from "./OrdenProcesoFormComponent";
import { getDestinatariosEntregaEvaluacionReparacion } from "../../api/UsersApi";
import { generalSendEmailInModal, getCorreoOrdenProcesoAprobada } from "../../utils/emails";

interface SubOPDetailsProps {
    userEmail: string,
    userName: string,
    subOrdenProceso: OrdenProceso,
    changeSubOP: (subOP: OrdenProceso) => void,
    userRoles: string[],
    deleteSubOP: (setState: () => void, subOP?: string) => void,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const SubOPDetails = ({ subOrdenProceso, changeSubOP, userPermissionFunction, userRoles, deleteSubOP, userEmail, userName }: SubOPDetailsProps): ReactElement => {

    const estadoOrdenProcesoOptions = useEstadosOrdenProceso(subOrdenProceso.estado, userRoles, subOrdenProceso.reparacion ? true : false, subOrdenProceso);
    const tipoProcesoOptions = useTipoProceso(subOrdenProceso.APP);
    const tipoOrdenProcesoOptions = useTipoOrdenProceso();
    const centroCostoProcesoOptions = useCentroCostoProceso();
    const uenElectromecanicoOptions = useUENElectromecanico();
    const uenPotenciaOptions = useUENPotencia();
    const uenLaboratorioOptions = useUENLaboratorio();
    const uenMecanicoOptions = useUENMecanico();
    const [uenOptions, setUenOptions] = useState<SelectOption<UnidadEstrategicaNegocio>[]>();
    const [centroCostoAnterior, setCentroCostoAnterior] = useState<string>('INICIAL');
    const datosOPHTML = bodyCardsFields(datosOPArray(subOrdenProceso));
    const generalEditPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER'] });
    const fieldsEditPermission = userPermissionFunction({ roles: ['FINANZAS'] });

    const formStateOrdenProceso = useFormState(
        formatOrdenProcesoForm(
            subOrdenProceso,
            tipoOrdenProcesoOptions,
            tipoProcesoOptions,
            centroCostoProcesoOptions,
            uenElectromecanicoOptions,
            uenPotenciaOptions,
            uenLaboratorioOptions,
            uenMecanicoOptions
        )
    );

    const [showEditSubOP, setShowEditSubOP] = useState(false);
    const handleShowEditSubOP = () => setShowEditSubOP(true);
    const handleCloseEditSubOP = () => {
        formStateOrdenProceso.reset();
        setShowEditSubOP(false);
    };

    const onUenOptionsChange = (uens?: SelectOption<UnidadEstrategicaNegocio>[]): void => {
        setUenOptions(uens);
    }

    const onCentroCostoAnteriorChange = (cc: string): void => {
        setCentroCostoAnterior(cc);
    }

    const fixedDelete = (): void => {
        deleteSubOP(handleCloseEditSubOP, subOrdenProceso.codigoOP);
    }

    const submitForm = (ordenProcesoForm: OrdenProcesoForm, setIsEmailSending: (state: boolean) => void) => {
        putOrdenProceso(formatOrdenProceso(subOrdenProceso, ordenProcesoForm, undefined, undefined))
            .then(async (response) => {
                if (response.data.codigoOP) {
                    if (subOrdenProceso.estado !== response.data.estado && response.data.estado === 'APROBADA' && subOrdenProceso.estado !== 'REPARADA') {
                        setIsEmailSending(true);
                        await getDestinatariosEntregaEvaluacionReparacion()
                            .then(async (responseDestinatarios) => {
                                await generalSendEmailInModal(
                                    getCorreoOrdenProcesoAprobada(response.data, responseDestinatarios.data.to, responseDestinatarios.data.cc),
                                    userName,
                                    userEmail,
                                    setIsEmailSending
                                )
                            }).catch(() => {
                                cogoToast.error('No fue posible adquirir los destinatarios para enviar el correo');
                            });
                    }
                    setShowEditSubOP(false);
                    changeSubOP(response.data);
                    formStateOrdenProceso.setForm(formatOrdenProcesoForm(
                        response.data,
                        tipoOrdenProcesoOptions,
                        tipoProcesoOptions,
                        centroCostoProcesoOptions,
                        uenElectromecanicoOptions,
                        uenPotenciaOptions,
                        uenLaboratorioOptions,
                        uenMecanicoOptions,
                        // estadoOrdenProcesoOptions
                    ));
                    cogoToast.success('Sub orden de proceso editada');
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible editar la sub orden de proceso');
                }
                formStateOrdenProceso.setSubmitting(false);
            })
            .catch(() => {
                // cogoToast.error(error.message);
                formStateOrdenProceso.setSubmitting(false);
                formStateOrdenProceso.reset();
            })
    };

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        {subOrdenProceso.componente ? `${subOrdenProceso.componente.descripcion}` : `${subOrdenProceso.codigoOP}`}
                    </div>
                    {(generalEditPermission || fieldsEditPermission) &&
                        <div className = "buttons-group">
                            <Button onClick = {handleShowEditSubOP}>Editar Sub-OP</Button>
                        </div>
                    }
                </Card.Header>
                <Card.Body className = "cards-body-groups">
                    {datosOPHTML}
                </Card.Body>
            </Card >
            <DetallesOP
                ordenProceso = {subOrdenProceso}
                onOrdenProcesoChange = {changeSubOP}
                // _id = {_id}
                userPermission = {generalEditPermission || fieldsEditPermission}
            />
            <AsignarComponente
                ordenProceso = {subOrdenProceso}
                onOrdenProcesoChange = {changeSubOP}
                // _id = {_id}
                userPermissionFunction = {userPermissionFunction}
            />
            {subOrdenProceso.componente && (
                <RecepcionOPComponent
                    ordenProceso = {subOrdenProceso}
                    onOrdenProcesoChange = {changeSubOP}
                    // _id = {_id}
                    userPermissionFunction = {userPermissionFunction}
                />
            )}
            {subOrdenProceso.recepcion && (
                <EvaluacionOPComponent
                    ordenProceso = {subOrdenProceso}
                    onOrdenProcesoChange = {changeSubOP}
                    userPermissionFunction = {userPermissionFunction}
                />
            )}
            {subOrdenProceso.evaluacion && (
                <ReparacionOPComponent
                    ordenProceso = {subOrdenProceso}
                    onOrdenProcesoChange = {changeSubOP}
                    userPermissionFunction = {userPermissionFunction}
                />
            )}
            {subOrdenProceso.recepcion &&
                // subOrdenProceso.reparacion && 
                (<EntregaOPComponent
                    ordenProceso = {subOrdenProceso}
                    onOrdenProcesoChange = {changeSubOP}
                    userPermissionFunction = {userPermissionFunction}
                />)
            }
            <ObservacionesOPComponent
                ordenProceso = {subOrdenProceso}
                onOrdenProcesoChange = {changeSubOP}
                userPermission = {generalEditPermission || fieldsEditPermission}
            />
            {showEditSubOP && (
                <Modal show = {showEditSubOP} onHide = {handleCloseEditSubOP}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar OP</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <OrdenProcesoFormComponent
                            formState = {formStateOrdenProceso}
                            submitForm = {submitForm}
                            onCancel = {handleCloseEditSubOP}
                            onDelete = {subOrdenProceso.componente ? undefined : fixedDelete}
                            tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                            tipoProcesoOptions = {tipoProcesoOptions}
                            centroCostoProcesoOptions = {centroCostoProcesoOptions}
                            uenOptions = {uenOptions}
                            onUenOptionsChange = {onUenOptionsChange}
                            centroCostoAnterior = {centroCostoAnterior}
                            onCentroCostoAnteriorChange = {onCentroCostoAnteriorChange}
                            uenElectromecanicoOptions = {uenElectromecanicoOptions}
                            uenPotenciaOptions = {uenPotenciaOptions}
                            uenLaboratorioOptions = {uenLaboratorioOptions}
                            uenMecanicoOptions = {uenMecanicoOptions}
                            estadoOrdenProcesoOptions = {estadoOrdenProcesoOptions}
                            currentEstado = {subOrdenProceso.estado}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default SubOPDetails;