import { ReactElement } from "react";
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import { Componente } from "../../models/componentes/Componente";
import DocumentsManager from "./DocumentsManager";
import { deleteListadoRepuestos, getListadoRepuesto, postListadoRepuestos } from "../../api/ComponentesApi";

interface ListadoRepuestosProps {
    componente: Componente,
    userPermission: boolean,
    isNotEditable?: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    documentosListado?: ArchivoNombre[]
}

const ListadoRepuestos = ({ componente, userPermission, isNotEditable, isBusy, changeBusyState, documentosListado }: ListadoRepuestosProps): ReactElement => (
    <DocumentsManager
        isMultiple
        documentosListado = {documentosListado}
        isNotEditable = {isNotEditable}
        userPermission = {userPermission}
        title = {"Listado de repuestos (Kit básico)"}
        externalIsBusy = {isBusy}
        changeExternalBusyState = {changeBusyState}
        getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getListadoRepuesto({ _id: _id }, onDownloadProgressFunction)}
        postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postListadoRepuestos({ modeloComponente: componente.modelo, descripcionComponente: componente.descripcion, archivo: archivo }, onUploadProgressFunction)}
        deleteDocumento = {(_id: string) => deleteListadoRepuestos({ _id: _id })}
        // getDocumentosListado = {() => getDocumentosListadoRepuestoslListado({ modeloComponente: componente.modelo, descripcionComponente: componente.descripcion })}
    />
)

export default ListadoRepuestos;