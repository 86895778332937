import { base64ToBlob } from "base64-blob";
import { jsPDF } from "jspdf";
import { Archivo } from "../models/Archivos";
import { saveAs } from "file-saver";
import { OrdenProcesoTabla } from "../models/estatusGeneral/OrdenProceso";
import xlsx from "json-as-xlsx";
import { Attachment } from "../models/Email";
import { PDFDocument } from "pdf-lib";
// import logotipo from '../../../assets/images/logotipo.jpg';
// import camion from '../../../assets/informesImages/camion.jpg';

export const combinePDFs = async (title: string, mainPDF: string, additionalPDFs: Archivo[]): Promise<void> => {
  const response = await PDFDocument.create();
  const addPDF = async (base64: string) => {
    const pdfDoc = await PDFDocument.load(base64);
    const pdfDocPages = await response.copyPages(pdfDoc, pdfDoc.getPageIndices());
    while (pdfDocPages.length > 0) {
      response.addPage(pdfDocPages.shift());
    }
  };
  response.setTitle(title);
  await addPDF(mainPDF);
  for (let i = 0; i < additionalPDFs.length; i++) {
    const base64 = additionalPDFs[i].base64;
    if (base64 && additionalPDFs[i].nombre.endsWith('.pdf')) {
      await addPDF(base64);
    }
  }
  saveAs(new Blob([await response.save()], { type: 'application/pdf' }), title);
}

export const rotateImageBase64 = async (base64Image: string, type: string): Promise<string> => new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64Image;
    img.onload = () => {
      if (img.height > img.width) {
        // Solo rota la imagen si la altura es mayor que el ancho
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

        // Rotar la imagen
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(-90 * (Math.PI / 180)); // Rotación hacia la izquierda
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        // Obtén la imagen rotada en formato Base64
        const rotatedBase64 = canvas.toDataURL(type); // Puedes ajustar el tipo MIME según tus necesidades

        resolve(rotatedBase64);
      } else {
        // Si la altura no es mayor que el ancho, no es necesario rotar
        resolve(base64Image);
      }
    };
    img.onerror = (error) => {
      reject(error);
    };
});

export const fileToBase64 = async (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event: ProgressEvent<FileReader>) => {
    if (event.target && typeof event.target.result === 'string') {
      const base64String = event.target.result;
      resolve(base64String);
    } else {
      reject(new Error('Error al leer el archivo en formato Base64'));
    }
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsDataURL(file);
});


export const blobToBase64 = async (file: Blob): Promise<string | undefined> => {
  var reader = new FileReader();
  reader.readAsDataURL(file as Blob);  
  return new Promise((reslove, reject) => {
    reader.onload = () => reslove(reader.result as any);
    reader.onerror = (error) => reject(error);
  });
}

export const downloadBlobFromBase64 = async (base64: string, nombre: string): Promise<void> => {
  saveAs(await base64ToBlob(base64), nombre);
}

export const areFilesOver10MB = async (
  attachments: Attachment[],
  changeIsEmailOverloadedState: (state: boolean) => void,
  changeIsCalculatingFilesSizeState: (state: boolean) => void,
  changeCurrentAttachmentsSize: (size: number) => void
): Promise<boolean> => {
  changeIsCalculatingFilesSizeState(true);
  let totalSize: number = 0;
  let response: boolean;
  for (let i = 0; i < attachments.length; i++) {
    totalSize = totalSize + (await base64ToBlob(attachments[i].content)).size;
  }
  changeCurrentAttachmentsSize(totalSize);
  if (totalSize > 10223616) {
    response = true;
  } else {
    response = false;
  }
  changeIsEmailOverloadedState(response);
  changeIsCalculatingFilesSizeState(false);
  return response;
}

/*
//PDF con 1 imagen por página
export const imagenesEnPDF2 = (archivos: Archivo[]): string => {
  const doc = new jsPDF();
  const width = 100;
  const height = 95;
  archivos.map((archivo) => {
    if (archivo.base64) {
      doc.addPage();
      doc.addImage(
        archivo.base64,
        archivo.nombre.substr(archivo.nombre.indexOf(".") + 1),
        5,
        5,

      );
    }
  })
}
*/

//PDF con 6 imágenes por página
export const imagenesEnPDF = (archivos: Archivo[]): string => {
  const doc = new jsPDF();
  const width = 100;
  const height = 95;
  let counterPerPage = 1;
  archivos.map((archivo, index) => {
    if (archivo.base64) {
      if (index !== 0 && (index % 6) === 0) {
        doc.addPage();
      }
      let currentX, currentY;
      if (((index + 1) % 2) !== 0) {
        currentX = 5;
      } else {
        currentX = width + 5;
      }
      if (counterPerPage === 1 || counterPerPage === 2) {
        currentY = 5;
      } else if (counterPerPage === 3 || counterPerPage === 4) {
        currentY = height + 5;
      } else {
        currentY = (height * 2) + 5;
      }
      doc.addImage(
        archivo.base64,
        archivo.nombre.substr(archivo.nombre.indexOf(".") + 1),
        currentX,
        currentY,
        width,
        height,
        `${archivo._id}`
      );
      if (counterPerPage !== 6) {
        counterPerPage = counterPerPage + 1;
      } else {
        counterPerPage = 1;
      }
    }
    return null;
  });
  return doc.output('dataurlstring');
}

// export const getDownloadProgress = (setProgress: (progress: number) => void): (progressEvent: any) => void => {
//   return (progressEvent: any) => {
//     const porcentaje = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//     setProgress(porcentaje);
//   }
// }

export const jsonToExcel = (json: OrdenProcesoTabla[]) => xlsx(
  [{
    sheet: 'Estatus General',
    columns: [
      { label: 'ITEM', value: "item" },
      { label: 'FECHA RECEPCIÓN', value: "fechaRecepcion" },
      { label: 'CC', value: "centroCosto" },
      { label: 'DESCRIPCIÓN DEL COMPONENTE', value: "descripcionComponente" },
      { label: 'MODELO', value: "modeloComponente" },
      { label: 'DIFERENCIA DE MODELO', value: 'subModeloComponente' },
      { label: 'N° DE PARTE CLIENTE', value: "numeroParteCliente" },
      { label: 'N/S', value: "numeroSerieComponente" },
      { label: 'CLIENTE', value: "cliente" },
      { label: 'ORDEN DE CLIENTE', value: "ordenTrabajoCliente" },
      { label: 'ORDEN PROCESO', value: "codigoOP" },
      { label: 'PROCESO', value: "proceso" },
      { label: 'ORACLE ZAL.', value: "codigoSolped" },
      { label: 'TIPO OP', value: "tipoOP" },
      { label: 'G/D CLIENTE', value: "numeroGuiaDespachoRecepcion" },
      { label: 'ETAPA PROCESO', value: "etapaProceso" },
      { label: 'N° COTIZACIÓN', value: "numeroCotizacion" },
      { label: 'FECHA ENVÍO PPTO', value: "fechaEnvioPPTO" },
      { label: 'ORDEN DE COMPRA', value: "ordenCompra" },
      { label: 'FECHA DE RECEPCIÓN OC', value: "fechaRecepcionOC" },
      { label: 'FECHA ENTREGA SEGÚN OC', value: "fechaEntregaOC" },
      { label: 'FECHA DE ENTREGA', value: "fechaEntrega" },
      { label: 'GUÍA', value: "numeroGuiaDespachoEntrega" },
      { label: 'Nº FACTURA ELECTRANS', value: "numeroFacturaElectrans" },
      { label: 'FECHA DE FACTURA', value: "fechaFactura" },
      { label: 'ESTADO', value: "estado" },
      { label: 'OBSERVACIONES', value: "observaciones" }
    ],
    content: json.map((op) => ({ ...op, subModeloComponente: `${op.modeloComponente}${op.subModeloComponente}` }))
  }],
  { fileName: `EstatusGeneral ${(new Date()).getFullYear()}`, extraLength: 4 }
);

export const isDBFull = (dbSize: string, fileSize: number): boolean => {
  if ((parseInt(dbSize) + (fileSize / 1000000)) > 40960) {
      return true;
  } else {
      return false;
  }
}