import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { deleteUser, putUser } from "../../api/UsersApi";
import useFormState from "../../hooks/useFormState";
import useEstadoUsuarioOptions from "../../hooks/users/useEstadoUsuario";
import useRolUsuarioOptions from "../../hooks/users/useRolUsuario";
import { User, UserForm } from "../../models/users/Users";
import { formatUser, formatUserForm } from "../../utils/users";
import UserFormComponent from "./UserFormComponent";

interface UserDetailsProps {
    user: User,
    onUserChange: (user: User) => void,
    logout: () => void,
    rolPermissionEditButton: boolean,
    userEmail: string,
    rolPermissionEditFields: (isSubmitting: boolean, field?: string) => boolean
}

const UserDetails = ({ user, onUserChange, logout, rolPermissionEditButton, userEmail, rolPermissionEditFields }: UserDetailsProps): ReactElement => {

    const estadoUsuarioOptions = useEstadoUsuarioOptions();
    const rolesUsuarioOptions = useRolUsuarioOptions();
    const history = useHistory();

    const formStateUsuario = useFormState(
        formatUserForm(user, estadoUsuarioOptions, rolesUsuarioOptions)
    );

    const [showEditUser, setShowEditUser] = useState(false);
    const handleShowEditUser = () => setShowEditUser(true);
    const handleCloseEditUser = () => {
        formStateUsuario.reset();
        setShowEditUser(false);
    }

    const submitForm = (userForm: UserForm) => {
        putUser(formatUser(userForm), user.email)
            .then((response) => {
                onUserChange(response.data);
                handleCloseEditUser();
                cogoToast.success('Usuario editado');
                formStateUsuario.setForm(
                    formatUserForm(response.data, estadoUsuarioOptions, rolesUsuarioOptions)
                );
            })
            .catch(() => {
                formStateUsuario.setSubmitting(false);
                formStateUsuario.reset();
                cogoToast.error('No fue posible editar el usuario');
            })
    }

    const deleteCurrentUser = (): void => {
        formStateUsuario.setSubmitting(true);
        deleteUser(user.email)
            .then(() => {
                cogoToast.success('Usuario eliminado');
                if (user.email !== userEmail) {
                    history.push('/users');
                } else {
                    logout();
                }
            })
            .catch(() => {
                cogoToast.error('No fue posible eliminar al usuario');
            });
    };

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        {user.nombre} {user.apellidos}
                    </div>
                    <div className = "buttons-group">
                        <Button onClick = {handleShowEditUser} disabled = {!rolPermissionEditButton}>Editar</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <h3>Email / ID: {user.email}</h3>
                    </Row>
                    <Row>
                        <h3>Estado: {user.estado}</h3>
                    </Row>
                    <Row>
                        <h3>Roles: {user.roles?.map((rol, index) => {
                                let rolText = rol;
                                if (rol === 'PLANIFICACION') {
                                    rolText = 'PLANIFICACIÓN';
                                }
                                if (user.roles && index + 1 < user.roles?.length) {
                                    return ( `${rolText}, ` );
                                } else {
                                    return ( rolText );
                                }
                            })}
                        </h3>
                    </Row>
                </Card.Body>
            </Card>
            {showEditUser &&
                (<Modal show = {showEditUser}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Editar Usuario</Modal.Title>
                        </Col>
                    </Modal.Header> 
                    <Modal.Body>
                        <UserFormComponent
                            formState = {formStateUsuario}
                            submitForm = {submitForm}
                            onDelete = {deleteCurrentUser}
                            onCancel = {handleCloseEditUser}
                            estadoUsuarioOptions = {estadoUsuarioOptions}
                            rolesUsuarioOptions = {rolesUsuarioOptions}
                            rolPermissionEditFields = {rolPermissionEditFields}
                        />
                    </Modal.Body>
                </Modal>)}
        </>
    );
}

export default UserDetails;