import { ReactElement, useContext, useEffect } from "react";
import EmailsOperadores from "../components/configuraciones/emailsOperadores/EmailsOperadores";
import ReporteOPNS from "../components/configuraciones/ReporteOPNS";
import AuthContext from "../contexts/AuthContext";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { getUserPermissionFunction } from "../utils/users";
import ParticipantesAdicionales from "../components/configuraciones/participantesAdicionales/ParticipantesAdicionales";
import DestinatariosEntregaEvaluacionReparacion from "../components/configuraciones/DestinatariosEntregaEvaluacionReparacion";

const ConfiguracionesPage = (): ReactElement => {

    const { userRoles } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();
    const evaluacionesReparacionesPermission = getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'JEFETALLER'] });

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'Configuraciones',
                inactive: true
            }
        ]);
    }, [breadcrumb]);

    return (
        <>
            {evaluacionesReparacionesPermission &&
                <>
                    <ReporteOPNS
                        userPermission = {evaluacionesReparacionesPermission}
                    />
                    <DestinatariosEntregaEvaluacionReparacion
                        userPermission = {evaluacionesReparacionesPermission}
                    />
                    <EmailsOperadores/>
                    <ParticipantesAdicionales
                        userPermission = {evaluacionesReparacionesPermission}
                    />
                    
                </>
            }
        </>
    );
}

export default ConfiguracionesPage;