import { ReactElement } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { DetallesOPForm } from "../../models/estatusGeneral/DetallesOP";
import FormButtons from "../form/buttons/FormButtons";
import { Input } from "../form/input/Input";
import DateSelect from "../form/DateSelect";

interface DetallesOPFormComponentProps {
    formState: FormState<DetallesOPForm>,
    submitForm: (detallesOPForm: DetallesOPForm) => void,
    onCancel: () => void,
}

const DetallesOPFormComponent = ({ formState, submitForm, onCancel }: DetallesOPFormComponentProps): ReactElement => {

    const {
        value: detallesOPForm,
        handleFieldChange,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
    } = formState;

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(detallesOPForm);
    }

    return (
        <>
            <Form>
                <Row>
                    <Input
                        title = "Orden Trabajo Cliente"
                        name = "ordenTrabajoCliente"
                        value = {detallesOPForm.ordenTrabajoCliente}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Input
                        title = "SAP"
                        name = "codigoSap"
                        value = {detallesOPForm.codigoSap}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Input
                        title = "SOLPED"
                        name = "codigoSolped"
                        value = {detallesOPForm.codigoSolped}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Col>
                        <Input
                            title = "Orden de Compra"
                            name = "ordenCompraCodigo"
                            value = {detallesOPForm.ordenCompraCodigo}
                            onChangeWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                        />
                    </Col>
                    <Col lg = {3}>
                        <Input
                            title = "Items"
                            name = "ordenCompraItems"
                            value = {detallesOPForm.ordenCompraItems}
                            onChangeWithName = {handleFieldChange}
                            disabled = {isSubmitting}
                        />
                    </Col>
                </Row>
                <Row>
                    <Input
                        title = "N° Parte Cliente"
                        name = "numeroParteCliente"
                        value = {detallesOPForm.numeroParteCliente}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Input
                        title = "N° Cotización"
                        name = "numeroCotizacion"
                        value = {detallesOPForm.numeroCotizacion}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                {/* <Row>
                    <Input
                        title = "Fecha Recepcion (Orden Compra)"
                        name = "fechaRecepcionOC"
                        type = 'date'
                        value = {detallesOPForm.fechaRecepcionOC}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Input
                        title = "Fecha Entrega (Orden Compra)"
                        name = "fechaEntregaOC"
                        type = 'date'
                        value = {detallesOPForm.fechaEntregaOC}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <Input
                        title = "Fecha Factura"
                        name = "fechaFactura"
                        type = 'date'
                        value = {detallesOPForm.fechaFactura}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row> */}
                <Row>
                    <Input
                        title = "N° Factura Electrans"
                        name = "numeroFacturaElectrans"
                        value = {detallesOPForm.numeroFacturaElectrans}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                    />
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha Recepcion (Orden Compra)'
                        name = 'fechaRecepcionOC'
                        onChangeField = {handleFieldChange}
                        selected = {detallesOPForm.fechaRecepcionOC}
                        disabled = {isSubmitting}
                        isClearable
                    />
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha Entrega (Orden Compra)'
                        name = 'fechaEntregaOC'
                        onChangeField = {handleFieldChange}
                        selected = {detallesOPForm.fechaEntregaOC}
                        disabled = {isSubmitting}
                        isClearable
                    />
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha Factura'
                        name = 'fechaFactura'
                        onChangeField = {handleFieldChange}
                        selected = {detallesOPForm.fechaFactura}
                        disabled = {isSubmitting}
                        isClearable
                    />
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha envío presupuesto'
                        name = 'fechaEnvioPPTO'
                        onChangeField = {handleFieldChange}
                        selected = {detallesOPForm.fechaEnvioPPTO}
                        disabled = {isSubmitting}
                        isClearable
                    />
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha recepción factura'
                        name = 'fechaRecepcionFactura'
                        onChangeField = {handleFieldChange}
                        selected = {detallesOPForm.fechaRecepcionFactura}
                        disabled = {isSubmitting}
                        isClearable
                    />
                </Row>
                {/* <div className = "form-group">
                    Fecha Recepcion (Orden Compra)
                </div>
                <DatePicker
                    className = "form-control is-invalid"
                    dateFormat = "dd/MM/yyyy"
                    selected = {
                        detallesOPForm.fechaRecepcionOC ?
                        parse(detallesOPForm.fechaRecepcionOC, 'dd/MM/yyyy', new Date()) :
                        null
                    }
                    onChange = {(date): void => {
                        let changedFilter;
                        if (date) {
                            changedFilter = format((date as Date), "dd/MM/yyyy");
                        } else {
                            changedFilter = '';
                        }
                        handleFieldChange("fechaRecepcionOC", changedFilter);
                    }}
                    disabled = {isSubmitting}
                    // isClearable
                    // maxDate = {new Date()}
                    // minDate = {detallesOPForm.fechaRecepcionOC ? detallesOPForm.fechaRecepcionOC : new Date(0)}
                /> 
                <div className = "form-group">
                    Fecha Entrega (Orden Compra)
                </div>
                <DatePicker
                    className = "form-control"
                    dateFormat = "dd/MM/yyyy"
                    selected = {
                        detallesOPForm.fechaEntregaOC ?
                        parse(detallesOPForm.fechaEntregaOC, 'dd/MM/yyyy', new Date()) :
                        null
                    }
                    onChange = {(date): void => {
                        let changedFilter;
                        if (date) {
                            changedFilter = format((date as Date), "dd/MM/yyyy");
                        } else {
                            changedFilter = '';
                        }
                        handleFieldChange("fechaEntregaOC", changedFilter);
                    }}
                    disabled = {isSubmitting}
                    // isClearable
                    // maxDate = {new Date()}
                    // minDate = {detallesOPForm.fechaRecepcionOC ? detallesOPForm.fechaRecepcionOC : new Date(0)}
                />
                <div className = "form-group">
                    Fecha Factura
                </div>
                <DatePicker
                    className = "form-control"
                    dateFormat = "dd/MM/yyyy"
                    selected = {
                        detallesOPForm.fechaFactura ?
                        parse(detallesOPForm.fechaFactura, 'dd/MM/yyyy', new Date()) :
                        null
                    }
                    onChange = {(date): void => {
                        let changedFilter;
                        if (date) {
                            changedFilter = format((date as Date), "dd/MM/yyyy");
                        } else {
                            changedFilter = '';
                        }
                        handleFieldChange("fechaFactura", changedFilter);
                    }}
                    disabled = {isSubmitting}
                    // isClearable
                    // maxDate = {new Date()}
                    // minDate = {detallesOPForm.fechaRecepcionOC ? detallesOPForm.fechaRecepcionOC : new Date(0)}
                />
                <div className = "form-group">
                    Fecha envío presupuesto
                </div>
                <DatePicker
                    className = "form-control"  
                    dateFormat = "dd/MM/yyyy"
                    selected = {
                        detallesOPForm.fechaEnvioPPTO ?
                        parse(detallesOPForm.fechaEnvioPPTO, 'dd/MM/yyyy', new Date()) :
                        null
                    }
                    onChange = {(date): void => {
                        let changedFilter;
                        if (date) {
                            changedFilter = format((date as Date), "dd/MM/yyyy");
                        } else {
                            changedFilter = '';
                        }
                        handleFieldChange("fechaEnvioPPTO", changedFilter);
                    }}
                    disabled = {isSubmitting}
                    // isClearable
                    // maxDate = {new Date()}
                    // minDate = {detallesOPForm.fechaRecepcionOC ? detallesOPForm.fechaRecepcionOC : new Date(0)}
                />
                <div className = "form-group">
                    Fecha recepción factura
                </div>
                <DatePicker
                    className = "form-control"
                    dateFormat = "dd/MM/yyyy"
                    selected = {
                        detallesOPForm.fechaRecepcionFactura ?
                        parse(detallesOPForm.fechaRecepcionFactura, 'dd/MM/yyyy', new Date()) :
                        null
                    }
                    onChange = {(date): void => {
                        let changedFilter;
                        if (date) {
                            changedFilter = format((date as Date), "dd/MM/yyyy");
                        } else {
                            changedFilter = '';
                        }
                        handleFieldChange("fechaRecepcionFactura", changedFilter);
                    }}
                    disabled = {isSubmitting}
                    // isClearable
                    // maxDate = {new Date()}
                    // minDate = {detallesOPForm.fechaRecepcionOC ? detallesOPForm.fechaRecepcionOC : new Date(0)}
                />*/}
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                />
            </Form>
        </>
    );
}

export default DetallesOPFormComponent;