import { ReactElement, useContext } from 'react';
import LayoutContext from '../../../contexts/LayoutContext';
import classNames from 'classnames';
import getMenuBarItems from '../../../utils/menubar';
import MenuBarItem from './MenuBarItem';
import AuthContext from '../../../contexts/AuthContext';

const MenuBar = (): ReactElement => {
    const { isMenuBarActive } = useContext(LayoutContext);
    const { userRoles } = useContext(AuthContext);

    const sidebarClassNames = classNames('menubar', { active: isMenuBarActive, });

    return (
        <div className = {sidebarClassNames}>
            <div className = "menubar-container">
                {getMenuBarItems(userRoles).map((menuBarItem) => (
                    <MenuBarItem menuBarItemModel = {menuBarItem} />
                ))}
            </div>
        </div>
    )

    /*
    return (
        <div className = {sidebarClassNames}>
            <ul className = "menubar-container">
                {getMenuBarItems(userRoles).map((menuBarItem) => (
                    <MenuBarItem menuBarItemModel = {menuBarItem} />
                ))}
            </ul>
        </div>
    )
    */
}

export default MenuBar;