import { ReactElement } from "react";
import { Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { EntregaForm } from "../../models/Entregas";
import { Input } from "../form/input/Input";
import FormButtons from "../form/buttons/FormButtons";
import DateSelect from "../form/DateSelect";

interface EntregaFormComponentProps {
    formState: FormState<EntregaForm>,
    submitForm: (entregaForm: EntregaForm) => void,
    onCancel: () => void,
    minDate: string,
    isCreating?: boolean
}

const EntregaFormComponent = ({ formState, submitForm, onCancel, minDate, isCreating }: EntregaFormComponentProps): ReactElement => {
    
    const {
        value: entregaForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    const cancelDelete = () => setConfirmingDelete(false);

    const isFormInvalid = (): boolean => {
        return !entregaForm.fechaEntrega
        || !entregaForm.numeroGuiaDespacho;
    }

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid()) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(entregaForm);
    }

    /*
    const isDisabled = (): boolean => {
        if (isCreating) {
            return isSubmitting;
        } else {
            return true;
        }
    }
    */

    return (
        <>
            <Form>
                <Row>
                    <Input
                        title = "Número guía de despacho"
                        name = "numeroGuiaDespacho"
                        value = {entregaForm.numeroGuiaDespacho}
                        onChangeWithName = {handleFieldChange}
                        disabled = {isSubmitting}
                        placeholder = 'Obligatorio'
                    />
                </Row>
                <Row>
                    <DateSelect
                        title = 'Fecha de Entrega'
                        name = 'fechaEntrega'
                        onChangeField = {handleFieldChange}
                        invalid = {areValidationsEnabled && !entregaForm.fechaEntrega}
                        selected = {entregaForm.fechaEntrega}
                        disabled = {isSubmitting}
                        minDate = {minDate}
                        placeholder = 'Obligatorio'
                    />
                    {/* <div className = "form-group">
                        Fecha de Entrega
                    </div>
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            entregaForm.fechaEntrega ?
                            parse(entregaForm.fechaEntrega, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFieldChange("fechaEntrega", changedFilter);
                        }}
                        disabled = {isSubmitting}
                        minDate = {parse(minDate, "dd/MM/yyyy", new Date())}
                        placeholderText = 'Obligatorio'
                    /> */}
                </Row>
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    // onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    // onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    isFormInvalid={isFormInvalid()}
                    areValidationsEnabled={areValidationsEnabled}
                />
            </Form>
        </>
    )
}

export default EntregaFormComponent;