import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { getComponenteReparado } from "../../api/ComponentesApi";
import ComponenteReparadoDetailsComponent from "../../components/componentes/ComponenteReparadoDetailsComponent";
import AuthContext from "../../contexts/AuthContext";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { ComponenteReparadoDetails } from "../../models/componentes/Componente";
import { getUserPermissionFunction } from "../../utils/users";

const QRComponenteReparadoPage = (): ReactElement => {
    
    const [ componenteReparado, setComponenteReparado ] = useState<ComponenteReparadoDetails>();
    const { _id } = useParams<{ _id: string }>();
    const { userRoles } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const userPermission = getUserPermissionFunction(userRoles);

    // const rolPermission = (): boolean => {
    //     if (userRoles.includes('ADMINISTRADOR') ||
    //         userRoles.includes('PLANIFICACION')) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    const isRolCliente = userPermission({ roles: ['CLIENTE'] });

    const handleOnComponenteReparadoChange = (componenteReparadoDetails: ComponenteReparadoDetails) => {
        setComponenteReparado(componenteReparadoDetails);
    }

    useEffect(() => {
        const jwt = localStorage.getItem('jwt');
        if (!componenteReparado && jwt) {
            getComponenteReparado(_id)
                .then((response) => {
                    if (response.data.codigoOP) {
                        setComponenteReparado(response.data);
                    } else {
                        // cogoToast.error(response.data.message);
                        cogoToast.error('No fue posible obtener el componente reparado');
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    // cogoToast.error(error);
                    cogoToast.error('No fue posible obtener el componente reparado');
                    setIsLoading(false);
                })
        }
    }, [_id])

    useEffect(() => {
        if (componenteReparado) {
            if (!isRolCliente) {
                breadcrumb.setItems([
                    {
                        label: 'QR Componentes',
                        href: '/componentesQR'
                    },
                    {
                        label: componenteReparado.codigoOP,
                        inactive: true
                    }
                ]);
            } else {
                breadcrumb.setItems([
                    {
                        label: "Componente Reparado",
                        inactive: true
                    }
                ]);
            }
        }
    }, [componenteReparado]);

    if (!componenteReparado) {
        if (isLoading) {
            return (
                <>
                    <Card>
                        <Card.Body className = "text-center">
                            <Spinner animation = "border" />
                        </Card.Body>
                    </Card>
                </>
            );
        } else {
            return (
                <>
                    <Card>
                        <Card.Body className = "text-center">
                            <p className = "text-center">No fue posible cargar el componente</p>
                        </Card.Body>
                    </Card>
                </>
            );
        }
    } else {
        return (
            <ComponenteReparadoDetailsComponent
                componenteReparado = {componenteReparado}
                onComponenteReparadoChange = {handleOnComponenteReparadoChange}
                userPermission = {userPermission({ roles: ['ADMINISTRADOR', 'PLANIFICACION', 'CALIDAD'] })}
                isRolCliente = {isRolCliente}
            />
        );
    }
}

export default QRComponenteReparadoPage;