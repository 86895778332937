import { Dispatch, ReactElement, ReactText, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { SelectOption } from "../../models/optionsFilters/Select";
import { Persona, UsersFilters } from "../../models/users/Users";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";
import SelectMultiple from "../form/select/SelectMultiple";

interface UsersFiltersComponentProps {
    usersFilters: UsersFilters,
    onFiltersChange: Dispatch<SetStateAction<UsersFilters>>,
    rolUsuariosOptions: SelectOption<string>[],
    estadoUsuariosOptions?: SelectOption<string>[],
    // isForSelection?: boolean
    existingPersonas?: Persona[]
}

const UsersFiltersComponent = ({
    usersFilters, onFiltersChange, estadoUsuariosOptions, rolUsuariosOptions, existingPersonas
}: UsersFiltersComponentProps): ReactElement => {

    const existingPersonasString = existingPersonas?.map((persona) => persona.email);

    const handleFilterChange = (name: string, value?: ReactText | ReactText[]): void => {
        onFiltersChange((prevFilters) => {
            let valueResponse = value;
            if (name === 'roles' && (!valueResponse || (valueResponse as ReactText[]).length === 0)) {
                valueResponse = rolUsuariosOptions.map((rol) => rol.value);
            }
            return {
                ...prevFilters,
                existingUsers: existingPersonasString,
                [name]: valueResponse
            }
        });
    };

    // const handleFilterChangeWithVariableName = (name: string): (value?: ReactText | ReactText[]) => void => {
    //     return ((value?: ReactText | ReactText[]) => {
    //         handleFilterChange(name, value);
    //     })
    // }

    return (
        <div className = "body-filters">
            <Row>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.nombre}
                        name = "nombre"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Nombre"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.apellidos}
                        name = "apellidos"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Apellidos"
                    />
                </Col>
                <Col lg = {3}>
                    <Input
                        value = {usersFilters.email}
                        name = "email"
                        onChangeWithName = {handleFilterChange}
                        type = "text"
                        title = "Email"
                    />
                </Col>
                <Col lg = {3}>
                    <SelectMultiple
                        title = "Rol"
                        options = {rolUsuariosOptions}
                        onFieldChange = {(value?: SelectOption<string>[]) => handleFilterChange('roles', value?.map((value) => value.value))}
                    />
                    {/* <Select
                        title = "Rol"
                        name = "rol"
                        options = {rolUsuariosOptions}
                        onSelectWithName = {(name, rol): void => {
                            handleFilterChange(name, rol.value.rol);
                        }}
                        onClearWithName = {(name): void => handleFilterChange(name, '')}
                    /> */}
                </Col>
                {estadoUsuariosOptions && 
                    (<Col lg = {3}>
                        <Select
                            title = "Estado"
                            name = "estado"
                            options = {estadoUsuariosOptions}
                            onSelectWithName = {(name, estado): void => {
                                handleFilterChange(name, estado.value);
                            }}
                            onClearWithName = {(name): void => handleFilterChange(name, '')}
                        />
                    </Col>)
                }
            </Row>
        </div>
    );
}

export default UsersFiltersComponent;