import { ReactElement } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { ComponenteReparadoForm } from "../../models/componentes/Componente";
import FormButtons from "../form/buttons/FormButtons";
import { Input } from "../form/input/Input";

interface ComponenteReparadoFormComponentProps {
    formState: FormState<ComponenteReparadoForm>,
    submitForm: (componenteReparadoForm: ComponenteReparadoForm) => void,
    onDelete?: () => void,
    onCancel: () => void
}

const ComponenteReparadoFormComponent = ({
    formState, submitForm, onDelete, onCancel
}: ComponenteReparadoFormComponentProps): ReactElement => {

    const {
        value: componenteReparadoForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    const isFormInvalid = !componenteReparadoForm.rsproveedor ||
        !componenteReparadoForm.pesoComponente ||
        !componenteReparadoForm.responsableReparacion ||
        !componenteReparadoForm.emailContactoReparacion ||
        !componenteReparadoForm.telefonoContactoReparacion;

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      setAreValidationsEnabled(true);
      if (isFormInvalid) return;
      setSubmitting(true);
      setAreValidationsEnabled(false);
      submitForm(componenteReparadoForm);
    };

    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);

    return (
        <>
            <Form>
                <div className = "modal-form-block">
                    <Row>
                        <Col>
                            <Input
                                title = "RS Proveedor"
                                name = "rsproveedor"
                                value = {componenteReparadoForm.rsproveedor}
                                onChangeWithName = {handleFieldChange}
                                invalid = {areValidationsEnabled && !componenteReparadoForm.rsproveedor}
                                invalidText = "Ingrese RS proveedor"
                                disabled = {isSubmitting}
                                placeholder = "Obligatorio"
                            />
                        </Col>
                        <Col>
                            <Input
                                title = "Peso del componente"
                                name = "pesoComponente"
                                type = "number"
                                placeholder = "Número en Kg (Obligatorio)"
                                value = {componenteReparadoForm.pesoComponente}
                                onChangeWithName = {handleFieldChange}
                                invalid = {areValidationsEnabled && !componenteReparadoForm.pesoComponente}
                                // invalidText = "Ingrese peso del componente"
                                disabled = {isSubmitting}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                title = "Responsable"
                                name = "responsableReparacion"
                                value = {componenteReparadoForm.responsableReparacion}
                                onChangeWithName = {handleFieldChange}
                                invalid = {areValidationsEnabled && !componenteReparadoForm.responsableReparacion}
                                // invalidText = "Ingrese responsable de reparación"
                                disabled = {isSubmitting}
                                placeholder = "Obligatorio"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                title = "Email responsable"
                                name = "emailContactoReparacion"
                                value = {componenteReparadoForm.emailContactoReparacion}
                                onChangeWithName = {handleFieldChange}
                                invalid = {areValidationsEnabled && !componenteReparadoForm.emailContactoReparacion}
                                // invalidText = "Ingrese email de contacto"
                                disabled = {isSubmitting}
                                placeholder = "Obligatorio"
                            />
                        </Col>
                        <Col>
                            <Input
                                title = "Teléfono responsable"
                                name = "telefonoContactoReparacion"
                                value = {componenteReparadoForm.telefonoContactoReparacion}
                                onChangeWithName = {handleFieldChange}
                                invalid = {areValidationsEnabled && !componenteReparadoForm.telefonoContactoReparacion}
                                // invalidText = "Ingrese teléfono de contacto"
                                disabled = {isSubmitting}
                                placeholder = "Obligatorio"
                            />
                        </Col>
                    </Row>
                </div>
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    isFormInvalid = {isFormInvalid}
                    areValidationsEnabled = {areValidationsEnabled}
                />
            </Form>
        </>
    );
}

export default ComponenteReparadoFormComponent;