import { Destinatario } from "../models/Email";
import { SelectOption } from "../models/optionsFilters/Select";
import { AcceptedRolesElements, AcceptedUsersElements, Persona, User, UserForm, UsersFilters } from "../models/users/Users";

export const getEmptyUsersFilters = (forSelectionElements?: { roles?: SelectOption<string>[], existingUsers?: Persona[] }): UsersFilters => {
    const response: UsersFilters = {
        email: '',
        nombre: '',
        apellidos: '',
        estado: '',
        // rol: '',
        page: 1
    };
    if (forSelectionElements) {
        response.estado = 'HABILITADO';
        if (forSelectionElements.existingUsers && forSelectionElements.existingUsers.length > 0) {
            response.existingUsers = forSelectionElements.existingUsers.map((persona) => persona.email);
        }
        if (forSelectionElements.roles && forSelectionElements.roles.length > 0) {
            response.roles = forSelectionElements.roles.map((rol) => rol.value);
        }
    }
    return response;
}

export const getEmptyUserForm = (): UserForm => {
    return {
        email: '',
        nombre: '',
        apellidos: '',
        estado: { label: 'HABILITADO', value: 'HABILITADO' },
        //roles: [{ label: 'USUARIO', value: { rol: 'USUARIO' } }],
        password: ''
    }
}

export const formatUserForm = (
    user: User,
    estadoUsuarioOptions: SelectOption<string>[],
    rolUsuarioOptions: SelectOption<string>[]
    ):  UserForm => {
        const estadoUsuario = estadoUsuarioOptions.find((estado) =>
            estado.value === user.estado);
        let rolesUsuario: SelectOption<string>[] = [];
        user.roles?.map((rol) => {
            rolUsuarioOptions.map((rolOption) => {
                if (rolOption.value === rol) {
                    rolesUsuario.push(rolOption);
                }
                return null;
            });
            return null;
        });
        return {
            email: user.email,
            nombre: user.nombre,
            apellidos: user.apellidos,
            estado: estadoUsuario,
            roles: rolesUsuario,
            password: ''
        };
}

export const formatUser = (userForm: UserForm): User => {
    return {
        email: userForm.email,
        nombre: userForm.nombre,
        apellidos: userForm.apellidos,
        estado: userForm.estado?.value,
        roles: userForm.roles?.map((rol) => rol.value),
        password: userForm.password
    }
}

export const formatDestinatarioCorreo = (personas: Persona[], type: string): Destinatario[] => {
    return personas.map((persona) => ({
        name: persona.name,
        email: persona.email,
        type: type
    }));
}

export const isUserEmailValid = (email: string): boolean => 
    (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email.toLowerCase())

export const getUserPermissionFunction = (userRoles: string[], userEmail?: string): (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean =>
    (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements): boolean => {
        const checkUserRolesInArray = (rolesArrayElements: AcceptedRolesElements): boolean => userRoles.some((rol) => {
            let response = false;
            if (!rolesArrayElements.notExactRol) {
                response = rolesArrayElements.roles.includes(rol);
            } else {
                response = rolesArrayElements.roles.some((arrayRol) => (new RegExp(arrayRol)).test(rol));
            }
            if (rolesArrayElements.isExcludingRoles) {
                response = !response;
            }
            return response;
        });
        let response = false;
        if (acceptedRolesElements) {
            response = checkUserRolesInArray(acceptedRolesElements);
        }
        if (!response && userEmail && acceptedUsersElements) {
            if (acceptedUsersElements.acceptedRolesElements) {
                if (checkUserRolesInArray(acceptedUsersElements.acceptedRolesElements)) {
                    response = acceptedUsersElements.acceptedUsers.includes(userEmail);
                }
            } else {
                response = acceptedUsersElements.acceptedUsers.includes(userEmail);
            }
        }
        return response;
    }

export const operariosRoles = [
    'OPERARIO ELÉCTRICO POTENCIA',
    'OPERARIO MECÁNICO'
];