import { Entrega, EntregaForm, EntregaRequestBody, GetEntregaRequest } from "../models/Entregas";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";

export const getEmptyEntregaForm = (): EntregaForm => {
    return {
        fechaEntrega: '',
        numeroGuiaDespacho: ''
    }
}

export const formatEntregaRequestBody = (entregaForm: EntregaForm, codigoOP: string, cliente: string): EntregaRequestBody => {
    return {
        fechaEntrega: entregaForm.fechaEntrega,
        numeroGuiaDespacho: entregaForm.numeroGuiaDespacho,
        codigoOP: codigoOP,
        cliente: cliente
    }
}

export const formatEntregaForm = (entrega: Entrega): EntregaForm => {
    return {
        fechaEntrega: entrega.fechaEntrega,
        numeroGuiaDespacho: entrega.numeroGuiaDespacho
    }
}

export const formatGetEntregaRequest = (ordenProceso: OrdenProceso): GetEntregaRequest => {
    return {
        numeroGuiaDespacho: ordenProceso.entrega?.numeroGuiaDespacho,
        cliente: ordenProceso.cliente,
        codigoOP: ordenProceso.codigoOP,
        fechaEntrega: ordenProceso.entrega?.fechaEntrega
    }
}