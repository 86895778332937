import axios, { AxiosPromise } from "axios";
import {
    OrdenProceso, OrdenesProcesoFilters, OrdenProcesoRequestBody, OrdenesProcesoTablaResponse, UpdateOrdenesProcesoEstado, OrdenProcesoCodigoResponse
} from "../models/estatusGeneral/OrdenProceso";

export function getOrdenesProceso(requestBody: OrdenesProcesoFilters): AxiosPromise<OrdenesProcesoTablaResponse> {
    return axios.get('api/ordenesProceso', { params: requestBody });
}

export function postOrdenProceso(requestBody: OrdenProcesoRequestBody): AxiosPromise<OrdenProcesoCodigoResponse> {
    return axios.post('api/ordenesProceso', requestBody);
}

export function getOrdenProceso(requestBody: String): AxiosPromise<OrdenProceso> {
    return axios.get(`api/ordenesProceso/${requestBody}`);
}

export function putOrdenProceso(requestBody: OrdenProceso): AxiosPromise<OrdenProceso> {
    return axios.put(`api/ordenesProceso/${requestBody.codigoOP}`, requestBody);
}

export function deleteOrdenProceso(requestBody: String): AxiosPromise<void> {
    return axios.delete(`api/ordenesProceso/${requestBody}`);
}

export function cambioEstadoOPs(requestBody: UpdateOrdenesProcesoEstado): AxiosPromise<void> {
    return axios.put('api/ordenesProceso/cambioEstado/codigosOP', requestBody);
}

export function getOrdenesProcesoExcel(
    // onDownloadProgressFunction: (progressEvent: any) => void
    ): AxiosPromise<OrdenesProcesoTablaResponse> {
    // const ruta = 'api/ordenesProceso/datos/excel'
    // return axios.get('api/ordenesProceso/datos/excel', { onDownloadProgress: onDownloadProgressFunction, timeout: 120000 });
    // if (onDownloadProgressFunction) {
    // } else {
    //     return axios.get(ruta);
    // }
    return axios.get('api/ordenesProceso/datos/excel', { timeout: 240000 });
}