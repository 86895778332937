import { ReactElement } from "react";

interface Props {
    name: string,
    pathInImages: string,
    requireElementTitle: boolean
}

const ImageToShowId = ({ name, pathInImages, requireElementTitle }: Props): ReactElement => (
    <>
        {name !== '1' && requireElementTitle && <u><h5>{name}</h5></u>}
        <div className = "centerTextCell">
            <img
                className = "evaluacionReparacionImg"
                src = {require(`../../assets/images/${pathInImages}`).default}
                alt = ""
            />
        </div>
    </>
);

export default ImageToShowId;