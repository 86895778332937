import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import LayoutContext from '../contexts/LayoutContext';
import { BreadcrumbItem } from '../models/layout/BreadcrumbItem';
import { LayoutContextState, LayoutContextValue } from '../models/layout/LayoutContext';

interface LayoutStoreProps {
    children?: ReactNode;
}

const LayoutStore = ({ children }: LayoutStoreProps): ReactElement => {
    const [layoutContextState, setLayoutContextState] = useState<LayoutContextState>({
        headerTitle: 'Título de página',
        breadcrumbItems: [
            /*
            {
                label: 'Inicio',
                href: '/',
                active: false,
            },
            {
                label: 'Ejemplo',
                active: true,
            },
            */
        ],
        isMenuBarActive: false,
    });

    const setHeaderTitle = useCallback(
        (title: string): void =>
            setLayoutContextState((prevState) => ({
                ...prevState,
                headerTitle: title,
            })),
        [],
    );

    const setBreadcrumbItems = useCallback(
        (items: Array<BreadcrumbItem>): void => 
            setLayoutContextState((prevState) => ({
                ...prevState,
                breadcrumbItems: items,
            })),
        [],
    );

    const toggleMenuBar = (menuBarState?: boolean): void => 
        setLayoutContextState((prevState) => {
            if (menuBarState !== undefined) {
                return {
                    ...prevState,
                    isMenuBarActive: menuBarState,
                }
            } else {
                return {
                    ...prevState,
                    isMenuBarActive: !prevState.isMenuBarActive,
                }
            }
        });
    

    const LayoutContextValue: LayoutContextValue = {
        ...layoutContextState,
        setHeaderTitle,
        setBreadcrumbItems,
        toggleMenuBar,
    };

    return <LayoutContext.Provider value={LayoutContextValue}>{children}</LayoutContext.Provider>;
};

export default LayoutStore;