import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useNumeroGalponOptions from "../../hooks/componentes/useNumeroGalpon";
import useTiposComponentesOptions from "../../hooks/componentes/useTiposComponentes";
import useFormState from "../../hooks/useFormState";
import { Componente, ComponenteForm } from "../../models/componentes/Componente";
import { formatComponenteForm, formatComponenteRequestBody } from "../../utils/componentes";
import ComponenteFormComponent from "./ComponenteFormComponent";
import { putComponente, deleteComponente } from "../../api/ComponentesApi";
import DocumentoComponente from "../files/DocumentoComponente";
import ListadoRepuestos from "../files/ListadoRepuestos";
import { FasePlanificacionComponente, PlanificacionComponente } from "../../models/Planificaciones";
import PlanificacionComponenteComponent from "./PlanificacionComponenteComponent";
import { putPlanificacionComponente } from "../../api/PlanificacionesApi";

interface ComponenteDetailsProps {
    componente: Componente,
    onComponenteChange: (componente: Componente) => void,
    codigoOP?: string,
    userPermission: boolean,
    planificacionesComponente?: PlanificacionComponente[]
    changePlanificacionesComponente: (newPlanificacionesComponente: PlanificacionComponente[]) => void
}

const ComponenteDetails = ({
    componente,
    onComponenteChange,
    codigoOP,
    userPermission,
    planificacionesComponente,
    changePlanificacionesComponente
}: ComponenteDetailsProps): ReactElement => {

    const tiposComponentesOptions = useTiposComponentesOptions();
    const numeroGalponOptions = useNumeroGalponOptions();
    const history = useHistory();

    const formState = useFormState(formatComponenteForm(
        componente,
        numeroGalponOptions,
        tiposComponentesOptions
    ));

    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ show, setShow ] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    }

    const submitForm = (componenteForm: ComponenteForm) => {
        setIsBusy(true);
        putComponente(formatComponenteRequestBody(componenteForm))
            .then((response) => {
                if (response.data.modelo) {
                    onComponenteChange(response.data);
                    cogoToast.success('Componente editado');
                    setShow(false);
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible editar el componente');
                }
                formState.setSubmitting(false);
                setIsBusy(false);
            })
            .catch(() => {
                cogoToast.error('No fue posible editar el componente');
                formState.setSubmitting(false);
                setIsBusy(false);
            });
    }

    const deleteCurrentComponente = () => {
        setIsBusy(true);
        formState.setSubmitting(true);
        deleteComponente(componente.modelo, componente.descripcion)
            .then(() => {
                cogoToast.success('Componente Eliminado');
                setIsBusy(false);
                history.push('/componentes');
            })
            .catch(() => {
                formState.setSubmitting(false);
                setIsBusy(false);
                cogoToast.error('No fue posible eliminar el componente');
                // cogoToast.error(error);
            });
    };

    const submitFasePlanificacionComponente = (fasePlanificacionComponente: FasePlanificacionComponente) => {
        const errorMessage = "No fue posible actualizar la planificación";
        setIsBusy(true);
        putPlanificacionComponente(fasePlanificacionComponente)
            .then((response) => {
                if (response.data) {
                    const newPlanificacionesComponente = planificacionesComponente;
                    if (newPlanificacionesComponente) {
                        for (let i = 0; i < newPlanificacionesComponente.length; i++) {
                            if (newPlanificacionesComponente[i].modeloWithSubModeloComponente === response.data.modeloWithSubModeloComponente) {
                                for (let e = 0; e < newPlanificacionesComponente[i].fases.length; e++) {
                                    if (newPlanificacionesComponente[i].fases[e].nombre === response.data.nombreFase) {
                                        newPlanificacionesComponente[i].fases[e] = { ...response.data, nombre: response.data.nombreFase };
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                        changePlanificacionesComponente([...newPlanificacionesComponente]);
                    }
                } else {
                    cogoToast.error(errorMessage);
                }
                setIsBusy(false);
            }).catch(() => {
                cogoToast.error(errorMessage);
                setIsBusy(false);
            })
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        {componente.descripcion}
                    </div>
                    {!codigoOP && userPermission &&
                        (<div className = "buttons-group">
                            <Button onClick = {handleShow} disabled = {isBusy}>Editar</Button>
                        </div>)
                    }
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            Modelo
                            <h3>{componente.modelo}</h3>
                        </Col>
                        {/* <Col>
                            Número de Parte
                            <h3>{`${componente.numeroParte}`}</h3>
                        </Col> */}
                        <Col>
                            Tipo de Componente
                            <h3>{`${componente.tipoComponente}`}</h3>
                        </Col>
                        <Col>
                            Galpón
                            <h3>{`${componente.galpon}`}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {/* {userPermission &&
                <DocumentoComponente
                    componente = {componente}
                    userPermission = {userPermission}
                    title
                    isNotEditable = {codigoOP ? true : false}
                />
            } */}
            <ListadoRepuestos
                componente = {componente}
                userPermission = {userPermission}
                isNotEditable = {codigoOP ? true : false}
                documentosListado = {componente.documentosListado}
            />
            {/* {planificacionesComponente &&
                <PlanificacionComponenteComponent
                    planificacionesComponente = {planificacionesComponente}
                    submitFasePlanificacionComponente = {submitFasePlanificacionComponente}
                    isBusy = {isBusy}
                />
            } */}
            {/* <ImagenComponente
                modelo = {componente.modelo}
                rolPermission = {rolPermission}
                isNotEditable = {codigoOP ? true : false}
            /> */}
            {!codigoOP && (
                <Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className="text-center">
                            <Modal.Title>Editar Componente</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {show &&
                            (<ComponenteFormComponent
                                formState = {formState}
                                submitForm = {submitForm}
                                onDelete = {deleteCurrentComponente}
                                onCancel = {handleClose}
                                tiposComponentesOptions = {tiposComponentesOptions}
                                numeroGalponOptions = {numeroGalponOptions}
                            />)
                        }
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default ComponenteDetails;