import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
// import { cambioEstadoOPs } from "../../api/OrdenesProcesoApi";
import { putReparacion } from "../../api/ReparacionesApi";
import useFormState from "../../hooks/useFormState";
import useTOTOptions from "../../hooks/useTOTOptions";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { Reparacion, ReparacionForm } from "../../models/Reparaciones";
// import { formatUpdateOrdenesProcesoEstado } from "../../utils/estatusGeneral";
import { formatReparacionForm, formatReparacionRequestBody } from "../../utils/reparaciones";
import DocumentosReparacion from "../files/DocumentosReparacion";
import ReparacionFormComponent from "./ReparacionFormComponent";
// import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import CorreoEntregaEvaluacionReparacion from "../evaluacionesReparaciones/CorreoEntregaEvaluacionReparacion";

interface ReparacionDetailsProps {
    reparacion: Reparacion,
    changeReparacion: (reparacion: Reparacion) => void,
    ordenProceso: OrdenProceso,
    userPermission: boolean,
    userData: { userName: string, userEmail: string, userRoles: string[] }
}

const ReparacionDetails = ({ reparacion, changeReparacion, userPermission, ordenProceso, userData }: ReparacionDetailsProps): ReactElement => {

    const formState = useFormState(formatReparacionForm(reparacion));
    const totOptions = useTOTOptions();
    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const [ show, setShow ] = useState(false);
    const handleShowEdit = () => setShow(true);
    const handleCloseEdit = () => {
        formState.reset();
        setShow(false);
    };

    const submitForm = (reparacionForm: ReparacionForm) => {
        putReparacion(formatReparacionRequestBody(reparacionForm), ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.fechaInicial) {
                    formState.setSubmitting(false);
                    formState.setForm(reparacionForm);
                    setShow(false);
                    changeReparacion(response.data);
                    cogoToast.success('Reparación Editada');
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible editar la reparación del componente');
                    formState.setSubmitting(false);
                }
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible editar la reparación');
            })
    }

    /*
    const changeOPState = () => {
        if (ordenProceso.estado === 'EVALUADA') {
            cambioEstadoOPs(formatUpdateOrdenesProcesoEstado([ordenProceso], 'REPARADA'))
            .then(() =>
                cogoToast.success('La orden de proceso cambió su estado a "REPARADA"')
            ).catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible cambiar el estado de las órdenes de proceso');
            })
        }
    }
    */

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">
                        Reparación de componente
                    </div>
                    <div className = "buttons-group">
                        {userPermission &&
                            <Button onClick = {handleShowEdit} disabled = {isBusy}>{isBusy ? <Spinner animation="border" size="sm" /> : 'Editar'}</Button>
                        }
                        {userPermission &&
                            <CorreoEntregaEvaluacionReparacion
                                userData = {userData}
                                ordenProceso = {ordenProceso}
                                isBusy = {isBusy}
                                setIsBusy = {(state: boolean) => setIsBusy(state)}
                                // doesGenerateFile = {doesGenerateFile}
                                // generarInforme = {generarInforme}
                                // isProcesoEtapaFinished = {doesGenerateFile ? isResumenComplete() : !!reparacion.fechaFinal}
                                fechaFinal = {reparacion.fechaFinal}
                                nombreEtapaProceso = "reparación"
                                // getFiles = {async (handleDocumentosResponse: (responseData?: Archivo[]) => void) => await getDocumentosReparacion(ordenProceso.codigoOP)
                                //     .then((response) => handleDocumentosResponse(response.data))
                                //     .catch(() => cogoToast.error('No fue posible obtener algunos documentos de evaluación'))
                                // }
                            />
                        }
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                    <Col>
                            Fecha inicial
                            <h3>{reparacion.fechaInicial}</h3>
                        </Col>
                        <Col>
                            Fecha final
                            <h3>{reparacion.fechaFinal}</h3>
                        </Col>
                        <Col>
                            T.O.T.
                            <h3>{reparacion.tot}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <DocumentosReparacion
                codigoOP = {ordenProceso.codigoOP}
                isBusy = {isBusy}
                changeBusyState = {(state: boolean) => setIsBusy(state)}
                userPermission = {userPermission}
                documentosListado = {reparacion.documentosListado}
            />
            {show && ordenProceso.evaluacion?.fechaInicial &&
                (<Modal show = {show} onHide = {handleCloseEdit}>
                    <Modal.Header>
                        <Col className = 'text-center'>
                            <Modal.Title>Editar Reparación</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <ReparacionFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleCloseEdit}
                            totOptions = {totOptions}
                            minDateReparacion = {ordenProceso.evaluacion.fechaInicial}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default ReparacionDetails;