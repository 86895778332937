import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ComponentesReparadosList from "../../components/componentes/ComponentesReparadosList";
import NuevoComponenteReparado from "../../components/componentes/NuevoComponenteReparado";
import QRComponentesFilters from "../../components/componentes/QRComponentesFilters";
import AuthContext from "../../contexts/AuthContext";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { ComponentesReparadosFilters } from "../../models/componentes/Componente";
import { getEmptyComponentesReparadosFilters } from "../../utils/componentes";
import { getUserPermissionFunction } from "../../utils/users";

const QRComponentesPage = (): ReactElement => {

    const [ componentesReparadosFilters, setComponentesReparadosFilters ] = useState<ComponentesReparadosFilters>(
        getEmptyComponentesReparadosFilters()
    );

    const { userRoles } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();

    const changeComponentesReparadosFilters = (componentesReparadosFilters: ComponentesReparadosFilters) => {
        setComponentesReparadosFilters(componentesReparadosFilters);
    }

    useEffect(() => {
        breadcrumb.setItems([
            {
                label: 'QR Componentes',
                inactive: true,
            }
        ]);
    }, []);

    return (
        <Card className = "cards-container">
            <Card.Body className = "cards-body-groups">
                <div className = "cards-header">
                    <div className = "body-filters">
                        <QRComponentesFilters
                            componentesReparadosFilters = {componentesReparadosFilters}
                            onFiltersChange = {setComponentesReparadosFilters}
                        />
                    </div>
                    <div className = "filters-buttons">
                        <NuevoComponenteReparado
                            userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'PLANIFICACION', 'CALIDAD'] })}
                        />
                    </div>
                </div>
                <ComponentesReparadosList
                    componentesReparadosFilters = {componentesReparadosFilters}
                    changeComponentesReparadosFilters = {changeComponentesReparadosFilters}
                />
            </Card.Body>
        </Card>
    );
}

export default QRComponentesPage;