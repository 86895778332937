import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { getOrdenesProceso } from "../../api/OrdenesProcesoApi";
import { OrdenesProcesoFilters, OrdenProcesoTabla } from "../../models/estatusGeneral/OrdenProceso";
import { Card, Spinner, Table } from 'react-bootstrap';
import { OrdenProcesoSelection } from "../../models/recepciones/OrdenProcesoSelection";
import PaginationComponent from "../form/table/PaginationComponent";

interface OPListProps {
    ordenesProcesoSelected: OrdenProcesoTabla[],
    ordenesProcesoFilters: OrdenesProcesoFilters,
    addOrdenesProcesoSelected: (op: OrdenProcesoTabla) => void,
    removeOrdenesProcesoSelected: (op: OrdenProcesoTabla) => void,
    setOrdenesProcesoFilters: (ordenesProcesoFilters: OrdenesProcesoFilters) => void
}

const OPList = ({
    ordenesProcesoSelected,
    ordenesProcesoFilters,
    addOrdenesProcesoSelected,
    removeOrdenesProcesoSelected,
    setOrdenesProcesoFilters
}: OPListProps): ReactElement => {

    const [ totalPages, setTotalPages ] = useState<number>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ ordenesProcesoSelection, setOrdenesProcesoSelection ] = useState<OrdenProcesoSelection[]>();

    const onChangeOpsSelectionCheck = (index: number) => {
        let opsSelection = ordenesProcesoSelection;
        if (opsSelection) {
            opsSelection[index].isChecked = !opsSelection[index].isChecked;
            if (opsSelection[index].isChecked) {
                addOrdenesProcesoSelected(opsSelection[index].ordenProceso);
            } else {
                removeOrdenesProcesoSelected(opsSelection[index].ordenProceso);
            }
        }
        setOrdenesProcesoSelection(opsSelection);
    }

    useEffect(() => {
        getOrdenesProceso(ordenesProcesoFilters)
            .then((response) => {
                if (response.data.ordenesProceso ) {
                    setTotalPages(response.data.totalPages);
                    let opsSelected: OrdenProcesoSelection[] = [];
                    response.data.ordenesProceso.forEach((op) => {
                        let isSelected = false;
                        ordenesProcesoSelected.forEach((opSelected) => {
                            if (opSelected.codigoOP === op.codigoOP) {
                                isSelected = true;
                            }
                        });
                        if (op.fechaRecepcion && op.numeroSerieComponente) {
                            opsSelected.push({
                                ordenProceso: op,
                                isChecked: isSelected,
                            });
                        }
                    });
                    setOrdenesProcesoSelection(opsSelected);
                    setIsLoading(false);
                } else {
                    // cogoToast.error(response.data);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener las órdenes de proceso');
                setIsLoading(false);
            });
    }, [ordenesProcesoFilters]);

    if (!ordenesProcesoSelection) {
        if (isLoading) {
            return (
                <Card className = "cards-container">
                    <Card.Body>
                        <div className = "text-center">
                            <Spinner animation = "border"/>
                        </div>
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">No fue posible cargar las órdenes de proceso</p>;
        }
    } else {
        return (
            <>
                <Table size = "sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>OP</th>
                            <th className = 'd-none d-sm-table-cell'>Tipo OP</th>
                            <th>Cliente</th>
                            <th>Descripción</th>
                            <th className = 'd-none d-sm-table-cell'>N/S</th>
                            <th>Recepción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ordenesProcesoSelection && 
                            (ordenesProcesoSelection.map((selectOP, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <input
                                                className = "largeCheckBox"
                                                type = "checkbox"
                                                defaultChecked = {selectOP.isChecked}
                                                onChange = {() => {
                                                    onChangeOpsSelectionCheck(index);
                                                }}
                                            />
                                        </td>
                                        <td>{selectOP.ordenProceso.codigoOP}</td>
                                        <td className = 'd-none d-sm-table-cell'>{selectOP.ordenProceso.proceso}</td>
                                        <td>{selectOP.ordenProceso.cliente}</td>
                                        <td>{selectOP.ordenProceso.descripcionComponente}</td>
                                        <td className = 'd-none d-sm-table-cell'>{selectOP.ordenProceso.numeroSerieComponente}</td>
                                        <td>{selectOP.ordenProceso.fechaRecepcion}</td>
                                    </tr>
                                )
                            }))
                        }
                    </tbody>
                </Table>
                {totalPages && (<PaginationComponent
                    pageCount={totalPages}
                    activePage={ordenesProcesoFilters.page}
                    onChange={(page) => setOrdenesProcesoFilters({ ...ordenesProcesoFilters, page: page })}
                />)}
            </>
        );
    }
}

export default OPList;