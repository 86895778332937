import { ReactElement, useEffect, useState } from "react";
import { Persona } from "../../models/users/Users";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import cogoToast from "cogo-toast";
import { getCorreoEmisorOperario, getDestinatariosEntregaEvaluacionReparacion } from "../../api/UsersApi";
import { isUserEmailValid } from "../../utils/users";
import ReactSelect from "react-select";
import { generalSendEmailInModal, getCorreoEntregaEvaluacionReparacion } from "../../utils/emails";
import { SelectOption } from "../../models/optionsFilters/Select";
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import { cambioEstadoOPs } from "../../api/OrdenesProcesoApi";
import { formatUpdateOrdenesProcesoEstado } from "../../utils/estatusGeneral";

interface Props {
    userData: { userName: string, userEmail: string, userRoles: string[] },
    ordenProceso: OrdenProceso,
    isBusy: boolean,
    setIsBusy: (state: boolean) => void
    fechaFinal?: string,
    // generateFile?: (nombreProceso: string, addAttachments?: (newAttachments: Attachment[]) => void) => Promise<void>,
    nombreEtapaProceso: string,
    // getFiles: (handleDocumentosResponse: (responseData?: Archivo[]) => void) => Promise<void>
}

const CorreoEntregaEvaluacionReparacion = ({ userData, ordenProceso, isBusy, fechaFinal, setIsBusy, nombreEtapaProceso }: Props): ReactElement => {

    const [ show, setShow ] = useState<boolean>(false);
    const [ addresses, setAddresses ] = useState<Persona[]>([]);
    const [ copiedAddresses, setCopiedAddresses ] = useState<Persona[]>();
    const [ userEmail, setUserEmail ] = useState<string>(userData.userEmail);
    const [ isSending, setIsSending ] = useState<boolean>(false);
    const [ validUserEmail, setValidUserEmail ] = useState<boolean>(false);
    const [ isLoadingEmail, setIsLoadingEmail ] = useState<boolean>(false);
    const [ notas, setNotas ] = useState<string>();
    const [ evaluadoPor, setEvaluadoPor ] = useState<string>();
    const [ reparadoPor, setReparadoPor ] = useState<string>();
    const [ liberadoPor, setLiberadoPor ] = useState<string>();
    const [ fechaLiberacion, setFechaLiberacion ] = useState<string>();
    const [ grado, setGrado ] = useState<string>();
    // const [ attachments, setAttachments ] = useState<Attachment[]>([]);

    const hideModal = () => setShow(false);
    // const addAttachments = (newAttachments: Attachment[]) => setAttachments(attachments.concat(newAttachments));

    useEffect(() => {
        (async () => {
            if (fechaFinal) {
                if (show) {
                    setIsBusy(true);
                    setIsLoadingEmail(true);
                    const defaultUserRol = userData.userRoles.find((rol) => rol.includes('OPERARIO'));
                    if (defaultUserRol) {
                        await getCorreoEmisorOperario(defaultUserRol)
                            .then((response) => {
                                if (response.data) {
                                    setUserEmail(response.data.email);
                                } else {
                                    cogoToast.error('No fue posible adquirir el correo del técnico');
                                }
                            }).catch(() => cogoToast.error('No fue posible adquirir el correo del técnico'));
                    }
                    if (isUserEmailValid(userEmail)) {
                        setValidUserEmail(true);
                        await getDestinatariosEntregaEvaluacionReparacion()
                            .then((response) => {
                                setCopiedAddresses(response.data.cc);
                                setAddresses(response.data.to);
                            }).catch(() => {
                                cogoToast.error('No fue posible adquirir los destinatarios');
                            });
                        // if (doesGenerateFile && generateFile) {
                        //     await generateFile(nombreEtapaProceso, addAttachments);
                        // } else {
                        //     await getFiles((responseData?: Archivo[]) => {
                        //         if (responseData) {
                        //             addAttachments(responseData.map((archivo) => ({ name: archivo.nombre, content: archivo.base64 || '' })));
                        //         }
                        //     });
                        // }
                    }
                    setIsLoadingEmail(false);
                    setIsBusy(false);
                } else {
                    // setAttachments([]);
                    setFechaLiberacion(undefined);
                    setReparadoPor(undefined);
                    setLiberadoPor(undefined);
                    setGrado(undefined);
                }
            }
        })().catch(() => cogoToast.error('No fue posible preparar el correo'));
    }, [show]);

    return (
        <>
            <Button onClick = {() => setShow(true)} disabled = {!fechaFinal || isBusy}>{isBusy ? <Spinner animation = "border" size = "sm" /> : 'Correo entrega'}</Button>
            {show && (
                <Modal show = {show} inHide = {hideModal}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>{`Reportar término de ${nombreEtapaProceso}`}</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {nombreEtapaProceso === 'evaluación' &&
                            <>
                                <div>
                                    <h6>Tipo de evaluación:</h6>
                                    <ReactSelect
                                        isClearable
                                        hideSelectedOptions
                                        isDisabled = {isBusy}
                                        onChange = {(value: SelectOption<string>) => setGrado(value?.value)}
                                        options = {[
                                            { label: 'BÁSICA', value: 'BÁSICA' },
                                            { label: 'MEDIA', value: 'MEDIA' },
                                            { label: 'MAYOR', value: 'MAYOR' },
                                            { label: 'DE BAJA', value: 'DE BAJA' }
                                        ]}
                                    />
                                </div>
                            </>
                        }
                        {!ordenProceso.desarmeEvaluacionComponente &&
                            <div>
                                <h6>Evaluado por:</h6>
                                <input
                                    className = "form-control"
                                    disabled = {isBusy}
                                    onChange = {(value) => setEvaluadoPor(value.target.value)}
                                />
                            </div>
                        }
                        {nombreEtapaProceso === 'reparación' &&
                            <>
                                <div>
                                    <h6>Reparado por:</h6>
                                        <input
                                            className = "form-control"
                                            disabled = {isBusy}
                                            onChange = {(value) => setReparadoPor(value.target.value)}
                                        />
                                </div>
                                <div>
                                    <h6>Liberado por:</h6>
                                        <input
                                            className = "form-control"
                                            disabled = {isBusy}
                                            onChange = {(value) => setLiberadoPor(value.target.value)}
                                        />
                                </div>
                                <div>
                                    <h6>Fecha de liberación:</h6>
                                    <DatePicker
                                        className = "form-control"
                                        dateFormat = "dd/MM/yyyy"
                                        selected = {fechaLiberacion ? parse(fechaLiberacion, 'dd/MM/yyyy', new Date()) : null}
                                        onChange = {(date) => setFechaLiberacion(date? format(date, "dd/MM/yyyy") : '')}
                                        minDate = {fechaFinal ? parse(fechaFinal, 'dd/MM/yyyy', new Date()) : null}
                                        disabled = {isBusy}
                                        isClearable
                                    />
                                </div>
                            </>
                        }
                        <div>
                            <h6>Notas:</h6>
                            <textarea
                                rows = {4}
                                className = "form-control"
                                disabled = {isBusy}
                                onChange = {(value) => setNotas(value.target.value)}
                            />
                        </div>
                        {/* {!isLoadingEmail && attachments.length < 1 && validUserEmail &&
                            <div className = "text-center">
                                <small>No hay documentos que adjuntar, el correo se enviará sin archivos.</small>
                            </div>
                        } */}
                        {!validUserEmail ?
                            <div className = "text-center">
                                <small>El usuario actual no tiene una dirección de correo válida.</small>
                            </div>
                        :
                            addresses.length < 1 ?
                                <div className = "text-center">
                                    <small>No hay destinatarios, para agregarlos solicítelo a un administrador</small>
                                </div>
                            :
                                isLoadingEmail ?
                                    <div className = "text-center">
                                        <small>Preparando correo ...</small>
                                        <Spinner animation="border" size="sm" />
                                    </div>
                                :
                                    isSending &&
                                        <div className = "text-center">
                                            <small>Enviando correo ...</small>
                                            <Spinner animation="border" size="sm" />
                                        </div>
                        }
                        <div className = "modal-buttons">
                            <Button
                                disabled = {(ordenProceso && validUserEmail && addresses && addresses.length > 0) ? (isBusy) : true}
                                onClick = {() => {
                                    if (addresses && ordenProceso) {
                                        const estado = nombreEtapaProceso === 'evaluación' ? 'EVALUADA' : 'REPARADA';
                                        generalSendEmailInModal(
                                            getCorreoEntregaEvaluacionReparacion(
                                                nombreEtapaProceso,
                                                ordenProceso,
                                                // attachments,
                                                addresses,
                                                copiedAddresses,
                                                fechaFinal,
                                                notas,
                                                grado,
                                                reparadoPor,
                                                liberadoPor,
                                                fechaLiberacion,
                                                ordenProceso.desarmeEvaluacionComponente ? ordenProceso.evaluacion?.realizadoPor : evaluadoPor
                                            ),
                                            userData.userName,
                                            userEmail,
                                            (state: boolean) => {
                                                setIsSending(state);
                                                setIsBusy(state);
                                            },
                                            hideModal,
                                            async () => await cambioEstadoOPs(formatUpdateOrdenesProcesoEstado([ordenProceso], estado))
                                                .then(() => cogoToast.success(`OP actualizada`))
                                                .catch(() => cogoToast.error('No fue posible cambiar el estado de la OP'))
                                        );
                                    }
                                }}
                            >
                                {isSending ? <Spinner animation="border" size="sm" /> : 'Enviar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isBusy}>
                                {isSending ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default CorreoEntregaEvaluacionReparacion;