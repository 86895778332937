import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import SubOPDetails from "./SubOPDetails";

interface SubOPTabProps {
    userEmail?: string,
    userName?: string,
    editSubOPs: (setState: () => void, subOP?: string) => void,
    codigoOP?: string,
    userRoles?: string[],
    userPermissionFunction?: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const SubOPTab = ({ codigoOP, editSubOPs, userPermissionFunction, userRoles, userEmail, userName }: SubOPTabProps): ReactElement => {

    const [ subOP, setSubOP ] = useState<OrdenProceso>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isCreating, setIsCreating ] = useState<boolean>(false);

    const changeSubOP = (subOP: OrdenProceso) => {
        setSubOP(subOP);
    }

    const createSubOP = () => {
        setIsCreating(true);
        editSubOPs(() => setIsCreating(false));
    }

    useEffect(() => {
        if (codigoOP) {
            getOrdenProceso(codigoOP)
                .then((response) => {
                    if (response.data.codigoOP) {
                        setSubOP(response.data)
                        setIsLoading(false);
                    } else {
                        cogoToast.error('No fue posible obtener la sub OP');
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('No fue posible obtener la sub OP');
                    setIsLoading(false);
                })
        }
    }, [codigoOP])

    if (codigoOP && userPermissionFunction && userRoles) {
        if (subOP && userEmail && userName) {
            return (
                <>
                    <SubOPDetails
                        subOrdenProceso = {subOP}
                        changeSubOP = {changeSubOP}
                        userPermissionFunction = {userPermissionFunction}
                        userRoles = {userRoles}
                        deleteSubOP = {editSubOPs}
                        userName = {userName}
                        userEmail = {userEmail}
                    />
                </>
            )
        } else {
            if (isLoading) {
                return (
                    <Card>
                        <Card.Body className = "text-center">
                            <Spinner animation = "border" />
                        </Card.Body>
                    </Card>
                );
            } else {
                return <p className = "text-center">No fue posible cargar la sub orden de proceso</p>;
            }
        }
    } else {
        return (
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-title">Nueva sub-OP</div>
                    <div className = "buttons-group">
                        <Button onClick = {createSubOP} disabled = {isCreating}>Agregar</Button>
                    </div>
                </Card.Header>
            </Card>
        )
    }
}

export default SubOPTab;