import { UnidadEstrategicaNegocio } from "../../../models/estatusGeneral/UnidadEstrategicaNegocio";
import { SelectOption } from "../../../models/optionsFilters/Select";


const useUENPotencia = (): SelectOption<UnidadEstrategicaNegocio>[] => {

    return [
        { label: 'POTENCIA', value: { uen: 'POTENCIA' } },
        { label: 'CONTACTORES', value: { uen: 'CONTACTORES' } },
        { label: 'PARRILLAS', value: { uen: 'PARRILLAS' } },
        { label: 'BANCO PARRILLAS', value: { uen: 'BANCO PARRILLAS' } },
        { label: 'ARNESES', value: { uen: 'ARNESES' } },
        { label: 'TORNO', value: { uen: 'TORNO' } },
        { label: 'BALANCEO', value: { uen: 'BALANCEO' } },
        { label: 'VPI', value: { uen: 'VPI' } }
    ];
    
}

export default useUENPotencia;