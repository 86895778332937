import { ReactElement } from "react";
import { Archivo, ArchivoNombre } from "../../models/Archivos";
import DocumentsManager from "./DocumentsManager";
import { deleteCertificadoCalibracionEquiposHerramientas, getCertificadoCalibracionEquiposHerramientas, postCertificadoCalibracionEquiposHerramientas } from "../../api/DocumentosApi";

interface Props {
    codigoOP: string,
    userPermission: boolean,
    isRolCliente: boolean,
    isBusy?: boolean,
    changeBusyState?: (state: boolean) => void,
    certificadoCalibracionEquiposHerramientasDoc?: ArchivoNombre,
}

const CertificadoCalibracionEquiposHerramientas = ({ codigoOP, userPermission, isRolCliente, isBusy, changeBusyState, certificadoCalibracionEquiposHerramientasDoc }: Props): ReactElement => (
    <DocumentsManager
        // code = {codigoOP}
        isNotEditable = {isRolCliente}
        userPermission = {userPermission}
        title = {'Calibración equipos y herramientas'}
        externalIsBusy = {isBusy}
        changeExternalBusyState = {changeBusyState}
        getDocumento = {(_id: string, onDownloadProgressFunction: (progressEvent: any) => void) => getCertificadoCalibracionEquiposHerramientas({ _id: _id }, onDownloadProgressFunction)}
        postDocumento = {(archivo: Archivo, onUploadProgressFunction: (progressEvent: any) => void) => postCertificadoCalibracionEquiposHerramientas({ codigoOP: codigoOP, archivo: archivo }, onUploadProgressFunction)}
        deleteDocumento = {(_id: string) => deleteCertificadoCalibracionEquiposHerramientas({ _id: _id })}
        // getDocumentosListado = {() => getCertificadoCalibracionEquiposHerramientasListado(codigoOP)}
        documentosListado = {certificadoCalibracionEquiposHerramientasDoc ? [certificadoCalibracionEquiposHerramientasDoc] : undefined}
    />
)

export default CertificadoCalibracionEquiposHerramientas;