import { ReactElement } from "react";
import { EvaluacionOrReparacionComponenteEtapa, TareaObs } from "../../models/EvaluacionesReparaciones";

interface Props {
    tareaObs: TareaObs,
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (changeReparacionEvaluacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    nombreTarea: string,
    requireElementTitle: boolean
}

const EvaluacionReparacionComponenteDetallesObs = ({ tareaObs, isNotEditable, changeEvaluacionReparacionComponenteEtapa, evaluacionReparacionComponenteEtapa, nombreTarea, requireElementTitle }: Props): ReactElement => {

    const changeObs = (value: string) => {
        const newEvaluacionReparacionComponenteEtapa = evaluacionReparacionComponenteEtapa;
        for (let i = 0; i < newEvaluacionReparacionComponenteEtapa.tareas.length; i++) {
            if (newEvaluacionReparacionComponenteEtapa.tareas[i].nombre === nombreTarea) {
                const obs = newEvaluacionReparacionComponenteEtapa.tareas[i].observations;
                if (obs) {
                    obs.value = value;
                    newEvaluacionReparacionComponenteEtapa.tareas[i].observations = obs;
                }
                break;
            }
        }
        changeEvaluacionReparacionComponenteEtapa(newEvaluacionReparacionComponenteEtapa);
    }

    return (
        <>
            {tareaObs.name !== '1' && requireElementTitle && (<u><h5>{tareaObs.name}</h5></u>)}
            <div className = "body-obs">
                <textarea
                    rows = {4}
                    className = "form-control"
                    defaultValue = {tareaObs.value}
                    disabled = {isNotEditable}
                    onChange = {(value) => changeObs(value.target.value)}
                />
            </div>
        </>
    );
}

export default EvaluacionReparacionComponenteDetallesObs;