import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Modal, Spinner, Table } from "react-bootstrap";
import { EvaluacionOrReparacionComponenteEtapa, InsumoElement, InsumosList } from "../../models/EvaluacionesReparaciones";
import { checkNumber } from "../../utils/inputs";

interface Props {
    nombreTarea: string,
    insumoList: InsumosList,
    isBusy: boolean,
    updateEvaluacionReparacionComponenteEtapaTarea: (hideModal: () => void) => void,
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa
}

const EvaluacionReparacionComponenteEditInsumosList = ({
    nombreTarea,
    insumoList,
    isBusy,
    updateEvaluacionReparacionComponenteEtapaTarea,
    isNotEditable,
    changeEvaluacionReparacionComponenteEtapa,
    evaluacionReparacionComponenteEtapa
}: Props): ReactElement => {

    const [ show, setShow ] = useState<boolean>(false);
    const [ areThereInsumosManual, setAreThereInsumosManual ] = useState<boolean>(false);
    const [ areThereInsumosAuto, setAreThereInsumosAuto ] = useState<boolean>(false);
    
    const hideModal = () => setShow(false);

    const getStates = (toDefault?: boolean): InsumoElement[] => (toDefault ? states : insumoList.insumos).map((insumo) => {
        if (!toDefault) {
            if (!areThereInsumosManual && insumo.manual) {
                setAreThereInsumosManual(true);
            }
            if (!areThereInsumosAuto && !insumo.manual) {
                setAreThereInsumosAuto(true);
            }
        }
        return {
            ...insumo,
            amountRequested: (insumo.manual || !toDefault) ? insumo.amountRequested : insumo.defaultAmount
        }
    });

    const [ states, setStates ] = useState<InsumoElement[]>(getStates());

    useEffect(() => {
        setStates(getStates());
    }, [show]);

    const getTable = (insumos: (InsumoElement | undefined)[]): ReactElement => (
        <Table bordered size = 'sm'>
            <tr>
                <th>Insumo</th>
                <th className = "centerTextCell">N/P Electrans</th>
                <th className = "centerTextCell">Cantidad</th>
            </tr>
            {insumos.map((insumo, index) => (
                insumo ?
                    <tr>
                        <td>{insumo.name}{insumo.unitName ? ` (${insumo.unitName})` : ''}</td>
                        <td className = "centerTextCell">{insumo.electransPartNumber}</td>
                        <td>
                            <input
                                className = "form-control text-center"
                                disabled = {insumo.never || isBusy}
                                // placeholder = {insumo.unitName}
                                value = {insumo.amountRequested}
                                onChange = {(input) => {
                                    const valueParameters = checkNumber(input.target.value);
                                    if (!input.target.value || (valueParameters.isNumber && (insumo.decimals || !valueParameters.isDecimal))) {
                                        const newStates = states;
                                        newStates[index].amountRequested = input.target.value;
                                        setStates([...newStates]);
                                    }
                                }}
                            />
                        </td>
                    </tr>
                :
                    <></>
            ))}
        </Table>
    );

    return (
        <>
            <Button onClick = {() => setShow(true)} disabled = {isNotEditable}>
                {!isBusy ? 'Editar' : <Spinner animation = "border" size = "sm"/>}
            </Button>
            {show &&
                <Modal show = {show} onHide = {hideModal}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Listado de insumos</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {areThereInsumosManual &&
                            <>
                                <h5>Selección manual</h5>
                                {getTable(states.map((insumo) => insumo.manual ? insumo : undefined))}
                            </>
                        }
                        {areThereInsumosAuto &&
                            <>
                                <h5>Selección automática</h5>
                                {getTable(states.map((insumo) => !insumo.manual ? insumo : undefined))}
                            </>
                        }
                        <div className = "modal-buttons">
                            <Button onClick = {() => setStates(getStates(true))} disabled = {!(areThereInsumosAuto || areThereInsumosManual) || isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Reiniciar automáticos'}
                            </Button>
                            <Button
                                variant = 'success'
                                disabled = {!(areThereInsumosAuto || areThereInsumosManual) || isBusy}
                                onClick = {() => {
                                    changeEvaluacionReparacionComponenteEtapa({ ...evaluacionReparacionComponenteEtapa, tareas: evaluacionReparacionComponenteEtapa.tareas.map((tarea) => {
                                        const tareaResponse = tarea;
                                        if (tareaResponse.nombre === nombreTarea && tareaResponse.insumosLists) {
                                            tareaResponse.insumosLists = [...tareaResponse.insumosLists?.map((newInsumosList) => {
                                                const insumosListResponse = newInsumosList;
                                                if (insumosListResponse.name === insumoList.name) {
                                                    insumosListResponse.insumos = [...states];
                                                }
                                                return insumosListResponse;
                                            })];
                                        }
                                        return tareaResponse;
                                    })});
                                    updateEvaluacionReparacionComponenteEtapaTarea(hideModal);
                                }}
                            >
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default EvaluacionReparacionComponenteEditInsumosList;