import { ReactElement } from "react";
import { Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { EvaluacionForm } from "../../models/Evaluaciones";
import { SelectOption } from "../../models/optionsFilters/Select";
import TOT from "../../models/TOT";
import FormButtons from "../form/buttons/FormButtons";
import Select from "../form/select/Select";
import DateSelect from "../form/DateSelect";

interface EvaluacionFormComponentProps {
    formState: FormState<EvaluacionForm>,
    submitForm: (evaluacionForm: EvaluacionForm) => void,
    onDelete?: () => void,
    onCancel: () => void,
    totOptions: SelectOption<TOT>[],
    minDateEvaluacion: string,
    withDesarmeEvaluacion?: boolean,
    isCreating?: boolean
}

const EvaluacionFormComponent = ({ formState, submitForm, onDelete, onCancel, totOptions, minDateEvaluacion, withDesarmeEvaluacion, isCreating }: EvaluacionFormComponentProps): ReactElement => {

    const {
        value: evaluacionForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    
    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);

    const isFormInvalid = (): boolean => {
        return !evaluacionForm.fechaInicial;
    }

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid()) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        submitForm(evaluacionForm);
    }

    return (
        <>
            <Form>
                {/* <Row>
                    <Input
                        title = "Fecha evaluación"
                        name = "fechaEvaluacion"
                        value = {evaluacionForm.fechaEvaluacion}
                        type = "date"
                        onChangeWithName = {handleFieldChange}
                    />
                </Row> */}
                <Row>
                    <DateSelect
                        title = 'Fecha inicial'
                        name = 'fechaInicial'
                        onChangeField = {handleFieldChange}
                        invalid = {areValidationsEnabled && !evaluacionForm.fechaInicial}
                        selected = {evaluacionForm.fechaInicial}
                        disabled = {isSubmitting}
                        minDate = {minDateEvaluacion}
                        placeholder = 'Obligatorio'
                    />
                    {/* <div className = "form-group">
                        Fecha inicial
                    </div>
                    <DatePicker
                        className = "form-control"
                        dateFormat = "dd/MM/yyyy"
                        selected = {
                            evaluacionForm.fechaInicial ?
                            parse(evaluacionForm.fechaInicial, 'dd/MM/yyyy', new Date()) :
                            null
                        }
                        onChange = {(date): void => {
                            let changedFilter;
                            if (date) {
                                changedFilter = format((date as Date), "dd/MM/yyyy");
                            } else {
                                changedFilter = '';
                            }
                            handleFieldChange("fechaInicial", changedFilter);
                        }}
                        disabled = {isSubmitting}
                        minDate = {parse(minDateEvaluacion, 'dd/MM/yyyy', new Date())}
                        placeholderText = 'Obligatorio'
                    /> */}
                </Row>
                {!withDesarmeEvaluacion &&
                    <>
                        {!isCreating &&
                            <Row>
                                <DateSelect
                                    title = 'Fecha final'
                                    name = 'fechaFinal'
                                    onChangeField = {handleFieldChange}
                                    // invalid = {areValidationsEnabled && !evaluacionForm.fechaFinal}
                                    selected = {evaluacionForm.fechaFinal}
                                    disabled = {isSubmitting}
                                    minDate = {evaluacionForm.fechaInicial}
                                    isClearable
                                    // placeholder = 'Obligatorio'
                                />
                                {/* <div className = "form-group">
                                    Fecha final
                                </div>
                                <DatePicker
                                    className = "form-control"
                                    dateFormat = "dd/MM/yyyy"
                                    selected = {
                                        evaluacionForm.fechaFinal ?
                                        parse(evaluacionForm.fechaFinal, 'dd/MM/yyyy', new Date()) :
                                        null
                                    }
                                    onChange = {(date): void => {
                                        let changedFilter;
                                        if (date) {
                                            changedFilter = format((date as Date), "dd/MM/yyyy");
                                        } else {
                                            changedFilter = '';
                                        }
                                        handleFieldChange("fechaFinal", changedFilter);
                                    }}
                                    disabled = {isSubmitting}
                                    minDate = {parse(evaluacionForm.fechaInicial, 'dd/MM/yyyy', new Date())}
                                /> */}
                            </Row>
                        }
                        <Row>
                            <Select
                                title = "T.O.T."
                                name = "tot"
                                value = {evaluacionForm.tot}
                                onSelectWithName = {handleFieldChange}
                                options = {totOptions}
                                disabled = {isSubmitting}
                            />
                        </Row>
                    </>
                }
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    isFormInvalid={isFormInvalid()}
                    areValidationsEnabled={areValidationsEnabled}
                />
            </Form>
        </>
    );
}

export default EvaluacionFormComponent;