import { ReactElement } from "react";
import { Form, Row } from "react-bootstrap";
import { FormState } from "../../hooks/useFormState";
import { SelectOption } from "../../models/optionsFilters/Select";
import { UserForm } from "../../models/users/Users";
import FormButtons from "../form/buttons/FormButtons";
import { Input } from "../form/input/Input";
import Select from "../form/select/Select";
// import ReactSelect from 'react-select';
import SelectMultiple from "../form/select/SelectMultiple";

interface UserFormComponentProps {
    formState: FormState<UserForm>,
    submitForm: (userForm: UserForm) => void,
    onDelete?: () => void,
    onCancel: () => void,
    estadoUsuarioOptions: SelectOption<string>[],
    rolesUsuarioOptions: SelectOption<string>[],
    rolPermissionEditFields?: (isSubmitting: boolean, field?: string) => boolean
}

const UserFormComponent = ({
    formState, submitForm, onDelete, onCancel, estadoUsuarioOptions, rolesUsuarioOptions, rolPermissionEditFields
}: UserFormComponentProps): ReactElement => {

    const {
        value: userForm,
        handleFieldChange,
        areValidationsEnabled,
        setAreValidationsEnabled,
        isSubmitting,
        setSubmitting,
        isConfirmingDelete,
        setConfirmingDelete,
    } = formState;

    // const getRolUsuarioOptions = (selected?: SelectOption<string>[]): SelectOption<string>[] => {
    //     const allOptions = [
    //         { label: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
    //         { label: 'USUARIO', value: 'USUARIO' },
    //         { label: 'PLANIFICACIÓN', value: 'PLANIFICACION' },
    //         { label: 'CLIENTE', value: 'CLIENTE' },
    //         { label: 'JEFETALLER', value: 'JEFETALLER' },
    //         { label: 'OPERARIO', value: 'OPERARIO' }
    //     ];
    //     if (selected) {
    //         let optionsHidingSelected: SelectOption<string>[] = [];
    //         allOptions.forEach((option) => {
    //             let isSelected: boolean = false;
    //             selected.forEach((optionSelected) => {
    //                 if (optionSelected.value === option.value) {
    //                     isSelected = true;
    //                 }
    //             });
    //             if (!isSelected) {
    //                 optionsHidingSelected.push(option);
    //             }
    //         });
    //         return optionsHidingSelected;
    //     } else {
    //         return allOptions;
    //     }
    // }

    // const [ rolesSelectedArray, setRolesSelectedArray ] = useState<SelectOption<string>[] | undefined>(
    //     ((): SelectOption<string>[] | undefined => {
    //         if (userForm.roles) {
    //             return userForm.roles;
    //         } else {
    //             return undefined;
    //         }
    //     })()
    // );

    // const [ rolesOptions, setRolesOptions ] = useState<SelectOption<string>[]>(
    //     getRolUsuarioOptions(rolesSelectedArray)
    // );

    const handleSubmitClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setAreValidationsEnabled(true);
        if (isFormInvalid) return;
        setSubmitting(true);
        setAreValidationsEnabled(false);
        // submitForm({ ...userForm, roles: rolesSelectedArray });
        submitForm(userForm);
    }

    const handleDeleteClick = () => setConfirmingDelete(true);
    const cancelDelete = () => setConfirmingDelete(false);
    
    const isNotEditting = (): boolean => {
        if (onDelete === undefined) {
            return isSubmitting;
        } else {
            return true;
        }
    }

    const isPasswordRequired = (): boolean => {
        if (onDelete === undefined) {
            if (userForm.password) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    const isFormInvalid =
        !userForm.nombre ||
        !userForm.email ||
        !userForm.estado ||
        !userForm.roles ||
        isPasswordRequired();

    // useEffect(() => {
    //     setRolesOptions(getRolUsuarioOptions(rolesSelectedArray));
    // }, [rolesSelectedArray]);

    return (
        <>
            <Form>
                <Row>
                    <Input
                        title = "Email o identificador"
                        name = "email"
                        value = {userForm.email}
                        onChangeWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && !userForm.email}
                        invalidText = "Ingrese email del usuario"
                        disabled = {isNotEditting()}
                        placeholder = "Obligatorio"
                    />
                </Row>
                <Row>
                    <Input
                        title = "Nombre"
                        name = "nombre"
                        value = {userForm.nombre}
                        onChangeWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && !userForm.nombre}
                        invalidText = "Ingrese nombre del usuario"
                        disabled = {rolPermissionEditFields ? !rolPermissionEditFields(isSubmitting, 'nombre') : isSubmitting}
                        placeholder = "Obligatorio"
                    />
                </Row>
                <Row>
                    <Input
                        title = "Apellidos"
                        name = "apellidos"
                        value = {userForm.apellidos}
                        onChangeWithName = {handleFieldChange}
                        disabled = {rolPermissionEditFields ? !rolPermissionEditFields(isSubmitting, 'apellidos') : isSubmitting}
                    />
                </Row>
                <Row>
                    <Select
                        title = "Estado"
                        name = "estado"
                        value = {userForm.estado}
                        options = {estadoUsuarioOptions}
                        onSelectWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && !userForm.estado}
                        invalidText = "Ingrese el estado del usuario"
                        disabled = {rolPermissionEditFields ? !rolPermissionEditFields(isSubmitting, 'estado') : isSubmitting}
                    />
                </Row>
                <Row>
                    <SelectMultiple
                        title = "Roles"
                        options = {rolesUsuarioOptions}
                        defaultValues = {userForm.roles}
                        onFieldChange = {(value?: SelectOption<string>[]) => handleFieldChange('roles', value)}
                        isDisabled = {rolPermissionEditFields ? !rolPermissionEditFields(isSubmitting, 'roles') : isSubmitting}
                        placeholder = "Obligatorio"
                    />
                    {/* <div className = "form-group">
                        Roles
                    </div>
                    <ReactSelect 
                        defaultValue = {rolesSelectedArray}
                        options = {rolesOptions}
                        onChange = {setRolesSelectedArray}
                        isDisabled = {rolPermissionEditFields ? !rolPermissionEditFields(isSubmitting, 'roles') : isSubmitting}
                        isMulti
                        hideSelectedOptions
                        placeholder = "Obligatorio"
                    /> */}
                </Row>
                <Row>
                    <Input
                        title = "Contraseña"
                        name = "password"
                        type="password"
                        value = {userForm.password}
                        onChangeWithName = {handleFieldChange}
                        invalid = {areValidationsEnabled && isPasswordRequired()}
                        invalidText = "Ingrese contraseña del usuario"
                        placeholder = {onDelete ? "Escribir sólo si cambia la contraseña" : "Obligatorio"}
                        disabled = {rolPermissionEditFields ? !rolPermissionEditFields(isSubmitting) : isSubmitting}
                    />
                </Row>
                <FormButtons
                    submitButtonContent="Guardar"
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={onCancel}
                    isConfirmingDelete={isConfirmingDelete}
                    onDeleteClick={onDelete ? handleDeleteClick : undefined}
                    onDeleteConfirm={onDelete}
                    onCancelDelete={cancelDelete}
                    deleteButtonContent="Eliminar"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    isNotDeleteable={rolPermissionEditFields && !rolPermissionEditFields(isSubmitting, 'eliminar')}
                    isFormInvalid = {isFormInvalid}
                    areValidationsEnabled = {areValidationsEnabled}
                />
            </Form>
        </>
    );
}

export default UserFormComponent;