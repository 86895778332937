import axios from "axios";
import cogoToast from "cogo-toast";
import jwt from 'jsonwebtoken';
import JWT from "../models/users/JWT";

export function requestInterceptor(savePreviousLocationAndRedirectToLogin: () => void): void {
    axios.interceptors.request.use((request) => {
        const jsonwebtoken = localStorage.getItem('jwt');
        if (request?.url !== 'api/auth/login') {
            if (jsonwebtoken && (jwt.decode(jsonwebtoken) as JWT).exp > Date.now()) {
                request.headers = {
                    ...request.headers,
                    jwt: jsonwebtoken
                };
                // return request;
            } else {
                cogoToast.error('No autorizado');
                savePreviousLocationAndRedirectToLogin();
                throw new axios.Cancel();
            }
        }
        return request;
    });
}

export function responseInterceptor(): void {
    axios.interceptors.response.use((response) => {
        if (response.data.message) {
            cogoToast.error(response.data.message);  
            throw new axios.Cancel();          
        }
        return response;
    }, (error) => {
        if (error.response.status === 401) {
            cogoToast.error('No autorizado');
        }
        if (error.response.status === 501) {
            cogoToast.error('No implementado');
            // throw new Error('No implementado');
        } else {
            cogoToast.error(error.response?.data?.message || 'Error');
            // throw new Error(error.response?.data?.message || 'Error.');
        }
        // redirectToLogin();
        throw new axios.Cancel();
    });
}