import { ReactElement, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";
import useEstadosOrdenProceso from "../../hooks/estatusGeneral/useEstadosOrdenProceso";
import useTipoOrdenProceso from "../../hooks/estatusGeneral/useTipoOrdenProceso";
import useTipoProceso from "../../hooks/estatusGeneral/useTipoProceso";
import { SubComponenteReparado } from "../../models/componentes/Componente";
import { OrdenesProcesoFilters, OrdenProceso, OrdenProcesoTabla } from "../../models/estatusGeneral/OrdenProceso";
import { bodyCardsFields } from "../../utils/bodyCards";
import { datosSubComponenteReparadoArray } from "../../utils/componentes";
import { getEmptyOrdenesProcesoFilters } from "../../utils/estatusGeneral";
import OrdenesProcesosFiltersComponent from "../estatusGeneral/OrdenesProcesoFiltersComponent";
import OrdenesProcesoList from "../estatusGeneral/OrdenesProcesoList";
import GuiaDespachoRecepcion from "../files/GuiaDespachoRecepcion";
import InformeFallasRecepcion from "../files/InformeFallasRecepcion";
import DocumentosReparacion from "../files/DocumentosReparacion";
import RegistroRecepcion from "../files/RegistroRecepcion";
import ConfirmDeletionModal from "../form/ConfirmDeletionModal";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import cogoToast from "cogo-toast";
import InformeEvaluacionQR from "../evaluaciones/InformeEvaluacionQR";
import DocumentosEvaluacion from "../files/DocumentosEvaluacion";
import CertificadoCalibracionEquiposHerramientas from "../files/CertificadoCalibracionEquiposHerramientas";
import CertificadoPersonal from "../files/CertificadoPersonal";
// import SubComponenteReparadoFormComponent from "./SubComponenteReparadoFormComponent";

interface SubComponenteReparadoComponentProps {
    cliente?: string,
    subComponenteReparado?: SubComponenteReparado,
    userPermission: boolean,
    isRolCliente: boolean,
    postSubComponenteReparado?: (subComponenteReparado: string, setShowAndSubmittingToFalse: () => void) => void,
    deleteSubComponenteReparado?: (codigoOP: string, handleClose: () => void) => void
}

const SubComponenteReparadoComponent = ({
    subComponenteReparado, userPermission, isRolCliente, postSubComponenteReparado, deleteSubComponenteReparado, cliente
}: SubComponenteReparadoComponentProps): ReactElement => {

    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const [ ordenesProcesoFilters, setOrdenesProcesoFilters ] = useState<OrdenesProcesoFilters>(getEmptyOrdenesProcesoFilters('qr'));
    const estadosOrdenProcesoOptions = useEstadosOrdenProceso('qr');
    const centroCostoOptions = useCentroCostoProceso();
    const tipoOrdenProcesoOptions = useTipoOrdenProceso();
    const tipoProcesoOptions = useTipoProceso(true);
    const datosSubComponenteReparadoHTML = subComponenteReparado ? bodyCardsFields(datosSubComponenteReparadoArray(subComponenteReparado)) : undefined;

    const [ isBusy, setIsBusy ] = useState<boolean>(false);
    const changeIsBusy = (state: boolean) => setIsBusy(state);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    /*
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const setIsLoadingToFalse = () => {
        setIsLoading(false);
    }

    
    const getIsLoading = (): boolean => {
        if (rolPermission) {
            return isLoading;
        } else {
            return false;
        }
    }
    */
   
    const changeOrdenesProcesoFilters = (ordenesProcesoFilters: OrdenesProcesoFilters) => {
        setOrdenesProcesoFilters(ordenesProcesoFilters);
    }

    const deleteSubComponente = () => {
        // setIsLoading(true);
        if (subComponenteReparado && deleteSubComponenteReparado) {
            deleteSubComponenteReparado(subComponenteReparado.codigoOP, handleClose);
        }
    };

    const addOrdenProcesoToQR = (ordenProceso: OrdenProcesoTabla) => {
        if (postSubComponenteReparado) {
            postSubComponenteReparado(ordenProceso.codigoOP, handleClose);
        }
    }

    const isSubOP = (): boolean => {
        if (subComponenteReparado && (subComponenteReparado.codigoOP.lastIndexOf('-') !== subComponenteReparado.codigoOP.indexOf('-'))) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (subComponenteReparado) {
            getOrdenProceso(subComponenteReparado?.codigoOP)
                .then((ordenProcesoResponse) => {
                    if (ordenProcesoResponse.data) {
                        setOrdenProceso(ordenProcesoResponse.data);
                    }
                }).catch(() => {
                    cogoToast.error('No fue posible obtener la orden de proceso');
                })
        }
    }, [subComponenteReparado]);

    return (
        <>
            {
                (!subComponenteReparado && !cliente && userPermission) ? 
                    <Card className = "cards-container">
                        <Card.Header className = "cards-header">
                            <div className = "body-title">Nuevo sub-componente</div>
                            <div className = "buttons-group">
                                <Button onClick = {handleShow}>Agregar</Button>
                            </div>
                        </Card.Header>
                    </Card>
                :
                subComponenteReparado && cliente && (
                    <>
                        <Card className = "cards-container">
                            <Card.Header className = "cards-body">
                                <div className = "body-title">Sub-componente</div>
                                {!isRolCliente && !isSubOP() && (
                                    <div>
                                        <ConfirmDeletionModal
                                            text = '¿Desea eliminar el sub-componente?'
                                            onDelete = {deleteSubComponente}
                                        />
                                    </div>
                                )}
                            </Card.Header>
                            <Card.Body>
                                {datosSubComponenteReparadoHTML}
                            </Card.Body>
                        </Card>
                        {subComponenteReparado.fechaRecepcion && 
                            <Card className = "cards-container">
                                <Card.Header className = "cards-body">
                                    <div className = "body-subtitle">Recepción</div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <u>Fecha</u>
                                            <h3>{subComponenteReparado.fechaRecepcion}</h3>
                                        </Col>
                                        {subComponenteReparado.codigoSolped && (
                                            <Col>
                                                <u>SOLPED</u>
                                                <h3>{subComponenteReparado.codigoSolped}</h3>
                                            </Col>
                                        )}
                                        <Col>
                                            <u>N° Guía Despacho</u>
                                            <h3>{subComponenteReparado.numeroGuiaDespacho}</h3>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <GuiaDespachoRecepcion
                                    isComponenteQR
                                    codigoOP = {subComponenteReparado.codigoOP}
                                    userPermission = {userPermission}
                                    isBusy = {isBusy}
                                    changeBusyState = {(state: boolean) => setIsBusy(state)}
                                    guiaDespachoDoc = {subComponenteReparado.guiaDespachoRecepcionDoc}
                                />
                                <RegistroRecepcion
                                    isComponenteQR
                                    codigoOP = {subComponenteReparado.codigoOP}
                                    userPermission = {userPermission}
                                    isBusy = {isBusy}
                                    changeBusyState = {(state: boolean) => setIsBusy(state)}
                                    registroDoc = {subComponenteReparado.registroDoc}
                                />
                                <InformeFallasRecepcion
                                    isComponenteQR
                                    codigoOP = {subComponenteReparado.codigoOP}
                                    userPermission = {userPermission}
                                    isBusy = {isBusy}
                                    changeBusyState = {(state: boolean) => setIsBusy(state)}
                                    informeFallasRecepcionDoc = {subComponenteReparado.informeFallasRecepcionDoc}
                                />
                            </Card>
                        }
                        {subComponenteReparado.fechaEvaluacion && 
                            <Card className = "cards-container">
                                <Card.Header className = "cards-body">
                                    <div className = "body-subtitle">Evaluación</div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <u>Fecha</u>
                                            <h3>{subComponenteReparado.fechaEvaluacion}</h3>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                {ordenProceso?.desarmeEvaluacionComponente ?
                                    <InformeEvaluacionQR
                                        ordenProceso = {ordenProceso}
                                    />
                                    :
                                    <DocumentosEvaluacion
                                        isComponenteQR
                                        codigoOP = {subComponenteReparado.codigoOP}
                                        userPermission = {userPermission}
                                        isBusy = {isBusy}
                                        changeBusyState = {changeIsBusy}
                                        documentosListado = {subComponenteReparado.evaluacionDocs}
                                    />
                                }
                            </Card>
                        }
                        {subComponenteReparado.fechaReparacion && 
                            <Card className = "cards-container">
                                <Card.Header className = "cards-body">
                                    <div className = "body-subtitle">
                                        Reparación
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <u>Fecha</u>
                                            <h3>{subComponenteReparado.fechaReparacion}</h3>
                                        </Col>
                                        {subComponenteReparado.ordenCompra && (
                                            <Col>
                                                <u>Orden de compra</u>
                                                <h3>{subComponenteReparado.ordenCompra}</h3>
                                            </Col>
                                        )}
                                    </Row>
                                </Card.Body>
                                <DocumentosReparacion
                                    isComponenteQR
                                    codigoOP = {subComponenteReparado.codigoOP}
                                    userPermission = {userPermission}
                                    isBusy = {isBusy}
                                    changeBusyState = {changeIsBusy}
                                    documentosListado = {subComponenteReparado.reparacionDocs}
                                />
                            </Card>
                        }
                        <CertificadoCalibracionEquiposHerramientas
                            codigoOP = {subComponenteReparado.codigoOP}
                            userPermission = {userPermission}
                            isRolCliente = {isRolCliente}
                            isBusy = {isBusy}
                            changeBusyState = {changeIsBusy}
                            certificadoCalibracionEquiposHerramientasDoc = {subComponenteReparado.certificadoCalibracionEquiposHerramientasDoc}
                        />
                        <CertificadoPersonal
                            codigoOP = {subComponenteReparado.codigoOP}
                            userPermission = {userPermission}
                            isRolCliente = {isRolCliente}
                            isBusy = {isBusy}
                            changeBusyState = {changeIsBusy}
                            certificadoPersonalDoc = {subComponenteReparado.certificadoPersonalDoc}
                        />
                    </>
                )
            }
            {show && estadosOrdenProcesoOptions && (
                <Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Sub-componente reparado</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <OrdenesProcesosFiltersComponent
                            ordenesProcesoFilters = {ordenesProcesoFilters}
                            estadosOrdenProcesoOptions = {estadosOrdenProcesoOptions}
                            centroCostoOptions = {centroCostoOptions}
                            tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                            tipoProcesoOptions = {tipoProcesoOptions}
                            onFiltersChange = {setOrdenesProcesoFilters}
                            componenteQR
                        />
                        <OrdenesProcesoList
                            ordenesProcesoFilters = {ordenesProcesoFilters}
                            setOrdenesProcesoFilters = {changeOrdenesProcesoFilters}
                            addOrdenProcesoToQR = {addOrdenProcesoToQR}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default SubComponenteReparadoComponent;