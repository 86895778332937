import { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { FinalCheckList } from "../../models/EvaluacionesReparaciones";

interface Props {
    finalCheckList: FinalCheckList
}

const EvaluacionReparacionComponenteFinalCheckList = ({ finalCheckList }: Props): ReactElement => {
    
    return (
        
        <Table bordered size = 'sm' className = "zebra">
            <tr>
                <th>{finalCheckList.name}</th>
                <th><div className = "centerTextCell">¿Aplica?</div></th>
            </tr>
            {finalCheckList.checkList.map((check) => (
                <tr>
                    <td>{check.name}</td>
                    <td className = "centerTextCell">
                        {check.check ? 'SÍ' : 'NO'}
                        {/* <input
                                className = "largeCheckBox"
                                type = "checkbox"
                                checked = {check.check}
                                disabled
                            /> */}
                    </td>
                </tr>
            ))}
        </Table>
    );
}

export default EvaluacionReparacionComponenteFinalCheckList;