import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState, useContext } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import RecepcionDetails from "../../components/recepciones/RecepcionDetails";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { Recepcion } from "../../models/recepciones/Recepcion";
// import { Archivo } from "../../models/Archivos";
// import { getImagenesRegistroRecepcion } from "../../api/DocumentosApi";
import { getRecepcionCodigoOP } from "../../api/RecepcionesApi";
import AuthContext from "../../contexts/AuthContext";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import { getUserPermissionFunction } from "../../utils/users";

const RecepcionPage = (): ReactElement => {
    
    const [ recepcion, setRecepcion ] = useState<Recepcion>();
    // const [images, setImages] = useState<Archivo[]>();
    //const { codigoOP, _id } = useParams<{ codigoOP: string, _id: string }>();
    const { codigoOP } = useParams<{ codigoOP: string }>();
    const breadcrumb = useBreadcrumb();
    const { userRoles } = useContext(AuthContext);
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();

    useEffect(() => {
        if (!recepcion) {
            getRecepcionCodigoOP(codigoOP)
                .then((response) => {
                    if (response.data.fechaRecepcion) {
                        setRecepcion(response.data);
                    } else {
                        // cogoToast.error(response.data);
                        cogoToast.error('No fue posible obtener la recepción');
                    }
                    setIsloading(false);
                })
                .catch(() => {
                    // cogoToast.error(error.message);
                    cogoToast.error('No fue posible obtener la recepción');
                    setIsloading(false);
                });
        }
        getOrdenProceso(codigoOP)
            .then((response) => {
                if (response.data.codigoOP) {
                    setOrdenProceso(response.data);
                } else {
                    cogoToast.error('No fue posible obtener la orden de proceso');
                }
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener la orden de proceso');
            })
        /* else if (_id && !recepcion) {
            getRecepcion(_id)
                .then((response) => {
                    setRecepcion(response.data);
                })
                .catch((error) => {
                    cogoToast.error(error.message);
                })
        }
        if (recepcion?.numeroGuiaDespacho && !guiaDespacho) {
            getGuiaDespachoRecepcion(recepcion.numeroGuiaDespacho)
                    .then((response) => {
                        if (response.data.nombre) {
                            setGuiaDespacho(response.data);
                        }
                    })
                    .catch((error) => {
                        cogoToast.error(error.message);
                    });
        }
        */
    }, [codigoOP]);

    useEffect(() => {
        if (codigoOP) {
            breadcrumb.setItems([
                {
                    label: 'Estatus General',
                    href: '/ordenesProceso',
                },
                {
                    label: codigoOP,
                    href: `/ordenesProceso/${codigoOP}`,
                },
                {
                    label: recepcion?.numeroGuiaDespacho ? recepcion.numeroGuiaDespacho : 'Recepción',
                    inactive: true,
                }
            ]);
        } /*else if (recepcion) {
            breadcrumb.setItems([
                {
                    label: 'Recepciones',
                    href: '/recepciones'
                },
                {
                    label: recepcion?.numeroGuiaDespacho ?
                        recepcion.numeroGuiaDespacho : recepcion?.ordenesProceso[0].ordenProceso,
                    inactive: true,
                }
            ]);
        }*/
    }, [codigoOP, recepcion]);

    const handleRecepcionChange = (recepcion: Recepcion): void => {
        setRecepcion(recepcion);
    }

    /*
    const handleImagesChange = (images: Archivo[]): void => {
        setImages(images);
    }
    */

    if (recepcion && ordenProceso) {
        return (
            <RecepcionDetails
                recepcion = {recepcion}
                onRecepcionChange = {handleRecepcionChange}
                ordenProceso = {ordenProceso}
                // images = {images}
                // onImagesChange = {handleImagesChange}
                userPermission = {getUserPermissionFunction(userRoles)({ roles: ['ADMINISTRADOR', 'PLANIFICACION', 'CALIDAD'] })}
            />
        );
    } else {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border" />
                    </Card.Body>
                </Card>
            );
        } else {
            return <p className = "text-center">No fue posible cargar la recepcion</p>;
        }
    }
}

export default RecepcionPage;