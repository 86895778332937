import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react"
import { Spinner, Table } from "react-bootstrap";
import { getUsers } from "../../api/UsersApi";
import { UserSelection, UsersFilters, UserTabla } from "../../models/users/Users"
import PaginationComponent from "../form/table/PaginationComponent";

interface Props {
    usersFilters: UsersFilters,
    changeUsersFilters: (filters: UsersFilters) => void,
    usersSelected: UserTabla[],
    changeUsersSelected: (newUsersSelected: UserTabla[]) => void
}

const UsersCheckList = ({ usersFilters, changeUsersFilters: setUsersFilters, usersSelected, changeUsersSelected }: Props): ReactElement => {

    const [ usersSelection, setUsersSelection ] = useState<UserSelection[]>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ totalPages, setTotalPages ] = useState<number>();

    const onChangeUserSelectionCheck = (index: number) => {
        const newUsersSelection = usersSelection;
        if (newUsersSelection) {
            newUsersSelection[index].isChecked = !newUsersSelection[index].isChecked;
            if (newUsersSelection[index].isChecked) {
                changeUsersSelected([...usersSelected, newUsersSelection[index].user]);
            } else {
                let newUsersSelected: UserTabla[] = [];
                usersSelected.forEach((userSelected) => {
                    if (newUsersSelection[index].user.email !== userSelected.email) {
                        newUsersSelected.push(userSelected);
                    }
                });
                changeUsersSelected(newUsersSelected);
            }
        }
    }

    useEffect(() => {
        getUsers(usersFilters)
            .then((response) => {
                // console.log(usersSelected)
                if (response.data.users) {
                    const newUsersSelection: UserSelection[] = [];
                    response.data.users.forEach((user) => {
                        let isChecked = false;
                        for (let i = 0; i < usersSelected.length; i++) {
                            if (usersSelected[i].email === user.email) {
                                isChecked = true;
                                break;
                            }
                        };
                        newUsersSelection.push({ user: user, isChecked: isChecked });
                    });
                    setUsersSelection(newUsersSelection);
                    setTotalPages(response.data.totalPages);
                } else {
                    cogoToast.error('No fue posible obtener los usuarios');
                }
                setIsLoading(false);
            })
            .catch(() => {
                cogoToast.error('No fue posible obtener los usuarios');
                setIsLoading(false);
            })
    }, [usersFilters]);

    if (!usersSelection) {
        if (isLoading) {
            return (
                <div className = "text-center">
                    <Spinner animation = "border"/>
                </div>
            );
        } else {
            return <p className = "text-center">No fue posible cargar los usuarios</p>;
        }
    } else {
        return (
            <>
                <div className = "scrollable-table-container">
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Apellidos</th>
                                <th>Rol</th>
                                <th>Email / ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersSelection.map((user, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <input
                                                className = "largeCheckBox"
                                                type = "checkbox"
                                                checked = {user.isChecked}
                                                onChange = {() => onChangeUserSelectionCheck(index)}
                                            />
                                        </td>
                                        <td>{user.user.nombre}</td>
                                        <td>{user.user.apellidos}</td>
                                        <td>{user.user.roles}</td>
                                        <td>{user.user.email}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                {totalPages && (
                    <PaginationComponent
                        pageCount={totalPages}
                        activePage={usersFilters.page}
                        onChange={(page) => setUsersFilters({ ...usersFilters, page: page })}
                    />
                )}
            </>
        );
    }
}

export default UsersCheckList;